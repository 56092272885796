<template>
  <div class="instruction">
    <b-media vertical-align="center">
      <template #aside>
        <b-icon icon="lightbulb-fill" style="color:black"></b-icon>
      </template>
      <span v-html="instruction"></span>
    </b-media>
  </div>
</template>

<script>
export default {
  props: [
    "instruction",
  ]
}
</script>

<style>
.instruction {
  background-color: rgba(255, 255, 255, .5);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  max-width: max-content;
  border-radius: 3px;
}
</style>