<template>
  <div>
    <div align="center" style="padding:20px">
      <h1>{{ $t('GetStarted.header') }}</h1>

      <br>

      <anna-introduction></anna-introduction>

      <br>

      <b-card-group deck>

        <b-card class="adventureCard" :title="$t('GetStarted.secretSocietyHeader')" :img-src="path_secretSociety"
          img-alt="Image" img-top>
          <b-card-text>
            <p>{{ $t('GetStarted.secretSocietyText') }}</p>
            <p style="font-style:italic">{{ $t('GetStarted.secretSocietyTopics') }}</p>
            <b-button class="b-home" @click="startSecretSociety">{{ $t('GetStarted.buttonText') }} <b-badge
                variant="light">Free</b-badge></b-button>
          </b-card-text>
        </b-card>

        <b-card class="adventureCard" :title="$t('GetStarted.treasureHuntHeader')" :img-src="path_TreasureHunt"
          img-alt="Image" img-top>
          <b-card-text>
            <p>{{ $t('GetStarted.treasureHuntText') }}</p>
            <p style="font-style:italic">{{ $t('GetStarted.treasureHuntTopics') }}</p>
            <b-button class="b-home" @click="startTreasureHunt">{{ $t('GetStarted.buttonText') }}</b-button>
          </b-card-text>
        </b-card>

        <b-card class="adventureCard" :title="$t('GetStarted.pirateBayHeader')" :img-src="path_PirateBay" img-alt="Image"
          img-top>
          <b-card-text>
            <p>{{ $t('GetStarted.pirateBayText') }}</p>
            <p style="font-style:italic">{{ $t('GetStarted.pirateBayTopics') }}</p>
            <b-button class="b-home" @click="startPirateBay">{{ $t('GetStarted.buttonText') }}</b-button>
          </b-card-text>
        </b-card>

      </b-card-group>

      <br><br>

      <b-card-group deck>

        <b-card :img-src="path_questionMark" img-alt="Image" img-top class="adventureCard newAdventuresCard">
          <b-card-text class="center-vertically">
            <span v-html="$t('GetStarted.moreAdventuresText')"></span>
          </b-card-text>
        </b-card>

        <b-card class="placeholderCard"></b-card>
        <b-card class="placeholderCard"></b-card>

      </b-card-group>
    </div>

    <pretty-dialog></pretty-dialog>


  </div>
</template>

<script>
import { setTreasureHuntPasswordTo, setPirateBayPasswordTo } from '@/password.js';
import { validateToken } from '@/tokenHandler.js';

import AnnaIntroduction from '@/components/AnnaIntroduction.vue'
import PrettyDialog from '@/components/PrettyDialog.vue';
import dialog from '@/dialog.js';

export default {
  components: {
    PrettyDialog,
    AnnaIntroduction,
  },
  data() {
    return {
      path_secretSociety: require("@/assets/backgrounds/secret-society.jpg"),
      path_TreasureHunt: require("@/assets/backgrounds/treasure-hunt.jpg"),
      path_PirateBay: require("@/assets/backgrounds/pirate-bay.jpg"),
      path_questionMark: require("@/assets/backgrounds/question-mark.jpg"),
      answer: "",
      confirmed: false,
    }
  },
  methods: {
    startSecretSociety() {
      this.$router.push('/secret-society');
    },

    async startTreasureHunt() {
      let password = await dialog
        .html()
        .title(this.$t('GetStarted.password.title'))
        .okText('OK')
        .cancelText(this.$t('GetStarted.password.cancel'))
        .inputType('password')
        .prompt(this.$t('GetStarted.password.body'))

      if (password) {
        validateToken(password, "treasure-hunt").then(validationResult => {
          if (validationResult == "validToken") {
            setTreasureHuntPasswordTo(true);
            this.$router.push('/treasure-hunt');
          }
        })
      }
    },
    async startPirateBay() {

      let password = await dialog
        .html()
        .title(this.$t('GetStarted.password.title'))
        .okText('OK')
        .cancelText(this.$t('GetStarted.password.cancel'))
        .inputType('password')
        .prompt(this.$t('GetStarted.password.body'))

      if (password) {
        try {
          let validationResult = await validateToken(password, "pirate-bay");

          if (validationResult === "validToken") {
            await dialog
              .html()
              .title(this.$t('GetStarted.generatedImagesHeader'))
              .alert(this.$t('GetStarted.generatedImagesBody'));

            setPirateBayPasswordTo(true);
            this.$router.push('/pirate-bay');
          }
        } catch (error) {
          console.error("Error validating token:", error);
          // Handle error
        }
      }
    },
  },
}
</script>

<style scoped>
.adventureCard:hover {
  box-shadow: 0 0 15px grey;
  transform: scale(1.01);
}

.newAdventuresCard {
  background: transparent;
  border: dashed grey 1px;
}

.placeholderCard {
  background: transparent;
  border: none;
}

.center-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* Make sure the b-card-text takes the full height */
  margin: 0;
}
</style>