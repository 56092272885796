<template>
  <div>
    <br><br><br><br><br>
    <h3>{{ $t('TreasureHunt.Chapter2.title') }}</h3>
    <h1>{{ $t('TreasureHunt.Chapter2.heading') }}</h1>

    <br>

    <img :src="imgPath_tresure_hunt_ch2_intro" alt="treasure-hunt-ch2_intro" width="85%">

    <br><br>


    <h4>{{ $t('TreasureHunt.Chapter2.dataInsightsHeader') }}</h4>
    <task-instruction :instruction="$t('TreasureHunt.Chapter2.dataInsightsDescription')"></task-instruction>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-1>{{
        $t('TreasureHunt.Chapter2.getNumberOfDroneImages')
      }}</b-button>
      <b-collapse id="collapse-ch2-1" class="mt-2 collapse-white">
        <span>{{ $t('TreasureHunt.Chapter2.numberOfDroneImages') }}</span>
      </b-collapse>
    </div>

    <br>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-2>{{
        $t('TreasureHunt.Chapter2.showSampleOfDroneImages')
      }}</b-button>
      <b-collapse id="collapse-ch2-2" class="mt-2 collapse-white">
        <p>{{ $t('TreasureHunt.Chapter2.sampleOfDroneImages') }}</p>
        <img alt="plot" src="@/assets/treasure_hunt/ch2_imageSample.png" height="300">
      </b-collapse>
    </div>

    <br>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-3>{{
        $t('TreasureHunt.Chapter2.showTimestampsOfDroneImages')
      }}</b-button>
      <b-collapse id="collapse-ch2-3" class="mt-2 collapse-white">
        <p>{{ $t('TreasureHunt.Chapter2.timestampsOfDroneImages') }}</p>
        <img alt="plot" src="@/assets/treasure_hunt/ch2_timestamps.png" height="400">
      </b-collapse>
    </div>

    <br>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-4>{{
        $t('TreasureHunt.Chapter2.showAllExistingPlanets')
      }}</b-button>
      <b-collapse id="collapse-ch2-4" class="mt-2 collapse-white">
        <p>{{ $t('TreasureHunt.Chapter2.allExistingPlanets') }}</p>
        <img alt="plot" src="@/assets/treasure_hunt/ch2_planets.png" height="300">
      </b-collapse>
    </div>

    <br>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-5>{{ $t('TreasureHunt.Chapter2.showPlanetLocations')
      }}</b-button>
      <b-collapse id="collapse-ch2-5" class="mt-2 collapse-white">
        <p>{{ $t('TreasureHunt.Chapter2.planetLocations') }}</p>
        <img alt="plot" src="@/assets/treasure_hunt/ch2_planetLocations.png" height="300">
        <p>{{ $t('TreasureHunt.Chapter2.droneRoute') }}</p>
      </b-collapse>
    </div>

    <hr>

    <h4>{{ $t('TreasureHunt.Chapter2.modelTrainingTitle') }}</h4>

    <task-instruction :instruction="$t('TreasureHunt.Chapter2.modelTrainingDescription')"></task-instruction>

    <p v-html="$t('TreasureHunt.Chapter2.modelTrainingText')"></p>

    <div style="display:flex; flex-wrap:wrap">
      <div v-for="image in images" :key="image" style="margin:5px;">
        <span style="font-size:80%">train_{{ image }}</span>
        <br>
        <img alt="planet_image" :src="require('@/assets/treasure_hunt/ch2_train/' + image + '.jpg')" height="100"
          width="140" style="object-fit: cover; object-position: center">
        <br>
        <b-form-select style="width: 140" v-model="labels[image]" :options="options" size="sm"
          class="mt-3"></b-form-select>
      </div>
    </div>
    <p>{{ $t('TreasureHunt.Chapter2.modelTrainingStep1') }}</p>

    <div style="display:flex; gap: 10px;">
      <b-button class="treasure-hunt-button" @click="checkLabling">{{ $t('TreasureHunt.Chapter2.trainModelButton')
      }}</b-button>
      <b-button v-b-toggle.collapse-fill-labels>{{ $t('TreasureHunt.Chapter2.autoFill.button1') }}</b-button>
    </div>
    <b-collapse id="collapse-fill-labels" class="mt-2">
      <b-card>
        <p>{{ $t('TreasureHunt.Chapter2.autoFill.text') }}</p>
        <b-form-input v-model="labelFileName"
          :placeholder="$t('TreasureHunt.Chapter2.autoFill.placeholder')"></b-form-input>
        <br>
        <b-button class="treasure-hunt-button" @click="fillLabling">{{ $t('TreasureHunt.Chapter2.autoFill.button2')
        }}</b-button>
        <span style="margin-left:10px">{{ loadLabelsResponse }}</span>
      </b-card>
    </b-collapse>

    <br>

    <div v-if="trainingInProgress" style="display: flex; align-items: center; margin:15px">
      <b-spinner label="Loading..."></b-spinner>
      <span style="margin-left:10px">{{ $t('TreasureHunt.Chapter2.trainingInProgressMessage') }}</span>
    </div>

    <div v-if="!trainingInProgress && !trainingComplete">
      <br>
      <p>{{ labelsFalseFeedback }}</p>
    </div>

    <div v-if="trainingComplete">
      <br>

      <p><b>{{ $t('TreasureHunt.Chapter2.trainingCompleteMessage') }}</b><br>{{
        $t('TreasureHunt.Chapter2.trainingCompleteSaveLocation') }}: <b>planet_labels.csv</b></p>

      <hr>

      <h4>{{ $t('TreasureHunt.Chapter2.modelUsageTitle') }}</h4>
      <task-instruction :instruction="$t('TreasureHunt.Chapter2.modelUsageText')"></task-instruction>


      <b-button class="treasure-hunt-button" @click="classifyImages">{{ $t('TreasureHunt.Chapter2.classifyImagesButton')
      }}</b-button>

      <div v-if="classificationInProgress" style="display: flex; align-items: center; margin:15px">
        <b-spinner label="Loading..."></b-spinner>
        <span style="margin-left:10px">{{ $t('TreasureHunt.Chapter2.classifyingImagesText') }}</span>
      </div>

      <div v-if="classificationComplete">
        <br>

        <p><b>{{ $t('TreasureHunt.Chapter2.classificationCompleteText') }}</b></p>

        <img alt="plot" src="@/assets/treasure_hunt/ch2_classification.png" height="300">
      </div>


      <div v-if="classificationComplete">
        <hr>

        <h4>{{ $t('TreasureHunt.Chapter2.drawLinesTitle') }}</h4>
        <task-instruction :instruction="$t('TreasureHunt.Chapter2.drawLinesDescription')"></task-instruction>

        <p>{{ $t('TreasureHunt.Chapter2.drawLinesText') }}</p>
        <img alt="plot" src="@/assets/treasure_hunt/ch2_howToDrawRoutes.png" height="250">

        <br><br>

        <div>
          <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-6>{{
            $t('TreasureHunt.Chapter2.drawAllRoutesButton')
          }}</b-button>
          <b-collapse id="collapse-ch2-6" class="mt-2 collapse-white">
            <img alt="plot" src="@/assets/treasure_hunt/ch2_allRoutesOneImage.png" height="250">
            <p v-html="$t('TreasureHunt.Chapter2.allRoutesText')"></p>
          </b-collapse>
        </div>

        <br>

        <div>
          <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-7>{{
            $t('TreasureHunt.Chapter2.startPatternExplorerButton') }}</b-button>
          <b-collapse id="collapse-ch2-7" class="mt-2 collapse-white">
            <p v-html="$t('TreasureHunt.Chapter2.patternExplorerText')"></p>
            <pattern-explorer></pattern-explorer>
          </b-collapse>
        </div>

        <br>

        <div>
          <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch2-8>{{
            $t('TreasureHunt.Chapter2.startRoutesPlotterButton')
          }}</b-button>
          <b-collapse id="collapse-ch2-8" class="mt-2 collapse-white">
            <routes-plotter></routes-plotter>
          </b-collapse>
        </div>
      </div>
    </div>

    <hr>

    <p><b>{{ $t('TreasureHunt.Chapter2.inWhichCountry') }}</b></p>

    <check-solution :solution="$t('TreasureHunt.Chapter2.solutionPhrase')"
      @solution-correct="showEpilog"></check-solution>

    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="treasure-hunt-button" backgroundClass="treasure-hunt-background"></hint-group>
    </div>

    <div v-if="solutionFound">
      <img :src="imgPath_tresure_hunt_ch2_epilogue" alt="treasure-hunt-ch2_epilogue" width="85%">
      <br><br>
    </div>

  </div>
</template>

<script>
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/CheckSolution.vue';

import PatternExplorer from './PatternExplorer.vue';
import RoutesPlotter from './RoutesPlotter.vue';

export default {
  components: {
    HintGroup,
    CheckSolution,
    PatternExplorer,
    RoutesPlotter
  },
  data() {
    return {
      solutionFound: false,
      images: ["image_0", "image_1", "image_2", "image_3", "image_4", "image_5", "image_6", "image_7", "image_8", "image_9", "image_10", "image_11", "image_12", "image_13", "image_14", "image_15", "image_16", "image_17", "image_18", "image_19", "image_20"],
      // images: ["image_0"], // Testing
      labels: {
        image_0: null,
        image_1: null,
        image_2: null,
        image_3: null,
        image_4: null,
        image_5: null,
        image_6: null,
        image_7: null,
        image_8: null,
        image_9: null,
        image_10: null,
        image_11: null,
        image_12: null,
        image_13: null,
        image_14: null,
        image_15: null,
        image_16: null,
        image_17: null,
        image_18: null,
        image_19: null,
        image_20: null,
      },
      options: [
        { value: null, text: '(Select label)' },
        { value: 'green', text: 'Green' },
        { value: 'red', text: 'Red' },
        { value: 'blue', text: 'Blue' },
        { value: 'teal', text: 'Teal' },
        { value: 'grey', text: 'Grey' },
        { value: 'orange', text: 'Orange' },
        { value: 'space', text: 'Space' },
      ],
      solution: {
        image_0: 'space',
        image_1: 'red',
        image_2: 'space',
        image_3: 'orange',
        image_4: 'teal',
        image_5: 'orange',
        image_6: 'grey',
        image_7: 'blue',
        image_8: 'grey',
        image_9: 'space',
        image_10: 'teal',
        image_11: 'blue',
        image_12: 'green',
        image_13: 'blue',
        image_14: 'teal',
        image_15: 'green',
        image_16: 'green',
        image_17: 'red',
        image_18: 'grey',
        image_19: 'red',
        image_20: 'orange'
      },
      allLabelsCorrect: false,
      labelsFalseFeedback: "",
      trainingInProgress: false,
      trainingComplete: false,
      classificationInProgress: false,
      classificationComplete: false,
      labelFileName: "",
      loadLabelsResponse: "",
    }
  },
  computed: {
    imgPath_tresure_hunt_ch2_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch2-intro_de.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch2-intro_en.png');
      } else {
        console.warn("No image found for treasure hunt ch2 intro.")
        return null
      }
    },
    imgPath_tresure_hunt_ch2_epilogue() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch2-epilogue_de.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch2-epilogue_en.png');
      } else {
        console.warn("No image found for treasure hunt ch2 epilogue.")
        return null
      }
    },
    hints() {
      return this.$t("TreasureHunt.Chapter2.hints")
    }
  },
  methods: {
    checkLabling() {
      this.trainingInProgress = true;
      this.trainingComplete = false;
      let results = []

      for (var i = 0; i < this.images.length; i++) {
        if (this.labels[this.images[i]] == this.solution[this.images[i]]) {
          results.push(true)
        } else {
          results.push(false)
          this.labelsFalseFeedback = this.$t('TreasureHunt.Chapter2.accuracyLowFeedback') + this.images[i] + this.$t('TreasureHunt.Chapter2.accuracyLowFeedback2')
          break
        }
      }

      let num_of_correct_labels = results.reduce((a, b) => a + b, 0)
      if (num_of_correct_labels == this.images.length) {
        this.allLabelsCorrect = true;
        this.labelsFalseFeedback = ""

        setTimeout(() => {
          this.trainingInProgress = false;
          this.trainingComplete = true;
        }, 2000);
      } else {
        setTimeout(() => {
          this.trainingInProgress = false;
        }, 2000);
      }
    },
    fillLabling() {
      if (this.labelFileName == "planet_labels.csv") {
        this.labels = JSON.parse(JSON.stringify(this.solution));
        this.loadLabelsResponse = this.$t('TreasureHunt.Chapter2.autoFill.responseSuccess')
      } else {
        this.loadLabelsResponse = this.$t('TreasureHunt.Chapter2.autoFill.responseFailure')
      }

      setTimeout(() => {
        this.loadLabelsResponse = "";
      }, 2000);
    },
    classifyImages() {
      this.classificationInProgress = true;

      setTimeout(() => {
        this.classificationInProgress = false;
        this.classificationComplete = true;
      }, 2000);
    },
    openColab() {
      window.open("https://colab.research.google.com/drive/1eU75BON6Dmra5xZh_gBkx7VFMCkMJewd#scrollTo=zmUsrXmHPf-U&forceEdit=true&sandboxMode=true");
    },
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true
        this.$emit("solution-correct", true);
      }
    },
  },
}
</script>