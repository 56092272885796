<template>
  <div>
    <div align="center" style="padding-top:15px; padding-bottom: 40px">
      <img alt="Compass" src="@/assets/treasure_hunt/compass.png" height="150">
      <h1>{{ $t('TreasureHunt.intro.header') }}</h1>

      <introduction-wrapper></introduction-wrapper>

      <br><br>

      <img :src="imgPath_tresure_hunt_intro" alt="treasure-hunt_intro" width="100%">

    </div>

    <chapter-one :isPreview="isPreview" @solution-correct="updateChapterTwoVisibility"></chapter-one>
    <chapter-two v-if="showChapterTwo" @solution-correct="updateChapterThreeVisibility">
    </chapter-two>
    <chapter-three v-if="showChapterThree"></chapter-three>
  </div>
</template>

<script>
import IntroductionWrapper from '@/components/IntroductionWrapper.vue'

import ChapterOne from '@/components/TreasureHunt/ChapterOne/ChapterOne.vue';
import ChapterTwo from '@/components/TreasureHunt/ChapterTwo/ChapterTwo.vue';
import ChapterThree from '@/components/TreasureHunt/ChapterThree/ChapterThree.vue';

export default {
  components: {
    IntroductionWrapper,
    ChapterOne,
    ChapterTwo,
    ChapterThree,
  },
  props: ["isPreview"],
  data() {
    return {
      showChapterTwo: false,
      showChapterThree: false,
    }
  },

  computed: {
    imgPath_tresure_hunt_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-intro_de.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-intro_en.png');
      } else {
        console.warn("No image found for treasure hunt intro.")
        return null
      }
    },
  },

  methods: {
    updateChapterTwoVisibility() {
      if (!this.isPreview) {
        this.showChapterTwo = true;
      }
    },
    updateChapterThreeVisibility() {
      if (!this.isPreview) {
        this.showChapterThree = true;
      }
    },
  },
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>