import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import router from './router.js'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'

import VueConfetti from 'vue-confetti';

import axios from 'axios';
Vue.prototype.$axios = axios;

import '@/components'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter)
Vue.use(VueConfetti);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
