<template>
  <div align="center" style="padding-top:15px; padding-bottom: 40px">
    <img alt="Pirate" src="@/assets/pirate_bay/pirate_symbol_1.png" height=200>
    <h1>{{ $t('PirateBay.intro.header') }}</h1>

    <introduction-wrapper></introduction-wrapper>

    <br>

    <img :src="imgPath_pirate_bay_intro" alt="pirate-bay_intro" width="100%">

  </div>
</template>

<script>
import IntroductionWrapper from '@/components/IntroductionWrapper.vue'

export default {
  components: {
    IntroductionWrapper,
  },

  computed: {
    imgPath_pirate_bay_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/intro.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/intro.png');
      } else {
        console.warn("No image found for Pirate Bay intro.")
        return null
      }
    },
  }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>