<template>
  <div>
    <h1>Admin</h1>
    <add-tokens></add-tokens>
  </div>
</template>

<script>
import AddTokens from './AddTokens.vue';

export default {
  components: {
    AddTokens,
  }
}

</script>
