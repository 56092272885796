<template>
  <div>
    <h3>{{ $t('TreasureHunt.Chapter1.chapter1Title') }}</h3>
    <h1>{{ $t('TreasureHunt.Chapter1.header') }}</h1>

    <br>

    <p v-html="$t('TreasureHunt.Chapter1.clueDescription')"></p>
    <!-- <p v-html="$t('TreasureHunt.Chapter1.clueInstructions')"></p> -->

    <hr>

    <h4>{{ $t('TreasureHunt.Chapter1.datainsightsHeader') }}</h4>
    <task-instruction :instruction="$t('TreasureHunt.Chapter1.datainsightsInstruction')"></task-instruction>


    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch1-1>{{ $t('TreasureHunt.Chapter1.showDataRows')
      }}</b-button>
      <b-collapse id="collapse-ch1-1" class="mt-2 collapse-white">
        <img alt="plot" src="@/assets/treasure_hunt/ch1_head.png" height="300">
      </b-collapse>
    </div>

    <br>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch1-2>{{ $t('TreasureHunt.Chapter1.plotCompleteDataset')
      }}</b-button>
      <b-collapse id="collapse-ch1-2" class="mt-2 collapse-white">
        <img alt="plot" src="@/assets/treasure_hunt/ch1_plot.png" height="300">
      </b-collapse>
    </div>

    <br>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch1-3>{{ $t('TreasureHunt.Chapter1.showDatasetStatistics')
      }}</b-button>
      <b-collapse id="collapse-ch1-3" class="mt-2 collapse-white">
        <img alt="plot" src="@/assets/treasure_hunt/ch1_describe.png" height="300">
        <br><br>
        <ul>
          <li v-html="$t('TreasureHunt.Chapter1.statisticCount')"></li>
          <li v-html="$t('TreasureHunt.Chapter1.statisticMean')"></li>
          <li v-html="$t('TreasureHunt.Chapter1.statisticStd')"></li>
          <li v-html="$t('TreasureHunt.Chapter1.statisticMin')"></li>
          <li v-html="$t('TreasureHunt.Chapter1.statistic25')"></li>
          <li v-html="$t('TreasureHunt.Chapter1.statistic50')"></li>
          <li v-html="$t('TreasureHunt.Chapter1.statistic75')"></li>
          <li v-html="$t('TreasureHunt.Chapter1.statisticMax')"></li>
        </ul>
      </b-collapse>
    </div>

    <br>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch1-4>{{ $t('TreasureHunt.Chapter1.showHistograms')
      }}</b-button>
      <b-collapse id="collapse-ch1-4" class="mt-2 collapse-white">
        <img alt="plot" src="@/assets/treasure_hunt/ch1_histogram.png" height="300">
        <br><br>
        <b-button v-b-toggle.collapse-ch1-4-inner size="sm">{{ $t('TreasureHunt.Chapter1.histogramDefinition')
        }}</b-button>
        <b-collapse id="collapse-ch1-4-inner" class="mt-2">
          <p>{{ $t('TreasureHunt.Chapter1.histogramExplanation') }}</p>
          <img :src="imgPath_tresure_hunt_hist_explaination" alt="histogram_explaination" height="300">
        </b-collapse>
      </b-collapse>
    </div>

    <hr>

    <h4>{{ $t('TreasureHunt.Chapter1.datamanipulatorHeader') }}</h4>
    <task-instruction :instruction="$t('TreasureHunt.Chapter1.datamanipulatorInstructions')"></task-instruction>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch1-5>{{ $t('TreasureHunt.Chapter1.startDataManipulator')
      }}</b-button>
      <b-collapse id="collapse-ch1-5" class="mt-2 collapse-white">
        <data-manipulator></data-manipulator>
      </b-collapse>
    </div>

    <hr>

    <h4>{{ $t('TreasureHunt.Chapter1.clueHeader') }}</h4>
    <task-instruction :instruction="$t('TreasureHunt.Chapter1.clueAdvice')"></task-instruction>

    <p v-html="$t('TreasureHunt.Chapter1.hintsDescription')"></p>
    <div class="treasure-hunt-highlight">
      <b><span style="font-family:Papyrus;" v-html="$t('TreasureHunt.Chapter1.twoLinerText')"></span></b>
    </div>

    <div>
      <img alt="paper" v-b-modal.modal-center src="@/assets/treasure_hunt/ch1_rolled_decoding_paper.png" height="100">

      <b-modal id="modal-center" centered title="Mysterious Decoding Paper" hide-footer>
        <div style="display:flex; justify-content: center;"><img alt="paper"
            src="@/assets/treasure_hunt/ch1_mysterious_decoding_paper.png" height="250"></div>
      </b-modal>
    </div>

    <hr>

    <p v-html="$t('TreasureHunt.Chapter1.treasureLocation')"></p>

    <check-solution solution="nowhere" @solution-correct="showEpilog"></check-solution>

    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="treasure-hunt-button" backgroundClass="treasure-hunt-background"></hint-group>
    </div>

    <div v-if="solutionFound">
      <img :src="imgPath_tresure_hunt_ch1_epilogue" alt="treasure-hunt-ch1_epilogue" width="85%">
    </div>

    <br><br>

  </div>
</template>

<script>
import DataManipulator from './DataManipulator.vue';
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/CheckSolution.vue';

export default {
  components: {
    DataManipulator,
    HintGroup,
    CheckSolution,
  },
  data() {
    return {
      solutionFound: false,
    }
  },
  computed: {
    imgPath_tresure_hunt_hist_explaination() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/treasure_hunt/ch1_hist_explaination_de.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/treasure_hunt/ch1_hist_explaination_en.png');
      } else {
        console.warn("No image found for treasure hunt ch2 intro.")
        return null
      }
    },
    imgPath_tresure_hunt_ch1_epilogue() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch1-epilogue_de.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch1-epilogue_en.png');
      } else {
        console.warn("No image found for treasure hunt epilogue.")
        return null
      }
    },
    hints() {
      return this.$t("TreasureHunt.Chapter1.hints")
    }
  },
  methods: {
    openColab() {
      window.open("https://colab.research.google.com/drive/1ifWz4ywnK63LBncKtv4y8G1d5vsswvxw#scrollTo=KTaoVhnMeuCE&forceEdit=true&sandboxMode=true");
    },
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true
        this.$emit("solution-correct", true);
      }
    },
  }
}
</script>

<style scoped>.box123 {
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}</style>