<template>
  <div>

    <range-input :label="$t('secretSociety.table.birthday')" :minValue="birthdayMin" :maxValue="birthdayMax" type="date"
      @update:minValue="updatebirthdayMin" @update:maxValue="updatebirthdayMax">
    </range-input>

    <range-input :label="$t('secretSociety.table.memberSinceYears')" :minValue="memberMin" :maxValue="memberMax" type="number"
      @update:minValue="updateMemberMin" @update:maxValue="updateMemberMax">
    </range-input>

    <br>

    <b-button class="secret-society-button" @click="applyFilter">{{$t('secretSociety.applyFilters')}}</b-button>

    <br><br>

    <div class="table">
      <b-table small hover bordered sticky-header="500px" :items="filteredItems" :fields="fields"></b-table>
    </div>

  </div>
</template>

<script>
import RangeInput from "./RangeInput.vue";

import { memberData } from "./memberData.js"
memberData.forEach(member => {
  // Parse the 'birthday' string into a Date object
  member.birthday = new Date(member.birthday);
});

export default {
  components: {
    RangeInput,
  },
  data() {
    return {
      memberMin: 0,
      memberMax: 100,
      birthdayMin: "1500-01-01",
      birthdayMax: "2500-02-22",
      items: memberData,
      filteredItems: memberData,
    }
  },
  computed: {
    fields() {
      const fields = [
        {
          key: 'name',
          label: "Name",
          sortable: true,
        },
        {
          key: 'residence',
          label: this.$t('secretSociety.table.residence'),
          sortable: true,
        },
        {
          key: 'birthday',
          label: this.$t('secretSociety.table.birthday'),
          sortable: true,
          formatter: this.formatDate,
        },
        {
          key: 'member_since_years',
          label: this.$t('secretSociety.table.memberSinceYears'),
          sortable: true,
        },
        {
          key: 'title',
          label: this.$t('secretSociety.table.title'),
          sortable: true,
        }
      ]
      return fields
    }
  },
  methods: {
    updateMemberMin(val) {
      this.memberMin = val;
    },
    updateMemberMax(val) {
      this.memberMax = val;
    },
    updatebirthdayMin(val) {
      this.birthdayMin = val;
    },
    updatebirthdayMax(val) {
      this.birthdayMax = val;
    },
    applyFilter() {
      this.filteredItems = this.items.filter(item =>
        Date.parse(item.birthday) >= Date.parse(this.birthdayMin)
        && Date.parse(item.birthday) <= Date.parse(this.birthdayMax)
        && item.member_since_years >= this.memberMin
        && item.member_since_years <= this.memberMax
      );
    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },

  beforeMount() {
    const items = this.items

    // birthdayMin
    this.birthdayMin = this.formatDate(items.reduce((min, obj) => {
      return obj.birthday < min ? obj.birthday : min;
    }, items[0].birthday))

    // birthdayMax
    this.birthdayMax = this.formatDate(items.reduce((max, obj) => {
      return obj.birthday > max ? obj.birthday : max;
    }, items[0].birthday))

    // memberMin
    this.memberMin = items.reduce((min, obj) => {
      return obj.member_since_years < min ? obj.member_since_years : min;
    }, items[0].member_since_years)

    // memberMax
    this.memberMax = items.reduce((max, obj) => {
      return obj.member_since_years > max ? obj.member_since_years : max;
    }, items[0].member_since_years)
  }
}
</script>

<style scoped>
.table {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
}
</style>