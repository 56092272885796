<template>
  <div>
    <div align="center" style="padding-top:15px; padding-bottom: 40px">
      <img alt="Secret-Society" src="@/assets/secret_society/secret-society.png" height="150">
      <h1>{{ $t("secretSociety.title") }}</h1>
      <h4>{{ $t("secretSociety.subtitle") }}</h4>
      <introduction-wrapper></introduction-wrapper>
    </div>


    <img :src="imgPath_secret_intro" alt="secret_intro" width="100%">

    <hr>

    <h4>{{ $t("secretSociety.thoughtsHeader") }}</h4>
    <p>{{ $t("secretSociety.thoughtssubheader") }}</p>

    <div style="display:flex">
      <div v-for="(thought, key) in $t('secretSociety.thoughts')" :key="key">
        <img alt="paper" v-b-modal="'thought-'.concat(key.toString())"
          :src="require('@/assets/reusables/thought_bubble.png')" height="140">
        <b-modal :id="'thought-'.concat(key.toString())" centered :title="thought.title" ok-only>
          <span v-html="thought.text"></span>
        </b-modal>
      </div>
    </div>

    <hr>

    <h4>{{ $t("secretSociety.data") }}</h4>
    <member-table></member-table>
    <europe-map></europe-map>

    <p><b>{{ $t("secretSociety.question") }}</b></p>

    <check-solution solution="palma de mallorca" @solution-correct="showEpilog"></check-solution>

    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="secret-society-button" backgroundClass="secret-society-background"></hint-group>
    </div>

    <div v-if="solutionFound">
      <p v-html="$t('secretSociety.epilog')"></p>
      <hr>
      <secret-debrief></secret-debrief>

      <br><br>

      <create-certificate :challenge="$t('certificate.secretSociety.challenge')"
        :learnings1="$t('certificate.secretSociety.learnings1')" :learnings2="$t('certificate.secretSociety.learnings2')"
        :learnings3="$t('certificate.secretSociety.learnings3')">
      </create-certificate>
    </div>

    <br>

  </div>
</template>

<script>
import IntroductionWrapper from '@/components/IntroductionWrapper.vue'
import MemberTable from './MemberTable.vue';
import EuropeMap from "./EuropeMap.vue";
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/CheckSolution.vue';
import SecretDebrief from '@/components/SecretSociety/SecretDebrief.vue';
import CreateCertificate from '@/components/AiaCertificate/CreateCertificate.vue';

export default {
  components: {
    IntroductionWrapper,
    MemberTable,
    EuropeMap,
    HintGroup,
    CheckSolution,
    SecretDebrief,
    CreateCertificate,
  },
  data() {
    return {
      solutionFound: false,
    }
  },
  computed: {
    imgPath_secret_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/secret_society/secret_intro_de.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/secret_society/secret_intro_en.png');
      } else {
        console.warn("No image found for secret society intro.")
        return null
      }
    },
    hints() {
      return this.$t("secretSociety.hints")
    }
  },
  methods: {
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true;
        this.start_confetti()
      }
    },
    start_confetti() {
      if (!this.confetti_in_execution) {
        this.confetti_in_execution = true
        this.$confetti.start();
        setTimeout(() => this.stop_confetti(), 2000)
      }
    },
    stop_confetti() {
      this.$confetti.stop();
      this.confetti_in_execution = false
    },
  },
}

</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>