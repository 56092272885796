<template>
  <div style="padding-bottom:15px">
    <p>{{ $t('hintGroup.hintText') }}</p>

    <b-button v-for="(hint, index) in  hints " :key="hint.id" @click="change_visibility(index)" :class="buttonClass" class="button">
      {{ $t('hintGroup.hint') }} {{ index + 1 }}
    </b-button>

    <b-collapse v-for="( hint, index ) in  hints " :key=" hint.id " v-model=" visible[index] " class="mt-2">
      <b-card :title=" $t('hintGroup.hint') + ' ' + (index + 1).toString() " :class="backgroundClass">
        <div v-if=" isArrayofLengthTwo(hint) ">
          <div v-show=" !visibleSolution[index] ">
            <span><b>Warning!</b> <span v-html=" hints[index][0] "></span></span>
            <br>
            <button type="button" class="btn btn-sm" style="margin-top:10px; margin-bottom:10px" :class="buttonClass"
              @click="showCompleteHint(index)">{{ $t('hintGroup.reveal') }}</button>
          </div>

          <div v-show=" visibleSolution[index] ">
            <span v-html=" hints[index][1] "></span>
          </div>
        </div>

        <div v-else v-html=" hints[index] ">

        </div>
      </b-card>
    </b-collapse>

  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: ["hints", "buttonClass", "backgroundClass"],
  data() {
    return {
      visible: this.hints.map(function () { return false }),
      visibleSolution: Vue.observable(this.hints.map(function () { return false })),
    }
  },
  methods: {
    change_visibility(index) {
      // Switch visibility of selected
      Vue.set(this.visible, index, !this.visible[index]) // this.visible[index] = !this.visible[index] is NOT reactive!

      // Reset all not selected to false
      for (let i = 0; i < this.hints.length; i++) {
        if (i != index) {
          Vue.set(this.visible, i, false)
        }
      }
    },
    isArrayofLengthTwo(varToCheck) {
      if (Array.isArray(varToCheck) && varToCheck.length === 2) {
        return true;
      } else {
        return false;
      }
    },
    showCompleteHint(index) {
      Vue.set(this.visibleSolution, index, true)
    },
  },
}
</script>

<style scoped>
.collapsed {
  background-color: Chocolate;
}

.button {
  margin-right: 15px;
  margin-bottom: 15px;
  border: none;
}
</style>