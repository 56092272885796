export const memberData = [
  {'name': 'Weldon Benton',
  'residence': 'Copenhagen',
  'birthday': '1966-07-16',
  'member_since_years': 17,
  'title': 'Fun Ambassador'},
  {'name': 'Joyce Lynn',
  'residence': 'Warsaw',
  'birthday': '1996-09-08',
  'member_since_years': 7,
  'title': 'Director of Fun and Games'},
  {'name': 'Christine Doyle',
  'residence': 'Prague',
  'birthday': '1831-03-19',
  'member_since_years': 2,
  'title': 'Chief Jokester'},
  {'name': 'Donald Allison',
  'residence': 'Tirana',
  'birthday': '1868-03-17',
  'member_since_years': 1,
  'title': 'Director of Awesomeness'},
  {'name': 'Minerva Horton',
  'residence': 'Luxemburg',
  'birthday': '2011-05-17',
  'member_since_years': 5,
  'title': 'Chief Fun Officer (CFO)'},
  {'name': 'Tina Douglas',
  'residence': 'Amsterdam',
  'birthday': '1992-01-11',
  'member_since_years': 3,
  'title': 'Head of Laughter Department'},
  {'name': 'Thad Best',
  'residence': 'Oslo',
  'birthday': '1985-07-21',
  'member_since_years': 12,
  'title': 'Director of Mischief'},
  {'name': 'Shawn Cain',
  'residence': 'Minsk',
  'birthday': '1942-12-03',
  'member_since_years': 1,
  'title': 'Fun Ambassador'},
  {'name': 'Alexander Patel',
  'residence': 'Warsaw',
  'birthday': '1984-04-01',
  'member_since_years': 6,
  'title': 'Director of Awesomeness'},
  {'name': 'Eunice Cooke',
  'residence': 'Luxembourg',
  'birthday': '1839-08-28',
  'member_since_years': 4,
  'title': 'Director of Mischief'},
  {'name': 'Hayden Obrien',
  'residence': 'Copenhagen',
  'birthday': '1834-08-02',
  'member_since_years': 2,
  'title': 'Master of Silliness'},
  {'name': 'Charlene Rosario',
  'residence': 'Warschau',
  'birthday': '1977-07-07',
  'member_since_years': 2,
  'title': 'Chief Jokester'},
  {'name': 'Allie Martin',
  'residence': 'Amsterdam',
  'birthday': '1836-07-11',
  'member_since_years': 2,
  'title': 'Director of Fun and Games'},
  {'name': 'Malik Little',
  'residence': 'Dublin',
  'birthday': '1868-10-10',
  'member_since_years': 3,
  'title': 'Head of Laughter Department'},
  {'name': 'Napoleon Hodges',
  'residence': 'Prague',
  'birthday': '1816-08-24',
  'member_since_years': 1,
  'title': 'Fun Ambassador'},
  {'name': 'Benito Leonard',
  'residence': 'Monaco',
  'birthday': '1992-01-10',
  'member_since_years': 4,
  'title': 'Chief Entertainment Officer (CEO)'},
  {'name': 'Terry Guerrero',
  'residence': 'Minsk',
  'birthday': '1969-11-21',
  'member_since_years': 7,
  'title': 'Chief Happiness Officer (CHO)'},
  {'name': 'Tiffany Rivas',
  'residence': 'Bern',
  'birthday': '1997-03-23',
  'member_since_years': 17,
  'title': 'Chief Jokester'},
  {'name': 'Perry Byrd',
  'residence': 'Helsinki',
  'birthday': '1954-12-26',
  'member_since_years': 13,
  'title': 'Chief Entertainment Officer (CEO)'},
  {'name': 'Iva Fletcher',
  'residence': 'Minsk',
  'birthday': '1859-12-26',
  'member_since_years': 1,
  'title': 'Chief Party Planner'},
  {'name': 'Augustine Olsen',
  'residence': 'Zagreb',
  'birthday': '1972-01-05',
  'member_since_years': 6,
  'title': 'Head of Laughter Department'},
  {'name': 'Cap Padilla',
  'residence': 'Helsinki',
  'birthday': '1962-01-21',
  'member_since_years': 8,
  'title': 'Chief Fun Officer (CFO)'},
  {'name': 'Francisco Franco',
  'residence': 'Chisinau',
  'birthday': '1958-03-17',
  'member_since_years': 15,
  'title': 'Master of Silliness'},
  {'name': 'Fleta Cunningham',
  'residence': 'Wien',
  'birthday': '1979-05-02',
  'member_since_years': 2,
  'title': 'Master of Silliness'},
  {'name': 'Lori Chen',
  'residence': 'Berlin',
  'birthday': '1961-11-13',
  'member_since_years': 19,
  'title': 'Vice President of Fun'},
  {'name': 'Peyton Fuentes',
  'residence': 'Berlin',
  'birthday': '2002-03-11',
  'member_since_years': 1,
  'title': 'Director of Mischief'},
  {'name': 'Elsie Chavez',
  'residence': 'Madrid',
  'birthday': '1814-07-18',
  'member_since_years': 5,
  'title': 'Chief Fun Officer (CFO)'},
  {'name': 'Tyler Mosley',
  'residence': 'Prag',
  'birthday': '1985-03-31',
  'member_since_years': 2,
  'title': 'Chief Happiness Officer (CHO)'},
  {'name': 'Clair Bishop',
  'residence': 'San Marino',
  'birthday': '1991-09-30',
  'member_since_years': 5,
  'title': 'Director of Fun and Games'},
  {'name': 'Fred Pace',
  'residence': 'Sarajevo',
  'birthday': '1961-04-19',
  'member_since_years': 5,
  'title': 'Vice President of Fun'},
  {'name': 'Keisha Hicks',
  'residence': 'Budapest',
  'birthday': '1855-12-02',
  'member_since_years': 2,
  'title': 'Chief Happiness Officer (CHO)'},
  {'name': 'Donna Barrett',
  'residence': 'Sofia',
  'birthday': '1958-11-21',
  'member_since_years': 3,
  'title': 'Director of Awesomeness'},
  {'name': 'Norris Graves',
  'residence': 'Bratislava',
  'birthday': '1817-11-21',
  'member_since_years': 2,
  'title': 'Vice President of Fun'},
  {'name': 'Rashad Wheeler',
  'residence': 'Minsk',
  'birthday': '1802-03-17',
  'member_since_years': 5,
  'title': 'Chief Entertainment Officer (CEO)'}
]