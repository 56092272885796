<template>
  <div>

    <h3>{{ $t('PirateBay.Chapter4.chapter4Title') }}</h3>
    <h1>{{ $t('PirateBay.Chapter4.header') }}</h1>

    <div align="center" style="padding-top:15px; padding-bottom: 40px">
      <img :src="imgPath_Ch4_intro" width="100%">
    </div>

    <!-- <p v-html="$t('PirateBay.Chapter4.clueDescription')"></p> -->

    <task-instruction :instruction="$t('PirateBay.Chapter4.Instruction')"></task-instruction>

    <chat-interface :save_context="true" :name_chat_partner="$t('PirateBay.Chapter4.chatPartner')"
      icon_path_chat_partner="pirate_bay/chat/pirate_icon.png"
      original_prompt="$t('PirateBay.Chapter4.prompt')"></chat-interface>

    <br>

    <h4>{{ $t('PirateBay.Chapter4.riddle1.Intro') }}</h4>
    <div>
      <br>
      <div class="pirate-bay-highlight">
        <i v-html="$t('PirateBay.Chapter4.riddle1.Question')"></i>
        <br><br>
        <i v-html="$t('PirateBay.Chapter4.riddle1.ChooseOption')"></i>
        <br>
        <i v-html="$t('PirateBay.Chapter4.riddle1.Option1')"></i>
        <br>
        <i v-html="$t('PirateBay.Chapter4.riddle1.Option2')"></i>
        <br>
        <i v-html="$t('PirateBay.Chapter4.riddle1.Option3')"></i>
      </div>
    </div>
    <b-form-select style="max-width: max-content" v-model="q1_selected" :options="q1_options"></b-form-select>
    <br><br>

    <h4>{{ $t('PirateBay.Chapter4.riddle2.Intro') }}</h4>
    <div>
      <br>
      <div class="pirate-bay-highlight">
        <i v-html="$t('PirateBay.Chapter4.riddle2.Question')"></i>
      </div>
    </div>
    <b-form-input style="max-width: max-content" type="number" min="0" max="100" v-model="q2_selected"></b-form-input>
    <br><br>

    <h4>{{ $t('PirateBay.Chapter4.riddle3.Intro') }}</h4>
    <div>
      <br>
      <div class="pirate-bay-highlight">
        <i v-html="$t('PirateBay.Chapter4.riddle3.Question')"></i>
      </div>
    </div>
    <b-form-select style="max-width: max-content" v-model="q3_selected" :options="q3_options"></b-form-select>
    <br><br>

    <p v-html="$t('PirateBay.Chapter4.clueSolution')"></p>

    <p>{{ $t('PirateBay.Chapter4.combinedSolution') }}: <b>{{ combined_answers }}</b></p>

    <check-solution @solution-correct="showEpilog"></check-solution>

    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="pirate-bay-button" backgroundClass="pirate-bay-background"></hint-group>
    </div>

    <div v-if="solutionFound" style="padding-top:15px; padding-bottom: 40px">
      <div align="center">
        <h3>{{ $t('PirateBay.Chapter4.congratulation') }}</h3>
        <img :src="imgPath_Ch4_epilogue" width="100%">
      </div>

      <br><br>

      <create-certificate :challenge="$t('certificate.pirateBay.challenge')"
        :learnings1="$t('certificate.pirateBay.learnings1')" :learnings2="$t('certificate.pirateBay.learnings2')"
        :learnings3="$t('certificate.pirateBay.learnings3')">
      </create-certificate>
    </div>

    <!-- <div v-if="solutionFound" align="center" style="padding-top:15px; padding-bottom: 40px"> -->
    <!-- <img src="@/assets/pirate_bay/GIF pirate party.gif" alt="Celebration GIF" height=400>
      <br><br>
    </div> -->

  </div>
</template>

<script>
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/PirateBay/ChapterFour/CheckSolution.vue';
import ChatInterface from "@/components/PirateBay/ChatInterface.vue";
import CreateCertificate from '@/components/AiaCertificate/CreateCertificate.vue';

export default {
  components: {
    HintGroup,
    CheckSolution,
    ChatInterface,
    CreateCertificate,
  },
  data() {
    return {
      q1_selected: "",
      q1_options: [
        { value: "", text: this.$t("PirateBay.Chapter4.SelectOptionText") },
        { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
        { value: "option3", text: "Option 3" }
      ],
      q2_selected: "",
      q3_selected: "",
      q3_options: [
        { value: "", text: this.$t("PirateBay.Chapter4.SelectOptionText") },
        { value: this.$t("PirateBay.Chapter4.riddle3.Option1Value"), text: this.$t("PirateBay.Chapter4.riddle3.Option1") },
        { value: this.$t("PirateBay.Chapter4.riddle3.Option2Value"), text: this.$t("PirateBay.Chapter4.riddle3.Option2") },
        { value: this.$t("PirateBay.Chapter4.riddle3.Option3Value"), text: this.$t("PirateBay.Chapter4.riddle3.Option3") }
      ],
      solutionFound: false,
      solution1Found: false,
      solution2Found: false,
      solution3Found: false,
    }
  },
  computed: {
    imgPath_Ch4_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/Chapter 4 Intro.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/Chapter 4 Intro.png');
      } else {
        console.warn("No image found for pirate bay ch4 intro.")
        return null
      }
    },
    imgPath_Ch4_epilogue() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/Chapter 4 epilogue.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/Chapter 4 epilogue.png');
      } else {
        console.warn("No image found for pirate bay ch4 epilogue.")
        return null
      }
    },
    hints() {
      return this.$t("PirateBay.Chapter4.hints")
    },
    combined_answers() {
      return this.q1_selected + "-" + this.q2_selected + "-" + this.q3_selected;
    },
  },
  methods: {
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true
        this.$emit("solution-correct", true);
      }
    },
  }
}
</script>