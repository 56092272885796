<template>
  <div>
    <h3>{{ $t('PirateBay.Chapter2.chapter2Title') }}</h3>
    <h1>{{ $t('PirateBay.Chapter2.header') }}</h1>

    <div align="center" style="padding-top:15px; padding-bottom: 40px">
      <img alt="Pirate" :src="imgPath_Ch2_intro" width="100%">
    </div>

    <!-- <p v-html="$t('PirateBay.Chapter2.clueDescription')"></p> -->

    <task-instruction :instruction="$t('PirateBay.Chapter2.Instruction')"></task-instruction>

    <chat-interface :save_context="true" :name_chat_partner="$t('PirateBay.Chapter2.chatPartner')" icon_path_chat_partner="pirate_bay/chat/book_icon.png" original_prompt=""></chat-interface>

    <br>

    <check-solution @solution-correct="showEpilog"></check-solution>

    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="pirate-bay-button" backgroundClass="pirate-bay-background"></hint-group>
    </div>

    <div v-if="solutionFound" align="center" style="padding-top:15px; padding-bottom: 40px">
      <!-- <p v-html="$t('PirateBay.Chapter1.epilog')"></p>       -->
      <img :src="imgPath_Ch2_epilogue" width="100%">
      <br><br>
    </div>
  </div>
</template>

<script>
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/PirateBay/ChapterTwo/CheckSolution.vue';
import ChatInterface from "@/components/PirateBay/ChatInterface.vue";

export default {
  components: {
    HintGroup,
    CheckSolution,
    ChatInterface,
  },
  data() {
    return {
      solutionFound: false,
    }
  },
  computed: {
    imgPath_Ch2_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/Chapter 2 Intro.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/Chapter 2 Intro.png');
      } else {
        console.warn("No image found for pirate bay ch2 intro.")
        return null
      }
    },
    imgPath_Ch2_epilogue() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/Chapter 2 epilogue.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/Chapter 2 epilogue.png');
      } else {
        console.warn("No image found for pirate bay ch2 epilogue.")
        return null
      }
    },
    hints() {
    return this.$t("PirateBay.Chapter2.hints")
  }
  },
  methods: {
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true
        this.$emit("solution-correct", true);
      }
    },
  }
}
</script>