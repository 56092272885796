export const isPlanetData = [
  { x: '12:00', y: 1 },
  { x: '12:01', y: 0 },
  { x: '12:02', y: 1 },
  { x: '12:03', y: 0 },
  { x: '12:04', y: 0 },
  { x: '12:05', y: 1 },
  { x: '12:06', y: 0 },
  { x: '12:07', y: 0 },
  { x: '12:08', y: 1 },
  { x: '12:09', y: 1 },
  { x: '12:10', y: 0 },
  { x: '12:11', y: 0 },
  { x: '12:12', y: 0 },
  { x: '12:13', y: 0 },
  { x: '12:14', y: 0 },
  { x: '12:15', y: 0 },
  { x: '12:16', y: 0 },
  { x: '12:17', y: 0 },
  { x: '12:18', y: 0 },
  { x: '12:19', y: 0 },
  { x: '12:20', y: 0 },
  { x: '12:21', y: 0 },
  { x: '12:22', y: 0 },
  { x: '12:23', y: 0 },
  { x: '12:24', y: 0 },
  { x: '12:25', y: 0 },
  { x: '12:26', y: 0 },
  { x: '12:27', y: 0 },
  { x: '12:28', y: 0 },
  { x: '12:29', y: 0 },
  { x: '12:30', y: 1 },
  { x: '12:31', y: 0 },
  { x: '12:32', y: 1 },
  { x: '12:33', y: 0 },
  { x: '12:34', y: 1 },
  { x: '12:35', y: 0 },
  { x: '12:36', y: 0 },
  { x: '12:37', y: 0 },
  { x: '12:38', y: 0 },
  { x: '12:39', y: 0 },
  { x: '12:40', y: 0 },
  { x: '12:41', y: 0 },
  { x: '12:42', y: 0 },
  { x: '12:43', y: 0 },
  { x: '12:44', y: 0 },
  { x: '12:45', y: 0 },
  { x: '12:46', y: 0 },
  { x: '12:47', y: 0 },
  { x: '12:48', y: 0 },
  { x: '12:49', y: 0 },
  { x: '12:50', y: 0 },
  { x: '12:51', y: 0 },
  { x: '12:52', y: 0 },
  { x: '12:53', y: 0 },
  { x: '12:54', y: 0 },
  { x: '12:55', y: 0 },
  { x: '12:56', y: 0 },
  { x: '12:57', y: 0 },
  { x: '12:58', y: 0 },
  { x: '12:59', y: 0 },
  { x: '13:00', y: 1 },
  { x: '13:01', y: 0 },
  { x: '13:02', y: 1 },
  { x: '13:03', y: 0 },
  { x: '13:04', y: 1 },
  { x: '13:05', y: 0 },
  { x: '13:06', y: 1 },
  { x: '13:07', y: 0 },
  { x: '13:08', y: 0 },
  { x: '13:09', y: 0 },
  { x: '13:10', y: 1 },
  { x: '13:11', y: 0 },
  { x: '13:12', y: 0 },
  { x: '13:13', y: 0 },
  { x: '13:14', y: 0 },
  { x: '13:15', y: 0 },
  { x: '13:16', y: 0 },
  { x: '13:17', y: 0 },
  { x: '13:18', y: 0 },
  { x: '13:19', y: 0 },
  { x: '13:20', y: 0 },
  { x: '13:21', y: 0 },
  { x: '13:22', y: 0 },
  { x: '13:23', y: 0 },
  { x: '13:24', y: 0 },
  { x: '13:25', y: 0 },
  { x: '13:26', y: 0 },
  { x: '13:27', y: 0 },
  { x: '13:28', y: 0 },
  { x: '13:29', y: 0 },
  { x: '13:30', y: 1 },
  { x: '13:31', y: 0 },
  { x: '13:32', y: 0 },
  { x: '13:33', y: 0 },
  { x: '13:34', y: 1 },
  { x: '13:35', y: 0 },
  { x: '13:36', y: 1 },
  { x: '13:37', y: 1 },
  { x: '13:38', y: 1 },
  { x: '13:39', y: 0 },
  { x: '13:40', y: 0 },
  { x: '13:41', y: 1 },
  { x: '13:42', y: 0 },
  { x: '13:43', y: 0 },
  { x: '13:44', y: 0 },
  { x: '13:45', y: 0 },
  { x: '13:46', y: 0 },
  { x: '13:47', y: 0 },
  { x: '13:48', y: 0 },
  { x: '13:49', y: 0 },
  { x: '13:50', y: 0 },
  { x: '13:51', y: 0 },
  { x: '13:52', y: 0 },
  { x: '13:53', y: 0 },
  { x: '13:54', y: 0 },
  { x: '13:55', y: 0 },
  { x: '13:56', y: 0 },
  { x: '13:57', y: 0 },
  { x: '13:58', y: 0 },
  { x: '13:59', y: 0 },
  { x: '14:00', y: 1 },
  { x: '14:01', y: 0 },
  { x: '14:02', y: 1 },
  { x: '14:03', y: 0 },
  { x: '14:04', y: 0 },
  { x: '14:05', y: 1 },
  { x: '14:06', y: 0 },
  { x: '14:07', y: 0 },
  { x: '14:08', y: 0 },
  { x: '14:09', y: 1 },
  { x: '14:10', y: 0 },
  { x: '14:11', y: 0 },
  { x: '14:12', y: 0 },
  { x: '14:13', y: 0 },
  { x: '14:14', y: 0 },
  { x: '14:15', y: 0 },
  { x: '14:16', y: 0 },
  { x: '14:17', y: 0 },
  { x: '14:18', y: 0 },
  { x: '14:19', y: 0 },
  { x: '14:20', y: 0 },
  { x: '14:21', y: 0 },
  { x: '14:22', y: 0 },
  { x: '14:23', y: 0 },
  { x: '14:24', y: 0 },
  { x: '14:25', y: 0 },
  { x: '14:26', y: 0 },
  { x: '14:27', y: 0 },
  { x: '14:28', y: 0 },
  { x: '14:29', y: 0 },
  { x: '14:30', y: 1 },
  { x: '14:31', y: 0 },
  { x: '14:32', y: 0 },
  { x: '14:33', y: 0 },
  { x: '14:34', y: 1 },
  { x: '14:35', y: 0 },
  { x: '14:36', y: 0 },
  { x: '14:37', y: 0 },
  { x: '14:38', y: 1 },
  { x: '14:39', y: 0 },
  { x: '14:40', y: 0 },
  { x: '14:41', y: 0 },
  { x: '14:42', y: 1 },
  { x: '14:43', y: 0 },
  { x: '14:44', y: 0 },
  { x: '14:45', y: 0 },
  { x: '14:46', y: 0 },
  { x: '14:47', y: 0 },
  { x: '14:48', y: 0 },
  { x: '14:49', y: 0 },
  { x: '14:50', y: 0 },
  { x: '14:51', y: 0 },
  { x: '14:52', y: 0 },
  { x: '14:53', y: 0 },
  { x: '14:54', y: 0 },
  { x: '14:55', y: 0 },
  { x: '14:56', y: 0 },
  { x: '14:57', y: 0 },
  { x: '14:58', y: 0 },
  { x: '14:59', y: 0 },
  { x: '15:00', y: 1 },
  { x: '15:01', y: 0 },
  { x: '15:02', y: 1 },
  { x: '15:03', y: 0 },
  { x: '15:04', y: 1 },
  { x: '15:05', y: 0 },
  { x: '15:06', y: 1 },
  { x: '15:07', y: 0 },
  { x: '15:08', y: 0 },
  { x: '15:09', y: 0 },
  { x: '15:10', y: 1 },
  { x: '15:11', y: 0 },
  { x: '15:12', y: 0 },
  { x: '15:13', y: 0 },
  { x: '15:14', y: 0 },
  { x: '15:15', y: 0 },
  { x: '15:16', y: 0 },
  { x: '15:17', y: 0 },
  { x: '15:18', y: 0 },
  { x: '15:19', y: 0 },
  { x: '15:20', y: 0 },
  { x: '15:21', y: 0 },
  { x: '15:22', y: 0 },
  { x: '15:23', y: 0 },
  { x: '15:24', y: 0 },
  { x: '15:25', y: 0 },
  { x: '15:26', y: 0 },
  { x: '15:27', y: 0 },
  { x: '15:28', y: 0 },
  { x: '15:29', y: 0 },
  { x: '15:30', y: 0 },
  { x: '15:31', y: 0 },
  { x: '15:32', y: 0 },
  { x: '15:33', y: 1 },
  { x: '15:34', y: 0 },
  { x: '15:35', y: 0 },
  { x: '15:36', y: 0 },
  { x: '15:37', y: 0 },
  { x: '15:38', y: 1 },
  { x: '15:39', y: 0 },
  { x: '15:40', y: 0 },
  { x: '15:41', y: 0 },
  { x: '15:42', y: 0 },
  { x: '15:43', y: 1 },
  { x: '15:44', y: 0 },
  { x: '15:45', y: 0 },
  { x: '15:46', y: 0 },
  { x: '15:47', y: 0 },
  { x: '15:48', y: 1 },
  { x: '15:49', y: 0 },
  { x: '15:50', y: 0 },
  { x: '15:51', y: 0 },
  { x: '15:52', y: 0 },
  { x: '15:53', y: 1 },
  { x: '15:54', y: 0 },
  { x: '15:55', y: 0 },
  { x: '15:56', y: 0 },
  { x: '15:57', y: 0 },
  { x: '15:58', y: 1 },
  { x: '15:59', y: 0 },
  { x: '16:00', y: 1 },
  { x: '16:01', y: 0 },
  { x: '16:02', y: 1 },
  { x: '16:03', y: 1 },
  { x: '16:04', y: 0 },
  { x: '16:05', y: 1 },
  { x: '16:06', y: 0 },
  { x: '16:07', y: 1 },
  { x: '16:08', y: 0 },
  { x: '16:09', y: 0 },
  { x: '16:10', y: 1 },
  { x: '16:11', y: 0 },
  { x: '16:12', y: 0 },
  { x: '16:13', y: 0 },
  { x: '16:14', y: 0 },
  { x: '16:15', y: 0 },
  { x: '16:16', y: 0 },
  { x: '16:17', y: 0 },
  { x: '16:18', y: 0 },
  { x: '16:19', y: 0 },
  { x: '16:20', y: 0 },
  { x: '16:21', y: 0 },
  { x: '16:22', y: 0 },
  { x: '16:23', y: 0 },
  { x: '16:24', y: 0 },
  { x: '16:25', y: 0 },
  { x: '16:26', y: 0 },
  { x: '16:27', y: 0 },
  { x: '16:28', y: 0 },
  { x: '16:29', y: 0 },
  { x: '16:30', y: 1 },
  { x: '16:31', y: 0 },
  { x: '16:32', y: 0 },
  { x: '16:33', y: 1 },
  { x: '16:34', y: 0 },
  { x: '16:35', y: 0 },
  { x: '16:36', y: 1 },
  { x: '16:37', y: 0 },
  { x: '16:38', y: 0 },
  { x: '16:39', y: 0 },
  { x: '16:40', y: 0 },
  { x: '16:41', y: 0 },
  { x: '16:42', y: 0 },
  { x: '16:43', y: 0 },
  { x: '16:44', y: 0 },
  { x: '16:45', y: 0 },
  { x: '16:46', y: 0 },
  { x: '16:47', y: 0 },
  { x: '16:48', y: 0 },
  { x: '16:49', y: 0 },
  { x: '16:50', y: 0 },
  { x: '16:51', y: 0 },
  { x: '16:52', y: 0 },
  { x: '16:53', y: 0 },
  { x: '16:54', y: 0 },
  { x: '16:55', y: 0 },
  { x: '16:56', y: 0 },
  { x: '16:57', y: 0 },
  { x: '16:58', y: 0 },
  { x: '16:59', y: 0 },
  { x: '17:00', y: 1 },
  { x: '17:01', y: 0 },
  { x: '17:02', y: 0 },
  { x: '17:03', y: 0 },
  { x: '17:04', y: 1 },
  { x: '17:05', y: 0 },
  { x: '17:06', y: 0 },
  { x: '17:07', y: 0 },
  { x: '17:08', y: 0 },
  { x: '17:09', y: 0 },
  { x: '17:10', y: 0 },
  { x: '17:11', y: 0 },
  { x: '17:12', y: 0 },
  { x: '17:13', y: 0 },
  { x: '17:14', y: 0 },
  { x: '17:15', y: 0 },
  { x: '17:16', y: 0 },
  { x: '17:17', y: 0 },
  { x: '17:18', y: 0 },
  { x: '17:19', y: 0 },
  { x: '17:20', y: 0 },
  { x: '17:21', y: 0 },
  { x: '17:22', y: 0 },
  { x: '17:23', y: 0 },
  { x: '17:24', y: 0 },
  { x: '17:25', y: 0 },
  { x: '17:26', y: 0 },
  { x: '17:27', y: 0 },
  { x: '17:28', y: 0 },
  { x: '17:29', y: 0 },
  { x: '17:30', y: 1 },
  { x: '17:31', y: 0 },
  { x: '17:32', y: 0 },
  { x: '17:33', y: 1 },
  { x: '17:34', y: 0 },
  { x: '17:35', y: 1 },
  { x: '17:36', y: 0 },
  { x: '17:37', y: 0 },
  { x: '17:38', y: 1 },
  { x: '17:39', y: 0 },
  { x: '17:40', y: 0 },
  { x: '17:41', y: 1 },
  { x: '17:42', y: 0 },
  { x: '17:43', y: 0 },
  { x: '17:44', y: 0 },
  { x: '17:45', y: 0 },
  { x: '17:46', y: 0 },
  { x: '17:47', y: 0 },
  { x: '17:48', y: 0 },
  { x: '17:49', y: 0 },
  { x: '17:50', y: 0 },
  { x: '17:51', y: 0 },
  { x: '17:52', y: 0 },
  { x: '17:53', y: 0 },
  { x: '17:54', y: 0 },
  { x: '17:55', y: 0 },
  { x: '17:56', y: 0 },
  { x: '17:57', y: 0 },
  { x: '17:58', y: 0 },
  { x: '17:59', y: 0 },
  { x: '18:00', y: 1 },
  { x: '18:01', y: 1 },
  { x: '18:02', y: 0 },
  { x: '18:03', y: 1 },
  { x: '18:04', y: 0 },
  { x: '18:05', y: 1 },
  { x: '18:06', y: 0 },
  { x: '18:07', y: 0 },
  { x: '18:08', y: 1 },
  { x: '18:09', y: 0 },
  { x: '18:10', y: 0 },
  { x: '18:11', y: 0 },
  { x: '18:12', y: 0 },
  { x: '18:13', y: 0 },
  { x: '18:14', y: 0 },
  { x: '18:15', y: 0 },
  { x: '18:16', y: 0 },
  { x: '18:17', y: 0 },
  { x: '18:18', y: 0 },
  { x: '18:19', y: 0 },
  { x: '18:20', y: 0 },
  { x: '18:21', y: 0 },
  { x: '18:22', y: 0 },
  { x: '18:23', y: 0 },
  { x: '18:24', y: 0 },
  { x: '18:25', y: 0 },
  { x: '18:26', y: 0 },
  { x: '18:27', y: 0 },
  { x: '18:28', y: 0 },
  { x: '18:29', y: 0 },
  { x: '18:30', y: 1 },
  { x: '18:31', y: 0 },
  { x: '18:32', y: 0 },
  { x: '18:33', y: 0 },
  { x: '18:34', y: 1 },
  { x: '18:35', y: 0 },
  { x: '18:36', y: 0 },
  { x: '18:37', y: 0 },
  { x: '18:38', y: 0 },
  { x: '18:39', y: 0 },
  { x: '18:40', y: 0 },
  { x: '18:41', y: 0 },
  { x: '18:42', y: 0 },
  { x: '18:43', y: 0 },
  { x: '18:44', y: 0 },
  { x: '18:45', y: 0 },
  { x: '18:46', y: 0 },
  { x: '18:47', y: 0 },
  { x: '18:48', y: 0 },
  { x: '18:49', y: 0 },
  { x: '18:50', y: 0 },
  { x: '18:51', y: 0 },
  { x: '18:52', y: 0 },
  { x: '18:53', y: 0 },
  { x: '18:54', y: 0 },
  { x: '18:55', y: 0 },
  { x: '18:56', y: 0 },
  { x: '18:57', y: 0 },
  { x: '18:58', y: 0 },
  { x: '18:59', y: 0 },
  { x: '19:00', y: 1 },
  { x: '19:01', y: 0 },
  { x: '19:02', y: 0 },
  { x: '19:03', y: 1 },
  { x: '19:04', y: 0 },
  { x: '19:05', y: 1 },
  { x: '19:06', y: 0 },
  { x: '19:07', y: 0 },
  { x: '19:08', y: 1 },
  { x: '19:09', y: 0 },
  { x: '19:10', y: 0 },
  { x: '19:11', y: 1 },
  { x: '19:12', y: 0 },
  { x: '19:13', y: 0 },
  { x: '19:14', y: 0 },
  { x: '19:15', y: 0 },
  { x: '19:16', y: 0 },
  { x: '19:17', y: 0 },
  { x: '19:18', y: 0 },
  { x: '19:19', y: 0 },
  { x: '19:20', y: 0 },
  { x: '19:21', y: 0 },
  { x: '19:22', y: 0 },
  { x: '19:23', y: 0 },
  { x: '19:24', y: 0 },
  { x: '19:25', y: 0 },
  { x: '19:26', y: 0 },
  { x: '19:27', y: 0 },
  { x: '19:28', y: 0 },
  { x: '19:29', y: 0 },
  { x: '19:30', y: 1 },
  { x: '19:31', y: 0 },
  { x: '19:32', y: 1 },
  { x: '19:33', y: 0 },
  { x: '19:34', y: 0 },
  { x: '19:35', y: 0 },
  { x: '19:36', y: 1 },
  { x: '19:37', y: 0 },
  { x: '19:38', y: 0 },
  { x: '19:39', y: 0 },
  { x: '19:40', y: 1 },
  { x: '19:41', y: 0 },
  { x: '19:42', y: 1 },
  { x: '19:43', y: 0 },
  { x: '19:44', y: 0 },
  { x: '19:45', y: 0 },
  { x: '19:46', y: 0 },
  { x: '19:47', y: 0 },
  { x: '19:48', y: 0 },
  { x: '19:49', y: 0 },
  { x: '19:50', y: 0 },
  { x: '19:51', y: 0 },
  { x: '19:52', y: 0 },
  { x: '19:53', y: 0 },
  { x: '19:54', y: 0 },
  { x: '19:55', y: 0 },
  { x: '19:56', y: 0 },
  { x: '19:57', y: 0 },
  { x: '19:58', y: 0 },
  { x: '19:59', y: 0 },
  { x: '20:00', y: 0 },
  { x: '20:01', y: 0 },
  { x: '20:02', y: 0 },
  { x: '20:03', y: 1 },
  { x: '20:04', y: 0 },
  { x: '20:05', y: 0 },
  { x: '20:06', y: 0 },
  { x: '20:07', y: 0 },
  { x: '20:08', y: 1 },
  { x: '20:09', y: 0 },
  { x: '20:10', y: 0 },
  { x: '20:11', y: 0 },
  { x: '20:12', y: 0 },
  { x: '20:13', y: 1 },
  { x: '20:14', y: 0 },
  { x: '20:15', y: 0 },
  { x: '20:16', y: 0 },
  { x: '20:17', y: 0 },
  { x: '20:18', y: 1 },
  { x: '20:19', y: 0 },
  { x: '20:20', y: 0 },
  { x: '20:21', y: 0 },
  { x: '20:22', y: 0 },
  { x: '20:23', y: 1 },
  { x: '20:24', y: 0 },
  { x: '20:25', y: 0 },
  { x: '20:26', y: 0 },
  { x: '20:27', y: 0 },
  { x: '20:28', y: 1 },
  { x: '20:29', y: 0 },
  { x: '20:30', y: 1 },
  { x: '20:31', y: 0 },
  { x: '20:32', y: 1 },
  { x: '20:33', y: 1 },
  { x: '20:34', y: 0 },
  { x: '20:35', y: 1 },
  { x: '20:36', y: 0 },
  { x: '20:37', y: 1 },
  { x: '20:38', y: 0 },
  { x: '20:39', y: 0 },
  { x: '20:40', y: 1 },
  { x: '20:41', y: 0 },
  { x: '20:42', y: 0 },
  { x: '20:43', y: 0 },
  { x: '20:44', y: 0 },
  { x: '20:45', y: 0 },
  { x: '20:46', y: 0 },
  { x: '20:47', y: 0 },
  { x: '20:48', y: 0 },
  { x: '20:49', y: 0 },
  { x: '20:50', y: 0 },
  { x: '20:51', y: 0 },
  { x: '20:52', y: 0 },
  { x: '20:53', y: 0 },
  { x: '20:54', y: 0 },
  { x: '20:55', y: 0 },
  { x: '20:56', y: 0 },
  { x: '20:57', y: 0 },
  { x: '20:58', y: 0 },
  { x: '20:59', y: 0 },
  { x: '21:00', y: 1 },
  { x: '21:01', y: 0 },
  { x: '21:02', y: 1 },
  { x: '21:03', y: 0 },
  { x: '21:04', y: 1 },
  { x: '21:05', y: 0 },
  { x: '21:06', y: 1 },
  { x: '21:07', y: 0 },
  { x: '21:08', y: 0 },
  { x: '21:09', y: 0 },
  { x: '21:10', y: 1 },
  { x: '21:11', y: 0 },
  { x: '21:12', y: 0 },
  { x: '21:13', y: 0 },
  { x: '21:14', y: 0 },
  { x: '21:15', y: 0 },
  { x: '21:16', y: 0 },
  { x: '21:17', y: 0 },
  { x: '21:18', y: 0 },
  { x: '21:19', y: 0 },
  { x: '21:20', y: 0 },
  { x: '21:21', y: 0 },
  { x: '21:22', y: 0 },
  { x: '21:23', y: 0 },
  { x: '21:24', y: 0 },
  { x: '21:25', y: 0 },
  { x: '21:26', y: 0 },
  { x: '21:27', y: 0 },
  { x: '21:28', y: 0 },
  { x: '21:29', y: 0 },
  { x: '21:30', y: 1 },
  { x: '21:31', y: 0 },
  { x: '21:32', y: 0 },
  { x: '21:33', y: 0 },
  { x: '21:34', y: 1 },
  { x: '21:35', y: 0 },
  { x: '21:36', y: 1 },
  { x: '21:37', y: 1 },
  { x: '21:38', y: 1 },
  { x: '21:39', y: 0 },
  { x: '21:40', y: 0 },
  { x: '21:41', y: 1 },
  { x: '21:42', y: 0 },
  { x: '21:43', y: 0 },
  { x: '21:44', y: 0 },
  { x: '21:45', y: 0 },
  { x: '21:46', y: 0 },
  { x: '21:47', y: 0 },
  { x: '21:48', y: 0 },
  { x: '21:49', y: 0 },
  { x: '21:50', y: 0 },
  { x: '21:51', y: 0 },
  { x: '21:52', y: 0 },
  { x: '21:53', y: 0 },
  { x: '21:54', y: 0 },
  { x: '21:55', y: 0 },
  { x: '21:56', y: 0 },
  { x: '21:57', y: 0 },
  { x: '21:58', y: 0 },
  { x: '21:59', y: 0 },
  { x: '22:00', y: 1 },
  { x: '22:01', y: 0 },
  { x: '22:02', y: 1 },
  { x: '22:03', y: 1 },
  { x: '22:04', y: 0 },
  { x: '22:05', y: 1 },
  { x: '22:06', y: 0 },
  { x: '22:07', y: 1 },
  { x: '22:08', y: 0 },
  { x: '22:09', y: 0 },
  { x: '22:10', y: 1 },
  { x: '22:11', y: 0 },
  { x: '22:12', y: 0 },
  { x: '22:13', y: 0 },
  { x: '22:14', y: 0 },
  { x: '22:15', y: 0 },
  { x: '22:16', y: 0 },
  { x: '22:17', y: 0 },
  { x: '22:18', y: 0 },
  { x: '22:19', y: 0 },
  { x: '22:20', y: 0 },
  { x: '22:21', y: 0 },
  { x: '22:22', y: 0 },
  { x: '22:23', y: 0 },
  { x: '22:24', y: 0 },
  { x: '22:25', y: 0 },
  { x: '22:26', y: 0 },
  { x: '22:27', y: 0 },
  { x: '22:28', y: 0 },
  { x: '22:29', y: 0 },
  { x: '22:30', y: 1 },
  { x: '22:31', y: 0 },
  { x: '22:32', y: 0 },
  { x: '22:33', y: 0 },
  { x: '22:34', y: 1 },
  { x: '22:35', y: 0 },
  { x: '22:36', y: 0 },
  { x: '22:37', y: 0 },
  { x: '22:38', y: 1 },
  { x: '22:39', y: 0 },
  { x: '22:40', y: 0 },
  { x: '22:41', y: 0 },
  { x: '22:42', y: 0 },
  { x: '22:43', y: 0 },
  { x: '22:44', y: 0 },
  { x: '22:45', y: 0 },
  { x: '22:46', y: 0 },
  { x: '22:47', y: 0 },
  { x: '22:48', y: 0 },
  { x: '22:49', y: 0 },
  { x: '22:50', y: 0 },
  { x: '22:51', y: 0 },
  { x: '22:52', y: 0 },
  { x: '22:53', y: 0 },
  { x: '22:54', y: 0 },
  { x: '22:55', y: 0 },
  { x: '22:56', y: 0 },
  { x: '22:57', y: 0 },
  { x: '22:58', y: 0 },
  { x: '22:59', y: 0 },
  { x: '23:00', y: 1 },
  { x: '23:01', y: 0 },
  { x: '23:02', y: 1 },
  { x: '23:03', y: 0 },
  { x: '23:04', y: 1 },
  { x: '23:05', y: 0 },
  { x: '23:06', y: 1 },
  { x: '23:07', y: 0 },
  { x: '23:08', y: 0 },
  { x: '23:09', y: 0 },
  { x: '23:10', y: 1 },
  { x: '23:11', y: 0 },
  { x: '23:12', y: 0 },
  { x: '23:13', y: 0 },
  { x: '23:14', y: 0 },
  { x: '23:15', y: 0 },
  { x: '23:16', y: 0 },
  { x: '23:17', y: 0 },
  { x: '23:18', y: 0 },
  { x: '23:19', y: 0 },
  { x: '23:20', y: 0 },
  { x: '23:21', y: 0 },
  { x: '23:22', y: 0 },
  { x: '23:23', y: 0 },
  { x: '23:24', y: 0 },
  { x: '23:25', y: 0 },
  { x: '23:26', y: 0 },
  { x: '23:27', y: 0 },
  { x: '23:28', y: 0 },
  { x: '23:29', y: 0 },
  { x: '23:30', y: 1 },
  { x: '23:31', y: 0 },
  { x: '23:32', y: 0 },
  { x: '23:33', y: 1 },
  { x: '23:34', y: 0 },
  { x: '23:35', y: 1 },
  { x: '23:36', y: 0 },
  { x: '23:37', y: 1 },
  { x: '23:38', y: 0 },
  { x: '23:39', y: 1 },
  { x: '23:40', y: 0 },
  { x: '23:41', y: 1 },
  { x: '23:42', y: 0 },
  { x: '23:43', y: 0 },
  { x: '23:44', y: 1 },
  { x: '23:45', y: 0 },
  { x: '23:46', y: 0 },
  { x: '23:47', y: 0 },
  { x: '23:48', y: 0 },
  { x: '23:49', y: 0 },
  { x: '23:50', y: 0 },
  { x: '23:51', y: 0 },
  { x: '23:52', y: 0 },
  { x: '23:53', y: 0 },
  { x: '23:54', y: 0 },
  { x: '23:55', y: 0 },
  { x: '23:56', y: 0 },
  { x: '23:57', y: 0 },
  { x: '23:58', y: 0 },
  { x: '23:59', y: 0 },
  { x: '00:00', y: 1 },
  { x: '00:01', y: 0 },
  { x: '00:02', y: 0 },
  { x: '00:03', y: 0 },
  { x: '00:04', y: 1 },
  { x: '00:05', y: 0 },
  { x: '00:06', y: 0 },
  { x: '00:07', y: 0 },
  { x: '00:08', y: 0 },
  { x: '00:09', y: 0 },
  { x: '00:10', y: 0 },
  { x: '00:11', y: 0 },
  { x: '00:12', y: 0 },
  { x: '00:13', y: 0 },
  { x: '00:14', y: 0 },
  { x: '00:15', y: 0 },
  { x: '00:16', y: 0 },
  { x: '00:17', y: 0 },
  { x: '00:18', y: 0 },
  { x: '00:19', y: 0 },
  { x: '00:20', y: 0 },
  { x: '00:21', y: 0 },
  { x: '00:22', y: 0 },
  { x: '00:23', y: 0 },
  { x: '00:24', y: 0 },
  { x: '00:25', y: 0 },
  { x: '00:26', y: 0 },
  { x: '00:27', y: 0 },
  { x: '00:28', y: 0 },
  { x: '00:29', y: 0 },
  { x: '00:30', y: 1 },
  { x: '00:31', y: 1 },
  { x: '00:32', y: 0 },
  { x: '00:33', y: 1 },
  { x: '00:34', y: 0 },
  { x: '00:35', y: 1 },
  { x: '00:36', y: 0 },
  { x: '00:37', y: 0 },
  { x: '00:38', y: 1 },
  { x: '00:39', y: 0 },
  { x: '00:40', y: 0 },
  { x: '00:41', y: 0 },
  { x: '00:42', y: 0 },
  { x: '00:43', y: 0 },
  { x: '00:44', y: 0 },
  { x: '00:45', y: 0 },
  { x: '00:46', y: 0 },
  { x: '00:47', y: 0 },
  { x: '00:48', y: 0 },
  { x: '00:49', y: 0 },
  { x: '00:50', y: 0 },
  { x: '00:51', y: 0 },
  { x: '00:52', y: 0 },
  { x: '00:53', y: 0 },
  { x: '00:54', y: 0 },
  { x: '00:55', y: 0 },
  { x: '00:56', y: 0 },
  { x: '00:57', y: 0 },
  { x: '00:58', y: 0 },
  { x: '00:59', y: 0 },
  { x: '01:00', y: 1 },
  { x: '01:01', y: 0 },
  { x: '01:02', y: 1 },
  { x: '01:03', y: 0 },
  { x: '01:04', y: 1 },
  { x: '01:05', y: 0 },
  { x: '01:06', y: 1 },
  { x: '01:07', y: 0 },
  { x: '01:08', y: 0 },
  { x: '01:09', y: 0 },
  { x: '01:10', y: 1 },
  { x: '01:11', y: 0 },
  { x: '01:12', y: 0 },
  { x: '01:13', y: 0 },
  { x: '01:14', y: 0 },
  { x: '01:15', y: 0 },
  { x: '01:16', y: 0 },
  { x: '01:17', y: 0 },
  { x: '01:18', y: 0 },
  { x: '01:19', y: 0 },
  { x: '01:20', y: 0 },
  { x: '01:21', y: 0 },
  { x: '01:22', y: 0 },
  { x: '01:23', y: 0 },
  { x: '01:24', y: 0 },
  { x: '01:25', y: 0 },
  { x: '01:26', y: 0 },
  { x: '01:27', y: 0 },
  { x: '01:28', y: 0 },
  { x: '01:29', y: 0 },
]