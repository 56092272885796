<template>
  <div>
    
    <pirate-bay-intro></pirate-bay-intro>
    <br>
    <chapter-one @solution-correct="updateChapterTwoVisibility"></chapter-one>
    <chapter-two v-if="showChapterTwo" @solution-correct="updateChapterThreeVisibility"></chapter-two>
    <chapter-three v-if="showChapterThree" @solution-correct="updateChapterFourVisibility"></chapter-three>
    <chapter-four v-if="showChapterFour"></chapter-four>
  </div>
</template>

<script>

import PirateBayIntro from '@/components/PirateBay/PirateBayIntro.vue';
import ChapterOne from '@/components/PirateBay/ChapterOne/ChapterOne.vue';
import ChapterTwo from '@/components/PirateBay/ChapterTwo/ChapterTwo.vue';
import ChapterThree from '@/components/PirateBay/ChapterThree/ChapterThree.vue';
import ChapterFour from '@/components/PirateBay/ChapterFour/ChapterFour.vue';

export default {
  components: {
    PirateBayIntro,
    ChapterOne,
    ChapterTwo,
    ChapterThree,
    ChapterFour,
  },
  data() {
    return {
      showChapterTwo: false,
      showChapterThree: false,
      showChapterFour: false , 
    }
  },

  methods: {
    updateChapterTwoVisibility() {
      this.showChapterTwo = true;
    },
    updateChapterThreeVisibility() {
      this.showChapterThree = true;
    },
    updateChapterFourVisibility() {
      this.showChapterFour = true;
    },
  },
}
</script>