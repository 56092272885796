<template>
  <div>
    <b-button v-b-modal.modal-contact id="contactButton" class="rounded-circle" size="lg" v-b-tooltip.hover
      :title="$t('App.contact.header')">
        <b-icon icon="envelope-fill" font-scale="1.5"></b-icon>
      </b-button>

      <b-modal id="modal-contact" :title="$t('App.contact.header')" ok-only>
        <p>{{ $t('App.contact.line1') }}</p>
        <p>{{ $t('App.contact.line2') }} <a href="https://forms.office.com/e/TkgvrvHDbf" target="blank">AI Adventure Feedback</a> <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right> </p>
        <p>{{ $t('App.contact.line3') }} <a href="mailto:julian.germek@alexanderthamm.com">julian.germek@alexanderthamm.com</a>.</p>
      </b-modal>
  </div>
</template>

<script>
import { BIcon } from 'bootstrap-vue'

export default {
  components: {
    BIcon
  }
}
</script>

<style scoped>
#contactButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: white;
  border: none;
  color: chocolate;
  cursor: pointer;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}
</style>