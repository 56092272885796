<template>
  <div>

    <h3>{{ $t('PirateBay.Chapter3.chapter3Title') }}</h3>
    <h1>{{ $t('PirateBay.Chapter3.header') }}</h1>

    <div align="center" style="padding-top:15px; padding-bottom: 40px">
      <img :src="imgPath_Ch3_intro" width="100%">
    </div>

    <p v-html="$t('PirateBay.Chapter3.clueDescription')"></p>

    <p style="margin-left: 15px">
        <i v-html="$t('PirateBay.Chapter3.instructions')"></i>
    </p>
    
    <p v-html="$t('PirateBay.Chapter3.clueDescription2')"></p>

    <task-instruction :instruction="$t('PirateBay.Chapter3.Promptinstruction')"></task-instruction>

    <br>

    <check-solution @solution-correct="showEpilog"></check-solution>

    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="pirate-bay-button" backgroundClass="pirate-bay-background"></hint-group>
    </div>

    <div v-if="solutionFound" align="center" style="padding-top:15px; padding-bottom: 40px">
      <!-- <p v-html="$t('PirateBay.Chapter1.epilog')"></p>       -->
      <img :src="imgPath_Ch3_epilogue" width="100%">
      <br><br>
    </div>
  </div>
</template>

<script>
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/PirateBay/ChapterThree/CheckSolution.vue';

export default {
  components: {
    HintGroup,
    CheckSolution,
  },
  data() {
    return {
      solutionFound: false,
    }
  },
  computed: {
    imgPath_Ch3_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/Chapter 3 Intro.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/Chapter 3 Intro.png');
      } else {
        console.warn("No image found for pirate bay ch3 intro.")
        return null
      }
    },
    imgPath_Ch3_epilogue() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/Chapter 3 epilogue.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/Chapter 3 epilogue.png');
      } else {
        console.warn("No image found for pirate bay ch3 epilogue.")
        return null
      }
    },
    hints() {
    return this.$t("PirateBay.Chapter3.hints")
  }
  },
  methods: {
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true
        this.$emit("solution-correct", true);
      }
    },
  }
}
</script>