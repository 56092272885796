<template>
  <div>
    <h3>Add tokens</h3>
    <b-form>
      <b-form-select v-model="adventure" :options="options" required></b-form-select>
      <b-form-input v-model="customer" placeholder="E.g. porsche" required></b-form-input>
      <b-form-input v-model="amount" type="number" required></b-form-input>
      <br>
      <b-button variant="primary" @click="generateTokens">Submit</b-button>

      <br>

      <p v-if="generatedTokens.length > 0"><br><b>Generated tokens:</b></p>
      <b-table striped hover :items="generatedTokens"></b-table>
    </b-form>
  </div>
</template>

<script>
import { generateToken } from '@/tokenHandler.js'


export default {
  name: 'AddTokens',
  data() {
    return {
      adventure: "",
      options: [
        {value: "treasure-hunt", text: "Treasure Hunt"},
        {value: "pirate-bay", text: "Pirate Bay"},
      ],
      customer: "",
      amount: 0,
      generatedTokens: [],
    }
  },
  methods: {
    async generateTokens() {
      if (this.customer == "") {
        alert("Please enter the customer's name.");
        return;
      }
      if (this.amount >= 10) {
        if(!confirm(`Are you sure you want to create ${this.amount} tokens?`)) {
          return;
        }
      }
      for (let i = 0; i < this.amount; i++) { 
        let token = await generateToken(this.adventure, this.customer)
        this.generatedTokens.push({token: token.PartitionKey, adventure: this.adventure, customer: this.customer})
      }
    }
  }
}
</script>