<template>
  <div>
    <!-- {{ $t('PirateBay.Chapter1.description')}} -->

    <h3>{{ $t('PirateBay.Chapter1.chapter1Title') }}</h3>
    <h1>{{ $t('PirateBay.Chapter1.header') }}</h1>
       
    <p v-html="$t('PirateBay.Chapter1.clueDescription')"></p>
    
    <task-instruction :instruction="$t('PirateBay.Chapter1.Instruction')"></task-instruction>

    <chat-interface :save_context="false" :name_chat_partner="$t('PirateBay.Chapter1.chatPartner')" icon_path_chat_partner="pirate_bay/chat/pirate_icon.png" :original_prompt="$t('PirateBay.Chapter1.prompt')"></chat-interface>

    <br>

    <check-solution :solution="$t('PirateBay.Chapter1.solutionPhrase')" @solution-correct="showEpilog"></check-solution>
 
    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="pirate-bay-button" backgroundClass="pirate-bay-background"></hint-group>
    </div>

    <div v-if="solutionFound" align="center" style="padding-top:15px; padding-bottom: 40px">
      <!-- <p v-html="$t('PirateBay.Chapter1.epilog')"></p>       -->
      <img :src="imgPath_Ch1_epilogue" width="100%">
      <br><br>
    </div>
  </div>
</template>

<script>
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/CheckSolution.vue';
import ChatInterface from "@/components/PirateBay/ChatInterface.vue";

export default {
  components: {
    HintGroup,
    CheckSolution,
    ChatInterface,
  },
  data() {
    return {
      solutionFound: false,
      }
    },
  computed: {
    imgPath_Ch1_epilogue() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/pirate_bay/deutsch/Chapter 1 epilogue.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/pirate_bay/english/Chapter 1 epilogue.png');
      } else {
        console.warn("No image found for pirate bay ch1 epilogue.")
        return null
      }
    },
    hints() {
    return this.$t("PirateBay.Chapter1.hints")
  }
  },
  methods: {
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true
        this.$emit("solution-correct", true);
      }
    },
  }
}
</script>