<template>
  <div>

    <b-container fluid>
      <b-row class="my-1">
        <b-col sm="2">
          <label>{{ $t("TreasureHunt.Chapter2.RoutesPlotter.variableName") }}</label>
        </b-col>
        <b-col sm="2">
          <b-form-input v-model="chartNumberUpdate" type="number" size="sm" :min="min" :max="max"></b-form-input>
        </b-col>
        <b-col sm="8">
          <span>{{ $t("TreasureHunt.Chapter2.RoutesPlotter.variableExplaination") }}</span>
        </b-col>
      </b-row>
    </b-container>

    <b-button @click="updateData">{{ $t("TreasureHunt.Chapter2.RoutesPlotter.updateChartButton") }}</b-button>
    <span style="margin-left:10px"><b-spinner small v-if="showUpdateChartSpinner"></b-spinner></span>

    <br><br>

    <div v-if="showPlots">
      <p>{{ $t("TreasureHunt.Chapter2.RoutesPlotter.resultPart1") }} {{ chartNumber }} {{ $t("TreasureHunt.Chapter2.RoutesPlotter.resultPart2") }}:</p>
      <img alt="plot" :src="require(`@/assets/treasure_hunt/ch2_routes/routes_plot_${chartNumber}.png`)" width="100%">
      <br><br>
      <p v-html='$t("TreasureHunt.Chapter2.RoutesPlotter.hint")'></p>
    </div>

    <p v-else>{{ outOfBoundsFeedback }}</p>

  </div>
</template>

<script>
export default {
  data() {
    return {
      chartNumber: 100,
      chartNumberUpdate: 100,
      min: 10,
      max: 100,
      outOfBoundsFeedback: "",
      showPlots: true,
      showUpdateChartSpinner: false,
    }
  },
  methods: {
    updateData() {
      this.showUpdateChartSpinner = true;

      if (this.chartNumberUpdate < this.min || this.chartNumberUpdate > this.max) {
        this.outOfBoundsFeedback = "Please enter a value between " + this.min + " and " + this.max + ".";
        this.showPlots = false;
      } else {
        this.chartNumber = this.chartNumberUpdate;
        this.showPlots = true;
      }

      setTimeout(() => this.showUpdateChartSpinner = false, 500);
    },
  },
}
</script>

<style scoped></style>