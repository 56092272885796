import i18n from '@/i18n';
import dialog from '@/dialog.js';

// Connection to Azure table Storage
const { TableClient, AzureSASCredential } = require("@azure/data-tables");

// Account Name
const account = process.env.VUE_APP_ACCOUNT_NAME;

// SAS Token 
const sas = process.env.VUE_APP_SAS_TOKEN

// Table Name
const tableName = process.env.VUE_APP_TABLE_NAME;

// Create a Connection 
const tableClient = new TableClient(`https://${account}.table.core.windows.net`, tableName, new AzureSASCredential(sas));

export async function generateToken(adventure, customer) {
  // while (true) {
  // Generate a unique token, you can use any library or custom logic here
  // For simplicity, this example uses a simple random number
  let token = Math.random().toString(36).substring(2);
  const entity = {
    PartitionKey: token,
    RowKey: "1",
    Adventure: adventure,
    Customer: customer,
    Used: false
  };

  try {
    await tableClient.createEntity(entity);
    return entity
  } catch (error) {
    console.log("token already exists")
  }
  // }
  console.log("Token saved successfully.");
}

export async function validateToken(token, adventure) {
  /**
   * Validates token.
   * 
   * If token does not exist
   *  Return invalidToken
   * 
   * Check if code is for correct adventure
   *  If Used == false:
   *    If correct adventure:
   *      Get confirmation from user
   *       If confirmation postive:
   *        Set Used = True
   *        Return validToken
   *       Else:
   *        Return invalidToken
   *    Else:
   *      Return invalidToken
   * 
   * If Used == true and time since activation <= hoursValidAfterActivation:
   *  Return validToken
   * 
   * If Used == true and time since activation > hoursValidAfterActivation:
   *  Return invalidToken
   */

  const hoursValidAfterActivation = 4

  // Admin password
  if (token == "aia") {
    return "validToken"
  }

  try {
    // Get Lists of entities
    let entitiesIter = tableClient.listEntities();

    let valueexists = false;
    for await (const entity of entitiesIter) {

      // Check if token exists
      if (entity.partitionKey === token) {
        valueexists = true;
        break;
      }
    }

    if (valueexists) {
      // Get entity
      const entity = await tableClient.getEntity(token, "1");

      if (entity["Adventure"] == adventure) {
        // Activate if never used before
        if (entity["Used"] == false) {
          const confirmation = await dialog
            .html()
            .title(i18n.t('tokenHandler.confirmTitle'))
            .okText(i18n.t('tokenHandler.confirmYes'))
            .cancelText(i18n.t('tokenHandler.confirmNo'))
            .confirm(i18n.t('tokenHandler.confirm', { hoursValidAfterActivation: hoursValidAfterActivation }))

          if (confirmation) {
            // Update the entity 
            entity.Used = true;
            entity.UsedTimestamp = new Date().toString();

            await tableClient.updateEntity(entity, 'Replace');
            await dialog.html().title(i18n.t('tokenHandler.tokenActivatedTitle')).alert(i18n.t('tokenHandler.tokenActivated'))
            return "validToken";
          } else {
            await dialog.html().title(i18n.t('tokenHandler.tokenActivationAbortedTitle')).alert(i18n.t('tokenHandler.tokenActivationAborted'))
            return "invalidToken";
          }

          // Check if token is still valid
        } else {
          // Check how long ago token was activated
          const currentTime = new Date();
          const usedTimestamp = new Date(entity["UsedTimestamp"]);
          const timeDifference = currentTime - usedTimestamp; // in milliseconds
          const hoursDifference = timeDifference / (1000 * 60 * 60);

          if (hoursDifference <= hoursValidAfterActivation) {
            const minutesRemaining = Math.floor(hoursValidAfterActivation * 60 - timeDifference / 1000 / 60)
            await dialog.html().title(i18n.t('tokenHandler.tokenStillvalidTitle')).alert(i18n.t('tokenHandler.tokenStillvalid', { minutes: minutesRemaining }))
            return "validToken"
          } else {
            await dialog.html().title(i18n.t('tokenHandler.tokenExpiredTitle')).alert(i18n.t('tokenHandler.tokenExpired'))
            return "invalidToken"
          }
        }
      } else {
        await dialog.html().title(i18n.t('tokenHandler.notValidForThisAdventureTitle')).alert(i18n.t('tokenHandler.notValidForThisAdventure'))
      }


    }
    // Token does not exist
    else {
      if (token != null) {
        await dialog.html().title(i18n.t('tokenHandler.tokenInvlaidTitle')).alert(i18n.t('tokenHandler.tokenInvlaid'))
      }
      return "invalidToken"
    }

  } catch (error) {
    console.error("Error:", error);
  }
}

if (require.main === module) {
  // generateToken("porsche")
  validateToken("kx0h3wpl7e").then(res => {
    console.log(res)
  })
}
