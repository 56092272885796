<template>
  <div v-if="dialog.state.active">
    <div class="dialog-inner">
      <h3 v-if="dialog.state.title">{{ dialog.state.title }}</h3>

      <p v-if="dialog.state.html" v-html="dialog.state.message"></p>
      <p v-else>{{ dialog.state.message }}</p>

      <p v-if="dialog.state.type === 'prompt'">
        <input ref="inputField" :type="dialog.state.inputType" v-model="userInput" @keyup.enter="pressEnter">
        <b-icon class="toggle-password" :icon=passwordIcon @click="togglePassword" font-scale="1.2"></b-icon>
      </p>

      <div>
        <b-button class="button" variant="primary" @click="ok">{{ dialog.state.okText }}</b-button>
        <b-button class="button" variant="danger" v-if="dialog.state.type !== 'alert'" @click="cancel">{{
    dialog.state.cancelText }}</b-button>
      </div>

    </div>
    <div class="dialog-bg" @click="dialog.cancel()"></div>
  </div>
</template>

<script>
import dialog from '@/dialog'
export default {
  data() {
    return {
      userInput: '',
      dialog
    }
  },

  computed: {
    passwordIcon() {
      return this.dialog.state.showPassword ? 'eye' : 'eye-slash';
    }
  },

  watch: {
    'dialog.state.active'(newValue) {
      if (newValue && this.dialog.state.type === 'prompt') {
        this.$nextTick(() => {
          this.$refs.inputField.focus();
        });
      }
    }
  },

  methods: {
    ok() {
      this.dialog.ok(this.userInput)
      this.userInput = ''
    },
    cancel() {
      dialog.cancel()
      this.userInput = ''
    },
    pressEnter() {
      if (this.dialog.state.type === 'prompt') {
        this.ok();
      }
    },
    togglePassword() {
      this.dialog.togglePassword()
    }
  },
}
</script>

<style scoped>
.button {
  margin-right: 3px;
}

.dialog-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
}

.dialog-inner {
  background: white;
  z-index: 2;
  padding: 20px;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
  border-radius: 3px;
}

.toggle-password {
  margin-left: 10px;
  cursor: pointer;
}
</style>
