<template>
  <div>
    <div class="chart-container">
      <Scatter ref="myChart" id="my-chart-id" :options="chartOptions" :data="chartData" />
    </div>

    <b-container fluid>
      <b-row class="my-1">
        <b-col sm="2">
          <label>n_datapoints</label>
        </b-col>
        <b-col sm="2">
          <b-form-input v-model="n_datapoints" type="number" size="sm" min="1" max="10"></b-form-input>
        </b-col>
        <b-col sm="8">
          <span>({{ $t('TreasureHunt.Chapter2.patternExplorerExplanation') }})</span>
        </b-col>
      </b-row>
    </b-container>

    <b-button @click="updateData">Update Chart</b-button>
    <span style="margin-left:10px"><b-spinner small v-if="showUpdateChartSpinner"></b-spinner></span>
    <p style="color:red">{{ patternExplorerOutOfBoundersMessage }}</p>

  </div>
</template>

<script>
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale
} from 'chart.js'
import { Scatter } from 'vue-chartjs'
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, CategoryScale)

import { isPlanetData } from "./isPlanetData.js"

export default {
  name: 'ScatterPlot',
  components: { Scatter },
  data() {
    return {
      chartDataOriginal: {
        datasets: [
          {
            label: 'Scatter Dataset',
            data: isPlanetData,
            backgroundColor: '#0096FF',
            borderWidth: 1,
            pointRadius: 5,
            pointBackgroundColor: '#0096FF',
          },
        ],
      },
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,

        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              text: 'Time',
            },
          },
          y: {
            ticks: {
              callback: function (value) {
                if (value == 1) {
                  return "is_planet"
                } else if (value == 0) {
                  return "is_space"
                }
              },
            }
          },
        },

        plugins: {
          legend: {
            display: false
          }
        }
      },
      n_datapoints: 10,
      patternExplorerOutOfBoundersMessage: "",
      showUpdateChartSpinner: false,
    }
  },
  methods: {
    updateData() {
      this.showUpdateChartSpinner = true;

      if (0 <= this.n_datapoints && this.n_datapoints <= 810) {
        this.patternExplorerOutOfBoundersMessage = "";

        let newChartData = JSON.parse(JSON.stringify(this.chartDataOriginal));
        newChartData.datasets[0].data = newChartData.datasets[0].data.slice(0, this.n_datapoints)
        this.chartData = newChartData;
      } else {
        this.patternExplorerOutOfBoundersMessage = "As the drone took 810 images, n_datapoints must be a positive number not exceeding 810.";
      }

      setTimeout(() => this.showUpdateChartSpinner = false, 500);
    }
  },
  beforeMount() {
    this.updateData()
  },
}
</script>

<style scoped>
.chart-container {
  position: relative;
  background-color: white;
  height: 150px;
  border-radius: 5px;
  padding: 10px
}
</style>