export const treasureData = [
  { x: 412, y: 166 },
  { x: 534, y: 781 },
  { x: 496, y: 561 },
  { x: 503, y: 374 },
  { x: 493, y: 793 },
  { x: 528, y: 251 },
  { x: 518, y: 573 },
  { x: 594, y: 512 },
  { x: 540, y: 213 },
  { x: 478, y: 325 },
  { x: 564, y: 710 },
  { x: 462, y: 189 },
  { x: 479, y: 457 },
  { x: 497, y: 211 },
  { x: 368, y: 188 },
  { x: 517, y: 806 },
  { x: 539, y: 782 },
  { x: 580, y: 547 },
  { x: 589, y: 413 },
  { x: 569, y: 343 },
  { x: 591, y: 404 },
  { x: 442, y: 157 },
  { x: 476, y: 687 },
  { x: 551, y: 378 },
  { x: 583, y: 794 },
  { x: 422, y: 202 },
  { x: 555, y: 509 },
  { x: 563, y: 437 },
  { x: 579, y: 339 },
  { x: 581, y: 507 },
  { x: 546, y: 187 },
  { x: 538, y: 732 },
  { x: 521, y: 808 },
  { x: 323, y: 209 },
  { x: 560, y: 642 },
  { x: 592, y: 755 },
  { x: 483, y: 307 },
  { x: 535, y: 627 },
  { x: 525, y: 534 },
  { x: 604, y: 671 },
  { x: 603, y: 472 },
  { x: 599, y: 588 },
  { x: 556, y: 783 },
  { x: 562, y: 379 },
  { x: 401, y: 136 },
  { x: 352, y: 162 },
  { x: 482, y: 355 },
  { x: 522, y: 246 },
  { x: 374, y: 185 },
  { x: 529, y: 230 },
  { x: 490, y: 623 },
  { x: 565, y: 237 },
  { x: 533, y: 669 },
  { x: 586, y: 388 },
  { x: 526, y: 285 },
  { x: 518, y: 263 },
  { x: 576, y: 356 },
  { x: 583, y: 211 },
  { x: 501, y: 830 },
  { x: 509, y: 457 },
  { x: 517, y: 586 },
  { x: 486, y: 300 },
  { x: 596, y: 402 },
  { x: 513, y: 261 },
  { x: 577, y: 309 },
  { x: 587, y: 281 },
  { x: 439, y: 253 },
  { x: 482, y: 815 },
  { x: 500, y: 763 },
  { x: 597, y: 629 },
  { x: 474, y: 254 },
  { x: 553, y: 379 },
  { x: 497, y: 434 },
  { x: 554, y: 477 },
  { x: 401, y: 175 },
  { x: 491, y: 697 },
  { x: 378, y: 218 },
  { x: 534, y: 266 },
  { x: 499, y: 665 },
  { x: 510, y: 247 },
  { x: 377, y: 166 },
  { x: 594, y: 571 },
  { x: 479, y: 164 },
  { x: 566, y: 698 },
  { x: 563, y: 487 },
  { x: 498, y: 381 },
  { x: 593, y: 690 },
  { x: 487, y: 415 },
  { x: 508, y: 796 },
  { x: 562, y: 245 },
  { x: 494, y: 653 },
  { x: 573, y: 646 },
  { x: 585, y: 568 },
  { x: 541, y: 476 },
  { x: 602, y: 613 },
  { x: 525, y: 585 },
  { x: 404, y: 178 },
  { x: 523, y: 398 },
  { x: 473, y: 390 },
  { x: 544, y: 332 },
  { x: 575, y: 478 },
  { x: 505, y: 148 },
  { x: 361, y: 224 },
  { x: 515, y: 669 },
  { x: 546, y: 749 },
  { x: 427, y: 232 },
  { x: 538, y: 349 },
  { x: 351, y: 260 },
  { x: 340, y: 183 },
  { x: 324, y: 249 },
  { x: 423, y: 219 },
  { x: 590, y: 508 },
  { x: 471, y: 194 },
  { x: 562, y: 568 },
  { x: 597, y: 484 },
  { x: 484, y: 329 },
  { x: 545, y: 543 },
  { x: 476, y: 652 },
  { x: 517, y: 315 },
  { x: 341, y: 171 },
  { x: 552, y: 511 },
  { x: 558, y: 732 },
  { x: 551, y: 700 },
  { x: 573, y: 679 },
  { x: 513, y: 432 },
  { x: 486, y: 195 },
  { x: 594, y: 547 },
  { x: 527, y: 268 },
  { x: 539, y: 303 },
  { x: 547, y: 804 },
  { x: 526, y: 527 },
  { x: 576, y: 668 },
  { x: 532, y: 701 },
  { x: 487, y: 820 },
  { x: 459, y: 228 },
  { x: 479, y: 533 },
  { x: 497, y: 375 },
  { x: 596, y: 781 },
  { x: 495, y: 168 },
  { x: 426, y: 152 },
  { x: 400, y: 173 },
  { x: 561, y: 793 },
  { x: 465, y: 181 },
  { x: 481, y: 164 },
  { x: 524, y: 340 },
  { x: 466, y: 585 },
  { x: 474, y: 202 },
  { x: 593, y: 676 },
  { x: 501, y: 604 },
  { x: 592, y: 522 },
  { x: 509, y: 458 },
  { x: 492, y: 233 },
  { x: 470, y: 151 },
  { x: 559, y: 696 },
  { x: 583, y: 267 },
  { x: 606, y: 746 },
  { x: 482, y: 485 },
  { x: 503, y: 809 },
  { x: 467, y: 748 },
  { x: 590, y: 536 },
  { x: 580, y: 479 },
  { x: 490, y: 441 },
  { x: 434, y: 218 },
  { x: 541, y: 766 },
  { x: 568, y: 293 },
  { x: 377, y: 240 },
  { x: 378, y: 195 },
  { x: 563, y: 186 },
  { x: 489, y: 183 },
  { x: 484, y: 576 },
  { x: 415, y: 232 },
  { x: 515, y: 762 },
  { x: 579, y: 520 },
  { x: 557, y: 227 },
  { x: 541, y: 487 },
  { x: 509, y: 335 },
  { x: 533, y: 641 },
  { x: 527, y: 528 },
  { x: 480, y: 635 },
  { x: 590, y: 661 },
  { x: 538, y: 501 },
  { x: 513, y: 649 },
  { x: 464, y: 269 },
  { x: 536, y: 350 },
  { x: 574, y: 444 },
  { x: 532, y: 479 },
  { x: 400, y: 140 },
  { x: 548, y: 694 },
  { x: 542, y: 160 },
  { x: 490, y: 660 },
  { x: 504, y: 327 },
  { x: 386, y: 174 },
  { x: 498, y: 567 },
  { x: 555, y: 587 },
  { x: 500, y: 695 },
  { x: 524, y: 348 },
  { x: 505, y: 772 },
  { x: 469, y: 755 },
  { x: 551, y: 206 },
  { x: 597, y: 677 },
  { x: 502, y: 241 },
  { x: 514, y: 726 },
  { x: 479, y: 505 },
  { x: 413, y: 214 },
  { x: 537, y: 785 },
  { x: 520, y: 622 },
  { x: 589, y: 224 },
  { x: 604, y: 691 },
  { x: 598, y: 714 },
  { x: 467, y: 724 },
  { x: 390, y: 165 },
  { x: 508, y: 735 },
  { x: 482, y: 793 },
  { x: 550, y: 831 },
  { x: 575, y: 618 },
  { x: 517, y: 678 },
  { x: 511, y: 671 },
  { x: 518, y: 786 },
  { x: 470, y: 637 },
  { x: 368, y: 146 },
  { x: 600, y: 259 },
  { x: 522, y: 581 },
  { x: 537, y: 724 },
  { x: 592, y: 526 },
  { x: 512, y: 812 },
  { x: 493, y: 414 },
  { x: 358, y: 148 },
  { x: 456, y: 230 },
  { x: 514, y: 436 },
  { x: 531, y: 552 },
  { x: 550, y: 170 },
  { x: 606, y: 659 },
  { x: 557, y: 287 },
  { x: 526, y: 647 },
  { x: 511, y: 234 },
  { x: 466, y: 408 },
  { x: 561, y: 191 },
  { x: 485, y: 762 },
  { x: 483, y: 467 },
  { x: 486, y: 353 },
  { x: 495, y: 325 },
  { x: 591, y: 802 },
  { x: 525, y: 513 },
  { x: 571, y: 299 },
  { x: 586, y: 714 },
  { x: 476, y: 813 },
  { x: 545, y: 729 },
  { x: 519, y: 386 },
  { x: 549, y: 428 },
  { x: 541, y: 563 },
  { x: 544, y: 263 },
  { x: 503, y: 732 },
  { x: 505, y: 585 },
  { x: 499, y: 164 },
  { x: 536, y: 664 },
  { x: 513, y: 548 },
  { x: 596, y: 599 },
  { x: 397, y: 243 },
  { x: 580, y: 284 },
  { x: 590, y: 464 },
  { x: 478, y: 820 },
  { x: 566, y: 362 },
  { x: 599, y: 430 },
  { x: 394, y: 139 },
  { x: 521, y: 748 },
  { x: 528, y: 226 },
  { x: 498, y: 316 },
  { x: 567, y: 385 },
  { x: 588, y: 636 },
  { x: 471, y: 141 },
  { x: 520, y: 809 },
  { x: 546, y: 655 },
  { x: 560, y: 758 },
  { x: 437, y: 176 },
  { x: 515, y: 718 },
  { x: 517, y: 541 },
  { x: 509, y: 145 },
  { x: 491, y: 237 },
  { x: 594, y: 641 },
  { x: 548, y: 351 },
  { x: 474, y: 527 },
  { x: 489, y: 434 },
  { x: 566, y: 333 },
  { x: 604, y: 504 },
  { x: 524, y: 475 },
  { x: 498, y: 355 },
  { x: 466, y: 668 },
  { x: 493, y: 728 },
  { x: 553, y: 194 },
  { x: 547, y: 315 },
  { x: 605, y: 612 },
  { x: 544, y: 371 },
  { x: 533, y: 685 },
  { x: 560, y: 232 },
  { x: 478, y: 392 },
  { x: 543, y: 654 },
  { x: 595, y: 397 },
  { x: 548, y: 247 },
  { x: 534, y: 735 },
  { x: 500, y: 373 },
  { x: 537, y: 388 },
  { x: 597, y: 512 },
  { x: 596, y: 424 },
  { x: 509, y: 377 },
  { x: 580, y: 823 },
  { x: 366, y: 246 },
  { x: 494, y: 142 },
  { x: 540, y: 763 },
  { x: 502, y: 412 },
  { x: 483, y: 391 },
  { x: 525, y: 613 },
  { x: 485, y: 471 },
  { x: 599, y: 511 },
  { x: 476, y: 812 },
  { x: 368, y: 233 },
  { x: 481, y: 622 },
  { x: 576, y: 286 },
  { x: 529, y: 344 },
  { x: 505, y: 565 },
  { x: 531, y: 791 },
  { x: 484, y: 458 },
  { x: 367, y: 215 },
  { x: 321, y: 219 },
  { x: 569, y: 413 },
  { x: 490, y: 146 },
  { x: 488, y: 825 },
  { x: 468, y: 349 },
  { x: 568, y: 688 },
  { x: 406, y: 136 },
  { x: 558, y: 308 },
  { x: 354, y: 253 },
  { x: 538, y: 641 },
  { x: 464, y: 192 },
  { x: 587, y: 393 },
  { x: 515, y: 560 },
  { x: 542, y: 282 },
  { x: 523, y: 664 },
  { x: 500, y: 475 },
  { x: 528, y: 341 },
  { x: 591, y: 395 },
  { x: 483, y: 657 },
  { x: 515, y: 801 },
  { x: 465, y: 303 },
  { x: 504, y: 524 },
  { x: 529, y: 154 },
  { x: 572, y: 642 },
  { x: 469, y: 412 },
  { x: 393, y: 214 },
  { x: 540, y: 502 },
  { x: 567, y: 760 },
  { x: 565, y: 355 },
  { x: 476, y: 460 },
  { x: 320, y: 215 },
  { x: 384, y: 185 },
  { x: 508, y: 180 },
  { x: 521, y: 155 },
  { x: 341, y: 258 },
  { x: 467, y: 560 },
  { x: 485, y: 677 },
  { x: 501, y: 671 },
  { x: 497, y: 707 },
  { x: 513, y: 459 },
  { x: 532, y: 599 },
  { x: 587, y: 229 },
  { x: 474, y: 778 },
  { x: 524, y: 685 },
  { x: 595, y: 704 },
  { x: 334, y: 182 },
  { x: 547, y: 777 },
  { x: 570, y: 216 },
  { x: 425, y: 164 },
  { x: 382, y: 197 },
  { x: 571, y: 550 },
  { x: 512, y: 340 },
  { x: 507, y: 604 },
  { x: 339, y: 160 },
  { x: 475, y: 473 },
  { x: 535, y: 587 },
  { x: 574, y: 442 },
  { x: 493, y: 747 },
  { x: 552, y: 232 },
  { x: 479, y: 443 },
  { x: 546, y: 792 },
  { x: 354, y: 261 },
  { x: 340, y: 205 },
  { x: 363, y: 177 },
  { x: 440, y: 137 },
  { x: 536, y: 218 },
  { x: 600, y: 649 },
  { x: 444, y: 174 },
  { x: 537, y: 611 },
  { x: 327, y: 231 },
  { x: 501, y: 500 },
  { x: 582, y: 489 },
  { x: 488, y: 227 },
  { x: 523, y: 149 },
  { x: 493, y: 608 },
  { x: 494, y: 331 },
  { x: 474, y: 378 },
  { x: 468, y: 479 },
  { x: 519, y: 564 },
  { x: 534, y: 531 },
  { x: 557, y: 357 },
  { x: 598, y: 290 },
  { x: 482, y: 303 },
  { x: 599, y: 319 },
  { x: 504, y: 304 },
  { x: 478, y: 288 },
  { x: 564, y: 269 },
  { x: 567, y: 532 },
  { x: 558, y: 418 },
  { x: 522, y: 255 },
  { x: 539, y: 470 },
  { x: 347, y: 210 },
  { x: 475, y: 658 },
  { x: 538, y: 512 },
  { x: 345, y: 258 },
  { x: 384, y: 232 },
  { x: 595, y: 404 },
  { x: 561, y: 543 },
  { x: 550, y: 806 },
  { x: 555, y: 465 },
  { x: 529, y: 584 },
  { x: 518, y: 632 },
  { x: 356, y: 169 },
  { x: 600, y: 390 },
  { x: 352, y: 205 },
  { x: 479, y: 147 },
  { x: 499, y: 253 },
  { x: 596, y: 284 },
  { x: 533, y: 313 },
  { x: 470, y: 501 },
  { x: 497, y: 141 },
  { x: 572, y: 685 },
  { x: 602, y: 768 },
  { x: 360, y: 220 },
  { x: 354, y: 191 },
  { x: 537, y: 183 },
  { x: 515, y: 309 },
  { x: 540, y: 733 },
  { x: 597, y: 497 },
  { x: 558, y: 439 },
  { x: 605, y: 384 },
  { x: 596, y: 426 },
  { x: 555, y: 808 },
  { x: 493, y: 496 },
  { x: 562, y: 423 },
  { x: 487, y: 658 },
  { x: 466, y: 635 },
  { x: 477, y: 361 },
  { x: 488, y: 479 },
  { x: 517, y: 246 },
  { x: 484, y: 326 },
  { x: 494, y: 692 },
  { x: 326, y: 191 },
  { x: 602, y: 448 },
  { x: 563, y: 392 },
  { x: 440, y: 248 },
  { x: 467, y: 645 },
  { x: 511, y: 324 },
  { x: 529, y: 802 },
  { x: 546, y: 452 },
  { x: 479, y: 220 },
  { x: 599, y: 449 },
  { x: 324, y: 195 },
  { x: 427, y: 198 },
  { x: 519, y: 669 },
  { x: 351, y: 250 },
  { x: 503, y: 403 },
  { x: 544, y: 476 },
  { x: 542, y: 665 },
  { x: 536, y: 546 },
  { x: 583, y: 275 },
  { x: 343, y: 150 },
  { x: 505, y: 505 },
  { x: 556, y: 554 },
  { x: 491, y: 621 },
  { x: 586, y: 339 },
  { x: 588, y: 715 },
  { x: 321, y: 221 },
  { x: 567, y: 770 },
  { x: 521, y: 271 },
  { x: 520, y: 759 },
  { x: 532, y: 335 },
  { x: 509, y: 656 },
  { x: 433, y: 163 },
  { x: 445, y: 245 },
  { x: 535, y: 322 },
  { x: 485, y: 428 },
  { x: 497, y: 169 },
  { x: 458, y: 141 },
  { x: 576, y: 281 },
  { x: 559, y: 209 },
  { x: 473, y: 765 },
  { x: 516, y: 412 },
  { x: 543, y: 204 },
  { x: 489, y: 208 },
  { x: 410, y: 212 },
  { x: 549, y: 192 },
  { x: 518, y: 530 },
  { x: 349, y: 261 },
  { x: 456, y: 210 },
  { x: 491, y: 579 },
  { x: 481, y: 645 },
  { x: 578, y: 561 },
  { x: 476, y: 187 },
  { x: 514, y: 588 },
  { x: 577, y: 340 },
  { x: 499, y: 212 },
  { x: 347, y: 258 },
  { x: 528, y: 620 },
  { x: 571, y: 193 },
  { x: 322, y: 229 },
  { x: 547, y: 463 },
  { x: 486, y: 144 },
  { x: 530, y: 174 },
  { x: 341, y: 181 },
  { x: 562, y: 779 },
  { x: 548, y: 367 },
  { x: 393, y: 180 },
  { x: 604, y: 322 },
  { x: 572, y: 488 },
  { x: 396, y: 196 },
  { x: 536, y: 337 },
  { x: 560, y: 737 },
  { x: 432, y: 242 },
  { x: 591, y: 555 },
  { x: 586, y: 370 },
  { x: 602, y: 507 },
  { x: 464, y: 171 },
  { x: 541, y: 521 },
  { x: 539, y: 158 },
  { x: 494, y: 285 },
  { x: 584, y: 480 },
  { x: 418, y: 165 },
  { x: 552, y: 533 },
  { x: 478, y: 789 },
  { x: 485, y: 159 },
  { x: 588, y: 761 },
  { x: 483, y: 323 },
  { x: 479, y: 583 },
  { x: 371, y: 238 },
  { x: 534, y: 666 },
  { x: 563, y: 243 },
  { x: 488, y: 364 },
  { x: 510, y: 302 },
  { x: 554, y: 768 },
  { x: 334, y: 206 },
  { x: 564, y: 766 },
  { x: 579, y: 392 },
  { x: 569, y: 299 },
  { x: 496, y: 813 },
  { x: 508, y: 728 },
  { x: 379, y: 175 },
  { x: 529, y: 681 },
  { x: 489, y: 200 },
  { x: 380, y: 150 },
  { x: 595, y: 773 },
  { x: 533, y: 464 },
  { x: 531, y: 637 },
  { x: 559, y: 731 },
  { x: 558, y: 203 },
  { x: 497, y: 371 },
  { x: 599, y: 477 },
  { x: 568, y: 548 },
  { x: 544, y: 510 },
  { x: 1389, y: 376 },
  { x: 1073, y: 549 },
  { x: 1379, y: 198 },
  { x: 1394, y: 168 },
  { x: 1372, y: 748 },
  { x: 1365, y: 688 },
  { x: 1339, y: 274 },
  { x: 974, y: 716 },
  { x: 978, y: 408 },
  { x: 967, y: 312 },
  { x: 1383, y: 751 },
  { x: 1103, y: 525 },
  { x: 1324, y: 444 },
  { x: 1393, y: 350 },
  { x: 1294, y: 512 },
  { x: 1006, y: 171 },
  { x: 1130, y: 553 },
  { x: 1423, y: 349 },
  { x: 1399, y: 811 },
  { x: 1377, y: 626 },
  { x: 1384, y: 779 },
  { x: 1163, y: 500 },
  { x: 1427, y: 348 },
  { x: 1179, y: 457 },
  { x: 1381, y: 337 },
  { x: 1068, y: 188 },
  { x: 1430, y: 665 },
  { x: 995, y: 272 },
  { x: 1280, y: 547 },
  { x: 1152, y: 516 },
  { x: 958, y: 508 },
  { x: 1380, y: 293 },
  { x: 971, y: 143 },
  { x: 1356, y: 681 },
  { x: 1064, y: 532 },
  { x: 937, y: 691 },
  { x: 1361, y: 240 },
  { x: 1331, y: 745 },
  { x: 988, y: 147 },
  { x: 1428, y: 356 },
  { x: 1057, y: 514 },
  { x: 1343, y: 609 },
  { x: 1360, y: 743 },
  { x: 1036, y: 381 },
  { x: 985, y: 667 },
  { x: 1440, y: 571 },
  { x: 980, y: 810 },
  { x: 1435, y: 630 },
  { x: 964, y: 331 },
  { x: 1058, y: 467 },
  { x: 1255, y: 509 },
  { x: 1281, y: 507 },
  { x: 1468, y: 421 },
  { x: 956, y: 657 },
  { x: 1418, y: 777 },
  { x: 1382, y: 677 },
  { x: 1467, y: 587 },
  { x: 1446, y: 433 },
  { x: 1464, y: 492 },
  { x: 1034, y: 780 },
  { x: 1309, y: 465 },
  { x: 1025, y: 742 },
  { x: 1312, y: 531 },
  { x: 965, y: 572 },
  { x: 1023, y: 209 },
  { x: 1047, y: 483 },
  { x: 1048, y: 590 },
  { x: 1453, y: 696 },
  { x: 1355, y: 417 },
  { x: 1020, y: 497 },
  { x: 997, y: 449 },
  { x: 1422, y: 309 },
  { x: 977, y: 268 },
  { x: 1444, y: 706 },
  { x: 1437, y: 411 },
  { x: 1225, y: 534 },
  { x: 1303, y: 472 },
  { x: 1353, y: 221 },
  { x: 992, y: 727 },
  { x: 1054, y: 403 },
  { x: 1352, y: 294 },
  { x: 1433, y: 205 },
  { x: 1350, y: 302 },
  { x: 947, y: 493 },
  { x: 1390, y: 142 },
  { x: 1019, y: 372 },
  { x: 952, y: 819 },
  { x: 1052, y: 162 },
  { x: 949, y: 342 },
  { x: 976, y: 253 },
  { x: 1439, y: 161 },
  { x: 1411, y: 560 },
  { x: 1442, y: 392 },
  { x: 1385, y: 416 },
  { x: 1420, y: 163 },
  { x: 1038, y: 569 },
  { x: 1046, y: 753 },
  { x: 1030, y: 476 },
  { x: 1104, y: 488 },
  { x: 1376, y: 812 },
  { x: 1396, y: 474 },
  { x: 1425, y: 306 },
  { x: 1429, y: 212 },
  { x: 1469, y: 578 },
  { x: 1388, y: 723 },
  { x: 1021, y: 347 },
  { x: 963, y: 338 },
  { x: 1348, y: 702 },
  { x: 1072, y: 480 },
  { x: 943, y: 785 },
  { x: 1026, y: 409 },
  { x: 1340, y: 765 },
  { x: 935, y: 183 },
  { x: 1041, y: 310 },
  { x: 1362, y: 535 },
  { x: 996, y: 281 },
  { x: 1025, y: 793 },
  { x: 1318, y: 532 },
  { x: 1422, y: 222 },
  { x: 1033, y: 689 },
  { x: 1433, y: 174 },
  { x: 1307, y: 547 },
  { x: 1425, y: 456 },
  { x: 1054, y: 506 },
  { x: 1209, y: 457 },
  { x: 940, y: 323 },
  { x: 972, y: 735 },
  { x: 994, y: 795 },
  { x: 1463, y: 645 },
  { x: 1355, y: 548 },
  { x: 942, y: 167 },
  { x: 1455, y: 687 },
  { x: 1374, y: 683 },
  { x: 1423, y: 401 },
  { x: 1414, y: 467 },
  { x: 1460, y: 615 },
  { x: 992, y: 549 },
  { x: 1438, y: 668 },
  { x: 1049, y: 463 },
  { x: 1003, y: 504 },
  { x: 931, y: 703 },
  { x: 1065, y: 632 },
  { x: 1369, y: 280 },
  { x: 934, y: 776 },
  { x: 1408, y: 327 },
  { x: 1006, y: 170 },
  { x: 1437, y: 240 },
  { x: 1146, y: 550 },
  { x: 1032, y: 729 },
  { x: 958, y: 340 },
  { x: 1019, y: 779 },
  { x: 1013, y: 274 },
  { x: 971, y: 817 },
  { x: 1442, y: 605 },
  { x: 1060, y: 607 },
  { x: 1074, y: 539 },
  { x: 1434, y: 284 },
  { x: 1375, y: 414 },
  { x: 959, y: 428 },
  { x: 1347, y: 371 },
  { x: 1447, y: 739 },
  { x: 1400, y: 561 },
  { x: 1055, y: 573 },
  { x: 1254, y: 477 },
  { x: 1441, y: 432 },
  { x: 1394, y: 149 },
  { x: 1103, y: 444 },
  { x: 1089, y: 439 },
  { x: 1072, y: 551 },
  { x: 949, y: 408 },
  { x: 1388, y: 778 },
  { x: 1000, y: 746 },
  { x: 1411, y: 353 },
  { x: 1080, y: 526 },
  { x: 982, y: 176 },
  { x: 963, y: 358 },
  { x: 1331, y: 370 },
  { x: 1362, y: 783 },
  { x: 933, y: 350 },
  { x: 975, y: 711 },
  { x: 1342, y: 590 },
  { x: 1381, y: 732 },
  { x: 1263, y: 487 },
  { x: 981, y: 543 },
  { x: 1370, y: 278 },
  { x: 1393, y: 312 },
  { x: 1053, y: 630 },
  { x: 1387, y: 760 },
  { x: 965, y: 686 },
  { x: 935, y: 572 },
  { x: 1241, y: 476 },
  { x: 993, y: 693 },
  { x: 1379, y: 820 },
  { x: 968, y: 509 },
  { x: 1070, y: 555 },
  { x: 1021, y: 433 },
  { x: 1429, y: 789 },
  { x: 1466, y: 737 },
  { x: 1028, y: 440 },
  { x: 1350, y: 241 },
  { x: 1376, y: 287 },
  { x: 1340, y: 640 },
  { x: 1432, y: 588 },
  { x: 1339, y: 666 },
  { x: 969, y: 210 },
  { x: 997, y: 466 },
  { x: 1377, y: 306 },
  { x: 947, y: 614 },
  { x: 1380, y: 768 },
  { x: 1430, y: 359 },
  { x: 1335, y: 635 },
  { x: 1415, y: 168 },
  { x: 1275, y: 478 },
  { x: 1357, y: 490 },
  { x: 985, y: 803 },
  { x: 1391, y: 459 },
  { x: 1439, y: 146 },
  { x: 1050, y: 521 },
  { x: 1087, y: 507 },
  { x: 1061, y: 224 },
  { x: 1454, y: 430 },
  { x: 945, y: 292 },
  { x: 1051, y: 260 },
  { x: 1467, y: 237 },
  { x: 1413, y: 557 },
  { x: 1040, y: 183 },
  { x: 1024, y: 249 },
  { x: 1385, y: 460 },
  { x: 1290, y: 508 },
  { x: 1011, y: 832 },
  { x: 1297, y: 484 },
  { x: 1339, y: 190 },
  { x: 1384, y: 248 },
  { x: 1437, y: 276 },
  { x: 1121, y: 548 },
  { x: 1327, y: 504 },
  { x: 1245, y: 543 },
  { x: 967, y: 582 },
  { x: 1336, y: 753 },
  { x: 1032, y: 291 },
  { x: 1405, y: 148 },
  { x: 1368, y: 381 },
  { x: 952, y: 447 },
  { x: 987, y: 562 },
  { x: 1401, y: 567 },
  { x: 1041, y: 171 },
  { x: 1252, y: 511 },
  { x: 1465, y: 772 },
  { x: 961, y: 408 },
  { x: 1445, y: 430 },
  { x: 1001, y: 797 },
  { x: 1334, y: 595 },
  { x: 1410, y: 833 },
  { x: 1376, y: 290 },
  { x: 988, y: 234 },
  { x: 1387, y: 683 },
  { x: 1419, y: 418 },
  { x: 968, y: 141 },
  { x: 1294, y: 547 },
  { x: 930, y: 619 },
  { x: 1373, y: 397 },
  { x: 1457, y: 209 },
  { x: 1446, y: 393 },
  { x: 1012, y: 211 },
  { x: 1059, y: 806 },
  { x: 1015, y: 328 },
  { x: 931, y: 707 },
  { x: 940, y: 456 },
  { x: 1005, y: 782 },
  { x: 1058, y: 368 },
  { x: 1226, y: 527 },
  { x: 1447, y: 207 },
  { x: 1051, y: 415 },
  { x: 950, y: 222 },
  { x: 933, y: 232 },
  { x: 1332, y: 636 },
  { x: 1014, y: 495 },
  { x: 1179, y: 533 },
  { x: 996, y: 623 },
  { x: 941, y: 712 },
  { x: 1416, y: 508 },
  { x: 1333, y: 191 },
  { x: 978, y: 776 },
  { x: 1443, y: 688 },
  { x: 1463, y: 483 },
  { x: 1120, y: 520 },
  { x: 1449, y: 755 },
  { x: 1398, y: 236 },
  { x: 1037, y: 733 },
  { x: 1431, y: 565 },
  { x: 1021, y: 471 },
  { x: 1428, y: 632 },
  { x: 1161, y: 493 },
  { x: 998, y: 439 },
  { x: 1363, y: 769 },
  { x: 934, y: 610 },
  { x: 990, y: 354 },
  { x: 1022, y: 454 },
  { x: 992, y: 680 },
  { x: 1435, y: 579 },
  { x: 997, y: 296 },
  { x: 1365, y: 176 },
  { x: 1063, y: 327 },
  { x: 1008, y: 434 },
  { x: 1292, y: 522 },
  { x: 1454, y: 254 },
  { x: 1209, y: 458 },
  { x: 1033, y: 717 },
  { x: 960, y: 258 },
  { x: 1423, y: 678 },
  { x: 1458, y: 525 },
  { x: 1048, y: 614 },
  { x: 1360, y: 262 },
  { x: 1335, y: 431 },
  { x: 1346, y: 271 },
  { x: 1019, y: 553 },
  { x: 1182, y: 485 },
  { x: 942, y: 539 },
  { x: 1290, y: 536 },
  { x: 1280, y: 479 },
  { x: 982, y: 719 },
  { x: 1442, y: 744 },
  { x: 1190, y: 441 },
  { x: 1347, y: 280 },
  { x: 943, y: 460 },
  { x: 1468, y: 566 },
  { x: 1450, y: 428 },
  { x: 1344, y: 645 },
  { x: 1469, y: 451 },
  { x: 1353, y: 192 },
  { x: 1448, y: 424 },
  { x: 1356, y: 407 },
  { x: 1342, y: 597 },
  { x: 959, y: 376 },
  { x: 1350, y: 664 },
  { x: 1023, y: 399 },
  { x: 1006, y: 787 },
  { x: 997, y: 815 },
  { x: 1418, y: 664 },
  { x: 930, y: 705 },
  { x: 972, y: 181 },
  { x: 942, y: 682 },
  { x: 1084, y: 553 },
  { x: 1361, y: 308 },
  { x: 1067, y: 781 },
  { x: 1059, y: 689 },
  { x: 1363, y: 754 },
  { x: 1156, y: 525 },
  { x: 1463, y: 422 },
  { x: 1410, y: 153 },
  { x: 1391, y: 612 },
  { x: 1279, y: 520 },
  { x: 1439, y: 827 },
  { x: 1342, y: 490 },
  { x: 1466, y: 780 },
  { x: 1353, y: 542 },
  { x: 1048, y: 271 },
  { x: 1241, y: 487 },
  { x: 973, y: 667 },
  { x: 1008, y: 141 },
  { x: 1311, y: 472 },
  { x: 1467, y: 467 },
  { x: 1358, y: 363 },
  { x: 937, y: 824 },
  { x: 1449, y: 176 },
  { x: 1064, y: 806 },
  { x: 1387, y: 688 },
  { x: 1227, y: 528 },
  { x: 951, y: 608 },
  { x: 998, y: 596 },
  { x: 957, y: 511 },
  { x: 1062, y: 613 },
  { x: 1238, y: 501 },
  { x: 1465, y: 518 },
  { x: 1443, y: 739 },
  { x: 1380, y: 718 },
  { x: 1050, y: 591 },
  { x: 950, y: 672 },
  { x: 1357, y: 437 },
  { x: 1395, y: 834 },
  { x: 1455, y: 783 },
  { x: 970, y: 139 },
  { x: 1415, y: 686 },
  { x: 984, y: 342 },
  { x: 1362, y: 148 },
  { x: 975, y: 402 },
  { x: 1020, y: 652 },
  { x: 1274, y: 444 },
  { x: 1385, y: 257 },
  { x: 1232, y: 479 },
  { x: 1094, y: 478 },
  { x: 1093, y: 473 },
  { x: 1345, y: 790 },
  { x: 974, y: 278 },
  { x: 1374, y: 266 },
  { x: 1083, y: 477 },
  { x: 1063, y: 355 },
  { x: 1061, y: 552 },
  { x: 1379, y: 453 },
  { x: 993, y: 748 },
  { x: 1021, y: 263 },
  { x: 1028, y: 337 },
  { x: 1460, y: 468 },
  { x: 935, y: 156 },
  { x: 1003, y: 503 },
  { x: 1452, y: 633 },
  { x: 1018, y: 143 },
  { x: 1007, y: 261 },
  { x: 1365, y: 175 },
  { x: 1408, y: 381 },
  { x: 1354, y: 749 },
  { x: 1450, y: 826 },
  { x: 965, y: 391 },
  { x: 1066, y: 731 },
  { x: 1153, y: 531 },
  { x: 1004, y: 179 },
  { x: 1101, y: 537 },
  { x: 1179, y: 505 },
  { x: 1016, y: 255 },
  { x: 958, y: 589 },
  { x: 961, y: 663 },
  { x: 938, y: 804 },
  { x: 994, y: 298 },
  { x: 1429, y: 640 },
  { x: 936, y: 333 },
  { x: 1430, y: 328 },
  { x: 1014, y: 546 },
  { x: 1105, y: 452 },
  { x: 1468, y: 294 },
  { x: 962, y: 639 },
  { x: 1461, y: 634 },
  { x: 1333, y: 669 },
  { x: 967, y: 684 },
  { x: 956, y: 436 },
  { x: 941, y: 303 },
  { x: 1436, y: 283 },
  { x: 1047, y: 279 },
  { x: 986, y: 829 },
  { x: 1416, y: 290 },
  { x: 1019, y: 461 },
  { x: 1017, y: 203 },
  { x: 1031, y: 426 },
  { x: 1338, y: 449 },
  { x: 992, y: 313 },
  { x: 1456, y: 155 },
  { x: 999, y: 445 },
  { x: 1405, y: 750 },
  { x: 1131, y: 442 },
  { x: 1344, y: 803 },
  { x: 938, y: 187 },
  { x: 1292, y: 526 },
  { x: 1420, y: 596 },
  { x: 1407, y: 779 },
  { x: 982, y: 566 },
  { x: 965, y: 294 },
  { x: 1058, y: 148 },
  { x: 1369, y: 725 },
  { x: 1231, y: 552 },
  { x: 1350, y: 623 },
  { x: 1008, y: 752 },
  { x: 1049, y: 515 },
  { x: 1409, y: 452 },
  { x: 962, y: 539 },
  { x: 1372, y: 735 },
  { x: 1375, y: 731 },
  { x: 1025, y: 373 },
  { x: 1183, y: 467 },
  { x: 999, y: 720 },
  { x: 1225, y: 513 },
  { x: 1026, y: 606 },
  { x: 1331, y: 363 },
  { x: 1399, y: 404 },
  { x: 1419, y: 781 },
  { x: 1017, y: 394 },
  { x: 1448, y: 400 },
  { x: 960, y: 668 },
  { x: 941, y: 142 },
  { x: 991, y: 688 },
  { x: 1365, y: 224 },
  { x: 934, y: 612 },
  { x: 1037, y: 396 },
  { x: 1351, y: 669 },
  { x: 1010, y: 829 },
  { x: 1030, y: 319 },
  { x: 932, y: 188 },
  { x: 1406, y: 444 },
  { x: 1413, y: 273 },
  { x: 942, y: 790 },
  { x: 1463, y: 391 },
  { x: 1336, y: 657 },
  { x: 1002, y: 737 },
  { x: 1159, y: 453 },
  { x: 1401, y: 542 },
  { x: 1346, y: 289 },
  { x: 978, y: 412 },
  { x: 1374, y: 633 },
  { x: 1107, y: 457 },
  { x: 1357, y: 462 },
  { x: 1070, y: 477 },
  { x: 1074, y: 441 },
  { x: 1213, y: 548 },
  { x: 949, y: 772 },
  { x: 1367, y: 622 },
  { x: 1028, y: 437 },
  { x: 1149, y: 502 },
  { x: 1290, y: 464 },
  { x: 1064, y: 411 },
  { x: 1450, y: 427 },
  { x: 984, y: 667 },
  { x: 1014, y: 434 },
  { x: 1466, y: 705 },
  { x: 948, y: 365 },
  { x: 1035, y: 789 },
  { x: 967, y: 221 },
  { x: 1361, y: 486 },
  { x: 1439, y: 180 },
  { x: 1443, y: 185 },
  { x: 1359, y: 661 },
  { x: 955, y: 281 },
  { x: 1435, y: 459 },
  { x: 1015, y: 369 },
  { x: 1370, y: 468 },
  { x: 1451, y: 209 },
  { x: 964, y: 783 },
  { x: 993, y: 271 },
  { x: 1462, y: 401 },
  { x: 1464, y: 823 },
  { x: 1441, y: 654 },
  { x: 1410, y: 478 },
  { x: 944, y: 184 },
  { x: 972, y: 658 },
  { x: 1052, y: 677 },
  { x: 1078, y: 525 },
  { x: 995, y: 698 },
  { x: 1445, y: 608 },
  { x: 961, y: 804 },
  { x: 954, y: 759 },
  { x: 1007, y: 347 },
  { x: 1061, y: 448 },
  { x: 1320, y: 546 },
  { x: 1217, y: 541 },
  { x: 1412, y: 648 },
  { x: 1001, y: 583 },
  { x: 929, y: 521 },
  { x: 1371, y: 751 },
  { x: 971, y: 579 },
  { x: 1376, y: 500 },
  { x: 1152, y: 455 },
  { x: 1385, y: 344 },
  { x: 951, y: 354 },
  { x: 1174, y: 527 },
  { x: 1415, y: 401 },
  { x: 1085, y: 438 },
  { x: 1326, y: 454 },
  { x: 1304, y: 504 },
  { x: 1224, y: 475 },
  { x: 994, y: 821 },
  { x: 1390, y: 478 },
  { x: 1338, y: 167 },
  { x: 1026, y: 669 },
  { x: 1329, y: 507 },
  { x: 1001, y: 787 },
  { x: 1022, y: 160 },
  { x: 1353, y: 813 },
  { x: 1005, y: 323 },
  { x: 1425, y: 486 },
  { x: 1008, y: 624 },
  { x: 992, y: 390 },
  { x: 1000, y: 457 },
  { x: 991, y: 148 },
  { x: 1443, y: 370 },
  { x: 982, y: 369 },
  { x: 955, y: 402 },
  { x: 1027, y: 538 },
  { x: 993, y: 666 },
  { x: 1016, y: 535 },
  { x: 987, y: 652 },
  { x: 959, y: 676 },
  { x: 1342, y: 213 },
  { x: 1333, y: 277 },
  { x: 938, y: 368 },
  { x: 1357, y: 165 },
  { x: 1367, y: 550 },
  { x: 1446, y: 611 },
  { x: 1365, y: 481 },
  { x: 1002, y: 288 },
  { x: 1388, y: 168 },
  { x: 953, y: 610 },
  { x: 1352, y: 447 },
  { x: 930, y: 796 },
  { x: 1379, y: 417 },
  { x: 1013, y: 604 },
  { x: 1417, y: 657 },
  { x: 1297, y: 512 },
  { x: 934, y: 210 },
  { x: 1041, y: 692 },
  { x: 1035, y: 584 },
  { x: 1066, y: 246 },
  { x: 986, y: 571 },
  { x: 933, y: 181 },
  { x: 1416, y: 455 },
  { x: 970, y: 387 },
  { x: 1056, y: 781 },
  { x: 957, y: 202 },
  { x: 1185, y: 471 },
  { x: 1356, y: 759 },
  { x: 966, y: 815 },
  { x: 1386, y: 362 },
  { x: 1466, y: 187 },
  { x: 1299, y: 511 },
  { x: 1068, y: 233 },
  { x: 965, y: 450 },
  { x: 1375, y: 509 },
  { x: 1401, y: 190 },
  { x: 1393, y: 385 },
  { x: 1328, y: 497 },
  { x: 1436, y: 250 },
  { x: 1036, y: 384 },
  { x: 1184, y: 458 },
  { x: 1067, y: 215 },
  { x: 1461, y: 295 },
  { x: 1397, y: 214 },
  { x: 1346, y: 797 },
  { x: 946, y: 171 },
  { x: 985, y: 341 },
  { x: 1021, y: 219 },
  { x: 1049, y: 828 },
  { x: 999, y: 249 },
  { x: 1029, y: 372 },
  { x: 1340, y: 269 },
  { x: 1028, y: 441 },
  { x: 1420, y: 573 },
  { x: 1377, y: 661 },
  { x: 1409, y: 313 },
  { x: 968, y: 778 },
  { x: 1463, y: 324 },
  { x: 969, y: 406 },
  { x: 1411, y: 453 },
  { x: 1060, y: 632 },
  { x: 1334, y: 198 },
  { x: 1054, y: 253 },
  { x: 1445, y: 708 },
  { x: 1421, y: 523 },
  { x: 962, y: 737 },
  { x: 947, y: 777 },
  { x: 979, y: 161 },
  { x: 1052, y: 557 },
  { x: 1031, y: 793 },
  { x: 1345, y: 248 },
  { x: 1042, y: 782 },
  { x: 1076, y: 463 },
  { x: 963, y: 317 },
  { x: 1428, y: 707 },
  { x: 1053, y: 691 },
  { x: 1419, y: 139 },
  { x: 1052, y: 750 },
  { x: 1200, y: 475 },
  { x: 1007, y: 662 },
  { x: 969, y: 414 },
  { x: 997, y: 665 },
  { x: 965, y: 678 },
  { x: 1057, y: 321 },
  { x: 983, y: 248 },
  { x: 1002, y: 548 },
  { x: 1135, y: 503 },
  { x: 940, y: 755 },
  { x: 1449, y: 624 },
  { x: 1370, y: 829 },
  { x: 981, y: 356 },
  { x: 1008, y: 529 },
  { x: 1359, y: 144 },
  { x: 946, y: 392 },
  { x: 1338, y: 554 },
  { x: 1024, y: 664 },
  { x: 1341, y: 689 },
  { x: 989, y: 347 },
  { x: 1204, y: 524 },
  { x: 1391, y: 715 },
  { x: 948, y: 487 },
  { x: 1467, y: 819 },
  { x: 1447, y: 493 },
  { x: 1050, y: 403 },
  { x: 1386, y: 815 },
  { x: 956, y: 394 },
  { x: 1372, y: 360 },
  { x: 1240, y: 502 },
  { x: 1419, y: 504 },
  { x: 1153, y: 441 },
  { x: 934, y: 629 },
  { x: 1035, y: 480 },
  { x: 1350, y: 283 },
  { x: 929, y: 238 },
  { x: 1375, y: 415 },
  { x: 1443, y: 223 },
  { x: 1176, y: 460 },
  { x: 1020, y: 215 },
  { x: 939, y: 471 },
  { x: 1358, y: 517 },
  { x: 1404, y: 290 },
  { x: 1005, y: 476 },
  { x: 1439, y: 409 },
  { x: 1041, y: 258 },
  { x: 1418, y: 590 },
  { x: 991, y: 249 },
  { x: 1421, y: 601 },
  { x: 935, y: 416 },
  { x: 1060, y: 293 },
  { x: 990, y: 348 },
  { x: 1213, y: 459 },
  { x: 1461, y: 279 },
  { x: 1376, y: 673 },
  { x: 944, y: 603 },
  { x: 982, y: 558 },
  { x: 1425, y: 265 },
  { x: 1424, y: 159 },
  { x: 1378, y: 188 },
  { x: 1405, y: 593 },
  { x: 1343, y: 447 },
  { x: 1018, y: 688 },
  { x: 1468, y: 213 },
  { x: 975, y: 444 },
  { x: 1357, y: 407 },
  { x: 1034, y: 182 },
  { x: 951, y: 746 },
  { x: 1446, y: 768 },
  { x: 1342, y: 535 },
  { x: 1457, y: 759 },
  { x: 1390, y: 247 },
  { x: 1033, y: 714 },
  { x: 1444, y: 202 },
  { x: 1400, y: 362 },
  { x: 1053, y: 528 },
  { x: 1271, y: 550 },
  { x: 1366, y: 267 },
  { x: 1414, y: 448 },
  { x: 1322, y: 537 },
  { x: 1001, y: 226 },
  { x: 1039, y: 160 },
  { x: 1175, y: 473 },
  { x: 1029, y: 706 },
  { x: 1023, y: 385 },
  { x: 980, y: 676 },
  { x: 1462, y: 681 },
  { x: 986, y: 282 },
  { x: 1437, y: 266 },
  { x: 994, y: 217 },
  { x: 1362, y: 383 },
  { x: 1079, y: 501 },
  { x: 1051, y: 405 },
  { x: 984, y: 811 },
  { x: 1412, y: 438 },
  { x: 1274, y: 442 },
  { x: 1399, y: 252 },
  { x: 1179, y: 443 },
  { x: 1331, y: 470 },
  { x: 961, y: 220 },
  { x: 931, y: 592 },
  { x: 1054, y: 261 },
  { x: 993, y: 483 },
  { x: 962, y: 168 },
  { x: 976, y: 586 },
  { x: 1310, y: 490 },
  { x: 1040, y: 205 },
  { x: 1063, y: 177 },
  { x: 1464, y: 774 },
  { x: 1406, y: 198 },
  { x: 1044, y: 386 },
  { x: 1326, y: 466 },
  { x: 930, y: 423 },
  { x: 966, y: 140 },
  { x: 1045, y: 643 },
  { x: 960, y: 595 },
  { x: 1011, y: 425 },
  { x: 1463, y: 603 },
  { x: 1027, y: 231 },
  { x: 1201, y: 500 },
  { x: 1282, y: 489 },
  { x: 932, y: 432 },
  { x: 934, y: 557 },
  { x: 1427, y: 769 },
  { x: 1395, y: 326 },
  { x: 1015, y: 587 },
  { x: 1338, y: 246 },
  { x: 948, y: 198 },
  { x: 1411, y: 286 },
  { x: 1409, y: 261 },
  { x: 1028, y: 524 },
  { x: 1315, y: 548 },
  { x: 987, y: 678 },
  { x: 1012, y: 816 },
  { x: 1341, y: 398 },
  { x: 1168, y: 479 },
  { x: 1003, y: 613 },
  { x: 1360, y: 189 },
  { x: 1372, y: 203 },
  { x: 977, y: 414 },
  { x: 1356, y: 488 },
  { x: 1101, y: 464 },
  { x: 1234, y: 531 },
  { x: 1343, y: 566 },
  { x: 937, y: 735 },
  { x: 1384, y: 646 },
  { x: 947, y: 518 },
  { x: 1440, y: 706 },
  { x: 1426, y: 459 },
  { x: 1019, y: 148 },
  { x: 999, y: 810 },
  { x: 962, y: 803 },
  { x: 1446, y: 271 },
  { x: 1429, y: 724 },
  { x: 954, y: 228 },
  { x: 1436, y: 536 },
  { x: 1346, y: 825 },
  { x: 1399, y: 434 },
  { x: 1403, y: 595 },
  { x: 1029, y: 150 },
  { x: 929, y: 563 },
  { x: 1267, y: 532 },
  { x: 953, y: 503 },
  { x: 945, y: 194 },
  { x: 1032, y: 725 },
  { x: 1364, y: 461 },
  { x: 1325, y: 471 },
  { x: 970, y: 401 },
  { x: 1450, y: 382 },
  { x: 1373, y: 399 },
  { x: 951, y: 213 },
  { x: 1053, y: 499 },
  { x: 964, y: 755 },
  { x: 1026, y: 831 },
  { x: 950, y: 247 },
  { x: 1412, y: 317 },
  { x: 1036, y: 714 },
  { x: 1455, y: 237 },
  { x: 988, y: 223 },
  { x: 1447, y: 254 },
  { x: 1239, y: 470 },
  { x: 1047, y: 210 },
  { x: 1238, y: 512 },
  { x: 1432, y: 649 },
  { x: 955, y: 529 },
  { x: 1332, y: 698 },
  { x: 1445, y: 426 },
  { x: 952, y: 417 },
  { x: 1351, y: 687 },
  { x: 1045, y: 258 },
  { x: 1062, y: 609 },
  { x: 974, y: 740 },
  { x: 1433, y: 662 },
  { x: 975, y: 686 },
  { x: 1097, y: 441 },
  { x: 986, y: 475 },
  { x: 1393, y: 146 },
  { x: 978, y: 817 },
  { x: 1037, y: 283 },
  { x: 1435, y: 173 },
  { x: 1318, y: 508 },
  { x: 1314, y: 453 },
  { x: 1380, y: 335 },
  { x: 1261, y: 543 },
  { x: 991, y: 801 },
  { x: 1367, y: 478 },
  { x: 1255, y: 465 },
  { x: 1347, y: 614 },
  { x: 1056, y: 169 },
  { x: 1385, y: 235 },
  { x: 1430, y: 593 },
  { x: 935, y: 556 },
  { x: 1437, y: 718 },
  { x: 1362, y: 812 },
  { x: 1007, y: 745 },
  { x: 1439, y: 547 },
  { x: 1052, y: 205 },
  { x: 1370, y: 243 },
  { x: 1420, y: 728 },
  { x: 1452, y: 700 },
  { x: 1394, y: 549 },
  { x: 997, y: 349 },
  { x: 944, y: 809 },
  { x: 1466, y: 216 },
  { x: 1042, y: 351 },
  { x: 1416, y: 463 },
  { x: 1044, y: 781 },
  { x: 1425, y: 560 },
  { x: 1064, y: 570 },
  { x: 931, y: 751 },
  { x: 982, y: 200 },
  { x: 1419, y: 219 },
  { x: 1391, y: 424 },
  { x: 1170, y: 501 },
  { x: 973, y: 670 },
  { x: 957, y: 397 },
  { x: 1043, y: 346 },
  { x: 1060, y: 220 },
  { x: 1339, y: 155 },
  { x: 958, y: 741 },
  { x: 1054, y: 191 },
  { x: 961, y: 625 },
  { x: 946, y: 510 },
  { x: 1426, y: 172 },
  { x: 1340, y: 509 },
  { x: 984, y: 670 },
  { x: 993, y: 357 },
  { x: 951, y: 327 },
  { x: 1297, y: 497 },
  { x: 1036, y: 721 },
  { x: 1258, y: 439 },
  { x: 1418, y: 173 },
  { x: 1444, y: 355 },
  { x: 1041, y: 386 },
  { x: 1368, y: 485 },
  { x: 1333, y: 465 },
  { x: 1457, y: 602 },
  { x: 1465, y: 586 },
  { x: 931, y: 631 },
  { x: 972, y: 401 },
  { x: 1427, y: 226 },
  { x: 1052, y: 330 },
  { x: 1005, y: 545 },
  { x: 1193, y: 496 },
  { x: 1035, y: 819 },
  { x: 1356, y: 436 },
  { x: 1093, y: 454 },
  { x: 1048, y: 370 },
  { x: 1033, y: 787 },
  { x: 1188, y: 479 },
  { x: 929, y: 470 },
  { x: 978, y: 540 },
  { x: 1002, y: 431 },
  { x: 1009, y: 584 },
  { x: 1020, y: 460 },
  { x: 1042, y: 618 },
  { x: 1388, y: 238 },
  { x: 1367, y: 756 },
  { x: 1026, y: 191 },
  { x: 1452, y: 711 },
  { x: 1441, y: 239 },
  { x: 1467, y: 608 },
  { x: 1421, y: 833 },
  { x: 1302, y: 448 },
  { x: 1015, y: 547 },
  { x: 988, y: 292 },
  { x: 938, y: 149 },
  { x: 939, y: 747 },
  { x: 985, y: 484 },
  { x: 1437, y: 817 },
  { x: 979, y: 314 },
  { x: 1381, y: 575 },
  { x: 1443, y: 562 },
  { x: 1246, y: 452 },
  { x: 957, y: 383 },
  { x: 1299, y: 449 },
  { x: 935, y: 655 },
  { x: 1334, y: 712 },
  { x: 977, y: 821 },
  { x: 1024, y: 195 },
  { x: 1440, y: 473 },
  { x: 999, y: 225 },
  { x: 1051, y: 250 },
  { x: 1027, y: 684 },
  { x: 1383, y: 179 },
  { x: 1446, y: 571 },
  { x: 1013, y: 778 },
  { x: 1244, y: 476 },
  { x: 945, y: 464 },
  { x: 1412, y: 333 },
  { x: 1236, y: 546 },
  { x: 1134, y: 501 },
  { x: 1043, y: 150 },
  { x: 1205, y: 505 },
  { x: 1019, y: 217 },
  { x: 995, y: 579 },
  { x: 1338, y: 585 },
  { x: 1430, y: 766 },
  { x: 1256, y: 554 },
  { x: 1393, y: 182 },
  { x: 1021, y: 221 },
  { x: 1136, y: 508 },
  { x: 1062, y: 344 },
  { x: 1417, y: 236 },
  { x: 1331, y: 557 },
  { x: 1006, y: 260 },
  { x: 1144, y: 537 },
  { x: 1458, y: 495 },
  { x: 965, y: 751 },
  { x: 997, y: 367 },
  { x: 1000, y: 548 },
  { x: 1408, y: 360 },
  { x: 1372, y: 285 },
  { x: 1447, y: 767 },
  { x: 994, y: 240 },
  { x: 968, y: 657 },
  { x: 1109, y: 520 },
  { x: 1411, y: 148 },
  { x: 1379, y: 818 },
  { x: 958, y: 529 },
  { x: 1076, y: 442 },
  { x: 1336, y: 801 },
  { x: 1011, y: 151 },
  { x: 1419, y: 753 },
  { x: 1420, y: 820 },
  { x: 1090, y: 481 },
  { x: 1341, y: 263 },
  { x: 973, y: 788 },
  { x: 943, y: 415 },
  { x: 1339, y: 432 },
  { x: 1414, y: 750 },
  { x: 1407, y: 366 },
  { x: 1365, y: 572 },
  { x: 1442, y: 293 },
  { x: 1056, y: 413 },
  { x: 1397, y: 274 },
  { x: 1132, y: 531 },
  { x: 974, y: 491 },
  { x: 1373, y: 206 },
  { x: 964, y: 618 },
  { x: 1381, y: 431 },
  { x: 1218, y: 530 },
  { x: 933, y: 149 },
  { x: 932, y: 528 },
  { x: 979, y: 540 },
  { x: 1409, y: 147 },
  { x: 959, y: 719 },
  { x: 1049, y: 261 },
  { x: 1030, y: 366 },
  { x: 1461, y: 336 },
  { x: 1415, y: 710 },
  { x: 1397, y: 522 },
  { x: 1402, y: 581 },
  { x: 1421, y: 557 },
  { x: 1339, y: 259 },
  { x: 934, y: 297 },
  { x: 1359, y: 349 },
  { x: 1432, y: 815 },
  { x: 966, y: 412 },
  { x: 1076, y: 444 },
  { x: 1447, y: 188 },
  { x: 1046, y: 697 },
  { x: 1380, y: 531 },
  { x: 1047, y: 258 },
  { x: 971, y: 684 },
  { x: 1391, y: 688 },
  { x: 1017, y: 325 },
  { x: 1366, y: 224 },
  { x: 1022, y: 229 },
  { x: 1423, y: 269 },
  { x: 1414, y: 389 },
  { x: 1247, y: 463 },
  { x: 1337, y: 241 },
  { x: 1041, y: 181 },
  { x: 1160, y: 524 },
  { x: 1050, y: 399 },
  { x: 1008, y: 655 },
  { x: 1362, y: 434 },
  { x: 1428, y: 208 },
  { x: 1045, y: 665 },
  { x: 1452, y: 167 },
  { x: 1340, y: 630 },
  { x: 1403, y: 313 },
  { x: 1054, y: 350 },
  { x: 1405, y: 286 },
  { x: 941, y: 437 },
  { x: 1056, y: 643 },
  { x: 1039, y: 706 },
  { x: 1272, y: 488 },
  { x: 942, y: 166 },
  { x: 1441, y: 804 },
  { x: 1014, y: 421 },
  { x: 1439, y: 201 },
  { x: 969, y: 348 },
  { x: 987, y: 440 },
  { x: 1464, y: 674 },
  { x: 1466, y: 607 },
  { x: 1351, y: 590 },
  { x: 948, y: 746 },
  { x: 1291, y: 555 },
  { x: 1379, y: 700 },
  { x: 1033, y: 690 },
  { x: 992, y: 487 },
  { x: 1302, y: 507 },
  { x: 1344, y: 612 },
  { x: 1449, y: 205 },
  { x: 1390, y: 558 },
  { x: 1412, y: 646 },
  { x: 1005, y: 827 },
  { x: 1111, y: 452 },
  { x: 1383, y: 505 },
  { x: 1370, y: 272 },
  { x: 1241, y: 521 },
  { x: 1458, y: 361 },
  { x: 1330, y: 312 },
  { x: 1284, y: 480 },
  { x: 1382, y: 625 },
  { x: 1427, y: 606 },
  { x: 1252, y: 533 },
  { x: 1375, y: 549 },
  { x: 1360, y: 234 },
  { x: 993, y: 381 },
  { x: 965, y: 550 },
  { x: 1392, y: 225 },
  { x: 1331, y: 756 },
  { x: 1335, y: 687 },
  { x: 1396, y: 511 },
  { x: 1026, y: 368 },
  { x: 1038, y: 430 },
  { x: 1446, y: 466 },
  { x: 999, y: 514 },
  { x: 1376, y: 717 },
  { x: 1034, y: 206 },
  { x: 1364, y: 680 },
  { x: 947, y: 718 },
  { x: 973, y: 461 },
  { x: 958, y: 795 },
  { x: 1000, y: 315 },
  { x: 960, y: 209 },
  { x: 930, y: 720 },
  { x: 1131, y: 506 },
  { x: 1378, y: 689 },
  { x: 1400, y: 780 },
  { x: 994, y: 492 },
  { x: 1358, y: 585 },
  { x: 983, y: 792 },
  { x: 1384, y: 291 },
  { x: 1233, y: 464 },
  { x: 1097, y: 441 },
  { x: 1052, y: 811 },
  { x: 1347, y: 471 },
  { x: 1371, y: 546 },
  { x: 1101, y: 502 },
  { x: 1299, y: 477 },
  { x: 1336, y: 265 },
  { x: 1268, y: 548 },
  { x: 1244, y: 510 },
  { x: 1934, y: 781 },
  { x: 1896, y: 561 },
  { x: 1841, y: 273 },
  { x: 1903, y: 374 },
  { x: 1893, y: 793 },
  { x: 1928, y: 251 },
  { x: 1918, y: 573 },
  { x: 1940, y: 213 },
  { x: 1830, y: 553 },
  { x: 1878, y: 325 },
  { x: 1847, y: 790 },
  { x: 1964, y: 710 },
  { x: 1863, y: 500 },
  { x: 1862, y: 189 },
  { x: 1879, y: 457 },
  { x: 1897, y: 211 },
  { x: 1917, y: 806 },
  { x: 1939, y: 782 },
  { x: 1852, y: 516 },
  { x: 1969, y: 343 },
  { x: 1842, y: 157 },
  { x: 1876, y: 687 },
  { x: 1951, y: 378 },
  { x: 1955, y: 509 },
  { x: 1963, y: 437 },
  { x: 1856, y: 761 },
  { x: 1834, y: 319 },
  { x: 1946, y: 187 },
  { x: 1938, y: 732 },
  { x: 1921, y: 808 },
  { x: 1838, y: 670 },
  { x: 1960, y: 642 },
  { x: 1845, y: 606 },
  { x: 1883, y: 307 },
  { x: 1935, y: 627 },
  { x: 1925, y: 534 },
  { x: 1956, y: 783 },
  { x: 1839, y: 633 },
  { x: 1962, y: 379 },
  { x: 1854, y: 290 },
  { x: 1861, y: 424 },
  { x: 1882, y: 355 },
  { x: 1922, y: 246 },
  { x: 1929, y: 230 },
  { x: 1890, y: 623 },
  { x: 1965, y: 237 },
  { x: 1933, y: 669 },
  { x: 1844, y: 750 },
  { x: 1926, y: 285 },
  { x: 1918, y: 263 },
  { x: 1901, y: 830 },
  { x: 1909, y: 457 },
  { x: 1845, y: 567 },
  { x: 1917, y: 586 },
  { x: 1886, y: 300 },
  { x: 1913, y: 261 },
  { x: 1839, y: 253 },
  { x: 1835, y: 418 },
  { x: 1846, y: 550 },
  { x: 1882, y: 815 },
  { x: 1863, y: 730 },
  { x: 1900, y: 763 },
  { x: 1848, y: 411 },
  { x: 1874, y: 254 },
  { x: 1953, y: 379 },
  { x: 1897, y: 434 },
  { x: 1954, y: 477 },
  { x: 1891, y: 697 },
  { x: 1934, y: 266 },
  { x: 1899, y: 665 },
  { x: 1910, y: 247 },
  { x: 1879, y: 164 },
  { x: 1966, y: 698 },
  { x: 1963, y: 487 },
  { x: 1898, y: 381 },
  { x: 1887, y: 415 },
  { x: 1908, y: 796 },
  { x: 1962, y: 245 },
  { x: 1854, y: 802 },
  { x: 1894, y: 653 },
  { x: 1941, y: 476 },
  { x: 1925, y: 585 },
  { x: 1923, y: 398 },
  { x: 1873, y: 390 },
  { x: 1944, y: 332 },
  { x: 1905, y: 148 },
  { x: 1864, y: 650 },
  { x: 1847, y: 558 },
  { x: 1915, y: 669 },
  { x: 1946, y: 749 },
  { x: 1938, y: 349 },
  { x: 1871, y: 194 },
  { x: 1856, y: 749 },
  { x: 1962, y: 568 },
  { x: 1884, y: 329 },
  { x: 1849, y: 699 },
  { x: 1945, y: 543 },
  { x: 1876, y: 652 },
  { x: 1917, y: 315 },
  { x: 1952, y: 511 },
  { x: 1958, y: 732 },
  { x: 1948, y: 147 },
  { x: 1951, y: 700 },
  { x: 1913, y: 432 },
  { x: 1886, y: 195 },
  { x: 1841, y: 655 },
  { x: 1927, y: 268 },
  { x: 1939, y: 303 },
  { x: 1947, y: 804 },
  { x: 1926, y: 527 },
  { x: 1932, y: 701 },
  { x: 1887, y: 820 },
  { x: 1859, y: 228 },
  { x: 1879, y: 533 },
  { x: 1897, y: 375 },
  { x: 1895, y: 168 },
  { x: 1831, y: 785 },
  { x: 1830, y: 405 },
  { x: 1961, y: 793 },
  { x: 1865, y: 181 },
  { x: 1861, y: 493 },
  { x: 1881, y: 164 },
  { x: 1924, y: 340 },
  { x: 1866, y: 585 },
  { x: 1874, y: 202 },
  { x: 1858, y: 633 },
  { x: 1901, y: 604 },
  { x: 1909, y: 458 },
  { x: 1862, y: 575 },
  { x: 1892, y: 233 },
  { x: 1870, y: 151 },
  { x: 1959, y: 696 },
  { x: 1882, y: 485 },
  { x: 1903, y: 809 },
  { x: 1867, y: 748 },
  { x: 1890, y: 441 },
  { x: 1834, y: 218 },
  { x: 1845, y: 300 },
  { x: 1854, y: 414 },
  { x: 1941, y: 766 },
  { x: 1968, y: 293 },
  { x: 1839, y: 349 },
  { x: 1853, y: 384 },
  { x: 1963, y: 186 },
  { x: 1889, y: 183 },
  { x: 1865, y: 659 },
  { x: 1884, y: 576 },
  { x: 1915, y: 762 },
  { x: 1856, y: 525 },
  { x: 1957, y: 227 },
  { x: 1941, y: 487 },
  { x: 1861, y: 390 },
  { x: 1909, y: 335 },
  { x: 1933, y: 641 },
  { x: 1927, y: 528 },
  { x: 1880, y: 635 },
  { x: 1849, y: 725 },
  { x: 1938, y: 501 },
  { x: 1913, y: 649 },
  { x: 1864, y: 269 },
  { x: 1936, y: 350 },
  { x: 1932, y: 479 },
  { x: 1948, y: 694 },
  { x: 1942, y: 160 },
  { x: 1890, y: 660 },
  { x: 1904, y: 327 },
  { x: 1898, y: 567 },
  { x: 1955, y: 587 },
  { x: 1900, y: 695 },
  { x: 1924, y: 348 },
  { x: 1905, y: 772 },
  { x: 1869, y: 755 },
  { x: 1951, y: 206 },
  { x: 1853, y: 531 },
  { x: 1902, y: 241 },
  { x: 1914, y: 726 },
  { x: 1879, y: 505 },
  { x: 1937, y: 785 },
  { x: 1920, y: 622 },
  { x: 1867, y: 724 },
  { x: 1908, y: 735 },
  { x: 1882, y: 793 },
  { x: 1917, y: 678 },
  { x: 1911, y: 671 },
  { x: 1918, y: 786 },
  { x: 1857, y: 620 },
  { x: 1870, y: 637 },
  { x: 1922, y: 581 },
  { x: 1937, y: 724 },
  { x: 1831, y: 442 },
  { x: 1834, y: 399 },
  { x: 1912, y: 812 },
  { x: 1893, y: 414 },
  { x: 1856, y: 230 },
  { x: 1914, y: 436 },
  { x: 1931, y: 552 },
  { x: 1950, y: 170 },
  { x: 1957, y: 287 },
  { x: 1926, y: 647 },
  { x: 1911, y: 234 },
  { x: 1866, y: 408 },
  { x: 1961, y: 191 },
  { x: 1885, y: 762 },
  { x: 1883, y: 467 },
  { x: 1886, y: 353 },
  { x: 1895, y: 325 },
  { x: 1925, y: 513 },
  { x: 1862, y: 588 },
  { x: 1876, y: 813 },
  { x: 1945, y: 729 },
  { x: 1919, y: 386 },
  { x: 1949, y: 428 },
  { x: 1941, y: 563 },
  { x: 1850, y: 424 },
  { x: 1944, y: 263 },
  { x: 1859, y: 453 },
  { x: 1857, y: 722 },
  { x: 1903, y: 732 },
  { x: 1905, y: 585 },
  { x: 1861, y: 388 },
  { x: 1899, y: 164 },
  { x: 1936, y: 664 },
  { x: 1913, y: 548 },
  { x: 1849, y: 502 },
  { x: 1839, y: 393 },
  { x: 1878, y: 820 },
  { x: 1966, y: 362 },
  { x: 1921, y: 748 },
  { x: 1928, y: 226 },
  { x: 1898, y: 316 },
  { x: 1967, y: 385 },
  { x: 1871, y: 141 },
  { x: 1920, y: 809 },
  { x: 1946, y: 655 },
  { x: 1960, y: 758 },
  { x: 1837, y: 176 },
  { x: 1915, y: 718 },
  { x: 1917, y: 541 },
  { x: 1858, y: 743 },
  { x: 1909, y: 145 },
  { x: 1891, y: 237 },
  { x: 1948, y: 351 },
  { x: 1852, y: 455 },
  { x: 1842, y: 687 },
  { x: 1874, y: 527 },
  { x: 1852, y: 715 },
  { x: 1889, y: 434 },
  { x: 1966, y: 333 },
  { x: 1924, y: 475 },
  { x: 1898, y: 355 },
  { x: 1866, y: 668 },
  { x: 1893, y: 728 },
  { x: 1832, y: 598 },
  { x: 1865, y: 359 },
  { x: 1953, y: 194 },
  { x: 1947, y: 315 },
  { x: 1944, y: 371 },
  { x: 1933, y: 685 },
  { x: 1960, y: 232 },
  { x: 1833, y: 695 },
  { x: 1878, y: 392 },
  { x: 1943, y: 654 },
  { x: 1948, y: 247 },
  { x: 1934, y: 735 },
  { x: 1900, y: 373 },
  { x: 1937, y: 388 },
  { x: 1909, y: 377 },
  { x: 1837, y: 783 },
  { x: 1894, y: 142 },
  { x: 1940, y: 763 },
  { x: 1851, y: 285 },
  { x: 1902, y: 412 },
  { x: 1836, y: 703 },
  { x: 1883, y: 391 },
  { x: 1925, y: 613 },
  { x: 1885, y: 471 },
  { x: 1859, y: 702 },
  { x: 1876, y: 812 },
  { x: 1881, y: 622 },
  { x: 1929, y: 344 },
  { x: 1857, y: 634 },
  { x: 1905, y: 565 },
  { x: 1931, y: 791 },
  { x: 1884, y: 458 },
  { x: 1969, y: 413 },
  { x: 1890, y: 146 },
  { x: 1888, y: 825 },
  { x: 1868, y: 349 },
  { x: 1968, y: 688 },
  { x: 1958, y: 308 },
  { x: 1834, y: 623 },
  { x: 1938, y: 641 },
  { x: 1864, y: 192 },
  { x: 1915, y: 560 },
  { x: 1942, y: 282 },
  { x: 1923, y: 664 },
  { x: 1900, y: 475 },
  { x: 1842, y: 636 },
  { x: 1928, y: 341 },
  { x: 1883, y: 657 },
  { x: 1835, y: 503 },
  { x: 1915, y: 801 },
  { x: 1865, y: 303 },
  { x: 1859, y: 739 },
  { x: 1834, y: 663 },
  { x: 1904, y: 524 },
  { x: 1929, y: 154 },
  { x: 1869, y: 412 },
  { x: 1940, y: 502 },
  { x: 1967, y: 760 },
  { x: 1831, y: 276 },
  { x: 1965, y: 355 },
  { x: 1853, y: 441 },
  { x: 1833, y: 656 },
  { x: 1876, y: 460 },
  { x: 1908, y: 180 },
  { x: 1921, y: 155 },
  { x: 1867, y: 560 },
  { x: 1885, y: 677 },
  { x: 1901, y: 671 },
  { x: 1897, y: 707 },
  { x: 1832, y: 350 },
  { x: 1913, y: 459 },
  { x: 1932, y: 599 },
  { x: 1874, y: 778 },
  { x: 1924, y: 685 },
  { x: 1947, y: 777 },
  { x: 1839, y: 720 },
  { x: 1860, y: 598 },
  { x: 1852, y: 782 },
  { x: 1912, y: 340 },
  { x: 1907, y: 604 },
  { x: 1875, y: 473 },
  { x: 1935, y: 587 },
  { x: 1893, y: 747 },
  { x: 1952, y: 232 },
  { x: 1879, y: 443 },
  { x: 1946, y: 792 },
  { x: 1837, y: 687 },
  { x: 1936, y: 218 },
  { x: 1844, y: 174 },
  { x: 1937, y: 611 },
  { x: 1901, y: 500 },
  { x: 1888, y: 227 },
  { x: 1853, y: 562 },
  { x: 1923, y: 149 },
  { x: 1841, y: 727 },
  { x: 1837, y: 697 },
  { x: 1849, y: 766 },
  { x: 1893, y: 608 },
  { x: 1894, y: 331 },
  { x: 1874, y: 378 },
  { x: 1868, y: 479 },
  { x: 1919, y: 564 },
  { x: 1934, y: 531 },
  { x: 1957, y: 357 },
  { x: 1882, y: 303 },
  { x: 1904, y: 304 },
  { x: 1878, y: 288 },
  { x: 1964, y: 269 },
  { x: 1967, y: 532 },
  { x: 1958, y: 418 },
  { x: 1922, y: 255 },
  { x: 1857, y: 310 },
  { x: 1939, y: 470 },
  { x: 1875, y: 658 },
  { x: 1938, y: 512 },
  { x: 1856, y: 285 },
  { x: 1961, y: 543 },
  { x: 1950, y: 806 },
  { x: 1955, y: 465 },
  { x: 1929, y: 584 },
  { x: 1918, y: 632 },
  { x: 1835, y: 726 },
  { x: 1855, y: 818 },
  { x: 1879, y: 147 },
  { x: 1899, y: 253 },
  { x: 1933, y: 313 },
  { x: 1870, y: 501 },
  { x: 1897, y: 141 },
  { x: 1843, y: 652 },
  { x: 1937, y: 183 },
  { x: 1915, y: 309 },
  { x: 1940, y: 733 },
  { x: 1958, y: 439 },
  { x: 1955, y: 808 },
  { x: 1893, y: 496 },
  { x: 1962, y: 423 },
  { x: 1887, y: 658 },
  { x: 1866, y: 635 },
  { x: 1877, y: 361 },
  { x: 1888, y: 479 },
  { x: 1917, y: 246 },
  { x: 1884, y: 326 },
  { x: 1894, y: 692 },
  { x: 1963, y: 392 },
  { x: 1831, y: 592 },
  { x: 1840, y: 248 },
  { x: 1867, y: 645 },
  { x: 1911, y: 324 },
  { x: 1929, y: 802 },
  { x: 1946, y: 452 },
  { x: 1879, y: 220 },
  { x: 1846, y: 261 },
  { x: 1919, y: 669 },
  { x: 1903, y: 403 },
  { x: 1944, y: 476 },
  { x: 1942, y: 665 },
  { x: 1936, y: 546 },
  { x: 1834, y: 501 },
  { x: 1905, y: 505 },
  { x: 1956, y: 554 },
  { x: 1891, y: 621 },
  { x: 1836, y: 508 },
  { x: 1967, y: 770 },
  { x: 1921, y: 271 },
  { x: 1920, y: 759 },
  { x: 1932, y: 335 },
  { x: 1909, y: 656 },
  { x: 1844, y: 537 },
  { x: 1833, y: 163 },
  { x: 1845, y: 245 },
  { x: 1935, y: 322 },
  { x: 1926, y: 135 },
  { x: 1830, y: 745 },
  { x: 1885, y: 428 },
  { x: 1897, y: 169 },
  { x: 1858, y: 141 },
  { x: 1959, y: 209 },
  { x: 1860, y: 613 },
  { x: 1873, y: 765 },
  { x: 1916, y: 412 },
  { x: 1943, y: 204 },
  { x: 1889, y: 208 },
  { x: 1949, y: 192 },
  { x: 1832, y: 531 },
  { x: 1840, y: 715 },
  { x: 1918, y: 530 },
  { x: 1856, y: 210 },
  { x: 1830, y: 382 },
  { x: 1891, y: 579 },
  { x: 1881, y: 645 },
  { x: 1839, y: 673 },
  { x: 1876, y: 187 },
  { x: 1834, y: 644 },
  { x: 1914, y: 588 },
  { x: 1899, y: 212 },
  { x: 1928, y: 620 },
  { x: 1947, y: 463 },
  { x: 1854, y: 621 },
  { x: 1886, y: 144 },
  { x: 1930, y: 174 },
  { x: 1860, y: 524 },
  { x: 1962, y: 779 },
  { x: 1948, y: 367 },
  { x: 1829, y: 309 },
  { x: 1936, y: 337 },
  { x: 1960, y: 737 },
  { x: 1832, y: 242 },
  { x: 1850, y: 584 },
  { x: 1864, y: 171 },
  { x: 1941, y: 521 },
  { x: 1939, y: 158 },
  { x: 1894, y: 285 },
  { x: 1952, y: 533 },
  { x: 1878, y: 789 },
  { x: 1885, y: 159 },
  { x: 1883, y: 323 },
  { x: 1879, y: 583 },
  { x: 1835, y: 575 },
  { x: 1927, y: 138 },
  { x: 1934, y: 666 },
  { x: 1963, y: 243 },
  { x: 1888, y: 364 },
  { x: 1910, y: 302 },
  { x: 1954, y: 768 },
  { x: 1851, y: 330 },
  { x: 1964, y: 766 },
  { x: 1969, y: 299 },
  { x: 1896, y: 813 },
  { x: 1831, y: 506 },
  { x: 1908, y: 728 },
  { x: 1929, y: 681 },
  { x: 1847, y: 300 },
  { x: 1889, y: 200 },
  { x: 1933, y: 464 },
  { x: 1931, y: 637 },
  { x: 1959, y: 731 },
  { x: 1848, y: 310 },
  { x: 1958, y: 203 },
  { x: 1897, y: 371 },
  { x: 1968, y: 548 },
  { x: 1944, y: 510 },
  { x: 2789, y: 376 },
  { x: 2473, y: 549 },
  { x: 2779, y: 198 },
  { x: 2794, y: 168 },
  { x: 2634, y: 781 },
  { x: 2739, y: 274 },
  { x: 2378, y: 408 },
  { x: 2367, y: 312 },
  { x: 2603, y: 374 },
  { x: 2593, y: 793 },
  { x: 2503, y: 525 },
  { x: 2724, y: 444 },
  { x: 2793, y: 350 },
  { x: 2694, y: 512 },
  { x: 2406, y: 171 },
  { x: 2530, y: 553 },
  { x: 2823, y: 349 },
  { x: 2578, y: 325 },
  { x: 2547, y: 790 },
  { x: 2875, y: 288 },
  { x: 2664, y: 710 },
  { x: 2827, y: 348 },
  { x: 2781, y: 337 },
  { x: 2617, y: 806 },
  { x: 2639, y: 782 },
  { x: 2395, y: 272 },
  { x: 2680, y: 547 },
  { x: 2524, y: 817 },
  { x: 2780, y: 293 },
  { x: 2371, y: 143 },
  { x: 2464, y: 532 },
  { x: 2689, y: 413 },
  { x: 2510, y: 815 },
  { x: 2669, y: 343 },
  { x: 2761, y: 240 },
  { x: 2388, y: 147 },
  { x: 2828, y: 356 },
  { x: 2691, y: 404 },
  { x: 2576, y: 687 },
  { x: 2457, y: 514 },
  { x: 2743, y: 609 },
  { x: 2651, y: 378 },
  { x: 2683, y: 794 },
  { x: 2436, y: 381 },
  { x: 2364, y: 331 },
  { x: 2458, y: 467 },
  { x: 2679, y: 339 },
  { x: 2556, y: 761 },
  { x: 2534, y: 319 },
  { x: 2681, y: 507 },
  { x: 2638, y: 732 },
  { x: 2728, y: 704 },
  { x: 2709, y: 465 },
  { x: 2720, y: 708 },
  { x: 2621, y: 808 },
  { x: 2712, y: 531 },
  { x: 2493, y: 778 },
  { x: 2423, y: 209 },
  { x: 2447, y: 483 },
  { x: 2322, y: 231 },
  { x: 2448, y: 590 },
  { x: 2538, y: 670 },
  { x: 2755, y: 417 },
  { x: 2660, y: 642 },
  { x: 2420, y: 497 },
  { x: 2397, y: 449 },
  { x: 2692, y: 755 },
  { x: 2822, y: 309 },
  { x: 2377, y: 268 },
  { x: 2545, y: 606 },
  { x: 2583, y: 307 },
  { x: 2635, y: 627 },
  { x: 2326, y: 138 },
  { x: 2704, y: 671 },
  { x: 2703, y: 472 },
  { x: 2753, y: 221 },
  { x: 2454, y: 403 },
  { x: 2699, y: 588 },
  { x: 2752, y: 294 },
  { x: 2833, y: 205 },
  { x: 2656, y: 783 },
  { x: 2490, y: 733 },
  { x: 2539, y: 633 },
  { x: 2750, y: 302 },
  { x: 2662, y: 379 },
  { x: 2790, y: 142 },
  { x: 2419, y: 372 },
  { x: 2880, y: 217 },
  { x: 2349, y: 342 },
  { x: 2376, y: 253 },
  { x: 2839, y: 161 },
  { x: 2526, y: 299 },
  { x: 2785, y: 416 },
  { x: 2820, y: 163 },
  { x: 2438, y: 569 },
  { x: 2582, y: 355 },
  { x: 2471, y: 600 },
  { x: 2430, y: 476 },
  { x: 2504, y: 488 },
  { x: 2796, y: 474 },
  { x: 2825, y: 306 },
  { x: 2829, y: 212 },
  { x: 2421, y: 347 },
  { x: 2363, y: 338 },
  { x: 2489, y: 341 },
  { x: 2472, y: 480 },
  { x: 2883, y: 259 },
  { x: 2426, y: 409 },
  { x: 2335, y: 183 },
  { x: 2729, y: 297 },
  { x: 2633, y: 669 },
  { x: 2441, y: 310 },
  { x: 2492, y: 740 },
  { x: 2717, y: 397 },
  { x: 2544, y: 750 },
  { x: 2686, y: 388 },
  { x: 2762, y: 535 },
  { x: 2396, y: 281 },
  { x: 2710, y: 305 },
  { x: 2718, y: 532 },
  { x: 2822, y: 222 },
  { x: 2833, y: 174 },
  { x: 2676, y: 356 },
  { x: 2707, y: 547 },
  { x: 2454, y: 506 },
  { x: 2601, y: 830 },
  { x: 2545, y: 567 },
  { x: 2586, y: 300 },
  { x: 2340, y: 323 },
  { x: 2755, y: 548 },
  { x: 2342, y: 167 },
  { x: 2902, y: 162 },
  { x: 2696, y: 402 },
  { x: 2823, y: 401 },
  { x: 2677, y: 309 },
  { x: 2449, y: 463 },
  { x: 2403, y: 504 },
  { x: 2465, y: 632 },
  { x: 2769, y: 280 },
  { x: 2808, y: 327 },
  { x: 2406, y: 170 },
  { x: 2837, y: 240 },
  { x: 2358, y: 340 },
  { x: 2582, y: 815 },
  { x: 2318, y: 151 },
  { x: 2563, y: 730 },
  { x: 2600, y: 763 },
  { x: 2413, y: 274 },
  { x: 2697, y: 629 },
  { x: 2460, y: 607 },
  { x: 2474, y: 539 },
  { x: 2834, y: 284 },
  { x: 2510, y: 721 },
  { x: 2775, y: 414 },
  { x: 2747, y: 371 },
  { x: 2653, y: 379 },
  { x: 2455, y: 573 },
  { x: 2525, y: 787 },
  { x: 2794, y: 149 },
  { x: 2503, y: 444 },
  { x: 2489, y: 439 },
  { x: 2521, y: 694 },
  { x: 2472, y: 551 },
  { x: 2811, y: 353 },
  { x: 2480, y: 526 },
  { x: 2382, y: 176 },
  { x: 2726, y: 680 },
  { x: 2363, y: 358 },
  { x: 2694, y: 571 },
  { x: 2731, y: 370 },
  { x: 2742, y: 590 },
  { x: 2666, y: 698 },
  { x: 2663, y: 487 },
  { x: 2598, y: 381 },
  { x: 2693, y: 690 },
  { x: 2770, y: 278 },
  { x: 2479, y: 559 },
  { x: 2793, y: 312 },
  { x: 2608, y: 796 },
  { x: 2453, y: 630 },
  { x: 2554, y: 802 },
  { x: 2673, y: 646 },
  { x: 2497, y: 580 },
  { x: 2685, y: 568 },
  { x: 2702, y: 613 },
  { x: 2623, y: 398 },
  { x: 2311, y: 165 },
  { x: 2491, y: 424 },
  { x: 2470, y: 555 },
  { x: 2421, y: 433 },
  { x: 2573, y: 390 },
  { x: 2428, y: 440 },
  { x: 2750, y: 241 },
  { x: 2776, y: 287 },
  { x: 2740, y: 640 },
  { x: 2498, y: 364 },
  { x: 2739, y: 666 },
  { x: 2369, y: 210 },
  { x: 2397, y: 466 },
  { x: 2777, y: 306 },
  { x: 2644, y: 332 },
  { x: 2830, y: 359 },
  { x: 2735, y: 635 },
  { x: 2815, y: 168 },
  { x: 2675, y: 478 },
  { x: 2722, y: 602 },
  { x: 2564, y: 650 },
  { x: 2757, y: 490 },
  { x: 2791, y: 459 },
  { x: 2839, y: 146 },
  { x: 2450, y: 521 },
  { x: 2487, y: 507 },
  { x: 2615, y: 669 },
  { x: 2646, y: 749 },
  { x: 2345, y: 292 },
  { x: 2638, y: 349 },
  { x: 2867, y: 237 },
  { x: 2517, y: 709 },
  { x: 2424, y: 249 },
  { x: 2785, y: 460 },
  { x: 2690, y: 508 },
  { x: 2556, y: 749 },
  { x: 2323, y: 186 },
  { x: 2724, y: 675 },
  { x: 2662, y: 568 },
  { x: 2697, y: 484 },
  { x: 2584, y: 329 },
  { x: 2784, y: 248 },
  { x: 2837, y: 276 },
  { x: 2521, y: 548 },
  { x: 2549, y: 699 },
  { x: 2727, y: 504 },
  { x: 2527, y: 572 },
  { x: 2645, y: 543 },
  { x: 2494, y: 662 },
  { x: 2432, y: 291 },
  { x: 2805, y: 148 },
  { x: 2711, y: 401 },
  { x: 2768, y: 381 },
  { x: 2617, y: 315 },
  { x: 2525, y: 347 },
  { x: 2658, y: 732 },
  { x: 2651, y: 700 },
  { x: 2673, y: 679 },
  { x: 2734, y: 595 },
  { x: 2776, y: 290 },
  { x: 2388, y: 234 },
  { x: 2819, y: 418 },
  { x: 2368, y: 141 },
  { x: 2541, y: 655 },
  { x: 2694, y: 547 },
  { x: 2773, y: 397 },
  { x: 2857, y: 209 },
  { x: 2412, y: 211 },
  { x: 2415, y: 328 },
  { x: 2639, y: 303 },
  { x: 2647, y: 804 },
  { x: 2458, y: 368 },
  { x: 2676, y: 668 },
  { x: 2847, y: 207 },
  { x: 2632, y: 701 },
  { x: 2587, y: 820 },
  { x: 2451, y: 415 },
  { x: 2350, y: 222 },
  { x: 2333, y: 232 },
  { x: 2498, y: 378 },
  { x: 2732, y: 636 },
  { x: 2414, y: 495 },
  { x: 2597, y: 375 },
  { x: 2696, y: 781 },
  { x: 2496, y: 628 },
  { x: 2520, y: 520 },
  { x: 2531, y: 785 },
  { x: 2723, y: 406 },
  { x: 2798, y: 236 },
  { x: 2421, y: 471 },
  { x: 2507, y: 353 },
  { x: 2661, y: 793 },
  { x: 2489, y: 643 },
  { x: 2510, y: 367 },
  { x: 2624, y: 340 },
  { x: 2398, y: 439 },
  { x: 2470, y: 641 },
  { x: 2390, y: 354 },
  { x: 2729, y: 314 },
  { x: 2422, y: 454 },
  { x: 2693, y: 676 },
  { x: 2397, y: 296 },
  { x: 2478, y: 657 },
  { x: 2558, y: 633 },
  { x: 2765, y: 176 },
  { x: 2463, y: 327 },
  { x: 2408, y: 434 },
  { x: 2692, y: 522 },
  { x: 2472, y: 651 },
  { x: 2854, y: 254 },
  { x: 2360, y: 258 },
  { x: 2448, y: 614 },
  { x: 2760, y: 262 },
  { x: 2735, y: 431 },
  { x: 2871, y: 196 },
  { x: 2746, y: 271 },
  { x: 2659, y: 696 },
  { x: 2419, y: 553 },
  { x: 2706, y: 746 },
  { x: 2603, y: 809 },
  { x: 2567, y: 748 },
  { x: 2690, y: 536 },
  { x: 2680, y: 479 },
  { x: 2747, y: 280 },
  { x: 2483, y: 591 },
  { x: 2722, y: 344 },
  { x: 2509, y: 747 },
  { x: 2545, y: 300 },
  { x: 2504, y: 598 },
  { x: 2641, y: 766 },
  { x: 2744, y: 645 },
  { x: 2511, y: 808 },
  { x: 2539, y: 349 },
  { x: 2756, y: 407 },
  { x: 2742, y: 597 },
  { x: 2553, y: 384 },
  { x: 2359, y: 376 },
  { x: 2423, y: 399 },
  { x: 2718, y: 412 },
  { x: 2372, y: 181 },
  { x: 2885, y: 235 },
  { x: 2484, y: 553 },
  { x: 2761, y: 308 },
  { x: 2565, y: 659 },
  { x: 2504, y: 810 },
  { x: 2615, y: 762 },
  { x: 2810, y: 153 },
  { x: 2679, y: 520 },
  { x: 2742, y: 490 },
  { x: 2753, y: 542 },
  { x: 2448, y: 271 },
  { x: 2561, y: 390 },
  { x: 2609, y: 335 },
  { x: 2711, y: 472 },
  { x: 2758, y: 363 },
  { x: 2633, y: 641 },
  { x: 2849, y: 176 },
  { x: 2502, y: 557 },
  { x: 2549, y: 725 },
  { x: 2311, y: 193 },
  { x: 2690, y: 661 },
  { x: 2712, y: 564 },
  { x: 2462, y: 613 },
  { x: 2895, y: 163 },
  { x: 2884, y: 234 },
  { x: 2716, y: 398 },
  { x: 2613, y: 649 },
  { x: 2450, y: 591 },
  { x: 2491, y: 665 },
  { x: 2757, y: 437 },
  { x: 2636, y: 350 },
  { x: 2370, y: 139 },
  { x: 2384, y: 342 },
  { x: 2375, y: 402 },
  { x: 2488, y: 394 },
  { x: 2785, y: 257 },
  { x: 2476, y: 561 },
  { x: 2494, y: 478 },
  { x: 2493, y: 473 },
  { x: 2540, y: 828 },
  { x: 2374, y: 278 },
  { x: 2648, y: 694 },
  { x: 2774, y: 266 },
  { x: 2483, y: 477 },
  { x: 2463, y: 355 },
  { x: 2461, y: 552 },
  { x: 2604, y: 327 },
  { x: 2779, y: 453 },
  { x: 2421, y: 263 },
  { x: 2428, y: 337 },
  { x: 2655, y: 587 },
  { x: 2335, y: 156 },
  { x: 2403, y: 503 },
  { x: 2523, y: 803 },
  { x: 2600, y: 695 },
  { x: 2407, y: 261 },
  { x: 2624, y: 348 },
  { x: 2605, y: 772 },
  { x: 2569, y: 755 },
  { x: 2765, y: 175 },
  { x: 2697, y: 677 },
  { x: 2808, y: 381 },
  { x: 2512, y: 605 },
  { x: 2507, y: 733 },
  { x: 2365, y: 391 },
  { x: 2404, y: 179 },
  { x: 2501, y: 537 },
  { x: 2614, y: 726 },
  { x: 2416, y: 255 },
  { x: 2637, y: 785 },
  { x: 2708, y: 419 },
  { x: 2620, y: 622 },
  { x: 2394, y: 298 },
  { x: 2830, y: 328 },
  { x: 2414, y: 546 },
  { x: 2505, y: 452 },
  { x: 2868, y: 294 },
  { x: 2506, y: 582 },
  { x: 2503, y: 563 },
  { x: 2733, y: 669 },
  { x: 2704, y: 691 },
  { x: 2698, y: 714 },
  { x: 2341, y: 303 },
  { x: 2567, y: 724 },
  { x: 2836, y: 283 },
  { x: 2722, y: 662 },
  { x: 2469, y: 592 },
  { x: 2447, y: 279 },
  { x: 2816, y: 290 },
  { x: 2608, y: 735 },
  { x: 2582, y: 793 },
  { x: 2419, y: 461 },
  { x: 2417, y: 203 },
  { x: 2650, y: 831 },
  { x: 2675, y: 618 },
  { x: 2617, y: 678 },
  { x: 2431, y: 426 },
  { x: 2611, y: 671 },
  { x: 2618, y: 786 },
  { x: 2557, y: 620 },
  { x: 2738, y: 449 },
  { x: 2392, y: 313 },
  { x: 2856, y: 155 },
  { x: 2399, y: 445 },
  { x: 2637, y: 724 },
  { x: 2875, y: 200 },
  { x: 2298, y: 190 },
  { x: 2338, y: 187 },
  { x: 2692, y: 526 },
  { x: 2534, y: 399 },
  { x: 2612, y: 812 },
  { x: 2365, y: 294 },
  { x: 2475, y: 582 },
  { x: 2750, y: 623 },
  { x: 2449, y: 515 },
  { x: 2721, y: 696 },
  { x: 2809, y: 452 },
  { x: 2488, y: 332 },
  { x: 2706, y: 659 },
  { x: 2425, y: 373 },
  { x: 2626, y: 647 },
  { x: 2585, y: 762 },
  { x: 2586, y: 353 },
  { x: 2595, y: 325 },
  { x: 2691, y: 802 },
  { x: 2484, y: 707 },
  { x: 2514, y: 703 },
  { x: 2296, y: 161 },
  { x: 2671, y: 299 },
  { x: 2731, y: 363 },
  { x: 2686, y: 714 },
  { x: 2799, y: 404 },
  { x: 2417, y: 394 },
  { x: 2716, y: 392 },
  { x: 2341, y: 142 },
  { x: 2765, y: 224 },
  { x: 2576, y: 813 },
  { x: 2645, y: 729 },
  { x: 2437, y: 396 },
  { x: 2528, y: 713 },
  { x: 2430, y: 319 },
  { x: 2332, y: 188 },
  { x: 2619, y: 386 },
  { x: 2485, y: 312 },
  { x: 2806, y: 444 },
  { x: 2813, y: 273 },
  { x: 2500, y: 706 },
  { x: 2736, y: 657 },
  { x: 2641, y: 563 },
  { x: 2557, y: 722 },
  { x: 2603, y: 732 },
  { x: 2746, y: 289 },
  { x: 2378, y: 412 },
  { x: 2507, y: 457 },
  { x: 2561, y: 388 },
  { x: 2757, y: 462 },
  { x: 2302, y: 146 },
  { x: 2636, y: 664 },
  { x: 2470, y: 477 },
  { x: 2474, y: 441 },
  { x: 2696, y: 599 },
  { x: 2428, y: 437 },
  { x: 2690, y: 464 },
  { x: 2464, y: 411 },
  { x: 2539, y: 393 },
  { x: 2578, y: 820 },
  { x: 2414, y: 434 },
  { x: 2871, y: 140 },
  { x: 2879, y: 214 },
  { x: 2367, y: 221 },
  { x: 2761, y: 486 },
  { x: 2666, y: 362 },
  { x: 2699, y: 430 },
  { x: 2839, y: 180 },
  { x: 2843, y: 185 },
  { x: 2481, y: 367 },
  { x: 2355, y: 281 },
  { x: 2621, y: 748 },
  { x: 2415, y: 369 },
  { x: 2770, y: 468 },
  { x: 2851, y: 209 },
  { x: 2598, y: 316 },
  { x: 2667, y: 385 },
  { x: 2393, y: 271 },
  { x: 2483, y: 708 },
  { x: 2688, y: 636 },
  { x: 2480, y: 586 },
  { x: 2314, y: 155 },
  { x: 2344, y: 184 },
  { x: 2620, y: 809 },
  { x: 2478, y: 525 },
  { x: 2646, y: 655 },
  { x: 2660, y: 758 },
  { x: 2407, y: 347 },
  { x: 2461, y: 448 },
  { x: 2615, y: 718 },
  { x: 2720, y: 546 },
  { x: 2558, y: 743 },
  { x: 2496, y: 318 },
  { x: 2694, y: 641 },
  { x: 2708, y: 383 },
  { x: 2776, y: 500 },
  { x: 2648, y: 351 },
  { x: 2727, y: 342 },
  { x: 2785, y: 344 },
  { x: 2542, y: 687 },
  { x: 2351, y: 354 },
  { x: 2552, y: 715 },
  { x: 2815, y: 401 },
  { x: 2893, y: 178 },
  { x: 2485, y: 438 },
  { x: 2304, y: 137 },
  { x: 2726, y: 454 },
  { x: 2666, y: 333 },
  { x: 2704, y: 504 },
  { x: 2598, y: 355 },
  { x: 2790, y: 478 },
  { x: 2566, y: 668 },
  { x: 2517, y: 820 },
  { x: 2729, y: 507 },
  { x: 2528, y: 665 },
  { x: 2497, y: 367 },
  { x: 2593, y: 728 },
  { x: 2532, y: 598 },
  { x: 2901, y: 143 },
  { x: 2565, y: 359 },
  { x: 2405, y: 323 },
  { x: 2876, y: 217 },
  { x: 2519, y: 321 },
  { x: 2392, y: 390 },
  { x: 2400, y: 457 },
  { x: 2391, y: 148 },
  { x: 2843, y: 370 },
  { x: 2382, y: 369 },
  { x: 2427, y: 538 },
  { x: 2647, y: 315 },
  { x: 2416, y: 535 },
  { x: 2705, y: 612 },
  { x: 2733, y: 277 },
  { x: 2644, y: 371 },
  { x: 2767, y: 550 },
  { x: 2633, y: 685 },
  { x: 2765, y: 481 },
  { x: 2402, y: 288 },
  { x: 2533, y: 695 },
  { x: 2788, y: 168 },
  { x: 2578, y: 392 },
  { x: 2643, y: 654 },
  { x: 2752, y: 447 },
  { x: 2695, y: 397 },
  { x: 2721, y: 618 },
  { x: 2719, y: 734 },
  { x: 2779, y: 417 },
  { x: 2634, y: 735 },
  { x: 2600, y: 373 },
  { x: 2505, y: 725 },
  { x: 2637, y: 388 },
  { x: 2697, y: 512 },
  { x: 2334, y: 210 },
  { x: 2696, y: 424 },
  { x: 2435, y: 584 },
  { x: 2490, y: 400 },
  { x: 2609, y: 377 },
  { x: 2537, y: 783 },
  { x: 2282, y: 156 },
  { x: 2680, y: 823 },
  { x: 2479, y: 592 },
  { x: 2640, y: 763 },
  { x: 2333, y: 181 },
  { x: 2489, y: 645 },
  { x: 2816, y: 455 },
  { x: 2370, y: 387 },
  { x: 2536, y: 703 },
  { x: 2357, y: 202 },
  { x: 2583, y: 391 },
  { x: 2625, y: 613 },
  { x: 2786, y: 362 },
  { x: 2559, y: 702 },
  { x: 2866, y: 187 },
  { x: 2699, y: 511 },
  { x: 2576, y: 812 },
  { x: 2292, y: 185 },
  { x: 2629, y: 344 },
  { x: 2775, y: 509 },
  { x: 2499, y: 673 },
  { x: 2801, y: 190 },
  { x: 2793, y: 385 },
  { x: 2557, y: 634 },
  { x: 2728, y: 497 },
  { x: 2836, y: 250 },
  { x: 2436, y: 384 },
  { x: 2631, y: 791 },
  { x: 2861, y: 295 },
  { x: 2475, y: 396 },
  { x: 2797, y: 214 },
  { x: 2346, y: 171 },
  { x: 2385, y: 341 },
  { x: 2421, y: 219 },
  { x: 2308, y: 212 },
  { x: 2399, y: 249 },
  { x: 2429, y: 372 },
  { x: 2740, y: 269 },
  { x: 2428, y: 441 },
  { x: 2331, y: 135 },
  { x: 2486, y: 700 },
  { x: 2809, y: 313 },
  { x: 2588, y: 825 },
  { x: 2568, y: 349 },
  { x: 2863, y: 324 },
  { x: 2668, y: 688 },
  { x: 2369, y: 406 },
  { x: 2811, y: 453 },
  { x: 2460, y: 632 },
  { x: 2658, y: 308 },
  { x: 2534, y: 623 },
  { x: 2638, y: 641 },
  { x: 2328, y: 169 },
  { x: 2687, y: 393 },
  { x: 2379, y: 161 },
  { x: 2512, y: 651 },
  { x: 2452, y: 557 },
  { x: 2745, y: 248 },
  { x: 2476, y: 463 },
  { x: 2363, y: 317 },
  { x: 2623, y: 664 },
  { x: 2819, y: 139 },
  { x: 2369, y: 414 },
  { x: 2542, y: 636 },
  { x: 2457, y: 321 },
  { x: 2628, y: 341 },
  { x: 2383, y: 248 },
  { x: 2691, y: 395 },
  { x: 2615, y: 801 },
  { x: 2565, y: 303 },
  { x: 2559, y: 739 },
  { x: 2381, y: 356 },
  { x: 2408, y: 529 },
  { x: 2738, y: 554 },
  { x: 2389, y: 347 },
  { x: 2534, y: 663 },
  { x: 2481, y: 401 },
  { x: 2672, y: 642 },
  { x: 2470, y: 711 },
  { x: 2450, y: 403 },
  { x: 2325, y: 236 },
  { x: 2709, y: 378 },
  { x: 2720, y: 313 },
  { x: 2772, y: 360 },
  { x: 2667, y: 760 },
  { x: 2507, y: 773 },
  { x: 2665, y: 355 },
  { x: 2533, y: 656 },
  { x: 2435, y: 480 },
  { x: 2750, y: 283 },
  { x: 2329, y: 238 },
  { x: 2775, y: 415 },
  { x: 2528, y: 351 },
  { x: 2843, y: 223 },
  { x: 2420, y: 215 },
  { x: 2758, y: 517 },
  { x: 2804, y: 290 },
  { x: 2405, y: 476 },
  { x: 2513, y: 805 },
  { x: 2298, y: 199 },
  { x: 2711, y: 327 },
  { x: 2441, y: 258 },
  { x: 2391, y: 249 },
  { x: 2597, y: 707 },
  { x: 2460, y: 293 },
  { x: 2532, y: 350 },
  { x: 2390, y: 348 },
  { x: 2487, y: 297 },
  { x: 2632, y: 599 },
  { x: 2861, y: 279 },
  { x: 2574, y: 778 },
  { x: 2900, y: 191 },
  { x: 2825, y: 265 },
  { x: 2624, y: 685 },
  { x: 2824, y: 159 },
  { x: 2778, y: 188 },
  { x: 2511, y: 638 },
  { x: 2743, y: 447 },
  { x: 2695, y: 704 },
  { x: 2868, y: 213 },
  { x: 2518, y: 342 },
  { x: 2757, y: 407 },
  { x: 2718, y: 406 },
  { x: 2647, y: 777 },
  { x: 2742, y: 535 },
  { x: 2512, y: 780 },
  { x: 2539, y: 720 },
  { x: 2549, y: 832 },
  { x: 2790, y: 247 },
  { x: 2307, y: 194 },
  { x: 2552, y: 782 },
  { x: 2844, y: 202 },
  { x: 2800, y: 362 },
  { x: 2453, y: 528 },
  { x: 2671, y: 550 },
  { x: 2766, y: 267 },
  { x: 2612, y: 340 },
  { x: 2814, y: 448 },
  { x: 2722, y: 537 },
  { x: 2476, y: 329 },
  { x: 2401, y: 226 },
  { x: 2423, y: 385 },
  { x: 2635, y: 587 },
  { x: 2386, y: 282 },
  { x: 2837, y: 266 },
  { x: 2394, y: 217 },
  { x: 2762, y: 383 },
  { x: 2479, y: 501 },
  { x: 2713, y: 310 },
  { x: 2451, y: 405 },
  { x: 2812, y: 438 },
  { x: 2593, y: 747 },
  { x: 2799, y: 252 },
  { x: 2646, y: 792 },
  { x: 2731, y: 470 },
  { x: 2361, y: 220 },
  { x: 2393, y: 483 },
  { x: 2362, y: 168 },
  { x: 2710, y: 490 },
  { x: 2521, y: 570 },
  { x: 2474, y: 436 },
  { x: 2806, y: 198 },
  { x: 2444, y: 386 },
  { x: 2472, y: 428 },
  { x: 2726, y: 466 },
  { x: 2723, y: 686 },
  { x: 2366, y: 140 },
  { x: 2537, y: 687 },
  { x: 2874, y: 184 },
  { x: 2700, y: 649 },
  { x: 2490, y: 630 },
  { x: 2411, y: 425 },
  { x: 2637, y: 611 },
  { x: 2427, y: 231 },
  { x: 2682, y: 489 },
  { x: 2795, y: 326 },
  { x: 2525, y: 343 },
  { x: 2348, y: 198 },
  { x: 2811, y: 286 },
  { x: 2809, y: 261 },
  { x: 2428, y: 524 },
  { x: 2715, y: 548 },
  { x: 2541, y: 727 },
  { x: 2537, y: 697 },
  { x: 2712, y: 597 },
  { x: 2549, y: 766 },
  { x: 2741, y: 398 },
  { x: 2594, y: 331 },
  { x: 2574, y: 378 },
  { x: 2760, y: 189 },
  { x: 2772, y: 203 },
  { x: 2377, y: 414 },
  { x: 2756, y: 488 },
  { x: 2501, y: 464 },
  { x: 2743, y: 566 },
  { x: 2657, y: 357 },
  { x: 2582, y: 303 },
  { x: 2699, y: 319 },
  { x: 2508, y: 374 },
  { x: 2604, y: 304 },
  { x: 2505, y: 618 },
  { x: 2846, y: 271 },
  { x: 2518, y: 344 },
  { x: 2354, y: 228 },
  { x: 2799, y: 434 },
  { x: 2667, y: 532 },
  { x: 2345, y: 194 },
  { x: 2764, y: 461 },
  { x: 2725, y: 471 },
  { x: 2370, y: 401 },
  { x: 2773, y: 399 },
  { x: 2351, y: 213 },
  { x: 2298, y: 175 },
  { x: 2453, y: 499 },
  { x: 2350, y: 247 },
  { x: 2812, y: 317 },
  { x: 2855, y: 237 },
  { x: 2388, y: 223 },
  { x: 2847, y: 254 },
  { x: 2557, y: 310 },
  { x: 2575, y: 658 },
  { x: 2732, y: 698 },
  { x: 2283, y: 170 },
  { x: 2445, y: 258 },
  { x: 2462, y: 609 },
  { x: 2522, y: 667 },
  { x: 2316, y: 212 },
  { x: 2497, y: 441 },
  { x: 2793, y: 146 },
  { x: 2437, y: 283 },
  { x: 2473, y: 683 },
  { x: 2835, y: 173 },
  { x: 2718, y: 508 },
  { x: 2695, y: 404 },
  { x: 2714, y: 453 },
  { x: 2780, y: 335 },
  { x: 2661, y: 543 },
  { x: 2650, y: 806 },
  { x: 2767, y: 478 },
  { x: 2747, y: 614 },
  { x: 2618, y: 632 },
  { x: 2700, y: 390 },
  { x: 2785, y: 235 },
  { x: 2535, y: 726 },
  { x: 2555, y: 818 },
  { x: 2770, y: 243 },
  { x: 2707, y: 411 },
  { x: 2520, y: 732 },
  { x: 2397, y: 349 },
  { x: 2483, y: 672 },
  { x: 2866, y: 216 },
  { x: 2498, y: 602 },
  { x: 2442, y: 351 },
  { x: 2464, y: 570 },
  { x: 2382, y: 200 },
  { x: 2633, y: 313 },
  { x: 2491, y: 674 },
  { x: 2325, y: 226 },
  { x: 2819, y: 219 },
  { x: 2724, y: 372 },
  { x: 2791, y: 424 },
  { x: 2472, y: 591 },
  { x: 2517, y: 391 },
  { x: 2672, y: 685 },
  { x: 2702, y: 768 },
  { x: 2727, y: 433 },
  { x: 2443, y: 346 },
  { x: 2543, y: 652 },
  { x: 2474, y: 585 },
  { x: 2826, y: 172 },
  { x: 2505, y: 791 },
  { x: 2740, y: 509 },
  { x: 2492, y: 409 },
  { x: 2615, y: 309 },
  { x: 2393, y: 357 },
  { x: 2351, y: 327 },
  { x: 2640, y: 733 },
  { x: 2697, y: 497 },
  { x: 2818, y: 173 },
  { x: 2874, y: 177 },
  { x: 2705, y: 384 },
  { x: 2844, y: 355 },
  { x: 2696, y: 426 },
  { x: 2441, y: 386 },
  { x: 2768, y: 485 },
  { x: 2733, y: 465 },
  { x: 2655, y: 808 },
  { x: 2496, y: 634 },
  { x: 2372, y: 401 },
  { x: 2827, y: 226 },
  { x: 2452, y: 330 },
  { x: 2480, y: 735 },
  { x: 2756, y: 436 },
  { x: 2493, y: 454 },
  { x: 2448, y: 370 },
  { x: 2566, y: 635 },
  { x: 2486, y: 398 },
  { x: 2577, y: 361 },
  { x: 2402, y: 431 },
  { x: 2488, y: 378 },
  { x: 2420, y: 460 },
  { x: 2442, y: 618 },
  { x: 2584, y: 326 },
  { x: 2788, y: 238 },
  { x: 2426, y: 191 },
  { x: 2841, y: 239 },
  { x: 2709, y: 587 },
  { x: 2702, y: 448 },
  { x: 2415, y: 547 },
  { x: 2663, y: 392 },
  { x: 2713, y: 561 },
  { x: 2388, y: 292 },
  { x: 2338, y: 149 },
  { x: 2531, y: 592 },
  { x: 2567, y: 645 },
  { x: 2611, y: 324 },
  { x: 2379, y: 314 },
  { x: 2629, y: 802 },
  { x: 2357, y: 383 },
  { x: 2547, y: 831 },
  { x: 2699, y: 449 },
  { x: 2424, y: 195 },
  { x: 2399, y: 225 },
  { x: 2619, y: 669 },
  { x: 2783, y: 179 },
  { x: 2812, y: 333 },
  { x: 2642, y: 665 },
  { x: 2513, y: 601 },
  { x: 2710, y: 567 },
  { x: 2512, y: 304 },
  { x: 2419, y: 217 },
  { x: 2738, y: 585 },
  { x: 2656, y: 554 },
  { x: 2793, y: 182 },
  { x: 2686, y: 339 },
  { x: 2688, y: 715 },
  { x: 2421, y: 221 },
  { x: 2462, y: 344 },
  { x: 2667, y: 770 },
  { x: 2817, y: 236 },
  { x: 2620, y: 759 },
  { x: 2632, y: 335 },
  { x: 2327, y: 197 },
  { x: 2731, y: 557 },
  { x: 2609, y: 656 },
  { x: 2406, y: 260 },
  { x: 2716, y: 674 },
  { x: 2397, y: 367 },
  { x: 2808, y: 360 },
  { x: 2772, y: 285 },
  { x: 2475, y: 416 },
  { x: 2394, y: 240 },
  { x: 2509, y: 520 },
  { x: 2515, y: 610 },
  { x: 2811, y: 148 },
  { x: 2635, y: 322 },
  { x: 2476, y: 442 },
  { x: 2499, y: 614 },
  { x: 2530, y: 745 },
  { x: 2411, y: 151 },
  { x: 2560, y: 613 },
  { x: 2490, y: 481 },
  { x: 2741, y: 263 },
  { x: 2495, y: 372 },
  { x: 2707, y: 695 },
  { x: 2573, y: 765 },
  { x: 2739, y: 432 },
  { x: 2508, y: 758 },
  { x: 2807, y: 366 },
  { x: 2485, y: 591 },
  { x: 2765, y: 572 },
  { x: 2842, y: 293 },
  { x: 2456, y: 413 },
  { x: 2528, y: 761 },
  { x: 2797, y: 274 },
  { x: 2532, y: 531 },
  { x: 2773, y: 206 },
  { x: 2540, y: 715 },
  { x: 2781, y: 431 },
  { x: 2486, y: 342 },
  { x: 2333, y: 149 },
  { x: 2809, y: 147 },
  { x: 2449, y: 261 },
  { x: 2430, y: 366 },
  { x: 2530, y: 382 },
  { x: 2678, y: 561 },
  { x: 2539, y: 673 },
  { x: 2534, y: 644 },
  { x: 2739, y: 259 },
  { x: 2334, y: 297 },
  { x: 2759, y: 349 },
  { x: 2366, y: 412 },
  { x: 2476, y: 444 },
  { x: 2483, y: 696 },
  { x: 2847, y: 188 },
  { x: 2677, y: 340 },
  { x: 2780, y: 531 },
  { x: 2481, y: 685 },
  { x: 2447, y: 258 },
  { x: 2628, y: 620 },
  { x: 2417, y: 325 },
  { x: 2766, y: 224 },
  { x: 2422, y: 229 },
  { x: 2823, y: 269 },
  { x: 2814, y: 389 },
  { x: 2554, y: 621 },
  { x: 2506, y: 740 },
  { x: 2873, y: 270 },
  { x: 2504, y: 669 },
  { x: 2522, y: 683 },
  { x: 2662, y: 779 },
  { x: 2450, y: 399 },
  { x: 2762, y: 434 },
  { x: 2828, y: 208 },
  { x: 2852, y: 167 },
  { x: 2740, y: 630 },
  { x: 2803, y: 313 },
  { x: 2454, y: 350 },
  { x: 2648, y: 367 },
  { x: 2805, y: 286 },
  { x: 2704, y: 322 },
  { x: 2456, y: 643 },
  { x: 2672, y: 488 },
  { x: 2529, y: 309 },
  { x: 2525, y: 384 },
  { x: 2342, y: 166 },
  { x: 2470, y: 634 },
  { x: 2636, y: 337 },
  { x: 2660, y: 737 },
  { x: 2414, y: 421 },
  { x: 2839, y: 201 },
  { x: 2369, y: 348 },
  { x: 2387, y: 440 },
  { x: 2751, y: 590 },
  { x: 2691, y: 555 },
  { x: 2686, y: 370 },
  { x: 2392, y: 487 },
  { x: 2550, y: 584 },
  { x: 2702, y: 507 },
  { x: 2744, y: 612 },
  { x: 2849, y: 205 },
  { x: 2783, y: 505 },
  { x: 2770, y: 272 },
  { x: 2730, y: 312 },
  { x: 2684, y: 480 },
  { x: 2652, y: 533 },
  { x: 2578, y: 789 },
  { x: 2775, y: 549 },
  { x: 2760, y: 234 },
  { x: 2688, y: 761 },
  { x: 2393, y: 381 },
  { x: 2583, y: 323 },
  { x: 2792, y: 225 },
  { x: 2735, y: 687 },
  { x: 2535, y: 575 },
  { x: 2517, y: 629 },
  { x: 2499, y: 782 },
  { x: 2796, y: 511 },
  { x: 2426, y: 368 },
  { x: 2438, y: 430 },
  { x: 2634, y: 666 },
  { x: 2588, y: 364 },
  { x: 2610, y: 302 },
  { x: 2654, y: 768 },
  { x: 2551, y: 330 },
  { x: 2523, y: 660 },
  { x: 2664, y: 766 },
  { x: 2566, y: 833 },
  { x: 2679, y: 392 },
  { x: 2669, y: 299 },
  { x: 2400, y: 315 },
  { x: 2360, y: 209 },
  { x: 2596, y: 813 },
  { x: 2608, y: 728 },
  { x: 2629, y: 681 },
  { x: 2394, y: 492 },
  { x: 2547, y: 300 },
  { x: 2695, y: 773 },
  { x: 2758, y: 585 },
  { x: 2784, y: 291 },
  { x: 2631, y: 637 },
  { x: 2659, y: 731 },
  { x: 2548, y: 310 },
  { x: 2488, y: 395 },
  { x: 2497, y: 441 },
  { x: 2747, y: 471 },
  { x: 2597, y: 371 },
  { x: 2771, y: 546 },
  { x: 2501, y: 502 },
  { x: 2699, y: 477 },
  { x: 2736, y: 265 },
  { x: 2668, y: 548 },
  { x: 3489, y: 376 },
  { x: 3173, y: 549 },
  { x: 3212, y: 166 },
  { x: 3479, y: 198 },
  { x: 3472, y: 748 },
  { x: 3334, y: 781 },
  { x: 3465, y: 688 },
  { x: 3439, y: 274 },
  { x: 3074, y: 716 },
  { x: 3078, y: 408 },
  { x: 3293, y: 793 },
  { x: 3483, y: 751 },
  { x: 3203, y: 525 },
  { x: 3424, y: 444 },
  { x: 3493, y: 350 },
  { x: 3394, y: 512 },
  { x: 3106, y: 171 },
  { x: 3340, y: 213 },
  { x: 3523, y: 349 },
  { x: 3202, y: 248 },
  { x: 3477, y: 626 },
  { x: 3247, y: 790 },
  { x: 3364, y: 710 },
  { x: 3263, y: 500 },
  { x: 3262, y: 189 },
  { x: 3527, y: 348 },
  { x: 3279, y: 457 },
  { x: 3481, y: 337 },
  { x: 3297, y: 211 },
  { x: 3168, y: 188 },
  { x: 3317, y: 806 },
  { x: 3339, y: 782 },
  { x: 3095, y: 272 },
  { x: 3380, y: 547 },
  { x: 3252, y: 516 },
  { x: 3224, y: 817 },
  { x: 3480, y: 293 },
  { x: 3456, y: 681 },
  { x: 3164, y: 532 },
  { x: 3210, y: 815 },
  { x: 3461, y: 240 },
  { x: 3431, y: 745 },
  { x: 3088, y: 147 },
  { x: 3528, y: 356 },
  { x: 3198, y: 292 },
  { x: 3242, y: 157 },
  { x: 3157, y: 514 },
  { x: 3443, y: 609 },
  { x: 3383, y: 794 },
  { x: 3460, y: 743 },
  { x: 3371, y: 174 },
  { x: 3136, y: 381 },
  { x: 3085, y: 667 },
  { x: 3080, y: 810 },
  { x: 3222, y: 202 },
  { x: 3184, y: 772 },
  { x: 3158, y: 467 },
  { x: 3355, y: 509 },
  { x: 3170, y: 746 },
  { x: 3363, y: 437 },
  { x: 3256, y: 761 },
  { x: 3381, y: 507 },
  { x: 3415, y: 284 },
  { x: 3482, y: 677 },
  { x: 3346, y: 187 },
  { x: 3546, y: 433 },
  { x: 3338, y: 732 },
  { x: 3428, y: 704 },
  { x: 3134, y: 780 },
  { x: 3409, y: 465 },
  { x: 3125, y: 742 },
  { x: 3420, y: 708 },
  { x: 3321, y: 808 },
  { x: 3412, y: 531 },
  { x: 3193, y: 778 },
  { x: 3123, y: 209 },
  { x: 3147, y: 483 },
  { x: 3148, y: 590 },
  { x: 3455, y: 417 },
  { x: 3120, y: 497 },
  { x: 3097, y: 449 },
  { x: 3392, y: 755 },
  { x: 3522, y: 309 },
  { x: 3077, y: 268 },
  { x: 3537, y: 411 },
  { x: 3325, y: 534 },
  { x: 3404, y: 671 },
  { x: 3403, y: 472 },
  { x: 3453, y: 221 },
  { x: 3092, y: 727 },
  { x: 3154, y: 403 },
  { x: 3399, y: 588 },
  { x: 3452, y: 294 },
  { x: 3356, y: 783 },
  { x: 3190, y: 733 },
  { x: 3450, y: 302 },
  { x: 3119, y: 372 },
  { x: 3152, y: 162 },
  { x: 3076, y: 253 },
  { x: 3181, y: 820 },
  { x: 3542, y: 392 },
  { x: 3485, y: 416 },
  { x: 3138, y: 569 },
  { x: 3171, y: 600 },
  { x: 3146, y: 753 },
  { x: 3130, y: 476 },
  { x: 3204, y: 488 },
  { x: 3496, y: 474 },
  { x: 3525, y: 306 },
  { x: 3174, y: 185 },
  { x: 3329, y: 230 },
  { x: 3422, y: 200 },
  { x: 3488, y: 723 },
  { x: 3121, y: 347 },
  { x: 3448, y: 702 },
  { x: 3189, y: 341 },
  { x: 3172, y: 480 },
  { x: 3126, y: 409 },
  { x: 3440, y: 765 },
  { x: 3365, y: 237 },
  { x: 3429, y: 297 },
  { x: 3141, y: 310 },
  { x: 3192, y: 740 },
  { x: 3417, y: 397 },
  { x: 3244, y: 750 },
  { x: 3462, y: 535 },
  { x: 3096, y: 281 },
  { x: 3428, y: 262 },
  { x: 3125, y: 793 },
  { x: 3418, y: 532 },
  { x: 3404, y: 229 },
  { x: 3522, y: 222 },
  { x: 3133, y: 689 },
  { x: 3407, y: 547 },
  { x: 3383, y: 211 },
  { x: 3525, y: 456 },
  { x: 3154, y: 506 },
  { x: 3301, y: 830 },
  { x: 3309, y: 457 },
  { x: 3072, y: 735 },
  { x: 3094, y: 795 },
  { x: 3455, y: 548 },
  { x: 3474, y: 683 },
  { x: 3523, y: 401 },
  { x: 3514, y: 467 },
  { x: 3092, y: 549 },
  { x: 3149, y: 463 },
  { x: 3103, y: 504 },
  { x: 3165, y: 632 },
  { x: 3469, y: 280 },
  { x: 3508, y: 327 },
  { x: 3106, y: 170 },
  { x: 3537, y: 240 },
  { x: 3132, y: 729 },
  { x: 3282, y: 815 },
  { x: 3119, y: 779 },
  { x: 3263, y: 730 },
  { x: 3300, y: 763 },
  { x: 3113, y: 274 },
  { x: 3397, y: 629 },
  { x: 3160, y: 607 },
  { x: 3174, y: 539 },
  { x: 3534, y: 284 },
  { x: 3475, y: 414 },
  { x: 3447, y: 371 },
  { x: 3155, y: 573 },
  { x: 3225, y: 787 },
  { x: 3354, y: 477 },
  { x: 3541, y: 432 },
  { x: 3201, y: 175 },
  { x: 3203, y: 444 },
  { x: 3189, y: 439 },
  { x: 3178, y: 218 },
  { x: 3172, y: 551 },
  { x: 3100, y: 746 },
  { x: 3511, y: 353 },
  { x: 3180, y: 526 },
  { x: 3082, y: 176 },
  { x: 3426, y: 680 },
  { x: 3177, y: 166 },
  { x: 3394, y: 571 },
  { x: 3431, y: 370 },
  { x: 3462, y: 783 },
  { x: 3075, y: 711 },
  { x: 3442, y: 590 },
  { x: 3279, y: 164 },
  { x: 3481, y: 732 },
  { x: 3363, y: 487 },
  { x: 3081, y: 543 },
  { x: 3393, y: 690 },
  { x: 3470, y: 278 },
  { x: 3179, y: 559 },
  { x: 3493, y: 312 },
  { x: 3308, y: 796 },
  { x: 3362, y: 245 },
  { x: 3153, y: 630 },
  { x: 3254, y: 802 },
  { x: 3487, y: 760 },
  { x: 3197, y: 580 },
  { x: 3385, y: 568 },
  { x: 3341, y: 476 },
  { x: 3093, y: 693 },
  { x: 3068, y: 509 },
  { x: 3402, y: 613 },
  { x: 3204, y: 178 },
  { x: 3191, y: 424 },
  { x: 3170, y: 555 },
  { x: 3121, y: 433 },
  { x: 3128, y: 440 },
  { x: 3450, y: 241 },
  { x: 3476, y: 287 },
  { x: 3440, y: 640 },
  { x: 3198, y: 364 },
  { x: 3421, y: 177 },
  { x: 3439, y: 666 },
  { x: 3069, y: 210 },
  { x: 3097, y: 466 },
  { x: 3477, y: 306 },
  { x: 3480, y: 768 },
  { x: 3530, y: 359 },
  { x: 3435, y: 635 },
  { x: 3375, y: 478 },
  { x: 3422, y: 602 },
  { x: 3305, y: 148 },
  { x: 3457, y: 490 },
  { x: 3085, y: 803 },
  { x: 3491, y: 459 },
  { x: 3150, y: 521 },
  { x: 3187, y: 507 },
  { x: 3161, y: 224 },
  { x: 3346, y: 749 },
  { x: 3227, y: 232 },
  { x: 3151, y: 260 },
  { x: 3140, y: 183 },
  { x: 3124, y: 249 },
  { x: 3223, y: 219 },
  { x: 3485, y: 460 },
  { x: 3390, y: 508 },
  { x: 3271, y: 194 },
  { x: 3256, y: 749 },
  { x: 3424, y: 675 },
  { x: 3362, y: 568 },
  { x: 3397, y: 484 },
  { x: 3439, y: 190 },
  { x: 3484, y: 248 },
  { x: 3537, y: 276 },
  { x: 3427, y: 504 },
  { x: 3345, y: 543 },
  { x: 3436, y: 753 },
  { x: 3194, y: 662 },
  { x: 3132, y: 291 },
  { x: 3411, y: 401 },
  { x: 3468, y: 381 },
  { x: 3087, y: 562 },
  { x: 3501, y: 567 },
  { x: 3141, y: 171 },
  { x: 3352, y: 511 },
  { x: 3358, y: 732 },
  { x: 3348, y: 147 },
  { x: 3169, y: 278 },
  { x: 3545, y: 430 },
  { x: 3101, y: 797 },
  { x: 3434, y: 595 },
  { x: 3476, y: 290 },
  { x: 3286, y: 195 },
  { x: 3088, y: 234 },
  { x: 3487, y: 683 },
  { x: 3519, y: 418 },
  { x: 3394, y: 547 },
  { x: 3473, y: 397 },
  { x: 3428, y: 182 },
  { x: 3546, y: 393 },
  { x: 3112, y: 211 },
  { x: 3159, y: 806 },
  { x: 3115, y: 328 },
  { x: 3347, y: 804 },
  { x: 3105, y: 782 },
  { x: 3158, y: 368 },
  { x: 3326, y: 527 },
  { x: 3287, y: 820 },
  { x: 3151, y: 415 },
  { x: 3198, y: 378 },
  { x: 3432, y: 636 },
  { x: 3114, y: 495 },
  { x: 3259, y: 228 },
  { x: 3279, y: 533 },
  { x: 3096, y: 623 },
  { x: 3396, y: 781 },
  { x: 3433, y: 191 },
  { x: 3295, y: 168 },
  { x: 3078, y: 776 },
  { x: 3196, y: 628 },
  { x: 3220, y: 520 },
  { x: 3231, y: 785 },
  { x: 3423, y: 406 },
  { x: 3498, y: 236 },
  { x: 3226, y: 152 },
  { x: 3137, y: 733 },
  { x: 3200, y: 173 },
  { x: 3121, y: 471 },
  { x: 3361, y: 793 },
  { x: 3265, y: 181 },
  { x: 3189, y: 643 },
  { x: 3261, y: 493 },
  { x: 3281, y: 164 },
  { x: 3098, y: 439 },
  { x: 3170, y: 641 },
  { x: 3463, y: 769 },
  { x: 3274, y: 202 },
  { x: 3090, y: 354 },
  { x: 3429, y: 314 },
  { x: 3122, y: 454 },
  { x: 3393, y: 676 },
  { x: 3092, y: 680 },
  { x: 3097, y: 296 },
  { x: 3178, y: 657 },
  { x: 3465, y: 176 },
  { x: 3402, y: 170 },
  { x: 3163, y: 327 },
  { x: 3108, y: 434 },
  { x: 3392, y: 522 },
  { x: 3172, y: 651 },
  { x: 3309, y: 458 },
  { x: 3133, y: 717 },
  { x: 3523, y: 678 },
  { x: 3148, y: 614 },
  { x: 3292, y: 233 },
  { x: 3460, y: 262 },
  { x: 3270, y: 151 },
  { x: 3435, y: 431 },
  { x: 3446, y: 271 },
  { x: 3119, y: 553 },
  { x: 3406, y: 746 },
  { x: 3282, y: 485 },
  { x: 3303, y: 809 },
  { x: 3267, y: 748 },
  { x: 3390, y: 536 },
  { x: 3380, y: 479 },
  { x: 3082, y: 719 },
  { x: 3404, y: 224 },
  { x: 3447, y: 280 },
  { x: 3234, y: 218 },
  { x: 3183, y: 591 },
  { x: 3209, y: 747 },
  { x: 3550, y: 428 },
  { x: 3204, y: 598 },
  { x: 3341, y: 766 },
  { x: 3444, y: 645 },
  { x: 3453, y: 192 },
  { x: 3211, y: 808 },
  { x: 3421, y: 261 },
  { x: 3548, y: 424 },
  { x: 3456, y: 407 },
  { x: 3442, y: 597 },
  { x: 3450, y: 664 },
  { x: 3177, y: 240 },
  { x: 3123, y: 399 },
  { x: 3178, y: 195 },
  { x: 3106, y: 787 },
  { x: 3097, y: 815 },
  { x: 3418, y: 412 },
  { x: 3518, y: 664 },
  { x: 3072, y: 181 },
  { x: 3184, y: 553 },
  { x: 3363, y: 186 },
  { x: 3461, y: 308 },
  { x: 3289, y: 183 },
  { x: 3167, y: 781 },
  { x: 3159, y: 689 },
  { x: 3215, y: 232 },
  { x: 3204, y: 810 },
  { x: 3315, y: 762 },
  { x: 3463, y: 754 },
  { x: 3256, y: 525 },
  { x: 3429, y: 801 },
  { x: 3491, y: 612 },
  { x: 3406, y: 218 },
  { x: 3379, y: 520 },
  { x: 3442, y: 490 },
  { x: 3453, y: 542 },
  { x: 3148, y: 271 },
  { x: 3357, y: 227 },
  { x: 3341, y: 487 },
  { x: 3073, y: 667 },
  { x: 3108, y: 141 },
  { x: 3411, y: 472 },
  { x: 3458, y: 363 },
  { x: 3164, y: 806 },
  { x: 3487, y: 688 },
  { x: 3327, y: 528 },
  { x: 3202, y: 557 },
  { x: 3098, y: 596 },
  { x: 3390, y: 661 },
  { x: 3412, y: 564 },
  { x: 3162, y: 613 },
  { x: 3338, y: 501 },
  { x: 3173, y: 288 },
  { x: 3416, y: 398 },
  { x: 3480, y: 718 },
  { x: 3150, y: 591 },
  { x: 3191, y: 665 },
  { x: 3457, y: 437 },
  { x: 3515, y: 686 },
  { x: 3084, y: 342 },
  { x: 3075, y: 402 },
  { x: 3120, y: 652 },
  { x: 3188, y: 394 },
  { x: 3374, y: 444 },
  { x: 3485, y: 257 },
  { x: 3332, y: 479 },
  { x: 3176, y: 561 },
  { x: 3200, y: 140 },
  { x: 3194, y: 478 },
  { x: 3193, y: 473 },
  { x: 3445, y: 790 },
  { x: 3240, y: 828 },
  { x: 3074, y: 278 },
  { x: 3474, y: 266 },
  { x: 3183, y: 477 },
  { x: 3342, y: 160 },
  { x: 3163, y: 355 },
  { x: 3161, y: 552 },
  { x: 3186, y: 174 },
  { x: 3479, y: 453 },
  { x: 3093, y: 748 },
  { x: 3121, y: 263 },
  { x: 3128, y: 337 },
  { x: 3103, y: 503 },
  { x: 3118, y: 143 },
  { x: 3223, y: 803 },
  { x: 3107, y: 261 },
  { x: 3305, y: 772 },
  { x: 3269, y: 755 },
  { x: 3465, y: 175 },
  { x: 3351, y: 206 },
  { x: 3397, y: 677 },
  { x: 3508, y: 381 },
  { x: 3454, y: 749 },
  { x: 3207, y: 733 },
  { x: 3166, y: 731 },
  { x: 3253, y: 531 },
  { x: 3104, y: 179 },
  { x: 3302, y: 241 },
  { x: 3201, y: 537 },
  { x: 3314, y: 726 },
  { x: 3279, y: 505 },
  { x: 3116, y: 255 },
  { x: 3213, y: 214 },
  { x: 3337, y: 785 },
  { x: 3408, y: 419 },
  { x: 3094, y: 298 },
  { x: 3389, y: 224 },
  { x: 3530, y: 328 },
  { x: 3114, y: 546 },
  { x: 3205, y: 452 },
  { x: 3203, y: 563 },
  { x: 3433, y: 669 },
  { x: 3404, y: 691 },
  { x: 3398, y: 714 },
  { x: 3536, y: 283 },
  { x: 3190, y: 165 },
  { x: 3422, y: 662 },
  { x: 3169, y: 592 },
  { x: 3147, y: 279 },
  { x: 3516, y: 290 },
  { x: 3308, y: 735 },
  { x: 3282, y: 793 },
  { x: 3119, y: 461 },
  { x: 3117, y: 203 },
  { x: 3185, y: 818 },
  { x: 3131, y: 426 },
  { x: 3318, y: 786 },
  { x: 3438, y: 449 },
  { x: 3092, y: 313 },
  { x: 3168, y: 146 },
  { x: 3400, y: 259 },
  { x: 3099, y: 445 },
  { x: 3337, y: 724 },
  { x: 3392, y: 526 },
  { x: 3312, y: 812 },
  { x: 3176, y: 775 },
  { x: 3171, y: 755 },
  { x: 3082, y: 566 },
  { x: 3175, y: 582 },
  { x: 3158, y: 148 },
  { x: 3469, y: 725 },
  { x: 3256, y: 230 },
  { x: 3450, y: 623 },
  { x: 3108, y: 752 },
  { x: 3149, y: 515 },
  { x: 3421, y: 696 },
  { x: 3509, y: 452 },
  { x: 3350, y: 170 },
  { x: 3204, y: 265 },
  { x: 3188, y: 332 },
  { x: 3406, y: 659 },
  { x: 3472, y: 735 },
  { x: 3475, y: 731 },
  { x: 3125, y: 373 },
  { x: 3311, y: 234 },
  { x: 3361, y: 191 },
  { x: 3285, y: 762 },
  { x: 3283, y: 467 },
  { x: 3391, y: 802 },
  { x: 3184, y: 707 },
  { x: 3099, y: 720 },
  { x: 3325, y: 513 },
  { x: 3126, y: 606 },
  { x: 3431, y: 363 },
  { x: 3386, y: 714 },
  { x: 3499, y: 404 },
  { x: 3117, y: 394 },
  { x: 3548, y: 400 },
  { x: 3416, y: 392 },
  { x: 3091, y: 688 },
  { x: 3465, y: 224 },
  { x: 3276, y: 813 },
  { x: 3345, y: 729 },
  { x: 3137, y: 396 },
  { x: 3451, y: 669 },
  { x: 3110, y: 829 },
  { x: 3130, y: 319 },
  { x: 3185, y: 312 },
  { x: 3506, y: 444 },
  { x: 3513, y: 273 },
  { x: 3200, y: 706 },
  { x: 3563, y: 391 },
  { x: 3436, y: 657 },
  { x: 3102, y: 737 },
  { x: 3259, y: 453 },
  { x: 3303, y: 732 },
  { x: 3446, y: 289 },
  { x: 3078, y: 412 },
  { x: 3474, y: 633 },
  { x: 3207, y: 457 },
  { x: 3414, y: 264 },
  { x: 3411, y: 152 },
  { x: 3457, y: 462 },
  { x: 3299, y: 164 },
  { x: 3170, y: 477 },
  { x: 3174, y: 441 },
  { x: 3396, y: 599 },
  { x: 3197, y: 243 },
  { x: 3467, y: 622 },
  { x: 3128, y: 437 },
  { x: 3249, y: 502 },
  { x: 3390, y: 464 },
  { x: 3164, y: 411 },
  { x: 3278, y: 820 },
  { x: 3550, y: 427 },
  { x: 3084, y: 667 },
  { x: 3114, y: 434 },
  { x: 3135, y: 789 },
  { x: 3461, y: 486 },
  { x: 3399, y: 430 },
  { x: 3459, y: 661 },
  { x: 3194, y: 139 },
  { x: 3181, y: 367 },
  { x: 3321, y: 748 },
  { x: 3115, y: 369 },
  { x: 3470, y: 468 },
  { x: 3328, y: 226 },
  { x: 3093, y: 271 },
  { x: 3187, y: 810 },
  { x: 3183, y: 708 },
  { x: 3271, y: 141 },
  { x: 3180, y: 586 },
  { x: 3412, y: 291 },
  { x: 3320, y: 809 },
  { x: 3072, y: 658 },
  { x: 3152, y: 677 },
  { x: 3178, y: 525 },
  { x: 3095, y: 698 },
  { x: 3360, y: 758 },
  { x: 3193, y: 252 },
  { x: 3107, y: 347 },
  { x: 3426, y: 268 },
  { x: 3237, y: 176 },
  { x: 3161, y: 448 },
  { x: 3420, y: 546 },
  { x: 3224, y: 830 },
  { x: 3317, y: 541 },
  { x: 3258, y: 743 },
  { x: 3512, y: 648 },
  { x: 3101, y: 583 },
  { x: 3309, y: 145 },
  { x: 3471, y: 751 },
  { x: 3071, y: 579 },
  { x: 3291, y: 237 },
  { x: 3196, y: 318 },
  { x: 3429, y: 774 },
  { x: 3394, y: 641 },
  { x: 3427, y: 342 },
  { x: 3252, y: 455 },
  { x: 3485, y: 344 },
  { x: 3274, y: 527 },
  { x: 3515, y: 401 },
  { x: 3185, y: 438 },
  { x: 3426, y: 454 },
  { x: 3404, y: 504 },
  { x: 3420, y: 291 },
  { x: 3324, y: 475 },
  { x: 3094, y: 821 },
  { x: 3490, y: 478 },
  { x: 3217, y: 820 },
  { x: 3438, y: 167 },
  { x: 3126, y: 669 },
  { x: 3429, y: 507 },
  { x: 3197, y: 367 },
  { x: 3101, y: 787 },
  { x: 3293, y: 728 },
  { x: 3173, y: 758 },
  { x: 3122, y: 160 },
  { x: 3105, y: 323 },
  { x: 3108, y: 624 },
  { x: 3092, y: 390 },
  { x: 3100, y: 457 },
  { x: 3091, y: 148 },
  { x: 3543, y: 370 },
  { x: 3082, y: 369 },
  { x: 3353, y: 194 },
  { x: 3127, y: 538 },
  { x: 3093, y: 666 },
  { x: 3116, y: 535 },
  { x: 3087, y: 652 },
  { x: 3442, y: 213 },
  { x: 3405, y: 612 },
  { x: 3433, y: 277 },
  { x: 3467, y: 550 },
  { x: 3465, y: 481 },
  { x: 3360, y: 232 },
  { x: 3102, y: 288 },
  { x: 3452, y: 447 },
  { x: 3421, y: 618 },
  { x: 3419, y: 734 },
  { x: 3348, y: 247 },
  { x: 3479, y: 417 },
  { x: 3334, y: 735 },
  { x: 3113, y: 604 },
  { x: 3517, y: 657 },
  { x: 3397, y: 512 },
  { x: 3141, y: 692 },
  { x: 3396, y: 424 },
  { x: 3135, y: 584 },
  { x: 3190, y: 400 },
  { x: 3237, y: 783 },
  { x: 3179, y: 592 },
  { x: 3166, y: 246 },
  { x: 3294, y: 142 },
  { x: 3086, y: 571 },
  { x: 3340, y: 763 },
  { x: 3189, y: 645 },
  { x: 3516, y: 455 },
  { x: 3070, y: 387 },
  { x: 3156, y: 781 },
  { x: 3285, y: 471 },
  { x: 3456, y: 759 },
  { x: 3486, y: 362 },
  { x: 3184, y: 827 },
  { x: 3399, y: 511 },
  { x: 3276, y: 812 },
  { x: 3168, y: 233 },
  { x: 3199, y: 673 },
  { x: 3493, y: 385 },
  { x: 3428, y: 497 },
  { x: 3536, y: 250 },
  { x: 3136, y: 384 },
  { x: 3331, y: 791 },
  { x: 3284, y: 458 },
  { x: 3167, y: 215 },
  { x: 3561, y: 295 },
  { x: 3418, y: 789 },
  { x: 3175, y: 396 },
  { x: 3497, y: 214 },
  { x: 3446, y: 797 },
  { x: 3085, y: 341 },
  { x: 3121, y: 219 },
  { x: 3149, y: 828 },
  { x: 3099, y: 249 },
  { x: 3129, y: 372 },
  { x: 3440, y: 269 },
  { x: 3128, y: 441 },
  { x: 3477, y: 661 },
  { x: 3186, y: 700 },
  { x: 3290, y: 146 },
  { x: 3509, y: 313 },
  { x: 3068, y: 778 },
  { x: 3288, y: 825 },
  { x: 3563, y: 324 },
  { x: 3069, y: 406 },
  { x: 3511, y: 453 },
  { x: 3160, y: 632 },
  { x: 3434, y: 198 },
  { x: 3154, y: 253 },
  { x: 3264, y: 192 },
  { x: 3079, y: 161 },
  { x: 3152, y: 557 },
  { x: 3131, y: 793 },
  { x: 3445, y: 248 },
  { x: 3142, y: 782 },
  { x: 3176, y: 463 },
  { x: 3416, y: 749 },
  { x: 3153, y: 691 },
  { x: 3424, y: 230 },
  { x: 3152, y: 750 },
  { x: 3300, y: 475 },
  { x: 3107, y: 662 },
  { x: 3069, y: 414 },
  { x: 3097, y: 665 },
  { x: 3157, y: 321 },
  { x: 3083, y: 248 },
  { x: 3102, y: 548 },
  { x: 3235, y: 503 },
  { x: 3315, y: 801 },
  { x: 3259, y: 739 },
  { x: 3081, y: 356 },
  { x: 3108, y: 529 },
  { x: 3438, y: 554 },
  { x: 3124, y: 664 },
  { x: 3441, y: 689 },
  { x: 3089, y: 347 },
  { x: 3304, y: 524 },
  { x: 3329, y: 154 },
  { x: 3181, y: 401 },
  { x: 3491, y: 715 },
  { x: 3170, y: 711 },
  { x: 3150, y: 403 },
  { x: 3472, y: 360 },
  { x: 3193, y: 214 },
  { x: 3340, y: 502 },
  { x: 3367, y: 760 },
  { x: 3207, y: 773 },
  { x: 3135, y: 480 },
  { x: 3450, y: 283 },
  { x: 3475, y: 415 },
  { x: 3276, y: 460 },
  { x: 3120, y: 215 },
  { x: 3184, y: 185 },
  { x: 3308, y: 180 },
  { x: 3428, y: 171 },
  { x: 3504, y: 290 },
  { x: 3321, y: 155 },
  { x: 3105, y: 476 },
  { x: 3213, y: 805 },
  { x: 3539, y: 409 },
  { x: 3141, y: 258 },
  { x: 3091, y: 249 },
  { x: 3160, y: 293 },
  { x: 3090, y: 348 },
  { x: 3313, y: 459 },
  { x: 3187, y: 297 },
  { x: 3387, y: 229 },
  { x: 3476, y: 673 },
  { x: 3274, y: 778 },
  { x: 3082, y: 558 },
  { x: 3525, y: 265 },
  { x: 3478, y: 188 },
  { x: 3505, y: 593 },
  { x: 3443, y: 447 },
  { x: 3395, y: 704 },
  { x: 3118, y: 688 },
  { x: 3075, y: 444 },
  { x: 3457, y: 407 },
  { x: 3418, y: 406 },
  { x: 3134, y: 182 },
  { x: 3347, y: 777 },
  { x: 3442, y: 535 },
  { x: 3212, y: 780 },
  { x: 3373, y: 173 },
  { x: 3421, y: 772 },
  { x: 3490, y: 247 },
  { x: 3370, y: 216 },
  { x: 3225, y: 164 },
  { x: 3252, y: 782 },
  { x: 3182, y: 197 },
  { x: 3133, y: 714 },
  { x: 3500, y: 362 },
  { x: 3153, y: 528 },
  { x: 3371, y: 550 },
  { x: 3466, y: 267 },
  { x: 3514, y: 448 },
  { x: 3422, y: 537 },
  { x: 3176, y: 329 },
  { x: 3101, y: 226 },
  { x: 3139, y: 160 },
  { x: 3275, y: 473 },
  { x: 3129, y: 706 },
  { x: 3123, y: 385 },
  { x: 3080, y: 676 },
  { x: 3086, y: 282 },
  { x: 3537, y: 266 },
  { x: 3094, y: 217 },
  { x: 3462, y: 383 },
  { x: 3179, y: 501 },
  { x: 3151, y: 405 },
  { x: 3084, y: 811 },
  { x: 3512, y: 438 },
  { x: 3374, y: 442 },
  { x: 3293, y: 747 },
  { x: 3499, y: 252 },
  { x: 3352, y: 232 },
  { x: 3279, y: 443 },
  { x: 3346, y: 792 },
  { x: 3431, y: 470 },
  { x: 3154, y: 261 },
  { x: 3093, y: 483 },
  { x: 3076, y: 586 },
  { x: 3410, y: 490 },
  { x: 3140, y: 205 },
  { x: 3174, y: 436 },
  { x: 3163, y: 177 },
  { x: 3397, y: 200 },
  { x: 3144, y: 386 },
  { x: 3172, y: 428 },
  { x: 3426, y: 466 },
  { x: 3423, y: 686 },
  { x: 3336, y: 218 },
  { x: 3145, y: 643 },
  { x: 3400, y: 649 },
  { x: 3244, y: 174 },
  { x: 3178, y: 743 },
  { x: 3190, y: 630 },
  { x: 3111, y: 425 },
  { x: 3127, y: 231 },
  { x: 3301, y: 500 },
  { x: 3382, y: 489 },
  { x: 3495, y: 326 },
  { x: 3115, y: 587 },
  { x: 3288, y: 227 },
  { x: 3438, y: 246 },
  { x: 3511, y: 286 },
  { x: 3323, y: 149 },
  { x: 3509, y: 261 },
  { x: 3128, y: 524 },
  { x: 3415, y: 548 },
  { x: 3087, y: 678 },
  { x: 3241, y: 727 },
  { x: 3112, y: 816 },
  { x: 3412, y: 597 },
  { x: 3249, y: 766 },
  { x: 3441, y: 398 },
  { x: 3268, y: 479 },
  { x: 3408, y: 778 },
  { x: 3103, y: 613 },
  { x: 3460, y: 189 },
  { x: 3472, y: 203 },
  { x: 3077, y: 414 },
  { x: 3456, y: 488 },
  { x: 3201, y: 464 },
  { x: 3334, y: 531 },
  { x: 3443, y: 566 },
  { x: 3484, y: 646 },
  { x: 3409, y: 798 },
  { x: 3526, y: 459 },
  { x: 3119, y: 148 },
  { x: 3099, y: 810 },
  { x: 3410, y: 811 },
  { x: 3546, y: 271 },
  { x: 3499, y: 434 },
  { x: 3503, y: 595 },
  { x: 3129, y: 150 },
  { x: 3367, y: 532 },
  { x: 3132, y: 725 },
  { x: 3464, y: 461 },
  { x: 3425, y: 471 },
  { x: 3070, y: 401 },
  { x: 3550, y: 382 },
  { x: 3473, y: 399 },
  { x: 3153, y: 499 },
  { x: 3512, y: 317 },
  { x: 3136, y: 714 },
  { x: 3088, y: 223 },
  { x: 3547, y: 254 },
  { x: 3339, y: 470 },
  { x: 3147, y: 210 },
  { x: 3338, y: 512 },
  { x: 3432, y: 698 },
  { x: 3545, y: 426 },
  { x: 3451, y: 687 },
  { x: 3145, y: 258 },
  { x: 3162, y: 609 },
  { x: 3074, y: 740 },
  { x: 3184, y: 232 },
  { x: 3075, y: 686 },
  { x: 3197, y: 441 },
  { x: 3086, y: 475 },
  { x: 3078, y: 817 },
  { x: 3137, y: 283 },
  { x: 3173, y: 683 },
  { x: 3418, y: 508 },
  { x: 3414, y: 453 },
  { x: 3480, y: 335 },
  { x: 3361, y: 543 },
  { x: 3350, y: 806 },
  { x: 3091, y: 801 },
  { x: 3467, y: 478 },
  { x: 3193, y: 405 },
  { x: 3355, y: 465 },
  { x: 3447, y: 614 },
  { x: 3156, y: 169 },
  { x: 3485, y: 235 },
  { x: 3107, y: 745 },
  { x: 3255, y: 818 },
  { x: 3152, y: 205 },
  { x: 3470, y: 243 },
  { x: 3407, y: 411 },
  { x: 3279, y: 147 },
  { x: 3199, y: 422 },
  { x: 3220, y: 732 },
  { x: 3097, y: 349 },
  { x: 3183, y: 672 },
  { x: 3198, y: 602 },
  { x: 3142, y: 351 },
  { x: 3516, y: 463 },
  { x: 3144, y: 781 },
  { x: 3164, y: 570 },
  { x: 3082, y: 200 },
  { x: 3191, y: 674 },
  { x: 3519, y: 219 },
  { x: 3424, y: 372 },
  { x: 3491, y: 424 },
  { x: 3270, y: 501 },
  { x: 3172, y: 591 },
  { x: 3195, y: 268 },
  { x: 3073, y: 670 },
  { x: 3297, y: 141 },
  { x: 3402, y: 768 },
  { x: 3427, y: 433 },
  { x: 3143, y: 346 },
  { x: 3160, y: 220 },
  { x: 3174, y: 585 },
  { x: 3154, y: 191 },
  { x: 3205, y: 791 },
  { x: 3440, y: 509 },
  { x: 3337, y: 183 },
  { x: 3192, y: 409 },
  { x: 3084, y: 670 },
  { x: 3093, y: 357 },
  { x: 3340, y: 733 },
  { x: 3397, y: 497 },
  { x: 3136, y: 721 },
  { x: 3358, y: 439 },
  { x: 3544, y: 355 },
  { x: 3396, y: 426 },
  { x: 3141, y: 386 },
  { x: 3468, y: 485 },
  { x: 3433, y: 465 },
  { x: 3355, y: 808 },
  { x: 3196, y: 634 },
  { x: 3197, y: 410 },
  { x: 3072, y: 401 },
  { x: 3527, y: 226 },
  { x: 3152, y: 330 },
  { x: 3180, y: 735 },
  { x: 3105, y: 545 },
  { x: 3293, y: 496 },
  { x: 3135, y: 819 },
  { x: 3456, y: 436 },
  { x: 3193, y: 454 },
  { x: 3148, y: 370 },
  { x: 3133, y: 787 },
  { x: 3186, y: 398 },
  { x: 3288, y: 479 },
  { x: 3078, y: 540 },
  { x: 3102, y: 431 },
  { x: 3426, y: 178 },
  { x: 3109, y: 584 },
  { x: 3188, y: 378 },
  { x: 3120, y: 460 },
  { x: 3142, y: 618 },
  { x: 3488, y: 238 },
  { x: 3467, y: 756 },
  { x: 3126, y: 191 },
  { x: 3541, y: 239 },
  { x: 3409, y: 587 },
  { x: 3402, y: 448 },
  { x: 3115, y: 547 },
  { x: 3194, y: 288 },
  { x: 3413, y: 561 },
  { x: 3088, y: 292 },
  { x: 3085, y: 484 },
  { x: 3079, y: 314 },
  { x: 3481, y: 575 },
  { x: 3329, y: 802 },
  { x: 3346, y: 452 },
  { x: 3279, y: 220 },
  { x: 3399, y: 449 },
  { x: 3434, y: 712 },
  { x: 3124, y: 195 },
  { x: 3227, y: 198 },
  { x: 3099, y: 225 },
  { x: 3151, y: 250 },
  { x: 3127, y: 684 },
  { x: 3113, y: 778 },
  { x: 3344, y: 476 },
  { x: 3512, y: 333 },
  { x: 3336, y: 546 },
  { x: 3234, y: 501 },
  { x: 3143, y: 150 },
  { x: 3305, y: 505 },
  { x: 3410, y: 567 },
  { x: 3119, y: 217 },
  { x: 3391, y: 147 },
  { x: 3095, y: 579 },
  { x: 3438, y: 585 },
  { x: 3356, y: 554 },
  { x: 3388, y: 715 },
  { x: 3121, y: 221 },
  { x: 3236, y: 508 },
  { x: 3162, y: 344 },
  { x: 3367, y: 770 },
  { x: 3517, y: 236 },
  { x: 3320, y: 759 },
  { x: 3431, y: 557 },
  { x: 3106, y: 260 },
  { x: 3244, y: 537 },
  { x: 3416, y: 674 },
  { x: 3233, y: 163 },
  { x: 3097, y: 367 },
  { x: 3100, y: 548 },
  { x: 3508, y: 360 },
  { x: 3472, y: 285 },
  { x: 3175, y: 416 },
  { x: 3094, y: 240 },
  { x: 3068, y: 657 },
  { x: 3209, y: 520 },
  { x: 3176, y: 442 },
  { x: 3436, y: 801 },
  { x: 3199, y: 614 },
  { x: 3230, y: 745 },
  { x: 3111, y: 151 },
  { x: 3297, y: 169 },
  { x: 3258, y: 141 },
  { x: 3359, y: 209 },
  { x: 3190, y: 481 },
  { x: 3441, y: 263 },
  { x: 3195, y: 372 },
  { x: 3407, y: 695 },
  { x: 3273, y: 765 },
  { x: 3073, y: 788 },
  { x: 3439, y: 432 },
  { x: 3343, y: 204 },
  { x: 3208, y: 758 },
  { x: 3507, y: 366 },
  { x: 3185, y: 591 },
  { x: 3289, y: 208 },
  { x: 3465, y: 572 },
  { x: 3542, y: 293 },
  { x: 3210, y: 212 },
  { x: 3156, y: 413 },
  { x: 3228, y: 761 },
  { x: 3349, y: 192 },
  { x: 3497, y: 274 },
  { x: 3232, y: 531 },
  { x: 3074, y: 491 },
  { x: 3473, y: 206 },
  { x: 3481, y: 431 },
  { x: 3318, y: 530 },
  { x: 3186, y: 342 },
  { x: 3079, y: 540 },
  { x: 3149, y: 261 },
  { x: 3130, y: 366 },
  { x: 3256, y: 210 },
  { x: 3561, y: 336 },
  { x: 3378, y: 561 },
  { x: 3515, y: 710 },
  { x: 3502, y: 581 },
  { x: 3276, y: 187 },
  { x: 3439, y: 259 },
  { x: 3459, y: 349 },
  { x: 3176, y: 444 },
  { x: 3183, y: 696 },
  { x: 3146, y: 697 },
  { x: 3299, y: 212 },
  { x: 3181, y: 685 },
  { x: 3147, y: 258 },
  { x: 3071, y: 684 },
  { x: 3491, y: 688 },
  { x: 3117, y: 325 },
  { x: 3371, y: 193 },
  { x: 3466, y: 224 },
  { x: 3122, y: 229 },
  { x: 3523, y: 269 },
  { x: 3427, y: 744 },
  { x: 3514, y: 389 },
  { x: 3347, y: 463 },
  { x: 3286, y: 144 },
  { x: 3330, y: 174 },
  { x: 3206, y: 740 },
  { x: 3437, y: 241 },
  { x: 3141, y: 181 },
  { x: 3204, y: 669 },
  { x: 3260, y: 524 },
  { x: 3362, y: 779 },
  { x: 3150, y: 399 },
  { x: 3108, y: 655 },
  { x: 3462, y: 434 },
  { x: 3145, y: 665 },
  { x: 3440, y: 630 },
  { x: 3503, y: 313 },
  { x: 3154, y: 350 },
  { x: 3505, y: 286 },
  { x: 3193, y: 180 },
  { x: 3156, y: 643 },
  { x: 3139, y: 706 },
  { x: 3372, y: 488 },
  { x: 3196, y: 196 },
  { x: 3170, y: 634 },
  { x: 3360, y: 737 },
  { x: 3114, y: 421 },
  { x: 3069, y: 348 },
  { x: 3087, y: 440 },
  { x: 3451, y: 590 },
  { x: 3232, y: 242 },
  { x: 3391, y: 555 },
  { x: 3479, y: 700 },
  { x: 3133, y: 690 },
  { x: 3420, y: 798 },
  { x: 3092, y: 487 },
  { x: 3402, y: 507 },
  { x: 3264, y: 171 },
  { x: 3444, y: 612 },
  { x: 3417, y: 771 },
  { x: 3490, y: 558 },
  { x: 3512, y: 646 },
  { x: 3105, y: 827 },
  { x: 3211, y: 452 },
  { x: 3470, y: 272 },
  { x: 3341, y: 521 },
  { x: 3339, y: 158 },
  { x: 3558, y: 361 },
  { x: 3430, y: 312 },
  { x: 3384, y: 480 },
  { x: 3218, y: 165 },
  { x: 3482, y: 625 },
  { x: 3352, y: 533 },
  { x: 3278, y: 789 },
  { x: 3475, y: 549 },
  { x: 3285, y: 159 },
  { x: 3460, y: 234 },
  { x: 3388, y: 761 },
  { x: 3093, y: 381 },
  { x: 3492, y: 225 },
  { x: 3431, y: 756 },
  { x: 3171, y: 238 },
  { x: 3435, y: 687 },
  { x: 3199, y: 782 },
  { x: 3418, y: 274 },
  { x: 3396, y: 226 },
  { x: 3126, y: 368 },
  { x: 3138, y: 430 },
  { x: 3327, y: 138 },
  { x: 3363, y: 243 },
  { x: 3400, y: 232 },
  { x: 3203, y: 279 },
  { x: 3354, y: 768 },
  { x: 3099, y: 514 },
  { x: 3476, y: 717 },
  { x: 3134, y: 206 },
  { x: 3464, y: 680 },
  { x: 3364, y: 766 },
  { x: 3073, y: 461 },
  { x: 3100, y: 315 },
  { x: 3296, y: 813 },
  { x: 3231, y: 506 },
  { x: 3308, y: 728 },
  { x: 3179, y: 175 },
  { x: 3478, y: 689 },
  { x: 3094, y: 492 },
  { x: 3289, y: 200 },
  { x: 3180, y: 150 },
  { x: 3395, y: 773 },
  { x: 3458, y: 585 },
  { x: 3083, y: 792 },
  { x: 3484, y: 291 },
  { x: 3333, y: 464 },
  { x: 3359, y: 731 },
  { x: 3188, y: 395 },
  { x: 3197, y: 441 },
  { x: 3358, y: 203 },
  { x: 3152, y: 811 },
  { x: 3447, y: 471 },
  { x: 3471, y: 546 },
  { x: 3201, y: 502 },
  { x: 3399, y: 477 },
  { x: 3436, y: 265 },
  { x: 3368, y: 548 },
  { x: 3344, y: 510 },
  { x: 3873, y: 549 },
  { x: 3912, y: 166 },
  { x: 4179, y: 198 },
  { x: 4194, y: 168 },
  { x: 4172, y: 748 },
  { x: 4034, y: 781 },
  { x: 3941, y: 273 },
  { x: 3993, y: 793 },
  { x: 4183, y: 751 },
  { x: 3903, y: 525 },
  { x: 4124, y: 444 },
  { x: 4094, y: 512 },
  { x: 4040, y: 213 },
  { x: 3930, y: 553 },
  { x: 4199, y: 811 },
  { x: 3902, y: 248 },
  { x: 4184, y: 779 },
  { x: 3947, y: 790 },
  { x: 3963, y: 500 },
  { x: 3962, y: 189 },
  { x: 3979, y: 457 },
  { x: 3997, y: 211 },
  { x: 3868, y: 188 },
  { x: 4017, y: 806 },
  { x: 4039, y: 782 },
  { x: 4080, y: 547 },
  { x: 3952, y: 516 },
  { x: 3924, y: 817 },
  { x: 3864, y: 532 },
  { x: 3910, y: 815 },
  { x: 4161, y: 240 },
  { x: 4131, y: 745 },
  { x: 3898, y: 292 },
  { x: 3942, y: 157 },
  { x: 3857, y: 514 },
  { x: 3914, y: 426 },
  { x: 4083, y: 794 },
  { x: 4160, y: 743 },
  { x: 4071, y: 174 },
  { x: 3836, y: 381 },
  { x: 3922, y: 202 },
  { x: 3884, y: 772 },
  { x: 3858, y: 467 },
  { x: 4055, y: 509 },
  { x: 3870, y: 746 },
  { x: 3956, y: 761 },
  { x: 3934, y: 319 },
  { x: 4081, y: 507 },
  { x: 4046, y: 187 },
  { x: 4038, y: 732 },
  { x: 3834, y: 780 },
  { x: 4109, y: 465 },
  { x: 3825, y: 742 },
  { x: 4021, y: 808 },
  { x: 4112, y: 531 },
  { x: 3893, y: 778 },
  { x: 3823, y: 209 },
  { x: 3847, y: 483 },
  { x: 3848, y: 590 },
  { x: 3938, y: 670 },
  { x: 3820, y: 497 },
  { x: 4092, y: 755 },
  { x: 3945, y: 606 },
  { x: 4025, y: 534 },
  { x: 4103, y: 472 },
  { x: 4153, y: 221 },
  { x: 3854, y: 403 },
  { x: 4056, y: 783 },
  { x: 3890, y: 733 },
  { x: 3939, y: 633 },
  { x: 4190, y: 142 },
  { x: 3819, y: 372 },
  { x: 3852, y: 162 },
  { x: 3881, y: 820 },
  { x: 3926, y: 299 },
  { x: 3838, y: 569 },
  { x: 3871, y: 600 },
  { x: 3846, y: 753 },
  { x: 3830, y: 476 },
  { x: 3904, y: 488 },
  { x: 4176, y: 812 },
  { x: 3905, y: 287 },
  { x: 4022, y: 246 },
  { x: 3874, y: 185 },
  { x: 4029, y: 230 },
  { x: 4122, y: 200 },
  { x: 4188, y: 723 },
  { x: 3821, y: 347 },
  { x: 3889, y: 341 },
  { x: 3872, y: 480 },
  { x: 3826, y: 409 },
  { x: 4140, y: 765 },
  { x: 4065, y: 237 },
  { x: 3841, y: 310 },
  { x: 3892, y: 740 },
  { x: 3944, y: 750 },
  { x: 4162, y: 535 },
  { x: 3825, y: 793 },
  { x: 4118, y: 532 },
  { x: 4104, y: 229 },
  { x: 3833, y: 689 },
  { x: 4107, y: 547 },
  { x: 4083, y: 211 },
  { x: 3854, y: 506 },
  { x: 4001, y: 830 },
  { x: 4009, y: 457 },
  { x: 3945, y: 567 },
  { x: 4155, y: 548 },
  { x: 4091, y: 139 },
  { x: 3849, y: 463 },
  { x: 3865, y: 632 },
  { x: 3939, y: 253 },
  { x: 3935, y: 418 },
  { x: 3946, y: 550 },
  { x: 3832, y: 729 },
  { x: 3982, y: 815 },
  { x: 3819, y: 779 },
  { x: 3963, y: 730 },
  { x: 4000, y: 763 },
  { x: 3813, y: 274 },
  { x: 3860, y: 607 },
  { x: 3874, y: 539 },
  { x: 3910, y: 721 },
  { x: 3855, y: 573 },
  { x: 3925, y: 787 },
  { x: 4054, y: 477 },
  { x: 3901, y: 175 },
  { x: 4194, y: 149 },
  { x: 3903, y: 444 },
  { x: 3889, y: 439 },
  { x: 3878, y: 218 },
  { x: 3921, y: 694 },
  { x: 3872, y: 551 },
  { x: 4188, y: 778 },
  { x: 3880, y: 526 },
  { x: 3877, y: 166 },
  { x: 4162, y: 783 },
  { x: 3979, y: 164 },
  { x: 4181, y: 732 },
  { x: 4063, y: 487 },
  { x: 3879, y: 559 },
  { x: 4008, y: 796 },
  { x: 4062, y: 245 },
  { x: 3853, y: 630 },
  { x: 3954, y: 802 },
  { x: 4187, y: 760 },
  { x: 3897, y: 580 },
  { x: 4041, y: 476 },
  { x: 4179, y: 820 },
  { x: 3904, y: 178 },
  { x: 3891, y: 424 },
  { x: 3870, y: 555 },
  { x: 3821, y: 433 },
  { x: 3828, y: 440 },
  { x: 4150, y: 241 },
  { x: 3898, y: 364 },
  { x: 4121, y: 177 },
  { x: 4180, y: 768 },
  { x: 4215, y: 168 },
  { x: 4075, y: 478 },
  { x: 4005, y: 148 },
  { x: 4157, y: 490 },
  { x: 3850, y: 521 },
  { x: 3887, y: 507 },
  { x: 3861, y: 224 },
  { x: 4046, y: 749 },
  { x: 3927, y: 232 },
  { x: 3851, y: 260 },
  { x: 3840, y: 183 },
  { x: 3917, y: 709 },
  { x: 3824, y: 249 },
  { x: 3923, y: 219 },
  { x: 4090, y: 508 },
  { x: 3971, y: 194 },
  { x: 3956, y: 749 },
  { x: 4097, y: 484 },
  { x: 4139, y: 190 },
  { x: 3921, y: 548 },
  { x: 4127, y: 504 },
  { x: 3927, y: 572 },
  { x: 4045, y: 543 },
  { x: 4136, y: 753 },
  { x: 3894, y: 662 },
  { x: 3832, y: 291 },
  { x: 4205, y: 148 },
  { x: 3925, y: 347 },
  { x: 3841, y: 171 },
  { x: 4052, y: 511 },
  { x: 4058, y: 732 },
  { x: 4048, y: 147 },
  { x: 3869, y: 278 },
  { x: 3986, y: 195 },
  { x: 3941, y: 655 },
  { x: 4094, y: 547 },
  { x: 4128, y: 182 },
  { x: 3812, y: 211 },
  { x: 3859, y: 806 },
  { x: 3815, y: 328 },
  { x: 4047, y: 804 },
  { x: 3858, y: 368 },
  { x: 4026, y: 527 },
  { x: 3987, y: 820 },
  { x: 3851, y: 415 },
  { x: 3898, y: 378 },
  { x: 3814, y: 495 },
  { x: 3959, y: 228 },
  { x: 3979, y: 533 },
  { x: 4096, y: 781 },
  { x: 4133, y: 191 },
  { x: 3995, y: 168 },
  { x: 3896, y: 628 },
  { x: 3920, y: 520 },
  { x: 4063, y: 138 },
  { x: 3931, y: 785 },
  { x: 4198, y: 236 },
  { x: 3930, y: 405 },
  { x: 3926, y: 152 },
  { x: 3837, y: 733 },
  { x: 3900, y: 173 },
  { x: 3821, y: 471 },
  { x: 3907, y: 353 },
  { x: 4061, y: 793 },
  { x: 3965, y: 181 },
  { x: 3889, y: 643 },
  { x: 3910, y: 367 },
  { x: 3961, y: 493 },
  { x: 3981, y: 164 },
  { x: 3870, y: 641 },
  { x: 4163, y: 769 },
  { x: 3974, y: 202 },
  { x: 3822, y: 454 },
  { x: 3878, y: 657 },
  { x: 4165, y: 176 },
  { x: 4102, y: 170 },
  { x: 3863, y: 327 },
  { x: 3808, y: 434 },
  { x: 4092, y: 522 },
  { x: 3872, y: 651 },
  { x: 4009, y: 458 },
  { x: 3833, y: 717 },
  { x: 3848, y: 614 },
  { x: 3992, y: 233 },
  { x: 3970, y: 151 },
  { x: 3819, y: 553 },
  { x: 4106, y: 746 },
  { x: 3982, y: 485 },
  { x: 4003, y: 809 },
  { x: 3967, y: 748 },
  { x: 4090, y: 536 },
  { x: 4080, y: 479 },
  { x: 4104, y: 224 },
  { x: 3934, y: 218 },
  { x: 3917, y: 427 },
  { x: 3883, y: 591 },
  { x: 3909, y: 747 },
  { x: 3945, y: 300 },
  { x: 3904, y: 598 },
  { x: 4041, y: 766 },
  { x: 4153, y: 192 },
  { x: 3911, y: 808 },
  { x: 3939, y: 349 },
  { x: 3877, y: 240 },
  { x: 3823, y: 399 },
  { x: 3878, y: 195 },
  { x: 3884, y: 553 },
  { x: 4063, y: 186 },
  { x: 3989, y: 183 },
  { x: 3867, y: 781 },
  { x: 3859, y: 689 },
  { x: 3915, y: 232 },
  { x: 3904, y: 810 },
  { x: 4015, y: 762 },
  { x: 4163, y: 754 },
  { x: 3956, y: 525 },
  { x: 4129, y: 801 },
  { x: 4210, y: 153 },
  { x: 4106, y: 218 },
  { x: 4079, y: 520 },
  { x: 4142, y: 490 },
  { x: 4153, y: 542 },
  { x: 3848, y: 271 },
  { x: 4057, y: 227 },
  { x: 4041, y: 487 },
  { x: 4111, y: 472 },
  { x: 3864, y: 806 },
  { x: 4027, y: 528 },
  { x: 3902, y: 557 },
  { x: 3949, y: 725 },
  { x: 3919, y: 406 },
  { x: 3862, y: 613 },
  { x: 4038, y: 501 },
  { x: 3873, y: 288 },
  { x: 3922, y: 265 },
  { x: 3850, y: 591 },
  { x: 3891, y: 665 },
  { x: 4162, y: 148 },
  { x: 3820, y: 652 },
  { x: 3888, y: 394 },
  { x: 4074, y: 444 },
  { x: 4032, y: 479 },
  { x: 3876, y: 561 },
  { x: 3900, y: 140 },
  { x: 3894, y: 478 },
  { x: 3893, y: 473 },
  { x: 4145, y: 790 },
  { x: 3940, y: 828 },
  { x: 3883, y: 477 },
  { x: 4042, y: 160 },
  { x: 3863, y: 355 },
  { x: 3861, y: 552 },
  { x: 3886, y: 174 },
  { x: 3821, y: 263 },
  { x: 3828, y: 337 },
  { x: 3923, y: 803 },
  { x: 4005, y: 772 },
  { x: 3969, y: 755 },
  { x: 4165, y: 175 },
  { x: 4051, y: 206 },
  { x: 4154, y: 749 },
  { x: 3912, y: 605 },
  { x: 3907, y: 733 },
  { x: 3866, y: 731 },
  { x: 3953, y: 531 },
  { x: 3926, y: 408 },
  { x: 4002, y: 241 },
  { x: 3901, y: 537 },
  { x: 4014, y: 726 },
  { x: 3979, y: 505 },
  { x: 3816, y: 255 },
  { x: 3913, y: 214 },
  { x: 4037, y: 785 },
  { x: 4089, y: 224 },
  { x: 3814, y: 546 },
  { x: 3905, y: 452 },
  { x: 3906, y: 582 },
  { x: 3903, y: 563 },
  { x: 3967, y: 724 },
  { x: 3890, y: 165 },
  { x: 3869, y: 592 },
  { x: 3847, y: 279 },
  { x: 4008, y: 735 },
  { x: 3982, y: 793 },
  { x: 3819, y: 461 },
  { x: 3817, y: 203 },
  { x: 3885, y: 818 },
  { x: 3831, y: 426 },
  { x: 4018, y: 786 },
  { x: 4138, y: 449 },
  { x: 3868, y: 146 },
  { x: 4037, y: 724 },
  { x: 4205, y: 750 },
  { x: 3931, y: 442 },
  { x: 4144, y: 803 },
  { x: 4092, y: 526 },
  { x: 3934, y: 399 },
  { x: 4012, y: 812 },
  { x: 3920, y: 426 },
  { x: 3876, y: 775 },
  { x: 4207, y: 779 },
  { x: 3871, y: 755 },
  { x: 3875, y: 582 },
  { x: 3858, y: 148 },
  { x: 4169, y: 725 },
  { x: 3956, y: 230 },
  { x: 3808, y: 752 },
  { x: 3849, y: 515 },
  { x: 4050, y: 170 },
  { x: 3904, y: 265 },
  { x: 3888, y: 332 },
  { x: 4172, y: 735 },
  { x: 4175, y: 731 },
  { x: 3825, y: 373 },
  { x: 4011, y: 234 },
  { x: 4061, y: 191 },
  { x: 3985, y: 762 },
  { x: 3983, y: 467 },
  { x: 4091, y: 802 },
  { x: 3884, y: 707 },
  { x: 4025, y: 513 },
  { x: 3914, y: 703 },
  { x: 3826, y: 606 },
  { x: 3817, y: 394 },
  { x: 4165, y: 224 },
  { x: 3976, y: 813 },
  { x: 4045, y: 729 },
  { x: 3837, y: 396 },
  { x: 3928, y: 713 },
  { x: 3830, y: 319 },
  { x: 3885, y: 312 },
  { x: 3900, y: 706 },
  { x: 3959, y: 453 },
  { x: 3957, y: 722 },
  { x: 4003, y: 732 },
  { x: 3907, y: 457 },
  { x: 4111, y: 152 },
  { x: 4157, y: 462 },
  { x: 3999, y: 164 },
  { x: 3870, y: 477 },
  { x: 3874, y: 441 },
  { x: 4013, y: 548 },
  { x: 3897, y: 243 },
  { x: 3828, y: 437 },
  { x: 3949, y: 502 },
  { x: 4090, y: 464 },
  { x: 3864, y: 411 },
  { x: 3939, y: 393 },
  { x: 3978, y: 820 },
  { x: 3814, y: 434 },
  { x: 3835, y: 789 },
  { x: 4161, y: 486 },
  { x: 3894, y: 139 },
  { x: 3881, y: 367 },
  { x: 4021, y: 748 },
  { x: 3815, y: 369 },
  { x: 4170, y: 468 },
  { x: 4028, y: 226 },
  { x: 3887, y: 810 },
  { x: 3883, y: 708 },
  { x: 3971, y: 141 },
  { x: 3880, y: 586 },
  { x: 4020, y: 809 },
  { x: 3852, y: 677 },
  { x: 3878, y: 525 },
  { x: 4060, y: 758 },
  { x: 3893, y: 252 },
  { x: 3937, y: 176 },
  { x: 3861, y: 448 },
  { x: 4120, y: 546 },
  { x: 3924, y: 830 },
  { x: 4017, y: 541 },
  { x: 3958, y: 743 },
  { x: 4009, y: 145 },
  { x: 4171, y: 751 },
  { x: 3991, y: 237 },
  { x: 3896, y: 318 },
  { x: 4129, y: 774 },
  { x: 4176, y: 500 },
  { x: 3952, y: 455 },
  { x: 3942, y: 687 },
  { x: 3974, y: 527 },
  { x: 3885, y: 438 },
  { x: 4126, y: 454 },
  { x: 4104, y: 504 },
  { x: 4024, y: 475 },
  { x: 3917, y: 820 },
  { x: 4138, y: 167 },
  { x: 3826, y: 669 },
  { x: 4129, y: 507 },
  { x: 3928, y: 665 },
  { x: 3897, y: 367 },
  { x: 3993, y: 728 },
  { x: 3932, y: 598 },
  { x: 3873, y: 758 },
  { x: 3822, y: 160 },
  { x: 4153, y: 813 },
  { x: 3808, y: 624 },
  { x: 3919, y: 321 },
  { x: 4053, y: 194 },
  { x: 3827, y: 538 },
  { x: 3816, y: 535 },
  { x: 4142, y: 213 },
  { x: 4157, y: 165 },
  { x: 4165, y: 481 },
  { x: 4060, y: 232 },
  { x: 3933, y: 695 },
  { x: 4188, y: 168 },
  { x: 4152, y: 447 },
  { x: 4119, y: 734 },
  { x: 4034, y: 735 },
  { x: 3905, y: 725 },
  { x: 3813, y: 604 },
  { x: 4097, y: 512 },
  { x: 3910, y: 425 },
  { x: 3841, y: 692 },
  { x: 3835, y: 584 },
  { x: 3890, y: 400 },
  { x: 3937, y: 783 },
  { x: 4130, y: 150 },
  { x: 4080, y: 823 },
  { x: 3879, y: 592 },
  { x: 3866, y: 246 },
  { x: 3994, y: 142 },
  { x: 4040, y: 763 },
  { x: 3889, y: 645 },
  { x: 3936, y: 703 },
  { x: 3856, y: 781 },
  { x: 3985, y: 471 },
  { x: 4156, y: 759 },
  { x: 3884, y: 827 },
  { x: 4099, y: 511 },
  { x: 3976, y: 812 },
  { x: 3868, y: 233 },
  { x: 4175, y: 509 },
  { x: 3899, y: 673 },
  { x: 4201, y: 190 },
  { x: 4128, y: 497 },
  { x: 3836, y: 384 },
  { x: 4031, y: 791 },
  { x: 3984, y: 458 },
  { x: 3867, y: 215 },
  { x: 4118, y: 789 },
  { x: 3875, y: 396 },
  { x: 4197, y: 214 },
  { x: 4146, y: 797 },
  { x: 3821, y: 219 },
  { x: 3849, y: 828 },
  { x: 3829, y: 372 },
  { x: 3828, y: 441 },
  { x: 3886, y: 700 },
  { x: 3990, y: 146 },
  { x: 3988, y: 825 },
  { x: 3860, y: 632 },
  { x: 4134, y: 198 },
  { x: 3854, y: 253 },
  { x: 3934, y: 623 },
  { x: 3964, y: 192 },
  { x: 3912, y: 651 },
  { x: 3852, y: 557 },
  { x: 3831, y: 793 },
  { x: 3842, y: 782 },
  { x: 3876, y: 463 },
  { x: 4116, y: 749 },
  { x: 3853, y: 691 },
  { x: 4124, y: 230 },
  { x: 3852, y: 750 },
  { x: 4000, y: 475 },
  { x: 3942, y: 636 },
  { x: 3857, y: 321 },
  { x: 3935, y: 503 },
  { x: 4015, y: 801 },
  { x: 4170, y: 829 },
  { x: 3959, y: 739 },
  { x: 3808, y: 529 },
  { x: 4159, y: 144 },
  { x: 3824, y: 664 },
  { x: 3934, y: 663 },
  { x: 4004, y: 524 },
  { x: 4029, y: 154 },
  { x: 3881, y: 401 },
  { x: 3870, y: 711 },
  { x: 3850, y: 403 },
  { x: 4186, y: 815 },
  { x: 3893, y: 214 },
  { x: 4040, y: 502 },
  { x: 4067, y: 760 },
  { x: 3931, y: 276 },
  { x: 3907, y: 773 },
  { x: 3933, y: 656 },
  { x: 3835, y: 480 },
  { x: 3928, y: 351 },
  { x: 3976, y: 460 },
  { x: 3820, y: 215 },
  { x: 3884, y: 185 },
  { x: 4008, y: 180 },
  { x: 4158, y: 517 },
  { x: 4128, y: 171 },
  { x: 4021, y: 155 },
  { x: 3913, y: 805 },
  { x: 3841, y: 258 },
  { x: 4127, y: 827 },
  { x: 3860, y: 293 },
  { x: 3932, y: 350 },
  { x: 4013, y: 459 },
  { x: 3887, y: 297 },
  { x: 4087, y: 229 },
  { x: 3974, y: 778 },
  { x: 4178, y: 188 },
  { x: 3911, y: 638 },
  { x: 4143, y: 447 },
  { x: 3818, y: 688 },
  { x: 3918, y: 342 },
  { x: 3834, y: 182 },
  { x: 4047, y: 777 },
  { x: 4142, y: 535 },
  { x: 3912, y: 780 },
  { x: 3939, y: 720 },
  { x: 4073, y: 173 },
  { x: 4121, y: 772 },
  { x: 4070, y: 216 },
  { x: 3925, y: 164 },
  { x: 3952, y: 782 },
  { x: 3882, y: 197 },
  { x: 3833, y: 714 },
  { x: 3853, y: 528 },
  { x: 4112, y: 820 },
  { x: 4071, y: 550 },
  { x: 4122, y: 537 },
  { x: 3876, y: 329 },
  { x: 3839, y: 160 },
  { x: 3975, y: 473 },
  { x: 3829, y: 706 },
  { x: 3823, y: 385 },
  { x: 3879, y: 501 },
  { x: 3851, y: 405 },
  { x: 3993, y: 747 },
  { x: 4052, y: 232 },
  { x: 4046, y: 792 },
  { x: 4131, y: 470 },
  { x: 3854, y: 261 },
  { x: 4110, y: 490 },
  { x: 3921, y: 570 },
  { x: 3840, y: 205 },
  { x: 3874, y: 436 },
  { x: 3863, y: 177 },
  { x: 4097, y: 200 },
  { x: 4206, y: 198 },
  { x: 3844, y: 386 },
  { x: 3872, y: 428 },
  { x: 4126, y: 466 },
  { x: 3937, y: 687 },
  { x: 4036, y: 218 },
  { x: 3845, y: 643 },
  { x: 3944, y: 174 },
  { x: 3878, y: 743 },
  { x: 3890, y: 630 },
  { x: 3811, y: 425 },
  { x: 3827, y: 231 },
  { x: 4001, y: 500 },
  { x: 4082, y: 489 },
  { x: 3815, y: 587 },
  { x: 3925, y: 343 },
  { x: 3988, y: 227 },
  { x: 4138, y: 246 },
  { x: 4023, y: 149 },
  { x: 3828, y: 524 },
  { x: 4115, y: 548 },
  { x: 3941, y: 727 },
  { x: 3937, y: 697 },
  { x: 3949, y: 766 },
  { x: 3968, y: 479 },
  { x: 4108, y: 778 },
  { x: 4160, y: 189 },
  { x: 4172, y: 203 },
  { x: 4156, y: 488 },
  { x: 3901, y: 464 },
  { x: 4034, y: 531 },
  { x: 4109, y: 798 },
  { x: 3819, y: 148 },
  { x: 3908, y: 374 },
  { x: 4110, y: 811 },
  { x: 3905, y: 618 },
  { x: 3918, y: 344 },
  { x: 4146, y: 825 },
  { x: 3829, y: 150 },
  { x: 4067, y: 532 },
  { x: 3832, y: 725 },
  { x: 4164, y: 461 },
  { x: 4125, y: 471 },
  { x: 3853, y: 499 },
  { x: 3836, y: 714 },
  { x: 4039, y: 470 },
  { x: 3847, y: 210 },
  { x: 4038, y: 512 },
  { x: 3845, y: 258 },
  { x: 3862, y: 609 },
  { x: 3922, y: 667 },
  { x: 3884, y: 232 },
  { x: 3897, y: 441 },
  { x: 4193, y: 146 },
  { x: 3837, y: 283 },
  { x: 3873, y: 683 },
  { x: 4118, y: 508 },
  { x: 4114, y: 453 },
  { x: 4061, y: 543 },
  { x: 4050, y: 806 },
  { x: 4167, y: 478 },
  { x: 3893, y: 405 },
  { x: 4055, y: 465 },
  { x: 3856, y: 169 },
  { x: 4185, y: 235 },
  { x: 3935, y: 726 },
  { x: 4162, y: 812 },
  { x: 3955, y: 818 },
  { x: 3852, y: 205 },
  { x: 4170, y: 243 },
  { x: 3979, y: 147 },
  { x: 3924, y: 264 },
  { x: 3899, y: 422 },
  { x: 3920, y: 732 },
  { x: 3883, y: 672 },
  { x: 3898, y: 602 },
  { x: 3842, y: 351 },
  { x: 3844, y: 781 },
  { x: 3864, y: 570 },
  { x: 3891, y: 674 },
  { x: 3970, y: 501 },
  { x: 3872, y: 591 },
  { x: 3895, y: 268 },
  { x: 3997, y: 141 },
  { x: 3917, y: 391 },
  { x: 4102, y: 768 },
  { x: 3843, y: 346 },
  { x: 3943, y: 652 },
  { x: 3860, y: 220 },
  { x: 3874, y: 585 },
  { x: 4139, y: 155 },
  { x: 3854, y: 191 },
  { x: 3905, y: 791 },
  { x: 4140, y: 509 },
  { x: 4037, y: 183 },
  { x: 3920, y: 430 },
  { x: 3892, y: 409 },
  { x: 4040, y: 733 },
  { x: 4097, y: 497 },
  { x: 3836, y: 721 },
  { x: 3841, y: 386 },
  { x: 4168, y: 485 },
  { x: 4133, y: 465 },
  { x: 4055, y: 808 },
  { x: 3896, y: 634 },
  { x: 3897, y: 410 },
  { x: 3852, y: 330 },
  { x: 3880, y: 735 },
  { x: 3993, y: 496 },
  { x: 3835, y: 819 },
  { x: 3893, y: 454 },
  { x: 3848, y: 370 },
  { x: 3833, y: 787 },
  { x: 3886, y: 398 },
  { x: 3988, y: 479 },
  { x: 4126, y: 178 },
  { x: 3809, y: 584 },
  { x: 3888, y: 378 },
  { x: 3820, y: 460 },
  { x: 3842, y: 618 },
  { x: 4017, y: 246 },
  { x: 4188, y: 238 },
  { x: 4167, y: 756 },
  { x: 3826, y: 191 },
  { x: 4102, y: 448 },
  { x: 3815, y: 547 },
  { x: 3894, y: 288 },
  { x: 3931, y: 592 },
  { x: 3917, y: 249 },
  { x: 3940, y: 248 },
  { x: 4029, y: 802 },
  { x: 4046, y: 452 },
  { x: 3979, y: 220 },
  { x: 3946, y: 261 },
  { x: 4099, y: 449 },
  { x: 3824, y: 195 },
  { x: 3927, y: 198 },
  { x: 3851, y: 250 },
  { x: 3827, y: 684 },
  { x: 4183, y: 179 },
  { x: 3813, y: 778 },
  { x: 4044, y: 476 },
  { x: 4036, y: 546 },
  { x: 3934, y: 501 },
  { x: 3913, y: 601 },
  { x: 3843, y: 150 },
  { x: 4005, y: 505 },
  { x: 3912, y: 304 },
  { x: 3819, y: 217 },
  { x: 4091, y: 147 },
  { x: 4095, y: 829 },
  { x: 4193, y: 182 },
  { x: 3821, y: 221 },
  { x: 3936, y: 508 },
  { x: 3862, y: 344 },
  { x: 4067, y: 770 },
  { x: 4020, y: 759 },
  { x: 3944, y: 537 },
  { x: 3933, y: 163 },
  { x: 3945, y: 245 },
  { x: 3875, y: 416 },
  { x: 3909, y: 520 },
  { x: 3915, y: 610 },
  { x: 4211, y: 148 },
  { x: 4179, y: 818 },
  { x: 3876, y: 442 },
  { x: 4136, y: 801 },
  { x: 3899, y: 614 },
  { x: 3930, y: 745 },
  { x: 3997, y: 169 },
  { x: 3958, y: 141 },
  { x: 4059, y: 209 },
  { x: 3890, y: 481 },
  { x: 3895, y: 372 },
  { x: 3973, y: 765 },
  { x: 4043, y: 204 },
  { x: 3908, y: 758 },
  { x: 3885, y: 591 },
  { x: 3989, y: 208 },
  { x: 3910, y: 212 },
  { x: 3856, y: 413 },
  { x: 3928, y: 761 },
  { x: 4049, y: 192 },
  { x: 3932, y: 531 },
  { x: 4173, y: 206 },
  { x: 3940, y: 715 },
  { x: 4018, y: 530 },
  { x: 3886, y: 342 },
  { x: 4209, y: 147 },
  { x: 3849, y: 261 },
  { x: 3830, y: 366 },
  { x: 3956, y: 210 },
  { x: 3930, y: 382 },
  { x: 3939, y: 673 },
  { x: 3976, y: 187 },
  { x: 3934, y: 644 },
  { x: 3876, y: 444 },
  { x: 3883, y: 696 },
  { x: 3846, y: 697 },
  { x: 3999, y: 212 },
  { x: 3881, y: 685 },
  { x: 3847, y: 258 },
  { x: 3817, y: 325 },
  { x: 4071, y: 193 },
  { x: 4166, y: 224 },
  { x: 3822, y: 229 },
  { x: 4127, y: 744 },
  { x: 4047, y: 463 },
  { x: 3986, y: 144 },
  { x: 4030, y: 174 },
  { x: 3906, y: 740 },
  { x: 4137, y: 241 },
  { x: 3841, y: 181 },
  { x: 3904, y: 669 },
  { x: 3922, y: 683 },
  { x: 3960, y: 524 },
  { x: 3913, y: 282 },
  { x: 4062, y: 779 },
  { x: 3850, y: 399 },
  { x: 3808, y: 655 },
  { x: 3845, y: 665 },
  { x: 3854, y: 350 },
  { x: 3893, y: 180 },
  { x: 3856, y: 643 },
  { x: 3839, y: 706 },
  { x: 4072, y: 488 },
  { x: 3929, y: 309 },
  { x: 3925, y: 384 },
  { x: 3896, y: 196 },
  { x: 3870, y: 634 },
  { x: 4060, y: 737 },
  { x: 3814, y: 421 },
  { x: 3932, y: 242 },
  { x: 3833, y: 690 },
  { x: 4120, y: 798 },
  { x: 4102, y: 507 },
  { x: 3964, y: 171 },
  { x: 4117, y: 771 },
  { x: 3911, y: 452 },
  { x: 4041, y: 521 },
  { x: 4039, y: 158 },
  { x: 4084, y: 480 },
  { x: 3918, y: 165 },
  { x: 4052, y: 533 },
  { x: 3978, y: 789 },
  { x: 3985, y: 159 },
  { x: 4160, y: 234 },
  { x: 4088, y: 761 },
  { x: 4192, y: 225 },
  { x: 4131, y: 756 },
  { x: 3871, y: 238 },
  { x: 3935, y: 575 },
  { x: 3917, y: 629 },
  { x: 3899, y: 782 },
  { x: 4096, y: 226 },
  { x: 3826, y: 368 },
  { x: 3838, y: 430 },
  { x: 4027, y: 138 },
  { x: 4063, y: 243 },
  { x: 4100, y: 232 },
  { x: 3903, y: 279 },
  { x: 4054, y: 768 },
  { x: 3834, y: 206 },
  { x: 3923, y: 660 },
  { x: 4064, y: 766 },
  { x: 3996, y: 813 },
  { x: 3905, y: 289 },
  { x: 3931, y: 506 },
  { x: 4008, y: 728 },
  { x: 3879, y: 175 },
  { x: 4200, y: 780 },
  { x: 3989, y: 200 },
  { x: 3880, y: 150 },
  { x: 4095, y: 773 },
  { x: 4033, y: 464 },
  { x: 4059, y: 731 },
  { x: 3888, y: 395 },
  { x: 3897, y: 441 },
  { x: 4058, y: 203 },
  { x: 3852, y: 811 },
  { x: 4147, y: 471 },
  { x: 3901, y: 502 },
  { x: 4099, y: 477 },
  { x: 4068, y: 548 },
  { x: 4044, y: 510 },
  { x: 4889, y: 376 },
  { x: 4573, y: 549 },
  { x: 4612, y: 166 },
  { x: 4879, y: 198 },
  { x: 4872, y: 748 },
  { x: 4734, y: 781 },
  { x: 4865, y: 688 },
  { x: 4839, y: 274 },
  { x: 4474, y: 716 },
  { x: 4478, y: 408 },
  { x: 4693, y: 793 },
  { x: 4883, y: 751 },
  { x: 4603, y: 525 },
  { x: 4824, y: 444 },
  { x: 4893, y: 350 },
  { x: 4794, y: 512 },
  { x: 4506, y: 171 },
  { x: 4740, y: 213 },
  { x: 4923, y: 349 },
  { x: 4602, y: 248 },
  { x: 4877, y: 626 },
  { x: 4647, y: 790 },
  { x: 4764, y: 710 },
  { x: 4663, y: 500 },
  { x: 4662, y: 189 },
  { x: 4927, y: 348 },
  { x: 4679, y: 457 },
  { x: 4881, y: 337 },
  { x: 4697, y: 211 },
  { x: 4568, y: 188 },
  { x: 4717, y: 806 },
  { x: 4739, y: 782 },
  { x: 4495, y: 272 },
  { x: 4780, y: 547 },
  { x: 4652, y: 516 },
  { x: 4624, y: 817 },
  { x: 4880, y: 293 },
  { x: 4856, y: 681 },
  { x: 4564, y: 532 },
  { x: 4610, y: 815 },
  { x: 4861, y: 240 },
  { x: 4831, y: 745 },
  { x: 4488, y: 147 },
  { x: 4928, y: 356 },
  { x: 4598, y: 292 },
  { x: 4642, y: 157 },
  { x: 4557, y: 514 },
  { x: 4843, y: 609 },
  { x: 4783, y: 794 },
  { x: 4860, y: 743 },
  { x: 4771, y: 174 },
  { x: 4536, y: 381 },
  { x: 4485, y: 667 },
  { x: 4480, y: 810 },
  { x: 4622, y: 202 },
  { x: 4584, y: 772 },
  { x: 4558, y: 467 },
  { x: 4755, y: 509 },
  { x: 4570, y: 746 },
  { x: 4763, y: 437 },
  { x: 4656, y: 761 },
  { x: 4781, y: 507 },
  { x: 4815, y: 284 },
  { x: 4882, y: 677 },
  { x: 4746, y: 187 },
  { x: 4946, y: 433 },
  { x: 4738, y: 732 },
  { x: 4828, y: 704 },
  { x: 4534, y: 780 },
  { x: 4809, y: 465 },
  { x: 4525, y: 742 },
  { x: 4820, y: 708 },
  { x: 4721, y: 808 },
  { x: 4812, y: 531 },
  { x: 4593, y: 778 },
  { x: 4523, y: 209 },
  { x: 4547, y: 483 },
  { x: 4548, y: 590 },
  { x: 4855, y: 417 },
  { x: 4520, y: 497 },
  { x: 4497, y: 449 },
  { x: 4792, y: 755 },
  { x: 4922, y: 309 },
  { x: 4477, y: 268 },
  { x: 4937, y: 411 },
  { x: 4725, y: 534 },
  { x: 4804, y: 671 },
  { x: 4803, y: 472 },
  { x: 4853, y: 221 },
  { x: 4492, y: 727 },
  { x: 4554, y: 403 },
  { x: 4799, y: 588 },
  { x: 4852, y: 294 },
  { x: 4756, y: 783 },
  { x: 4590, y: 733 },
  { x: 4850, y: 302 },
  { x: 4519, y: 372 },
  { x: 4552, y: 162 },
  { x: 4476, y: 253 },
  { x: 4581, y: 820 },
  { x: 4942, y: 392 },
  { x: 4885, y: 416 },
  { x: 4538, y: 569 },
  { x: 4571, y: 600 },
  { x: 4546, y: 753 },
  { x: 4530, y: 476 },
  { x: 4604, y: 488 },
  { x: 4896, y: 474 },
  { x: 4925, y: 306 },
  { x: 4574, y: 185 },
  { x: 4729, y: 230 },
  { x: 4822, y: 200 },
  { x: 4888, y: 723 },
  { x: 4521, y: 347 },
  { x: 4848, y: 702 },
  { x: 4589, y: 341 },
  { x: 4572, y: 480 },
  { x: 4526, y: 409 },
  { x: 4840, y: 765 },
  { x: 4765, y: 237 },
  { x: 4829, y: 297 },
  { x: 4541, y: 310 },
  { x: 4592, y: 740 },
  { x: 4817, y: 397 },
  { x: 4644, y: 750 },
  { x: 4862, y: 535 },
  { x: 4496, y: 281 },
  { x: 4828, y: 262 },
  { x: 4525, y: 793 },
  { x: 4818, y: 532 },
  { x: 4804, y: 229 },
  { x: 4922, y: 222 },
  { x: 4533, y: 689 },
  { x: 4807, y: 547 },
  { x: 4783, y: 211 },
  { x: 4925, y: 456 },
  { x: 4554, y: 506 },
  { x: 4701, y: 830 },
  { x: 4709, y: 457 },
  { x: 4472, y: 735 },
  { x: 4494, y: 795 },
  { x: 4855, y: 548 },
  { x: 4874, y: 683 },
  { x: 4923, y: 401 },
  { x: 4914, y: 467 },
  { x: 4492, y: 549 },
  { x: 4549, y: 463 },
  { x: 4503, y: 504 },
  { x: 4565, y: 632 },
  { x: 4869, y: 280 },
  { x: 4908, y: 327 },
  { x: 4506, y: 170 },
  { x: 4937, y: 240 },
  { x: 4532, y: 729 },
  { x: 4682, y: 815 },
  { x: 4519, y: 779 },
  { x: 4663, y: 730 },
  { x: 4700, y: 763 },
  { x: 4513, y: 274 },
  { x: 4797, y: 629 },
  { x: 4560, y: 607 },
  { x: 4574, y: 539 },
  { x: 4934, y: 284 },
  { x: 4875, y: 414 },
  { x: 4847, y: 371 },
  { x: 4555, y: 573 },
  { x: 4625, y: 787 },
  { x: 4754, y: 477 },
  { x: 4941, y: 432 },
  { x: 4601, y: 175 },
  { x: 4603, y: 444 },
  { x: 4589, y: 439 },
  { x: 4578, y: 218 },
  { x: 4572, y: 551 },
  { x: 4500, y: 746 },
  { x: 4911, y: 353 },
  { x: 4580, y: 526 },
  { x: 4482, y: 176 },
  { x: 4826, y: 680 },
  { x: 4577, y: 166 },
  { x: 4794, y: 571 },
  { x: 4831, y: 370 },
  { x: 4862, y: 783 },
  { x: 4475, y: 711 },
  { x: 4842, y: 590 },
  { x: 4679, y: 164 },
  { x: 4881, y: 732 },
  { x: 4763, y: 487 },
  { x: 4481, y: 543 },
  { x: 4793, y: 690 },
  { x: 4870, y: 278 },
  { x: 4579, y: 559 },
  { x: 4893, y: 312 },
  { x: 4708, y: 796 },
  { x: 4762, y: 245 },
  { x: 4553, y: 630 },
  { x: 4654, y: 802 },
  { x: 4887, y: 760 },
  { x: 4597, y: 580 },
  { x: 4785, y: 568 },
  { x: 4741, y: 476 },
  { x: 4493, y: 693 },
  { x: 4468, y: 509 },
  { x: 4802, y: 613 },
  { x: 4604, y: 178 },
  { x: 4591, y: 424 },
  { x: 4570, y: 555 },
  { x: 4521, y: 433 },
  { x: 4528, y: 440 },
  { x: 4850, y: 241 },
  { x: 4876, y: 287 },
  { x: 4840, y: 640 },
  { x: 4598, y: 364 },
  { x: 4821, y: 177 },
  { x: 4839, y: 666 },
  { x: 4469, y: 210 },
  { x: 4497, y: 466 },
  { x: 4877, y: 306 },
  { x: 4880, y: 768 },
  { x: 4930, y: 359 },
  { x: 4835, y: 635 },
  { x: 4775, y: 478 },
  { x: 4822, y: 602 },
  { x: 4705, y: 148 },
  { x: 4857, y: 490 },
  { x: 4485, y: 803 },
  { x: 4891, y: 459 },
  { x: 4550, y: 521 },
  { x: 4587, y: 507 },
  { x: 4561, y: 224 },
  { x: 4746, y: 749 },
  { x: 4627, y: 232 },
  { x: 4551, y: 260 },
  { x: 4540, y: 183 },
  { x: 4524, y: 249 },
  { x: 4623, y: 219 },
  { x: 4885, y: 460 },
  { x: 4790, y: 508 },
  { x: 4671, y: 194 },
  { x: 4656, y: 749 },
  { x: 4824, y: 675 },
  { x: 4762, y: 568 },
  { x: 4797, y: 484 },
  { x: 4839, y: 190 },
  { x: 4884, y: 248 },
  { x: 4937, y: 276 },
  { x: 4827, y: 504 },
  { x: 4745, y: 543 },
  { x: 4836, y: 753 },
  { x: 4594, y: 662 },
  { x: 4532, y: 291 },
  { x: 4811, y: 401 },
  { x: 4868, y: 381 },
  { x: 4487, y: 562 },
  { x: 4901, y: 567 },
  { x: 4541, y: 171 },
  { x: 4752, y: 511 },
  { x: 4758, y: 732 },
  { x: 4748, y: 147 },
  { x: 4569, y: 278 },
  { x: 4945, y: 430 },
  { x: 4501, y: 797 },
  { x: 4834, y: 595 },
  { x: 4876, y: 290 },
  { x: 4686, y: 195 },
  { x: 4488, y: 234 },
  { x: 4887, y: 683 },
  { x: 4919, y: 418 },
  { x: 4794, y: 547 },
  { x: 4873, y: 397 },
  { x: 4828, y: 182 },
  { x: 4946, y: 393 },
  { x: 4512, y: 211 },
  { x: 4559, y: 806 },
  { x: 4515, y: 328 },
  { x: 4747, y: 804 },
  { x: 4505, y: 782 },
  { x: 4558, y: 368 },
  { x: 4726, y: 527 },
  { x: 4687, y: 820 },
  { x: 4551, y: 415 },
  { x: 4598, y: 378 },
  { x: 4832, y: 636 },
  { x: 4514, y: 495 },
  { x: 4659, y: 228 },
  { x: 4679, y: 533 },
  { x: 4496, y: 623 },
  { x: 4796, y: 781 },
  { x: 4833, y: 191 },
  { x: 4695, y: 168 },
  { x: 4478, y: 776 },
  { x: 4596, y: 628 },
  { x: 4620, y: 520 },
  { x: 4631, y: 785 },
  { x: 4823, y: 406 },
  { x: 4898, y: 236 },
  { x: 4626, y: 152 },
  { x: 4537, y: 733 },
  { x: 4600, y: 173 },
  { x: 4521, y: 471 },
  { x: 4761, y: 793 },
  { x: 4665, y: 181 },
  { x: 4589, y: 643 },
  { x: 4661, y: 493 },
  { x: 4681, y: 164 },
  { x: 4498, y: 439 },
  { x: 4570, y: 641 },
  { x: 4863, y: 769 },
  { x: 4674, y: 202 },
  { x: 4490, y: 354 },
  { x: 4829, y: 314 },
  { x: 4522, y: 454 },
  { x: 4793, y: 676 },
  { x: 4492, y: 680 },
  { x: 4497, y: 296 },
  { x: 4578, y: 657 },
  { x: 4865, y: 176 },
  { x: 4802, y: 170 },
  { x: 4563, y: 327 },
  { x: 4508, y: 434 },
  { x: 4792, y: 522 },
  { x: 4572, y: 651 },
  { x: 4709, y: 458 },
  { x: 4533, y: 717 },
  { x: 4923, y: 678 },
  { x: 4548, y: 614 },
  { x: 4692, y: 233 },
  { x: 4860, y: 262 },
  { x: 4670, y: 151 },
  { x: 4835, y: 431 },
  { x: 4846, y: 271 },
  { x: 4519, y: 553 },
  { x: 4806, y: 746 },
  { x: 4682, y: 485 },
  { x: 4703, y: 809 },
  { x: 4667, y: 748 },
  { x: 4790, y: 536 },
  { x: 4780, y: 479 },
  { x: 4482, y: 719 },
  { x: 4804, y: 224 },
  { x: 4847, y: 280 },
  { x: 4634, y: 218 },
  { x: 4583, y: 591 },
  { x: 4609, y: 747 },
  { x: 4950, y: 428 },
  { x: 4604, y: 598 },
  { x: 4741, y: 766 },
  { x: 4844, y: 645 },
  { x: 4853, y: 192 },
  { x: 4611, y: 808 },
  { x: 4821, y: 261 },
  { x: 4948, y: 424 },
  { x: 4856, y: 407 },
  { x: 4842, y: 597 },
  { x: 4850, y: 664 },
  { x: 4577, y: 240 },
  { x: 4523, y: 399 },
  { x: 4578, y: 195 },
  { x: 4506, y: 787 },
  { x: 4497, y: 815 },
  { x: 4818, y: 412 },
  { x: 4918, y: 664 },
  { x: 4472, y: 181 },
  { x: 4584, y: 553 },
  { x: 4763, y: 186 },
  { x: 4861, y: 308 },
  { x: 4689, y: 183 },
  { x: 4567, y: 781 },
  { x: 4559, y: 689 },
  { x: 4615, y: 232 },
  { x: 4604, y: 810 },
  { x: 4715, y: 762 },
  { x: 4863, y: 754 },
  { x: 4656, y: 525 },
  { x: 4829, y: 801 },
  { x: 4891, y: 612 },
  { x: 4806, y: 218 },
  { x: 4779, y: 520 },
  { x: 4842, y: 490 },
  { x: 4853, y: 542 },
  { x: 4548, y: 271 },
  { x: 4757, y: 227 },
  { x: 4741, y: 487 },
  { x: 4473, y: 667 },
  { x: 4508, y: 141 },
  { x: 4811, y: 472 },
  { x: 4858, y: 363 },
  { x: 4564, y: 806 },
  { x: 4887, y: 688 },
  { x: 4727, y: 528 },
  { x: 4602, y: 557 },
  { x: 4498, y: 596 },
  { x: 4790, y: 661 },
  { x: 4812, y: 564 },
  { x: 4562, y: 613 },
  { x: 4738, y: 501 },
  { x: 4573, y: 288 },
  { x: 4816, y: 398 },
  { x: 4880, y: 718 },
  { x: 4550, y: 591 },
  { x: 4591, y: 665 },
  { x: 4857, y: 437 },
  { x: 4915, y: 686 },
  { x: 4484, y: 342 },
  { x: 4475, y: 402 },
  { x: 4520, y: 652 },
  { x: 4588, y: 394 },
  { x: 4774, y: 444 },
  { x: 4885, y: 257 },
  { x: 4732, y: 479 },
  { x: 4576, y: 561 },
  { x: 4600, y: 140 },
  { x: 4594, y: 478 },
  { x: 4593, y: 473 },
  { x: 4845, y: 790 },
  { x: 4640, y: 828 },
  { x: 4474, y: 278 },
  { x: 4874, y: 266 },
  { x: 4583, y: 477 },
  { x: 4742, y: 160 },
  { x: 4563, y: 355 },
  { x: 4561, y: 552 },
  { x: 4586, y: 174 },
  { x: 4879, y: 453 },
  { x: 4493, y: 748 },
  { x: 4521, y: 263 },
  { x: 4528, y: 337 },
  { x: 4503, y: 503 },
  { x: 4518, y: 143 },
  { x: 4623, y: 803 },
  { x: 4507, y: 261 },
  { x: 4705, y: 772 },
  { x: 4669, y: 755 },
  { x: 4865, y: 175 },
  { x: 4751, y: 206 },
  { x: 4797, y: 677 },
  { x: 4908, y: 381 },
  { x: 4854, y: 749 },
  { x: 4607, y: 733 },
  { x: 4566, y: 731 },
  { x: 4653, y: 531 },
  { x: 4504, y: 179 },
  { x: 4702, y: 241 },
  { x: 4601, y: 537 },
  { x: 4714, y: 726 },
  { x: 4679, y: 505 },
  { x: 4516, y: 255 },
  { x: 4613, y: 214 },
  { x: 4737, y: 785 },
  { x: 4808, y: 419 },
  { x: 4494, y: 298 },
  { x: 4789, y: 224 },
  { x: 4930, y: 328 },
  { x: 4514, y: 546 },
  { x: 4605, y: 452 },
  { x: 4603, y: 563 },
  { x: 4833, y: 669 },
  { x: 4804, y: 691 },
  { x: 4798, y: 714 },
  { x: 4936, y: 283 },
  { x: 4590, y: 165 },
  { x: 4822, y: 662 },
  { x: 4569, y: 592 },
  { x: 4547, y: 279 },
  { x: 4916, y: 290 },
  { x: 4708, y: 735 },
  { x: 4682, y: 793 },
  { x: 4519, y: 461 },
  { x: 4517, y: 203 },
  { x: 4585, y: 818 },
  { x: 4531, y: 426 },
  { x: 4718, y: 786 },
  { x: 4838, y: 449 },
  { x: 4492, y: 313 },
  { x: 4568, y: 146 },
  { x: 4800, y: 259 },
  { x: 4499, y: 445 },
  { x: 4737, y: 724 },
  { x: 4792, y: 526 },
  { x: 4712, y: 812 },
  { x: 4576, y: 775 },
  { x: 4571, y: 755 },
  { x: 4482, y: 566 },
  { x: 4575, y: 582 },
  { x: 4558, y: 148 },
  { x: 4869, y: 725 },
  { x: 4656, y: 230 },
  { x: 4850, y: 623 },
  { x: 4508, y: 752 },
  { x: 4549, y: 515 },
  { x: 4821, y: 696 },
  { x: 4909, y: 452 },
  { x: 4750, y: 170 },
  { x: 4604, y: 265 },
  { x: 4588, y: 332 },
  { x: 4806, y: 659 },
  { x: 4872, y: 735 },
  { x: 4875, y: 731 },
  { x: 4525, y: 373 },
  { x: 4711, y: 234 },
  { x: 4761, y: 191 },
  { x: 4685, y: 762 },
  { x: 4683, y: 467 },
  { x: 4791, y: 802 },
  { x: 4584, y: 707 },
  { x: 4499, y: 720 },
  { x: 4725, y: 513 },
  { x: 4526, y: 606 },
  { x: 4831, y: 363 },
  { x: 4786, y: 714 },
  { x: 4899, y: 404 },
  { x: 4517, y: 394 },
  { x: 4948, y: 400 },
  { x: 4816, y: 392 },
  { x: 4491, y: 688 },
  { x: 4865, y: 224 },
  { x: 4676, y: 813 },
  { x: 4745, y: 729 },
  { x: 4537, y: 396 },
  { x: 4851, y: 669 },
  { x: 4510, y: 829 },
  { x: 4530, y: 319 },
  { x: 4585, y: 312 },
  { x: 4906, y: 444 },
  { x: 4913, y: 273 },
  { x: 4600, y: 706 },
  { x: 4963, y: 391 },
  { x: 4836, y: 657 },
  { x: 4502, y: 737 },
  { x: 4659, y: 453 },
  { x: 4703, y: 732 },
  { x: 4846, y: 289 },
  { x: 4478, y: 412 },
  { x: 4874, y: 633 },
  { x: 4607, y: 457 },
  { x: 4814, y: 264 },
  { x: 4811, y: 152 },
  { x: 4857, y: 462 },
  { x: 4699, y: 164 },
  { x: 4570, y: 477 },
  { x: 4574, y: 441 },
  { x: 4796, y: 599 },
  { x: 4597, y: 243 },
  { x: 4867, y: 622 },
  { x: 4528, y: 437 },
  { x: 4649, y: 502 },
  { x: 4790, y: 464 },
  { x: 4564, y: 411 },
  { x: 4678, y: 820 },
  { x: 4950, y: 427 },
  { x: 4484, y: 667 },
  { x: 4514, y: 434 },
  { x: 4535, y: 789 },
  { x: 4861, y: 486 },
  { x: 4799, y: 430 },
  { x: 4859, y: 661 },
  { x: 4594, y: 139 },
  { x: 4581, y: 367 },
  { x: 4721, y: 748 },
  { x: 4515, y: 369 },
  { x: 4870, y: 468 },
  { x: 4728, y: 226 },
  { x: 4493, y: 271 },
  { x: 4587, y: 810 },
  { x: 4583, y: 708 },
  { x: 4671, y: 141 },
  { x: 4580, y: 586 },
  { x: 4812, y: 291 },
  { x: 4720, y: 809 },
  { x: 4472, y: 658 },
  { x: 4552, y: 677 },
  { x: 4578, y: 525 },
  { x: 4495, y: 698 },
  { x: 4760, y: 758 },
  { x: 4593, y: 252 },
  { x: 4507, y: 347 },
  { x: 4826, y: 268 },
  { x: 4637, y: 176 },
  { x: 4561, y: 448 },
  { x: 4820, y: 546 },
  { x: 4624, y: 830 },
  { x: 4717, y: 541 },
  { x: 4658, y: 743 },
  { x: 4912, y: 648 },
  { x: 4501, y: 583 },
  { x: 4709, y: 145 },
  { x: 4871, y: 751 },
  { x: 4471, y: 579 },
  { x: 4691, y: 237 },
  { x: 4596, y: 318 },
  { x: 4829, y: 774 },
  { x: 4794, y: 641 },
  { x: 4827, y: 342 },
  { x: 4652, y: 455 },
  { x: 4885, y: 344 },
  { x: 4674, y: 527 },
  { x: 4915, y: 401 },
  { x: 4585, y: 438 },
  { x: 4826, y: 454 },
  { x: 4804, y: 504 },
  { x: 4820, y: 291 },
  { x: 4724, y: 475 },
  { x: 4494, y: 821 },
  { x: 4890, y: 478 },
  { x: 4617, y: 820 },
  { x: 4838, y: 167 },
  { x: 4526, y: 669 },
  { x: 4829, y: 507 },
  { x: 4597, y: 367 },
  { x: 4501, y: 787 },
  { x: 4693, y: 728 },
  { x: 4573, y: 758 },
  { x: 4522, y: 160 },
  { x: 4505, y: 323 },
  { x: 4508, y: 624 },
  { x: 4492, y: 390 },
  { x: 4500, y: 457 },
  { x: 4491, y: 148 },
  { x: 4943, y: 370 },
  { x: 4482, y: 369 },
  { x: 4753, y: 194 },
  { x: 4527, y: 538 },
  { x: 4493, y: 666 },
  { x: 4516, y: 535 },
  { x: 4487, y: 652 },
  { x: 4842, y: 213 },
  { x: 4805, y: 612 },
  { x: 4833, y: 277 },
  { x: 4867, y: 550 },
  { x: 4865, y: 481 },
  { x: 4760, y: 232 },
  { x: 4502, y: 288 },
  { x: 4852, y: 447 },
  { x: 4821, y: 618 },
  { x: 4819, y: 734 },
  { x: 4748, y: 247 },
  { x: 4879, y: 417 },
  { x: 4734, y: 735 },
  { x: 4513, y: 604 },
  { x: 4917, y: 657 },
  { x: 4797, y: 512 },
  { x: 4541, y: 692 },
  { x: 4796, y: 424 },
  { x: 4535, y: 584 },
  { x: 4590, y: 400 },
  { x: 4637, y: 783 },
  { x: 4579, y: 592 },
  { x: 4566, y: 246 },
  { x: 4694, y: 142 },
  { x: 4486, y: 571 },
  { x: 4740, y: 763 },
  { x: 4589, y: 645 },
  { x: 4916, y: 455 },
  { x: 4470, y: 387 },
  { x: 4556, y: 781 },
  { x: 4685, y: 471 },
  { x: 4856, y: 759 },
  { x: 4886, y: 362 },
  { x: 4584, y: 827 },
  { x: 4799, y: 511 },
  { x: 4676, y: 812 },
  { x: 4568, y: 233 },
  { x: 4599, y: 673 },
  { x: 4893, y: 385 },
  { x: 4828, y: 497 },
  { x: 4936, y: 250 },
  { x: 4536, y: 384 },
  { x: 4731, y: 791 },
  { x: 4684, y: 458 },
  { x: 4567, y: 215 },
  { x: 4961, y: 295 },
  { x: 4818, y: 789 },
  { x: 4575, y: 396 },
  { x: 4897, y: 214 },
  { x: 4846, y: 797 },
  { x: 4485, y: 341 },
  { x: 4521, y: 219 },
  { x: 4549, y: 828 },
  { x: 4499, y: 249 },
  { x: 4529, y: 372 },
  { x: 4840, y: 269 },
  { x: 4528, y: 441 },
  { x: 4877, y: 661 },
  { x: 4586, y: 700 },
  { x: 4690, y: 146 },
  { x: 4909, y: 313 },
  { x: 4468, y: 778 },
  { x: 4688, y: 825 },
  { x: 4963, y: 324 },
  { x: 4469, y: 406 },
  { x: 4911, y: 453 },
  { x: 4560, y: 632 },
  { x: 4834, y: 198 },
  { x: 4554, y: 253 },
  { x: 4664, y: 192 },
  { x: 4479, y: 161 },
  { x: 4552, y: 557 },
  { x: 4531, y: 793 },
  { x: 4845, y: 248 },
  { x: 4542, y: 782 },
  { x: 4576, y: 463 },
  { x: 4816, y: 749 },
  { x: 4553, y: 691 },
  { x: 4824, y: 230 },
  { x: 4552, y: 750 },
  { x: 4700, y: 475 },
  { x: 4507, y: 662 },
  { x: 4469, y: 414 },
  { x: 4497, y: 665 },
  { x: 4557, y: 321 },
  { x: 4483, y: 248 },
  { x: 4502, y: 548 },
  { x: 4635, y: 503 },
  { x: 4715, y: 801 },
  { x: 4659, y: 739 },
  { x: 4481, y: 356 },
  { x: 4508, y: 529 },
  { x: 4838, y: 554 },
  { x: 4524, y: 664 },
  { x: 4841, y: 689 },
  { x: 4489, y: 347 },
  { x: 4704, y: 524 },
  { x: 4729, y: 154 },
  { x: 4581, y: 401 },
  { x: 4891, y: 715 },
  { x: 4570, y: 711 },
  { x: 4550, y: 403 },
  { x: 4872, y: 360 },
  { x: 4593, y: 214 },
  { x: 4740, y: 502 },
  { x: 4767, y: 760 },
  { x: 4607, y: 773 },
  { x: 4535, y: 480 },
  { x: 4850, y: 283 },
  { x: 4875, y: 415 },
  { x: 4676, y: 460 },
  { x: 4520, y: 215 },
  { x: 4584, y: 185 },
  { x: 4708, y: 180 },
  { x: 4828, y: 171 },
  { x: 4904, y: 290 },
  { x: 4721, y: 155 },
  { x: 4505, y: 476 },
  { x: 4613, y: 805 },
  { x: 4939, y: 409 },
  { x: 4541, y: 258 },
  { x: 4491, y: 249 },
  { x: 4560, y: 293 },
  { x: 4490, y: 348 },
  { x: 4713, y: 459 },
  { x: 4587, y: 297 },
  { x: 4787, y: 229 },
  { x: 4876, y: 673 },
  { x: 4674, y: 778 },
  { x: 4482, y: 558 },
  { x: 4925, y: 265 },
  { x: 4878, y: 188 },
  { x: 4905, y: 593 },
  { x: 4843, y: 447 },
  { x: 4795, y: 704 },
  { x: 4518, y: 688 },
  { x: 4475, y: 444 },
  { x: 4857, y: 407 },
  { x: 4818, y: 406 },
  { x: 4534, y: 182 },
  { x: 4747, y: 777 },
  { x: 4842, y: 535 },
  { x: 4612, y: 780 },
  { x: 4773, y: 173 },
  { x: 4821, y: 772 },
  { x: 4890, y: 247 },
  { x: 4770, y: 216 },
  { x: 4625, y: 164 },
  { x: 4652, y: 782 },
  { x: 4582, y: 197 },
  { x: 4533, y: 714 },
  { x: 4900, y: 362 },
  { x: 4553, y: 528 },
  { x: 4771, y: 550 },
  { x: 4866, y: 267 },
  { x: 4914, y: 448 },
  { x: 4822, y: 537 },
  { x: 4576, y: 329 },
  { x: 4501, y: 226 },
  { x: 4539, y: 160 },
  { x: 4675, y: 473 },
  { x: 4529, y: 706 },
  { x: 4523, y: 385 },
  { x: 4480, y: 676 },
  { x: 4486, y: 282 },
  { x: 4937, y: 266 },
  { x: 4494, y: 217 },
  { x: 4862, y: 383 },
  { x: 4579, y: 501 },
  { x: 4551, y: 405 },
  { x: 4484, y: 811 },
  { x: 4912, y: 438 },
  { x: 4774, y: 442 },
  { x: 4693, y: 747 },
  { x: 4899, y: 252 },
  { x: 4752, y: 232 },
  { x: 4679, y: 443 },
  { x: 4746, y: 792 },
  { x: 4831, y: 470 },
  { x: 4554, y: 261 },
  { x: 4493, y: 483 },
  { x: 4476, y: 586 },
  { x: 4810, y: 490 },
  { x: 4540, y: 205 },
  { x: 4574, y: 436 },
  { x: 4563, y: 177 },
  { x: 4797, y: 200 },
  { x: 4544, y: 386 },
  { x: 4572, y: 428 },
  { x: 4826, y: 466 },
  { x: 4823, y: 686 },
  { x: 4736, y: 218 },
  { x: 4545, y: 643 },
  { x: 4800, y: 649 },
  { x: 4644, y: 174 },
  { x: 4578, y: 743 },
  { x: 4590, y: 630 },
  { x: 4511, y: 425 },
  { x: 4527, y: 231 },
  { x: 4701, y: 500 },
  { x: 4782, y: 489 },
  { x: 4895, y: 326 },
  { x: 4515, y: 587 },
  { x: 4688, y: 227 },
  { x: 4838, y: 246 },
  { x: 4911, y: 286 },
  { x: 4723, y: 149 },
  { x: 4909, y: 261 },
  { x: 4528, y: 524 },
  { x: 4815, y: 548 },
  { x: 4487, y: 678 },
  { x: 4641, y: 727 },
  { x: 4512, y: 816 },
  { x: 4812, y: 597 },
  { x: 4649, y: 766 },
  { x: 4841, y: 398 },
  { x: 4668, y: 479 },
  { x: 4808, y: 778 },
  { x: 4503, y: 613 },
  { x: 4860, y: 189 },
  { x: 4872, y: 203 },
  { x: 4477, y: 414 },
  { x: 4856, y: 488 },
  { x: 4601, y: 464 },
  { x: 4734, y: 531 },
  { x: 4843, y: 566 },
  { x: 4884, y: 646 },
  { x: 4809, y: 798 },
  { x: 4926, y: 459 },
  { x: 4519, y: 148 },
  { x: 4499, y: 810 },
  { x: 4810, y: 811 },
  { x: 4946, y: 271 },
  { x: 4899, y: 434 },
  { x: 4903, y: 595 },
  { x: 4529, y: 150 },
  { x: 4767, y: 532 },
  { x: 4532, y: 725 },
  { x: 4864, y: 461 },
  { x: 4825, y: 471 },
  { x: 4470, y: 401 },
  { x: 4950, y: 382 },
  { x: 4873, y: 399 },
  { x: 4553, y: 499 },
  { x: 4912, y: 317 },
  { x: 4536, y: 714 },
  { x: 4488, y: 223 },
  { x: 4947, y: 254 },
  { x: 4739, y: 470 },
  { x: 4547, y: 210 },
  { x: 4738, y: 512 },
  { x: 4832, y: 698 },
  { x: 4945, y: 426 },
  { x: 4851, y: 687 },
  { x: 4545, y: 258 },
  { x: 4562, y: 609 },
  { x: 4474, y: 740 },
  { x: 4584, y: 232 },
  { x: 4475, y: 686 },
  { x: 4597, y: 441 },
  { x: 4486, y: 475 },
  { x: 4478, y: 817 },
  { x: 4537, y: 283 },
  { x: 4573, y: 683 },
  { x: 4818, y: 508 },
  { x: 4814, y: 453 },
  { x: 4880, y: 335 },
  { x: 4761, y: 543 },
  { x: 4750, y: 806 },
  { x: 4491, y: 801 },
  { x: 4867, y: 478 },
  { x: 4593, y: 405 },
  { x: 4755, y: 465 },
  { x: 4847, y: 614 },
  { x: 4556, y: 169 },
  { x: 4885, y: 235 },
  { x: 4507, y: 745 },
  { x: 4655, y: 818 },
  { x: 4552, y: 205 },
  { x: 4870, y: 243 },
  { x: 4807, y: 411 },
  { x: 4679, y: 147 },
  { x: 4599, y: 422 },
  { x: 4620, y: 732 },
  { x: 4497, y: 349 },
  { x: 4583, y: 672 },
  { x: 4598, y: 602 },
  { x: 4542, y: 351 },
  { x: 4916, y: 463 },
  { x: 4544, y: 781 },
  { x: 4564, y: 570 },
  { x: 4482, y: 200 },
  { x: 4591, y: 674 },
  { x: 4919, y: 219 },
  { x: 4824, y: 372 },
  { x: 4891, y: 424 },
  { x: 4670, y: 501 },
  { x: 4572, y: 591 },
  { x: 4595, y: 268 },
  { x: 4473, y: 670 },
  { x: 4697, y: 141 },
  { x: 4802, y: 768 },
  { x: 4827, y: 433 },
  { x: 4543, y: 346 },
  { x: 4560, y: 220 },
  { x: 4574, y: 585 },
  { x: 4554, y: 191 },
  { x: 4605, y: 791 },
  { x: 4840, y: 509 },
  { x: 4737, y: 183 },
  { x: 4592, y: 409 },
  { x: 4484, y: 670 },
  { x: 4493, y: 357 },
  { x: 4740, y: 733 },
  { x: 4797, y: 497 },
  { x: 4536, y: 721 },
  { x: 4758, y: 439 },
  { x: 4944, y: 355 },
  { x: 4796, y: 426 },
  { x: 4541, y: 386 },
  { x: 4868, y: 485 },
  { x: 4833, y: 465 },
  { x: 4755, y: 808 },
  { x: 4596, y: 634 },
  { x: 4597, y: 410 },
  { x: 4472, y: 401 },
  { x: 4927, y: 226 },
  { x: 4552, y: 330 },
  { x: 4580, y: 735 },
  { x: 4505, y: 545 },
  { x: 4693, y: 496 },
  { x: 4535, y: 819 },
  { x: 4856, y: 436 },
  { x: 4593, y: 454 },
  { x: 4548, y: 370 },
  { x: 4533, y: 787 },
  { x: 4586, y: 398 },
  { x: 4688, y: 479 },
  { x: 4478, y: 540 },
  { x: 4502, y: 431 },
  { x: 4826, y: 178 },
  { x: 4509, y: 584 },
  { x: 4588, y: 378 },
  { x: 4520, y: 460 },
  { x: 4542, y: 618 },
  { x: 4888, y: 238 },
  { x: 4867, y: 756 },
  { x: 4526, y: 191 },
  { x: 4941, y: 239 },
  { x: 4809, y: 587 },
  { x: 4802, y: 448 },
  { x: 4515, y: 547 },
  { x: 4594, y: 288 },
  { x: 4813, y: 561 },
  { x: 4488, y: 292 },
  { x: 4485, y: 484 },
  { x: 4479, y: 314 },
  { x: 4881, y: 575 },
  { x: 4729, y: 802 },
  { x: 4746, y: 452 },
  { x: 4679, y: 220 },
  { x: 4799, y: 449 },
  { x: 4834, y: 712 },
  { x: 4524, y: 195 },
  { x: 4627, y: 198 },
  { x: 4499, y: 225 },
  { x: 4551, y: 250 },
  { x: 4527, y: 684 },
  { x: 4513, y: 778 },
  { x: 4744, y: 476 },
  { x: 4912, y: 333 },
  { x: 4736, y: 546 },
  { x: 4634, y: 501 },
  { x: 4543, y: 150 },
  { x: 4705, y: 505 },
  { x: 4810, y: 567 },
  { x: 4519, y: 217 },
  { x: 4791, y: 147 },
  { x: 4495, y: 579 },
  { x: 4838, y: 585 },
  { x: 4756, y: 554 },
  { x: 4788, y: 715 },
  { x: 4521, y: 221 },
  { x: 4636, y: 508 },
  { x: 4562, y: 344 },
  { x: 4767, y: 770 },
  { x: 4917, y: 236 },
  { x: 4720, y: 759 },
  { x: 4831, y: 557 },
  { x: 4506, y: 260 },
  { x: 4644, y: 537 },
  { x: 4816, y: 674 },
  { x: 4633, y: 163 },
  { x: 4497, y: 367 },
  { x: 4500, y: 548 },
  { x: 4908, y: 360 },
  { x: 4872, y: 285 },
  { x: 4575, y: 416 },
  { x: 4494, y: 240 },
  { x: 4468, y: 657 },
  { x: 4609, y: 520 },
  { x: 4576, y: 442 },
  { x: 4836, y: 801 },
  { x: 4599, y: 614 },
  { x: 4630, y: 745 },
  { x: 4511, y: 151 },
  { x: 4697, y: 169 },
  { x: 4658, y: 141 },
  { x: 4759, y: 209 },
  { x: 4590, y: 481 },
  { x: 4841, y: 263 },
  { x: 4595, y: 372 },
  { x: 4807, y: 695 },
  { x: 4673, y: 765 },
  { x: 4473, y: 788 },
  { x: 4839, y: 432 },
  { x: 4743, y: 204 },
  { x: 4608, y: 758 },
  { x: 4907, y: 366 },
  { x: 4585, y: 591 },
  { x: 4689, y: 208 },
  { x: 4865, y: 572 },
  { x: 4942, y: 293 },
  { x: 4610, y: 212 },
  { x: 4556, y: 413 },
  { x: 4628, y: 761 },
  { x: 4749, y: 192 },
  { x: 4897, y: 274 },
  { x: 4632, y: 531 },
  { x: 4474, y: 491 },
  { x: 4873, y: 206 },
  { x: 4881, y: 431 },
  { x: 4718, y: 530 },
  { x: 4586, y: 342 },
  { x: 4479, y: 540 },
  { x: 4549, y: 261 },
  { x: 4530, y: 366 },
  { x: 4656, y: 210 },
  { x: 4961, y: 336 },
  { x: 4778, y: 561 },
  { x: 4915, y: 710 },
  { x: 4902, y: 581 },
  { x: 4676, y: 187 },
  { x: 4839, y: 259 },
  { x: 4859, y: 349 },
  { x: 4576, y: 444 },
  { x: 4583, y: 696 },
  { x: 4546, y: 697 },
  { x: 4699, y: 212 },
  { x: 4581, y: 685 },
  { x: 4547, y: 258 },
  { x: 4471, y: 684 },
  { x: 4891, y: 688 },
  { x: 4517, y: 325 },
  { x: 4771, y: 193 },
  { x: 4866, y: 224 },
  { x: 4522, y: 229 },
  { x: 4923, y: 269 },
  { x: 4827, y: 744 },
  { x: 4914, y: 389 },
  { x: 4747, y: 463 },
  { x: 4686, y: 144 },
  { x: 4730, y: 174 },
  { x: 4606, y: 740 },
  { x: 4837, y: 241 },
  { x: 4541, y: 181 },
  { x: 4604, y: 669 },
  { x: 4660, y: 524 },
  { x: 4762, y: 779 },
  { x: 4550, y: 399 },
  { x: 4508, y: 655 },
  { x: 4862, y: 434 },
  { x: 4545, y: 665 },
  { x: 4840, y: 630 },
  { x: 4903, y: 313 },
  { x: 4554, y: 350 },
  { x: 4905, y: 286 },
  { x: 4593, y: 180 },
  { x: 4556, y: 643 },
  { x: 4539, y: 706 },
  { x: 4772, y: 488 },
  { x: 4596, y: 196 },
  { x: 4570, y: 634 },
  { x: 4760, y: 737 },
  { x: 4514, y: 421 },
  { x: 4469, y: 348 },
  { x: 4487, y: 440 },
  { x: 4851, y: 590 },
  { x: 4632, y: 242 },
  { x: 4791, y: 555 },
  { x: 4879, y: 700 },
  { x: 4533, y: 690 },
  { x: 4820, y: 798 },
  { x: 4492, y: 487 },
  { x: 4802, y: 507 },
  { x: 4664, y: 171 },
  { x: 4844, y: 612 },
  { x: 4817, y: 771 },
  { x: 4890, y: 558 },
  { x: 4912, y: 646 },
  { x: 4505, y: 827 },
  { x: 4611, y: 452 },
  { x: 4870, y: 272 },
  { x: 4741, y: 521 },
  { x: 4739, y: 158 },
  { x: 4958, y: 361 },
  { x: 4830, y: 312 },
  { x: 4784, y: 480 },
  { x: 4618, y: 165 },
  { x: 4882, y: 625 },
  { x: 4752, y: 533 },
  { x: 4678, y: 789 },
  { x: 4875, y: 549 },
  { x: 4685, y: 159 },
  { x: 4860, y: 234 },
  { x: 4788, y: 761 },
  { x: 4493, y: 381 },
  { x: 4892, y: 225 },
  { x: 4831, y: 756 },
  { x: 4571, y: 238 },
  { x: 4835, y: 687 },
  { x: 4599, y: 782 },
  { x: 4818, y: 274 },
  { x: 4796, y: 226 },
  { x: 4526, y: 368 },
  { x: 4538, y: 430 },
  { x: 4727, y: 138 },
  { x: 4763, y: 243 },
  { x: 4800, y: 232 },
  { x: 4603, y: 279 },
  { x: 4754, y: 768 },
  { x: 4499, y: 514 },
  { x: 4876, y: 717 },
  { x: 4534, y: 206 },
  { x: 4864, y: 680 },
  { x: 4764, y: 766 },
  { x: 4473, y: 461 },
  { x: 4500, y: 315 },
  { x: 4696, y: 813 },
  { x: 4631, y: 506 },
  { x: 4708, y: 728 },
  { x: 4579, y: 175 },
  { x: 4878, y: 689 },
  { x: 4494, y: 492 },
  { x: 4689, y: 200 },
  { x: 4580, y: 150 },
  { x: 4795, y: 773 },
  { x: 4858, y: 585 },
  { x: 4483, y: 792 },
  { x: 4884, y: 291 },
  { x: 4733, y: 464 },
  { x: 4759, y: 731 },
  { x: 4588, y: 395 },
  { x: 4597, y: 441 },
  { x: 4758, y: 203 },
  { x: 4552, y: 811 },
  { x: 4847, y: 471 },
  { x: 4871, y: 546 },
  { x: 4601, y: 502 },
  { x: 4799, y: 477 },
  { x: 4836, y: 265 },
  { x: 4768, y: 548 },
  { x: 4744, y: 510 },
  { x: 5589, y: 376 },
  { x: 5700, y: 539 },
  { x: 5312, y: 166 },
  { x: 5579, y: 198 },
  { x: 5572, y: 748 },
  { x: 5434, y: 781 },
  { x: 5565, y: 688 },
  { x: 5539, y: 274 },
  { x: 5174, y: 716 },
  { x: 5178, y: 408 },
  { x: 5167, y: 312 },
  { x: 5393, y: 793 },
  { x: 5583, y: 751 },
  { x: 5428, y: 251 },
  { x: 5593, y: 350 },
  { x: 5206, y: 171 },
  { x: 5440, y: 213 },
  { x: 5623, y: 349 },
  { x: 5577, y: 626 },
  { x: 5347, y: 790 },
  { x: 5464, y: 710 },
  { x: 5362, y: 189 },
  { x: 5627, y: 348 },
  { x: 5581, y: 337 },
  { x: 5397, y: 211 },
  { x: 5268, y: 188 },
  { x: 5417, y: 806 },
  { x: 5439, y: 782 },
  { x: 5630, y: 665 },
  { x: 5195, y: 272 },
  { x: 5324, y: 817 },
  { x: 5158, y: 508 },
  { x: 5580, y: 293 },
  { x: 5171, y: 143 },
  { x: 5556, y: 681 },
  { x: 5264, y: 532 },
  { x: 5310, y: 815 },
  { x: 5137, y: 691 },
  { x: 5561, y: 240 },
  { x: 5531, y: 745 },
  { x: 5188, y: 147 },
  { x: 5628, y: 356 },
  { x: 5342, y: 157 },
  { x: 5257, y: 514 },
  { x: 5543, y: 609 },
  { x: 5483, y: 794 },
  { x: 5560, y: 743 },
  { x: 5471, y: 174 },
  { x: 5236, y: 381 },
  { x: 5185, y: 667 },
  { x: 5640, y: 571 },
  { x: 5180, y: 810 },
  { x: 5322, y: 202 },
  { x: 5284, y: 772 },
  { x: 5635, y: 630 },
  { x: 5164, y: 331 },
  { x: 5258, y: 467 },
  { x: 5270, y: 746 },
  { x: 5356, y: 761 },
  { x: 5668, y: 421 },
  { x: 5515, y: 284 },
  { x: 5156, y: 657 },
  { x: 5582, y: 677 },
  { x: 5689, y: 477 },
  { x: 5446, y: 187 },
  { x: 5667, y: 587 },
  { x: 5646, y: 433 },
  { x: 5438, y: 732 },
  { x: 5664, y: 492 },
  { x: 5528, y: 704 },
  { x: 5234, y: 780 },
  { x: 5225, y: 742 },
  { x: 5520, y: 708 },
  { x: 5421, y: 808 },
  { x: 5165, y: 572 },
  { x: 5293, y: 778 },
  { x: 5223, y: 209 },
  { x: 5247, y: 483 },
  { x: 5248, y: 590 },
  { x: 5653, y: 696 },
  { x: 5555, y: 417 },
  { x: 5220, y: 497 },
  { x: 5197, y: 449 },
  { x: 5492, y: 755 },
  { x: 5622, y: 309 },
  { x: 5177, y: 268 },
  { x: 5644, y: 706 },
  { x: 5637, y: 411 },
  { x: 5504, y: 671 },
  { x: 5553, y: 221 },
  { x: 5192, y: 727 },
  { x: 5254, y: 403 },
  { x: 5552, y: 294 },
  { x: 5456, y: 783 },
  { x: 5290, y: 733 },
  { x: 5550, y: 302 },
  { x: 5147, y: 493 },
  { x: 5219, y: 372 },
  { x: 5152, y: 819 },
  { x: 5252, y: 162 },
  { x: 5149, y: 342 },
  { x: 5176, y: 253 },
  { x: 5281, y: 820 },
  { x: 5611, y: 560 },
  { x: 5695, y: 446 },
  { x: 5642, y: 392 },
  { x: 5585, y: 416 },
  { x: 5238, y: 569 },
  { x: 5246, y: 753 },
  { x: 5230, y: 476 },
  { x: 5596, y: 474 },
  { x: 5625, y: 306 },
  { x: 5669, y: 578 },
  { x: 5422, y: 246 },
  { x: 5274, y: 185 },
  { x: 5429, y: 230 },
  { x: 5690, y: 597 },
  { x: 5522, y: 200 },
  { x: 5588, y: 723 },
  { x: 5676, y: 387 },
  { x: 5221, y: 347 },
  { x: 5163, y: 338 },
  { x: 5548, y: 702 },
  { x: 5143, y: 785 },
  { x: 5679, y: 429 },
  { x: 5226, y: 409 },
  { x: 5540, y: 765 },
  { x: 5465, y: 237 },
  { x: 5135, y: 183 },
  { x: 5529, y: 297 },
  { x: 5241, y: 310 },
  { x: 5292, y: 740 },
  { x: 5344, y: 750 },
  { x: 5562, y: 535 },
  { x: 5196, y: 281 },
  { x: 5528, y: 262 },
  { x: 5689, y: 373 },
  { x: 5225, y: 793 },
  { x: 5510, y: 305 },
  { x: 5504, y: 229 },
  { x: 5233, y: 689 },
  { x: 5483, y: 211 },
  { x: 5625, y: 456 },
  { x: 5254, y: 506 },
  { x: 5140, y: 323 },
  { x: 5172, y: 735 },
  { x: 5194, y: 795 },
  { x: 5663, y: 645 },
  { x: 5142, y: 167 },
  { x: 5655, y: 687 },
  { x: 5574, y: 683 },
  { x: 5623, y: 401 },
  { x: 5614, y: 467 },
  { x: 5660, y: 615 },
  { x: 5192, y: 549 },
  { x: 5638, y: 668 },
  { x: 5487, y: 281 },
  { x: 5682, y: 553 },
  { x: 5249, y: 463 },
  { x: 5203, y: 504 },
  { x: 5131, y: 703 },
  { x: 5265, y: 632 },
  { x: 5569, y: 280 },
  { x: 5134, y: 776 },
  { x: 5608, y: 327 },
  { x: 5206, y: 170 },
  { x: 5677, y: 365 },
  { x: 5232, y: 729 },
  { x: 5158, y: 340 },
  { x: 5382, y: 815 },
  { x: 5219, y: 779 },
  { x: 5363, y: 730 },
  { x: 5400, y: 763 },
  { x: 5213, y: 274 },
  { x: 5171, y: 817 },
  { x: 5642, y: 605 },
  { x: 5260, y: 607 },
  { x: 5634, y: 284 },
  { x: 5575, y: 414 },
  { x: 5159, y: 428 },
  { x: 5547, y: 371 },
  { x: 5670, y: 570 },
  { x: 5600, y: 561 },
  { x: 5255, y: 573 },
  { x: 5325, y: 787 },
  { x: 5641, y: 432 },
  { x: 5301, y: 175 },
  { x: 5278, y: 218 },
  { x: 5149, y: 408 },
  { x: 5200, y: 746 },
  { x: 5611, y: 353 },
  { x: 5182, y: 176 },
  { x: 5410, y: 247 },
  { x: 5526, y: 680 },
  { x: 5277, y: 166 },
  { x: 5163, y: 358 },
  { x: 5562, y: 783 },
  { x: 5133, y: 350 },
  { x: 5175, y: 711 },
  { x: 5379, y: 164 },
  { x: 5466, y: 698 },
  { x: 5581, y: 732 },
  { x: 5181, y: 543 },
  { x: 5493, y: 690 },
  { x: 5570, y: 278 },
  { x: 5593, y: 312 },
  { x: 5408, y: 796 },
  { x: 5462, y: 245 },
  { x: 5253, y: 630 },
  { x: 5354, y: 802 },
  { x: 5678, y: 637 },
  { x: 5587, y: 760 },
  { x: 5165, y: 686 },
  { x: 5135, y: 572 },
  { x: 5193, y: 693 },
  { x: 5168, y: 509 },
  { x: 5304, y: 178 },
  { x: 5221, y: 433 },
  { x: 5228, y: 440 },
  { x: 5550, y: 241 },
  { x: 5576, y: 287 },
  { x: 5540, y: 640 },
  { x: 5632, y: 588 },
  { x: 5521, y: 177 },
  { x: 5539, y: 666 },
  { x: 5169, y: 210 },
  { x: 5197, y: 466 },
  { x: 5577, y: 306 },
  { x: 5147, y: 614 },
  { x: 5580, y: 768 },
  { x: 5630, y: 359 },
  { x: 5535, y: 635 },
  { x: 5405, y: 148 },
  { x: 5185, y: 803 },
  { x: 5591, y: 459 },
  { x: 5250, y: 521 },
  { x: 5261, y: 224 },
  { x: 5446, y: 749 },
  { x: 5654, y: 430 },
  { x: 5145, y: 292 },
  { x: 5327, y: 232 },
  { x: 5251, y: 260 },
  { x: 5613, y: 557 },
  { x: 5240, y: 183 },
  { x: 5224, y: 249 },
  { x: 5323, y: 219 },
  { x: 5585, y: 460 },
  { x: 5371, y: 194 },
  { x: 5356, y: 749 },
  { x: 5524, y: 675 },
  { x: 5539, y: 190 },
  { x: 5584, y: 248 },
  { x: 5637, y: 276 },
  { x: 5167, y: 582 },
  { x: 5536, y: 753 },
  { x: 5232, y: 291 },
  { x: 5568, y: 381 },
  { x: 5152, y: 447 },
  { x: 5187, y: 562 },
  { x: 5601, y: 567 },
  { x: 5241, y: 171 },
  { x: 5458, y: 732 },
  { x: 5448, y: 147 },
  { x: 5674, y: 612 },
  { x: 5161, y: 408 },
  { x: 5645, y: 430 },
  { x: 5201, y: 797 },
  { x: 5576, y: 290 },
  { x: 5386, y: 195 },
  { x: 5188, y: 234 },
  { x: 5587, y: 683 },
  { x: 5619, y: 418 },
  { x: 5168, y: 141 },
  { x: 5130, y: 619 },
  { x: 5573, y: 397 },
  { x: 5528, y: 182 },
  { x: 5646, y: 393 },
  { x: 5212, y: 211 },
  { x: 5259, y: 806 },
  { x: 5215, y: 328 },
  { x: 5131, y: 707 },
  { x: 5447, y: 804 },
  { x: 5140, y: 456 },
  { x: 5205, y: 782 },
  { x: 5258, y: 368 },
  { x: 5387, y: 820 },
  { x: 5251, y: 415 },
  { x: 5150, y: 222 },
  { x: 5133, y: 232 },
  { x: 5532, y: 636 },
  { x: 5214, y: 495 },
  { x: 5359, y: 228 },
  { x: 5196, y: 623 },
  { x: 5141, y: 712 },
  { x: 5616, y: 508 },
  { x: 5496, y: 781 },
  { x: 5533, y: 191 },
  { x: 5395, y: 168 },
  { x: 5178, y: 776 },
  { x: 5643, y: 688 },
  { x: 5663, y: 483 },
  { x: 5331, y: 785 },
  { x: 5598, y: 236 },
  { x: 5326, y: 152 },
  { x: 5237, y: 733 },
  { x: 5300, y: 173 },
  { x: 5631, y: 565 },
  { x: 5221, y: 471 },
  { x: 5461, y: 793 },
  { x: 5365, y: 181 },
  { x: 5628, y: 632 },
  { x: 5381, y: 164 },
  { x: 5198, y: 439 },
  { x: 5563, y: 769 },
  { x: 5134, y: 610 },
  { x: 5374, y: 202 },
  { x: 5190, y: 354 },
  { x: 5529, y: 314 },
  { x: 5222, y: 454 },
  { x: 5493, y: 676 },
  { x: 5192, y: 680 },
  { x: 5635, y: 579 },
  { x: 5197, y: 296 },
  { x: 5502, y: 170 },
  { x: 5263, y: 327 },
  { x: 5208, y: 434 },
  { x: 5233, y: 717 },
  { x: 5160, y: 258 },
  { x: 5623, y: 678 },
  { x: 5658, y: 525 },
  { x: 5248, y: 614 },
  { x: 5392, y: 233 },
  { x: 5560, y: 262 },
  { x: 5370, y: 151 },
  { x: 5546, y: 271 },
  { x: 5219, y: 553 },
  { x: 5483, y: 267 },
  { x: 5506, y: 746 },
  { x: 5403, y: 809 },
  { x: 5142, y: 539 },
  { x: 5367, y: 748 },
  { x: 5182, y: 719 },
  { x: 5504, y: 224 },
  { x: 5547, y: 280 },
  { x: 5334, y: 218 },
  { x: 5143, y: 460 },
  { x: 5309, y: 747 },
  { x: 5668, y: 566 },
  { x: 5650, y: 428 },
  { x: 5441, y: 766 },
  { x: 5544, y: 645 },
  { x: 5669, y: 451 },
  { x: 5553, y: 192 },
  { x: 5311, y: 808 },
  { x: 5521, y: 261 },
  { x: 5648, y: 424 },
  { x: 5556, y: 407 },
  { x: 5159, y: 376 },
  { x: 5550, y: 664 },
  { x: 5277, y: 240 },
  { x: 5223, y: 399 },
  { x: 5673, y: 316 },
  { x: 5278, y: 195 },
  { x: 5206, y: 787 },
  { x: 5197, y: 815 },
  { x: 5618, y: 664 },
  { x: 5130, y: 705 },
  { x: 5172, y: 181 },
  { x: 5142, y: 682 },
  { x: 5672, y: 614 },
  { x: 5463, y: 186 },
  { x: 5670, y: 323 },
  { x: 5561, y: 308 },
  { x: 5389, y: 183 },
  { x: 5267, y: 781 },
  { x: 5259, y: 689 },
  { x: 5315, y: 232 },
  { x: 5304, y: 810 },
  { x: 5681, y: 523 },
  { x: 5415, y: 762 },
  { x: 5563, y: 754 },
  { x: 5663, y: 422 },
  { x: 5591, y: 612 },
  { x: 5506, y: 218 },
  { x: 5248, y: 271 },
  { x: 5457, y: 227 },
  { x: 5173, y: 667 },
  { x: 5208, y: 141 },
  { x: 5667, y: 467 },
  { x: 5558, y: 363 },
  { x: 5264, y: 806 },
  { x: 5587, y: 688 },
  { x: 5349, y: 725 },
  { x: 5151, y: 608 },
  { x: 5198, y: 596 },
  { x: 5157, y: 511 },
  { x: 5262, y: 613 },
  { x: 5665, y: 518 },
  { x: 5580, y: 718 },
  { x: 5250, y: 591 },
  { x: 5150, y: 672 },
  { x: 5557, y: 437 },
  { x: 5170, y: 139 },
  { x: 5615, y: 686 },
  { x: 5184, y: 342 },
  { x: 5175, y: 402 },
  { x: 5220, y: 652 },
  { x: 5585, y: 257 },
  { x: 5300, y: 140 },
  { x: 5545, y: 790 },
  { x: 5340, y: 828 },
  { x: 5174, y: 278 },
  { x: 5574, y: 266 },
  { x: 5442, y: 160 },
  { x: 5263, y: 355 },
  { x: 5261, y: 552 },
  { x: 5286, y: 174 },
  { x: 5579, y: 453 },
  { x: 5193, y: 748 },
  { x: 5221, y: 263 },
  { x: 5228, y: 337 },
  { x: 5660, y: 468 },
  { x: 5135, y: 156 },
  { x: 5203, y: 503 },
  { x: 5652, y: 633 },
  { x: 5218, y: 143 },
  { x: 5323, y: 803 },
  { x: 5207, y: 261 },
  { x: 5405, y: 772 },
  { x: 5369, y: 755 },
  { x: 5451, y: 206 },
  { x: 5497, y: 677 },
  { x: 5608, y: 381 },
  { x: 5554, y: 749 },
  { x: 5307, y: 733 },
  { x: 5165, y: 391 },
  { x: 5266, y: 731 },
  { x: 5204, y: 179 },
  { x: 5402, y: 241 },
  { x: 5414, y: 726 },
  { x: 5216, y: 255 },
  { x: 5313, y: 214 },
  { x: 5437, y: 785 },
  { x: 5158, y: 589 },
  { x: 5161, y: 663 },
  { x: 5138, y: 804 },
  { x: 5194, y: 298 },
  { x: 5629, y: 640 },
  { x: 5136, y: 333 },
  { x: 5489, y: 224 },
  { x: 5630, y: 328 },
  { x: 5214, y: 546 },
  { x: 5162, y: 639 },
  { x: 5661, y: 634 },
  { x: 5533, y: 669 },
  { x: 5504, y: 691 },
  { x: 5167, y: 684 },
  { x: 5156, y: 436 },
  { x: 5498, y: 714 },
  { x: 5141, y: 303 },
  { x: 5367, y: 724 },
  { x: 5636, y: 283 },
  { x: 5290, y: 165 },
  { x: 5522, y: 662 },
  { x: 5689, y: 556 },
  { x: 5678, y: 396 },
  { x: 5247, y: 279 },
  { x: 5186, y: 829 },
  { x: 5616, y: 290 },
  { x: 5408, y: 735 },
  { x: 5382, y: 793 },
  { x: 5219, y: 461 },
  { x: 5217, y: 203 },
  { x: 5285, y: 818 },
  { x: 5231, y: 426 },
  { x: 5693, y: 497 },
  { x: 5418, y: 786 },
  { x: 5192, y: 313 },
  { x: 5268, y: 146 },
  { x: 5500, y: 259 },
  { x: 5199, y: 445 },
  { x: 5437, y: 724 },
  { x: 5605, y: 750 },
  { x: 5138, y: 187 },
  { x: 5620, y: 596 },
  { x: 5412, y: 812 },
  { x: 5672, y: 562 },
  { x: 5276, y: 775 },
  { x: 5271, y: 755 },
  { x: 5182, y: 566 },
  { x: 5165, y: 294 },
  { x: 5258, y: 148 },
  { x: 5569, y: 725 },
  { x: 5356, y: 230 },
  { x: 5550, y: 623 },
  { x: 5208, y: 752 },
  { x: 5249, y: 515 },
  { x: 5521, y: 696 },
  { x: 5609, y: 452 },
  { x: 5162, y: 539 },
  { x: 5450, y: 170 },
  { x: 5572, y: 735 },
  { x: 5575, y: 731 },
  { x: 5225, y: 373 },
  { x: 5411, y: 234 },
  { x: 5461, y: 191 },
  { x: 5385, y: 762 },
  { x: 5491, y: 802 },
  { x: 5199, y: 720 },
  { x: 5226, y: 606 },
  { x: 5486, y: 714 },
  { x: 5599, y: 404 },
  { x: 5217, y: 394 },
  { x: 5648, y: 400 },
  { x: 5160, y: 668 },
  { x: 5191, y: 688 },
  { x: 5565, y: 224 },
  { x: 5376, y: 813 },
  { x: 5445, y: 729 },
  { x: 5134, y: 612 },
  { x: 5237, y: 396 },
  { x: 5551, y: 669 },
  { x: 5210, y: 829 },
  { x: 5230, y: 319 },
  { x: 5132, y: 188 },
  { x: 5606, y: 444 },
  { x: 5692, y: 495 },
  { x: 5613, y: 273 },
  { x: 5142, y: 790 },
  { x: 5663, y: 391 },
  { x: 5536, y: 657 },
  { x: 5678, y: 568 },
  { x: 5444, y: 263 },
  { x: 5202, y: 737 },
  { x: 5601, y: 542 },
  { x: 5357, y: 722 },
  { x: 5403, y: 732 },
  { x: 5546, y: 289 },
  { x: 5178, y: 412 },
  { x: 5574, y: 633 },
  { x: 5514, y: 264 },
  { x: 5693, y: 483 },
  { x: 5399, y: 164 },
  { x: 5149, y: 772 },
  { x: 5297, y: 243 },
  { x: 5480, y: 284 },
  { x: 5567, y: 622 },
  { x: 5228, y: 437 },
  { x: 5264, y: 411 },
  { x: 5378, y: 820 },
  { x: 5650, y: 427 },
  { x: 5184, y: 667 },
  { x: 5214, y: 434 },
  { x: 5148, y: 365 },
  { x: 5235, y: 789 },
  { x: 5167, y: 221 },
  { x: 5561, y: 486 },
  { x: 5559, y: 661 },
  { x: 5294, y: 139 },
  { x: 5155, y: 281 },
  { x: 5421, y: 748 },
  { x: 5697, y: 561 },
  { x: 5635, y: 459 },
  { x: 5215, y: 369 },
  { x: 5570, y: 468 },
  { x: 5428, y: 226 },
  { x: 5164, y: 783 },
  { x: 5193, y: 271 },
  { x: 5287, y: 810 },
  { x: 5662, y: 401 },
  { x: 5641, y: 654 },
  { x: 5371, y: 141 },
  { x: 5610, y: 478 },
  { x: 5512, y: 291 },
  { x: 5144, y: 184 },
  { x: 5420, y: 809 },
  { x: 5172, y: 658 },
  { x: 5252, y: 677 },
  { x: 5195, y: 698 },
  { x: 5645, y: 608 },
  { x: 5460, y: 758 },
  { x: 5161, y: 804 },
  { x: 5154, y: 759 },
  { x: 5207, y: 347 },
  { x: 5526, y: 268 },
  { x: 5337, y: 176 },
  { x: 5261, y: 448 },
  { x: 5415, y: 718 },
  { x: 5324, y: 830 },
  { x: 5358, y: 743 },
  { x: 5684, y: 479 },
  { x: 5612, y: 648 },
  { x: 5201, y: 583 },
  { x: 5409, y: 145 },
  { x: 5129, y: 521 },
  { x: 5571, y: 751 },
  { x: 5171, y: 579 },
  { x: 5391, y: 237 },
  { x: 5529, y: 774 },
  { x: 5576, y: 500 },
  { x: 5585, y: 344 },
  { x: 5151, y: 354 },
  { x: 5615, y: 401 },
  { x: 5678, y: 337 },
  { x: 5520, y: 291 },
  { x: 5194, y: 821 },
  { x: 5590, y: 478 },
  { x: 5317, y: 820 },
  { x: 5226, y: 669 },
  { x: 5201, y: 787 },
  { x: 5393, y: 728 },
  { x: 5273, y: 758 },
  { x: 5222, y: 160 },
  { x: 5205, y: 323 },
  { x: 5625, y: 486 },
  { x: 5208, y: 624 },
  { x: 5192, y: 390 },
  { x: 5200, y: 457 },
  { x: 5191, y: 148 },
  { x: 5643, y: 370 },
  { x: 5182, y: 369 },
  { x: 5453, y: 194 },
  { x: 5155, y: 402 },
  { x: 5227, y: 538 },
  { x: 5193, y: 666 },
  { x: 5216, y: 535 },
  { x: 5187, y: 652 },
  { x: 5159, y: 676 },
  { x: 5542, y: 213 },
  { x: 5533, y: 277 },
  { x: 5138, y: 368 },
  { x: 5567, y: 550 },
  { x: 5646, y: 611 },
  { x: 5691, y: 414 },
  { x: 5565, y: 481 },
  { x: 5460, y: 232 },
  { x: 5674, y: 462 },
  { x: 5202, y: 288 },
  { x: 5153, y: 610 },
  { x: 5519, y: 734 },
  { x: 5130, y: 796 },
  { x: 5448, y: 247 },
  { x: 5579, y: 417 },
  { x: 5434, y: 735 },
  { x: 5305, y: 725 },
  { x: 5213, y: 604 },
  { x: 5617, y: 657 },
  { x: 5134, y: 210 },
  { x: 5241, y: 692 },
  { x: 5235, y: 584 },
  { x: 5337, y: 783 },
  { x: 5266, y: 246 },
  { x: 5394, y: 142 },
  { x: 5186, y: 571 },
  { x: 5440, y: 763 },
  { x: 5133, y: 181 },
  { x: 5616, y: 455 },
  { x: 5170, y: 387 },
  { x: 5256, y: 781 },
  { x: 5510, y: 293 },
  { x: 5157, y: 202 },
  { x: 5556, y: 759 },
  { x: 5166, y: 815 },
  { x: 5586, y: 362 },
  { x: 5284, y: 827 },
  { x: 5376, y: 812 },
  { x: 5268, y: 233 },
  { x: 5165, y: 450 },
  { x: 5575, y: 509 },
  { x: 5593, y: 385 },
  { x: 5236, y: 384 },
  { x: 5431, y: 791 },
  { x: 5267, y: 215 },
  { x: 5661, y: 295 },
  { x: 5518, y: 789 },
  { x: 5597, y: 214 },
  { x: 5146, y: 171 },
  { x: 5185, y: 341 },
  { x: 5221, y: 219 },
  { x: 5249, y: 828 },
  { x: 5199, y: 249 },
  { x: 5229, y: 372 },
  { x: 5540, y: 269 },
  { x: 5228, y: 441 },
  { x: 5620, y: 573 },
  { x: 5577, y: 661 },
  { x: 5390, y: 146 },
  { x: 5609, y: 313 },
  { x: 5168, y: 778 },
  { x: 5388, y: 825 },
  { x: 5663, y: 324 },
  { x: 5169, y: 406 },
  { x: 5611, y: 453 },
  { x: 5260, y: 632 },
  { x: 5534, y: 198 },
  { x: 5254, y: 253 },
  { x: 5645, y: 708 },
  { x: 5621, y: 523 },
  { x: 5696, y: 433 },
  { x: 5364, y: 192 },
  { x: 5162, y: 737 },
  { x: 5692, y: 405 },
  { x: 5147, y: 777 },
  { x: 5179, y: 161 },
  { x: 5252, y: 557 },
  { x: 5231, y: 793 },
  { x: 5545, y: 248 },
  { x: 5242, y: 782 },
  { x: 5516, y: 749 },
  { x: 5163, y: 317 },
  { x: 5628, y: 707 },
  { x: 5253, y: 691 },
  { x: 5524, y: 230 },
  { x: 5252, y: 750 },
  { x: 5207, y: 662 },
  { x: 5169, y: 414 },
  { x: 5197, y: 665 },
  { x: 5165, y: 678 },
  { x: 5257, y: 321 },
  { x: 5183, y: 248 },
  { x: 5202, y: 548 },
  { x: 5140, y: 755 },
  { x: 5415, y: 801 },
  { x: 5649, y: 624 },
  { x: 5359, y: 739 },
  { x: 5181, y: 356 },
  { x: 5208, y: 529 },
  { x: 5682, y: 449 },
  { x: 5146, y: 392 },
  { x: 5224, y: 664 },
  { x: 5541, y: 689 },
  { x: 5189, y: 347 },
  { x: 5429, y: 154 },
  { x: 5591, y: 715 },
  { x: 5148, y: 487 },
  { x: 5647, y: 493 },
  { x: 5250, y: 403 },
  { x: 5156, y: 394 },
  { x: 5520, y: 313 },
  { x: 5572, y: 360 },
  { x: 5293, y: 214 },
  { x: 5467, y: 760 },
  { x: 5619, y: 504 },
  { x: 5307, y: 773 },
  { x: 5134, y: 629 },
  { x: 5235, y: 480 },
  { x: 5680, y: 512 },
  { x: 5550, y: 283 },
  { x: 5129, y: 238 },
  { x: 5575, y: 415 },
  { x: 5220, y: 215 },
  { x: 5139, y: 471 },
  { x: 5284, y: 185 },
  { x: 5408, y: 180 },
  { x: 5528, y: 171 },
  { x: 5604, y: 290 },
  { x: 5421, y: 155 },
  { x: 5205, y: 476 },
  { x: 5313, y: 805 },
  { x: 5639, y: 409 },
  { x: 5241, y: 258 },
  { x: 5618, y: 590 },
  { x: 5191, y: 249 },
  { x: 5691, y: 575 },
  { x: 5686, y: 619 },
  { x: 5621, y: 601 },
  { x: 5135, y: 416 },
  { x: 5260, y: 293 },
  { x: 5190, y: 348 },
  { x: 5487, y: 229 },
  { x: 5576, y: 673 },
  { x: 5144, y: 603 },
  { x: 5374, y: 778 },
  { x: 5182, y: 558 },
  { x: 5625, y: 265 },
  { x: 5605, y: 593 },
  { x: 5495, y: 704 },
  { x: 5218, y: 688 },
  { x: 5175, y: 444 },
  { x: 5557, y: 407 },
  { x: 5234, y: 182 },
  { x: 5447, y: 777 },
  { x: 5151, y: 746 },
  { x: 5312, y: 780 },
  { x: 5473, y: 173 },
  { x: 5521, y: 772 },
  { x: 5590, y: 247 },
  { x: 5470, y: 216 },
  { x: 5325, y: 164 },
  { x: 5352, y: 782 },
  { x: 5282, y: 197 },
  { x: 5233, y: 714 },
  { x: 5600, y: 362 },
  { x: 5253, y: 528 },
  { x: 5566, y: 267 },
  { x: 5614, y: 448 },
  { x: 5201, y: 226 },
  { x: 5239, y: 160 },
  { x: 5229, y: 706 },
  { x: 5223, y: 385 },
  { x: 5180, y: 676 },
  { x: 5687, y: 572 },
  { x: 5662, y: 681 },
  { x: 5186, y: 282 },
  { x: 5637, y: 266 },
  { x: 5194, y: 217 },
  { x: 5562, y: 383 },
  { x: 5513, y: 310 },
  { x: 5251, y: 405 },
  { x: 5184, y: 811 },
  { x: 5612, y: 438 },
  { x: 5393, y: 747 },
  { x: 5599, y: 252 },
  { x: 5452, y: 232 },
  { x: 5446, y: 792 },
  { x: 5161, y: 220 },
  { x: 5131, y: 592 },
  { x: 5254, y: 261 },
  { x: 5193, y: 483 },
  { x: 5162, y: 168 },
  { x: 5176, y: 586 },
  { x: 5240, y: 205 },
  { x: 5263, y: 177 },
  { x: 5497, y: 200 },
  { x: 5684, y: 618 },
  { x: 5244, y: 386 },
  { x: 5130, y: 423 },
  { x: 5523, y: 686 },
  { x: 5166, y: 140 },
  { x: 5436, y: 218 },
  { x: 5245, y: 643 },
  { x: 5160, y: 595 },
  { x: 5344, y: 174 },
  { x: 5278, y: 743 },
  { x: 5699, y: 563 },
  { x: 5211, y: 425 },
  { x: 5663, y: 603 },
  { x: 5227, y: 231 },
  { x: 5132, y: 432 },
  { x: 5134, y: 557 },
  { x: 5595, y: 326 },
  { x: 5215, y: 587 },
  { x: 5388, y: 227 },
  { x: 5538, y: 246 },
  { x: 5148, y: 198 },
  { x: 5611, y: 286 },
  { x: 5423, y: 149 },
  { x: 5609, y: 261 },
  { x: 5228, y: 524 },
  { x: 5187, y: 678 },
  { x: 5341, y: 727 },
  { x: 5212, y: 816 },
  { x: 5349, y: 766 },
  { x: 5508, y: 778 },
  { x: 5203, y: 613 },
  { x: 5560, y: 189 },
  { x: 5572, y: 203 },
  { x: 5177, y: 414 },
  { x: 5137, y: 735 },
  { x: 5584, y: 646 },
  { x: 5509, y: 798 },
  { x: 5498, y: 290 },
  { x: 5147, y: 518 },
  { x: 5640, y: 706 },
  { x: 5626, y: 459 },
  { x: 5219, y: 148 },
  { x: 5199, y: 810 },
  { x: 5162, y: 803 },
  { x: 5646, y: 271 },
  { x: 5464, y: 269 },
  { x: 5629, y: 724 },
  { x: 5154, y: 228 },
  { x: 5636, y: 536 },
  { x: 5599, y: 434 },
  { x: 5603, y: 595 },
  { x: 5229, y: 150 },
  { x: 5129, y: 563 },
  { x: 5153, y: 503 },
  { x: 5145, y: 194 },
  { x: 5232, y: 725 },
  { x: 5564, y: 461 },
  { x: 5170, y: 401 },
  { x: 5650, y: 382 },
  { x: 5573, y: 399 },
  { x: 5151, y: 213 },
  { x: 5253, y: 499 },
  { x: 5164, y: 755 },
  { x: 5150, y: 247 },
  { x: 5612, y: 317 },
  { x: 5422, y: 255 },
  { x: 5686, y: 511 },
  { x: 5236, y: 714 },
  { x: 5188, y: 223 },
  { x: 5247, y: 210 },
  { x: 5632, y: 649 },
  { x: 5155, y: 529 },
  { x: 5532, y: 698 },
  { x: 5645, y: 426 },
  { x: 5152, y: 417 },
  { x: 5551, y: 687 },
  { x: 5245, y: 258 },
  { x: 5262, y: 609 },
  { x: 5174, y: 740 },
  { x: 5633, y: 662 },
  { x: 5284, y: 232 },
  { x: 5175, y: 686 },
  { x: 5186, y: 475 },
  { x: 5178, y: 817 },
  { x: 5237, y: 283 },
  { x: 5580, y: 335 },
  { x: 5450, y: 806 },
  { x: 5191, y: 801 },
  { x: 5567, y: 478 },
  { x: 5547, y: 614 },
  { x: 5256, y: 169 },
  { x: 5585, y: 235 },
  { x: 5630, y: 593 },
  { x: 5335, y: 726 },
  { x: 5135, y: 556 },
  { x: 5637, y: 718 },
  { x: 5506, y: 287 },
  { x: 5207, y: 745 },
  { x: 5355, y: 818 },
  { x: 5680, y: 615 },
  { x: 5639, y: 547 },
  { x: 5252, y: 205 },
  { x: 5570, y: 243 },
  { x: 5620, y: 728 },
  { x: 5379, y: 147 },
  { x: 5594, y: 549 },
  { x: 5320, y: 732 },
  { x: 5197, y: 349 },
  { x: 5144, y: 809 },
  { x: 5242, y: 351 },
  { x: 5616, y: 463 },
  { x: 5244, y: 781 },
  { x: 5625, y: 560 },
  { x: 5264, y: 570 },
  { x: 5131, y: 751 },
  { x: 5182, y: 200 },
  { x: 5496, y: 284 },
  { x: 5591, y: 424 },
  { x: 5173, y: 670 },
  { x: 5397, y: 141 },
  { x: 5157, y: 397 },
  { x: 5502, y: 768 },
  { x: 5243, y: 346 },
  { x: 5260, y: 220 },
  { x: 5158, y: 741 },
  { x: 5254, y: 191 },
  { x: 5161, y: 625 },
  { x: 5146, y: 510 },
  { x: 5305, y: 791 },
  { x: 5437, y: 183 },
  { x: 5184, y: 670 },
  { x: 5193, y: 357 },
  { x: 5151, y: 327 },
  { x: 5440, y: 733 },
  { x: 5236, y: 721 },
  { x: 5644, y: 355 },
  { x: 5241, y: 386 },
  { x: 5568, y: 485 },
  { x: 5455, y: 808 },
  { x: 5657, y: 602 },
  { x: 5665, y: 586 },
  { x: 5131, y: 631 },
  { x: 5172, y: 401 },
  { x: 5252, y: 330 },
  { x: 5280, y: 735 },
  { x: 5205, y: 545 },
  { x: 5235, y: 819 },
  { x: 5248, y: 370 },
  { x: 5233, y: 787 },
  { x: 5129, y: 470 },
  { x: 5178, y: 540 },
  { x: 5202, y: 431 },
  { x: 5526, y: 178 },
  { x: 5209, y: 584 },
  { x: 5220, y: 460 },
  { x: 5242, y: 618 },
  { x: 5417, y: 246 },
  { x: 5588, y: 238 },
  { x: 5567, y: 756 },
  { x: 5226, y: 191 },
  { x: 5667, y: 608 },
  { x: 5215, y: 547 },
  { x: 5188, y: 292 },
  { x: 5704, y: 494 },
  { x: 5139, y: 747 },
  { x: 5185, y: 484 },
  { x: 5179, y: 314 },
  { x: 5581, y: 575 },
  { x: 5643, y: 562 },
  { x: 5429, y: 802 },
  { x: 5157, y: 383 },
  { x: 5379, y: 220 },
  { x: 5135, y: 655 },
  { x: 5534, y: 712 },
  { x: 5177, y: 821 },
  { x: 5224, y: 195 },
  { x: 5640, y: 473 },
  { x: 5327, y: 198 },
  { x: 5199, y: 225 },
  { x: 5251, y: 250 },
  { x: 5227, y: 684 },
  { x: 5646, y: 571 },
  { x: 5213, y: 778 },
  { x: 5145, y: 464 },
  { x: 5612, y: 333 },
  { x: 5483, y: 275 },
  { x: 5243, y: 150 },
  { x: 5219, y: 217 },
  { x: 5195, y: 579 },
  { x: 5488, y: 715 },
  { x: 5221, y: 221 },
  { x: 5262, y: 344 },
  { x: 5467, y: 770 },
  { x: 5617, y: 236 },
  { x: 5420, y: 759 },
  { x: 5206, y: 260 },
  { x: 5658, y: 495 },
  { x: 5516, y: 674 },
  { x: 5333, y: 163 },
  { x: 5165, y: 751 },
  { x: 5345, y: 245 },
  { x: 5197, y: 367 },
  { x: 5200, y: 548 },
  { x: 5608, y: 360 },
  { x: 5694, y: 533 },
  { x: 5572, y: 285 },
  { x: 5194, y: 240 },
  { x: 5168, y: 657 },
  { x: 5158, y: 529 },
  { x: 5330, y: 745 },
  { x: 5211, y: 151 },
  { x: 5397, y: 169 },
  { x: 5358, y: 141 },
  { x: 5476, y: 281 },
  { x: 5459, y: 209 },
  { x: 5541, y: 263 },
  { x: 5507, y: 695 },
  { x: 5373, y: 765 },
  { x: 5173, y: 788 },
  { x: 5143, y: 415 },
  { x: 5443, y: 204 },
  { x: 5308, y: 758 },
  { x: 5607, y: 366 },
  { x: 5389, y: 208 },
  { x: 5565, y: 572 },
  { x: 5642, y: 293 },
  { x: 5310, y: 212 },
  { x: 5256, y: 413 },
  { x: 5328, y: 761 },
  { x: 5449, y: 192 },
  { x: 5597, y: 274 },
  { x: 5174, y: 491 },
  { x: 5573, y: 206 },
  { x: 5164, y: 618 },
  { x: 5581, y: 431 },
  { x: 5671, y: 393 },
  { x: 5132, y: 528 },
  { x: 5179, y: 540 },
  { x: 5159, y: 719 },
  { x: 5249, y: 261 },
  { x: 5230, y: 366 },
  { x: 5356, y: 210 },
  { x: 5661, y: 336 },
  { x: 5615, y: 710 },
  { x: 5597, y: 522 },
  { x: 5602, y: 581 },
  { x: 5376, y: 187 },
  { x: 5621, y: 557 },
  { x: 5539, y: 259 },
  { x: 5134, y: 297 },
  { x: 5559, y: 349 },
  { x: 5166, y: 412 },
  { x: 5246, y: 697 },
  { x: 5399, y: 212 },
  { x: 5580, y: 531 },
  { x: 5675, y: 569 },
  { x: 5247, y: 258 },
  { x: 5171, y: 684 },
  { x: 5591, y: 688 },
  { x: 5217, y: 325 },
  { x: 5471, y: 193 },
  { x: 5566, y: 224 },
  { x: 5222, y: 229 },
  { x: 5623, y: 269 },
  { x: 5527, y: 744 },
  { x: 5614, y: 389 },
  { x: 5386, y: 144 },
  { x: 5430, y: 174 },
  { x: 5306, y: 740 },
  { x: 5693, y: 551 },
  { x: 5537, y: 241 },
  { x: 5241, y: 181 },
  { x: 5695, y: 410 },
  { x: 5462, y: 779 },
  { x: 5250, y: 399 },
  { x: 5208, y: 655 },
  { x: 5562, y: 434 },
  { x: 5245, y: 665 },
  { x: 5540, y: 630 },
  { x: 5603, y: 313 },
  { x: 5254, y: 350 },
  { x: 5605, y: 286 },
  { x: 5293, y: 180 },
  { x: 5141, y: 437 },
  { x: 5256, y: 643 },
  { x: 5239, y: 706 },
  { x: 5296, y: 196 },
  { x: 5142, y: 166 },
  { x: 5460, y: 737 },
  { x: 5690, y: 472 },
  { x: 5214, y: 421 },
  { x: 5169, y: 348 },
  { x: 5187, y: 440 },
  { x: 5664, y: 674 },
  { x: 5666, y: 607 },
  { x: 5551, y: 590 },
  { x: 5332, y: 242 },
  { x: 5148, y: 746 },
  { x: 5579, y: 700 },
  { x: 5233, y: 690 },
  { x: 5520, y: 798 },
  { x: 5192, y: 487 },
  { x: 5364, y: 171 },
  { x: 5544, y: 612 },
  { x: 5517, y: 771 },
  { x: 5590, y: 558 },
  { x: 5612, y: 646 },
  { x: 5205, y: 827 },
  { x: 5583, y: 505 },
  { x: 5570, y: 272 },
  { x: 5439, y: 158 },
  { x: 5658, y: 361 },
  { x: 5530, y: 312 },
  { x: 5318, y: 165 },
  { x: 5582, y: 625 },
  { x: 5627, y: 606 },
  { x: 5378, y: 789 },
  { x: 5575, y: 549 },
  { x: 5385, y: 159 },
  { x: 5560, y: 234 },
  { x: 5488, y: 761 },
  { x: 5193, y: 381 },
  { x: 5165, y: 550 },
  { x: 5592, y: 225 },
  { x: 5531, y: 756 },
  { x: 5271, y: 238 },
  { x: 5535, y: 687 },
  { x: 5299, y: 782 },
  { x: 5596, y: 511 },
  { x: 5518, y: 274 },
  { x: 5496, y: 226 },
  { x: 5226, y: 368 },
  { x: 5238, y: 430 },
  { x: 5463, y: 243 },
  { x: 5500, y: 232 },
  { x: 5646, y: 466 },
  { x: 5454, y: 768 },
  { x: 5199, y: 514 },
  { x: 5576, y: 717 },
  { x: 5234, y: 206 },
  { x: 5564, y: 680 },
  { x: 5147, y: 718 },
  { x: 5464, y: 766 },
  { x: 5674, y: 450 },
  { x: 5173, y: 461 },
  { x: 5158, y: 795 },
  { x: 5200, y: 315 },
  { x: 5160, y: 209 },
  { x: 5396, y: 813 },
  { x: 5130, y: 720 },
  { x: 5698, y: 518 },
  { x: 5408, y: 728 },
  { x: 5279, y: 175 },
  { x: 5578, y: 689 },
  { x: 5194, y: 492 },
  { x: 5389, y: 200 },
  { x: 5280, y: 150 },
  { x: 5495, y: 773 },
  { x: 5558, y: 585 },
  { x: 5183, y: 792 },
  { x: 5584, y: 291 },
  { x: 5459, y: 731 },
  { x: 5701, y: 442 },
  { x: 5458, y: 203 },
  { x: 5252, y: 811 },
  { x: 5571, y: 546 },
  { x: 5536, y: 265 },
  { x: 5973, y: 549 },
  { x: 6012, y: 166 },
  { x: 6279, y: 198 },
  { x: 6294, y: 168 },
  { x: 6272, y: 748 },
  { x: 6134, y: 781 },
  { x: 6041, y: 273 },
  { x: 6093, y: 793 },
  { x: 6283, y: 751 },
  { x: 6003, y: 525 },
  { x: 6224, y: 444 },
  { x: 6194, y: 512 },
  { x: 6140, y: 213 },
  { x: 6030, y: 553 },
  { x: 6299, y: 811 },
  { x: 6002, y: 248 },
  { x: 6284, y: 779 },
  { x: 6047, y: 790 },
  { x: 6063, y: 500 },
  { x: 6062, y: 189 },
  { x: 6079, y: 457 },
  { x: 6097, y: 211 },
  { x: 5968, y: 188 },
  { x: 6117, y: 806 },
  { x: 6139, y: 782 },
  { x: 6180, y: 547 },
  { x: 6052, y: 516 },
  { x: 6024, y: 817 },
  { x: 5964, y: 532 },
  { x: 6010, y: 815 },
  { x: 6261, y: 240 },
  { x: 6231, y: 745 },
  { x: 5998, y: 292 },
  { x: 6042, y: 157 },
  { x: 5957, y: 514 },
  { x: 6014, y: 426 },
  { x: 6183, y: 794 },
  { x: 6260, y: 743 },
  { x: 6171, y: 174 },
  { x: 5936, y: 381 },
  { x: 6022, y: 202 },
  { x: 5984, y: 772 },
  { x: 5958, y: 467 },
  { x: 6155, y: 509 },
  { x: 5970, y: 746 },
  { x: 6056, y: 761 },
  { x: 6034, y: 319 },
  { x: 6181, y: 507 },
  { x: 6146, y: 187 },
  { x: 6138, y: 732 },
  { x: 5934, y: 780 },
  { x: 6209, y: 465 },
  { x: 5925, y: 742 },
  { x: 6121, y: 808 },
  { x: 6212, y: 531 },
  { x: 5993, y: 778 },
  { x: 5923, y: 209 },
  { x: 5947, y: 483 },
  { x: 5948, y: 590 },
  { x: 6038, y: 670 },
  { x: 5920, y: 497 },
  { x: 6192, y: 755 },
  { x: 6045, y: 606 },
  { x: 6125, y: 534 },
  { x: 6203, y: 472 },
  { x: 6253, y: 221 },
  { x: 5954, y: 403 },
  { x: 6156, y: 783 },
  { x: 5990, y: 733 },
  { x: 6039, y: 633 },
  { x: 6290, y: 142 },
  { x: 5919, y: 372 },
  { x: 5952, y: 162 },
  { x: 5981, y: 820 },
  { x: 6026, y: 299 },
  { x: 5938, y: 569 },
  { x: 5971, y: 600 },
  { x: 5946, y: 753 },
  { x: 5930, y: 476 },
  { x: 6004, y: 488 },
  { x: 6276, y: 812 },
  { x: 6005, y: 287 },
  { x: 6122, y: 246 },
  { x: 5974, y: 185 },
  { x: 6129, y: 230 },
  { x: 6222, y: 200 },
  { x: 6288, y: 723 },
  { x: 5921, y: 347 },
  { x: 5989, y: 341 },
  { x: 5972, y: 480 },
  { x: 5926, y: 409 },
  { x: 6240, y: 765 },
  { x: 6165, y: 237 },
  { x: 5941, y: 310 },
  { x: 5992, y: 740 },
  { x: 6044, y: 750 },
  { x: 6262, y: 535 },
  { x: 5925, y: 793 },
  { x: 6218, y: 532 },
  { x: 6204, y: 229 },
  { x: 5933, y: 689 },
  { x: 6207, y: 547 },
  { x: 6183, y: 211 },
  { x: 5954, y: 506 },
  { x: 6101, y: 830 },
  { x: 6109, y: 457 },
  { x: 6045, y: 567 },
  { x: 6255, y: 548 },
  { x: 6191, y: 139 },
  { x: 5949, y: 463 },
  { x: 5965, y: 632 },
  { x: 6039, y: 253 },
  { x: 6035, y: 418 },
  { x: 6046, y: 550 },
  { x: 5932, y: 729 },
  { x: 6082, y: 815 },
  { x: 5919, y: 779 },
  { x: 6063, y: 730 },
  { x: 6100, y: 763 },
  { x: 5913, y: 274 },
  { x: 5960, y: 607 },
  { x: 5974, y: 539 },
  { x: 6010, y: 721 },
  { x: 5955, y: 573 },
  { x: 6025, y: 787 },
  { x: 6154, y: 477 },
  { x: 6001, y: 175 },
  { x: 6294, y: 149 },
  { x: 6003, y: 444 },
  { x: 5989, y: 439 },
  { x: 5978, y: 218 },
  { x: 6021, y: 694 },
  { x: 5972, y: 551 },
  { x: 6288, y: 778 },
  { x: 5980, y: 526 },
  { x: 5977, y: 166 },
  { x: 6262, y: 783 },
  { x: 6079, y: 164 },
  { x: 6281, y: 732 },
  { x: 6163, y: 487 },
  { x: 5979, y: 559 },
  { x: 6108, y: 796 },
  { x: 6162, y: 245 },
  { x: 5953, y: 630 },
  { x: 6054, y: 802 },
  { x: 6287, y: 760 },
  { x: 5997, y: 580 },
  { x: 6141, y: 476 },
  { x: 6279, y: 820 },
  { x: 6004, y: 178 },
  { x: 5991, y: 424 },
  { x: 5970, y: 555 },
  { x: 5921, y: 433 },
  { x: 5928, y: 440 },
  { x: 6250, y: 241 },
  { x: 5998, y: 364 },
  { x: 6221, y: 177 },
  { x: 6280, y: 768 },
  { x: 6315, y: 168 },
  { x: 6175, y: 478 },
  { x: 6105, y: 148 },
  { x: 6257, y: 490 },
  { x: 5950, y: 521 },
  { x: 5987, y: 507 },
  { x: 5961, y: 224 },
  { x: 6146, y: 749 },
  { x: 6027, y: 232 },
  { x: 5951, y: 260 },
  { x: 5940, y: 183 },
  { x: 6017, y: 709 },
  { x: 5924, y: 249 },
  { x: 6023, y: 219 },
  { x: 6190, y: 508 },
  { x: 6071, y: 194 },
  { x: 6056, y: 749 },
  { x: 6197, y: 484 },
  { x: 6239, y: 190 },
  { x: 6021, y: 548 },
  { x: 6227, y: 504 },
  { x: 6027, y: 572 },
  { x: 6145, y: 543 },
  { x: 6236, y: 753 },
  { x: 5994, y: 662 },
  { x: 5932, y: 291 },
  { x: 6305, y: 148 },
  { x: 6025, y: 347 },
  { x: 5941, y: 171 },
  { x: 6152, y: 511 },
  { x: 6158, y: 732 },
  { x: 6148, y: 147 },
  { x: 5969, y: 278 },
  { x: 6086, y: 195 },
  { x: 6041, y: 655 },
  { x: 6194, y: 547 },
  { x: 6228, y: 182 },
  { x: 5912, y: 211 },
  { x: 5959, y: 806 },
  { x: 5915, y: 328 },
  { x: 6147, y: 804 },
  { x: 5958, y: 368 },
  { x: 6126, y: 527 },
  { x: 6087, y: 820 },
  { x: 5951, y: 415 },
  { x: 5998, y: 378 },
  { x: 5914, y: 495 },
  { x: 6059, y: 228 },
  { x: 6079, y: 533 },
  { x: 6196, y: 781 },
  { x: 6233, y: 191 },
  { x: 6095, y: 168 },
  { x: 5996, y: 628 },
  { x: 6020, y: 520 },
  { x: 6163, y: 138 },
  { x: 6031, y: 785 },
  { x: 6298, y: 236 },
  { x: 6030, y: 405 },
  { x: 6026, y: 152 },
  { x: 5937, y: 733 },
  { x: 6000, y: 173 },
  { x: 5921, y: 471 },
  { x: 6007, y: 353 },
  { x: 6161, y: 793 },
  { x: 6065, y: 181 },
  { x: 5989, y: 643 },
  { x: 6010, y: 367 },
  { x: 6061, y: 493 },
  { x: 6081, y: 164 },
  { x: 5970, y: 641 },
  { x: 6263, y: 769 },
  { x: 6074, y: 202 },
  { x: 5922, y: 454 },
  { x: 5978, y: 657 },
  { x: 6265, y: 176 },
  { x: 6202, y: 170 },
  { x: 5963, y: 327 },
  { x: 5908, y: 434 },
  { x: 6192, y: 522 },
  { x: 5972, y: 651 },
  { x: 6109, y: 458 },
  { x: 5933, y: 717 },
  { x: 5948, y: 614 },
  { x: 6092, y: 233 },
  { x: 6070, y: 151 },
  { x: 5919, y: 553 },
  { x: 6206, y: 746 },
  { x: 6082, y: 485 },
  { x: 6103, y: 809 },
  { x: 6067, y: 748 },
  { x: 6190, y: 536 },
  { x: 6180, y: 479 },
  { x: 6204, y: 224 },
  { x: 6034, y: 218 },
  { x: 6017, y: 427 },
  { x: 5983, y: 591 },
  { x: 6009, y: 747 },
  { x: 6045, y: 300 },
  { x: 6004, y: 598 },
  { x: 6141, y: 766 },
  { x: 6253, y: 192 },
  { x: 6011, y: 808 },
  { x: 6039, y: 349 },
  { x: 5977, y: 240 },
  { x: 5923, y: 399 },
  { x: 5978, y: 195 },
  { x: 5984, y: 553 },
  { x: 6163, y: 186 },
  { x: 6089, y: 183 },
  { x: 5967, y: 781 },
  { x: 5959, y: 689 },
  { x: 6015, y: 232 },
  { x: 6004, y: 810 },
  { x: 6115, y: 762 },
  { x: 6263, y: 754 },
  { x: 6056, y: 525 },
  { x: 6229, y: 801 },
  { x: 6310, y: 153 },
  { x: 6206, y: 218 },
  { x: 6179, y: 520 },
  { x: 6242, y: 490 },
  { x: 6253, y: 542 },
  { x: 5948, y: 271 },
  { x: 6157, y: 227 },
  { x: 6141, y: 487 },
  { x: 6211, y: 472 },
  { x: 5964, y: 806 },
  { x: 6127, y: 528 },
  { x: 6002, y: 557 },
  { x: 6049, y: 725 },
  { x: 6019, y: 406 },
  { x: 5962, y: 613 },
  { x: 6138, y: 501 },
  { x: 5973, y: 288 },
  { x: 6022, y: 265 },
  { x: 5950, y: 591 },
  { x: 5991, y: 665 },
  { x: 6262, y: 148 },
  { x: 5920, y: 652 },
  { x: 5988, y: 394 },
  { x: 6174, y: 444 },
  { x: 6132, y: 479 },
  { x: 5976, y: 561 },
  { x: 6000, y: 140 },
  { x: 5994, y: 478 },
  { x: 5993, y: 473 },
  { x: 6245, y: 790 },
  { x: 6040, y: 828 },
  { x: 5983, y: 477 },
  { x: 6142, y: 160 },
  { x: 5963, y: 355 },
  { x: 5961, y: 552 },
  { x: 5986, y: 174 },
  { x: 5921, y: 263 },
  { x: 5928, y: 337 },
  { x: 6023, y: 803 },
  { x: 6105, y: 772 },
  { x: 6069, y: 755 },
  { x: 6265, y: 175 },
  { x: 6151, y: 206 },
  { x: 6254, y: 749 },
  { x: 6012, y: 605 },
  { x: 6007, y: 733 },
  { x: 5966, y: 731 },
  { x: 6053, y: 531 },
  { x: 6026, y: 408 },
  { x: 6102, y: 241 },
  { x: 6001, y: 537 },
  { x: 6114, y: 726 },
  { x: 6079, y: 505 },
  { x: 5916, y: 255 },
  { x: 6013, y: 214 },
  { x: 6137, y: 785 },
  { x: 6189, y: 224 },
  { x: 5914, y: 546 },
  { x: 6005, y: 452 },
  { x: 6006, y: 582 },
  { x: 6003, y: 563 },
  { x: 6067, y: 724 },
  { x: 5990, y: 165 },
  { x: 5969, y: 592 },
  { x: 5947, y: 279 },
  { x: 6108, y: 735 },
  { x: 6082, y: 793 },
  { x: 5919, y: 461 },
  { x: 5917, y: 203 },
  { x: 5985, y: 818 },
  { x: 5931, y: 426 },
  { x: 6118, y: 786 },
  { x: 6238, y: 449 },
  { x: 5968, y: 146 },
  { x: 6137, y: 724 },
  { x: 6305, y: 750 },
  { x: 6031, y: 442 },
  { x: 6244, y: 803 },
  { x: 6192, y: 526 },
  { x: 6034, y: 399 },
  { x: 6112, y: 812 },
  { x: 6020, y: 426 },
  { x: 5976, y: 775 },
  { x: 6307, y: 779 },
  { x: 5971, y: 755 },
  { x: 5975, y: 582 },
  { x: 5958, y: 148 },
  { x: 6269, y: 725 },
  { x: 6056, y: 230 },
  { x: 5908, y: 752 },
  { x: 5949, y: 515 },
  { x: 6150, y: 170 },
  { x: 6004, y: 265 },
  { x: 5988, y: 332 },
  { x: 6272, y: 735 },
  { x: 6275, y: 731 },
  { x: 5925, y: 373 },
  { x: 6111, y: 234 },
  { x: 6161, y: 191 },
  { x: 6085, y: 762 },
  { x: 6083, y: 467 },
  { x: 6191, y: 802 },
  { x: 5984, y: 707 },
  { x: 6125, y: 513 },
  { x: 6014, y: 703 },
  { x: 5926, y: 606 },
  { x: 5917, y: 394 },
  { x: 6265, y: 224 },
  { x: 6076, y: 813 },
  { x: 6145, y: 729 },
  { x: 5937, y: 396 },
  { x: 6028, y: 713 },
  { x: 5930, y: 319 },
  { x: 5985, y: 312 },
  { x: 6000, y: 706 },
  { x: 6059, y: 453 },
  { x: 6057, y: 722 },
  { x: 6103, y: 732 },
  { x: 6007, y: 457 },
  { x: 6211, y: 152 },
  { x: 6257, y: 462 },
  { x: 6099, y: 164 },
  { x: 5970, y: 477 },
  { x: 5974, y: 441 },
  { x: 6113, y: 548 },
  { x: 5997, y: 243 },
  { x: 5928, y: 437 },
  { x: 6049, y: 502 },
  { x: 6190, y: 464 },
  { x: 5964, y: 411 },
  { x: 6039, y: 393 },
  { x: 6078, y: 820 },
  { x: 5914, y: 434 },
  { x: 5935, y: 789 },
  { x: 6261, y: 486 },
  { x: 5994, y: 139 },
  { x: 5981, y: 367 },
  { x: 6121, y: 748 },
  { x: 5915, y: 369 },
  { x: 6270, y: 468 },
  { x: 6128, y: 226 },
  { x: 5987, y: 810 },
  { x: 5983, y: 708 },
  { x: 6071, y: 141 },
  { x: 5980, y: 586 },
  { x: 6120, y: 809 },
  { x: 5952, y: 677 },
  { x: 5978, y: 525 },
  { x: 6160, y: 758 },
  { x: 5993, y: 252 },
  { x: 6037, y: 176 },
  { x: 5961, y: 448 },
  { x: 6220, y: 546 },
  { x: 6024, y: 830 },
  { x: 6117, y: 541 },
  { x: 6058, y: 743 },
  { x: 6109, y: 145 },
  { x: 6271, y: 751 },
  { x: 6091, y: 237 },
  { x: 5996, y: 318 },
  { x: 6229, y: 774 },
  { x: 6276, y: 500 },
  { x: 6052, y: 455 },
  { x: 6042, y: 687 },
  { x: 6074, y: 527 },
  { x: 5985, y: 438 },
  { x: 6226, y: 454 },
  { x: 6204, y: 504 },
  { x: 6124, y: 475 },
  { x: 6017, y: 820 },
  { x: 6238, y: 167 },
  { x: 5926, y: 669 },
  { x: 6229, y: 507 },
  { x: 6028, y: 665 },
  { x: 5997, y: 367 },
  { x: 6093, y: 728 },
  { x: 6032, y: 598 },
  { x: 5973, y: 758 },
  { x: 5922, y: 160 },
  { x: 6253, y: 813 },
  { x: 5908, y: 624 },
  { x: 6019, y: 321 },
  { x: 6153, y: 194 },
  { x: 5927, y: 538 },
  { x: 5916, y: 535 },
  { x: 6242, y: 213 },
  { x: 6257, y: 165 },
  { x: 6265, y: 481 },
  { x: 6160, y: 232 },
  { x: 6033, y: 695 },
  { x: 6288, y: 168 },
  { x: 6252, y: 447 },
  { x: 6219, y: 734 },
  { x: 6134, y: 735 },
  { x: 6005, y: 725 },
  { x: 5913, y: 604 },
  { x: 6197, y: 512 },
  { x: 6010, y: 425 },
  { x: 5941, y: 692 },
  { x: 5935, y: 584 },
  { x: 5990, y: 400 },
  { x: 6037, y: 783 },
  { x: 6230, y: 150 },
  { x: 6180, y: 823 },
  { x: 5979, y: 592 },
  { x: 5966, y: 246 },
  { x: 6094, y: 142 },
  { x: 6140, y: 763 },
  { x: 5989, y: 645 },
  { x: 6036, y: 703 },
  { x: 5956, y: 781 },
  { x: 6085, y: 471 },
  { x: 6256, y: 759 },
  { x: 5984, y: 827 },
  { x: 6199, y: 511 },
  { x: 6076, y: 812 },
  { x: 5968, y: 233 },
  { x: 6275, y: 509 },
  { x: 5999, y: 673 },
  { x: 6301, y: 190 },
  { x: 6228, y: 497 },
  { x: 5936, y: 384 },
  { x: 6131, y: 791 },
  { x: 6084, y: 458 },
  { x: 5967, y: 215 },
  { x: 6218, y: 789 },
  { x: 5975, y: 396 },
  { x: 6297, y: 214 },
  { x: 6246, y: 797 },
  { x: 5921, y: 219 },
  { x: 5949, y: 828 },
  { x: 5929, y: 372 },
  { x: 5928, y: 441 },
  { x: 5986, y: 700 },
  { x: 6090, y: 146 },
  { x: 6088, y: 825 },
  { x: 5960, y: 632 },
  { x: 6234, y: 198 },
  { x: 5954, y: 253 },
  { x: 6034, y: 623 },
  { x: 6064, y: 192 },
  { x: 6012, y: 651 },
  { x: 5952, y: 557 },
  { x: 5931, y: 793 },
  { x: 5942, y: 782 },
  { x: 5976, y: 463 },
  { x: 6216, y: 749 },
  { x: 5953, y: 691 },
  { x: 6224, y: 230 },
  { x: 5952, y: 750 },
  { x: 6100, y: 475 },
  { x: 6042, y: 636 },
  { x: 5957, y: 321 },
  { x: 6035, y: 503 },
  { x: 6115, y: 801 },
  { x: 6270, y: 829 },
  { x: 6059, y: 739 },
  { x: 5908, y: 529 },
  { x: 6259, y: 144 },
  { x: 5924, y: 664 },
  { x: 6034, y: 663 },
  { x: 6104, y: 524 },
  { x: 6129, y: 154 },
  { x: 5981, y: 401 },
  { x: 5970, y: 711 },
  { x: 5950, y: 403 },
  { x: 6286, y: 815 },
  { x: 5993, y: 214 },
  { x: 6140, y: 502 },
  { x: 6167, y: 760 },
  { x: 6031, y: 276 },
  { x: 6007, y: 773 },
  { x: 6033, y: 656 },
  { x: 5935, y: 480 },
  { x: 6028, y: 351 },
  { x: 6076, y: 460 },
  { x: 5920, y: 215 },
  { x: 5984, y: 185 },
  { x: 6108, y: 180 },
  { x: 6258, y: 517 },
  { x: 6228, y: 171 },
  { x: 6121, y: 155 },
  { x: 6013, y: 805 },
  { x: 5941, y: 258 },
  { x: 6227, y: 827 },
  { x: 5960, y: 293 },
  { x: 6032, y: 350 },
  { x: 6113, y: 459 },
  { x: 5987, y: 297 },
  { x: 6187, y: 229 },
  { x: 6074, y: 778 },
  { x: 6278, y: 188 },
  { x: 6011, y: 638 },
  { x: 6243, y: 447 },
  { x: 5918, y: 688 },
  { x: 6018, y: 342 },
  { x: 5934, y: 182 },
  { x: 6147, y: 777 },
  { x: 6242, y: 535 },
  { x: 6012, y: 780 },
  { x: 6039, y: 720 },
  { x: 6173, y: 173 },
  { x: 6221, y: 772 },
  { x: 6170, y: 216 },
  { x: 6025, y: 164 },
  { x: 6052, y: 782 },
  { x: 5982, y: 197 },
  { x: 5933, y: 714 },
  { x: 5953, y: 528 },
  { x: 6212, y: 820 },
  { x: 6171, y: 550 },
  { x: 6222, y: 537 },
  { x: 5976, y: 329 },
  { x: 5939, y: 160 },
  { x: 6075, y: 473 },
  { x: 5929, y: 706 },
  { x: 5923, y: 385 },
  { x: 5979, y: 501 },
  { x: 5951, y: 405 },
  { x: 6093, y: 747 },
  { x: 6152, y: 232 },
  { x: 6146, y: 792 },
  { x: 6231, y: 470 },
  { x: 5954, y: 261 },
  { x: 6210, y: 490 },
  { x: 6021, y: 570 },
  { x: 5940, y: 205 },
  { x: 5974, y: 436 },
  { x: 5963, y: 177 },
  { x: 6197, y: 200 },
  { x: 6306, y: 198 },
  { x: 5944, y: 386 },
  { x: 5972, y: 428 },
  { x: 6226, y: 466 },
  { x: 6037, y: 687 },
  { x: 6136, y: 218 },
  { x: 5945, y: 643 },
  { x: 6044, y: 174 },
  { x: 5978, y: 743 },
  { x: 5990, y: 630 },
  { x: 5911, y: 425 },
  { x: 5927, y: 231 },
  { x: 6101, y: 500 },
  { x: 6182, y: 489 },
  { x: 5915, y: 587 },
  { x: 6025, y: 343 },
  { x: 6088, y: 227 },
  { x: 6238, y: 246 },
  { x: 6123, y: 149 },
  { x: 5928, y: 524 },
  { x: 6215, y: 548 },
  { x: 6041, y: 727 },
  { x: 6037, y: 697 },
  { x: 6049, y: 766 },
  { x: 6068, y: 479 },
  { x: 6208, y: 778 },
  { x: 6260, y: 189 },
  { x: 6272, y: 203 },
  { x: 6256, y: 488 },
  { x: 6001, y: 464 },
  { x: 6134, y: 531 },
  { x: 6209, y: 798 },
  { x: 5919, y: 148 },
  { x: 6008, y: 374 },
  { x: 6210, y: 811 },
  { x: 6005, y: 618 },
  { x: 6018, y: 344 },
  { x: 6246, y: 825 },
  { x: 5929, y: 150 },
  { x: 6167, y: 532 },
  { x: 5932, y: 725 },
  { x: 6264, y: 461 },
  { x: 6225, y: 471 },
  { x: 5953, y: 499 },
  { x: 5936, y: 714 },
  { x: 6139, y: 470 },
  { x: 5947, y: 210 },
  { x: 6138, y: 512 },
  { x: 5945, y: 258 },
  { x: 5962, y: 609 },
  { x: 6022, y: 667 },
  { x: 5984, y: 232 },
  { x: 5997, y: 441 },
  { x: 6293, y: 146 },
  { x: 5937, y: 283 },
  { x: 5973, y: 683 },
  { x: 6218, y: 508 },
  { x: 6214, y: 453 },
  { x: 6161, y: 543 },
  { x: 6150, y: 806 },
  { x: 6267, y: 478 },
  { x: 5993, y: 405 },
  { x: 6155, y: 465 },
  { x: 5956, y: 169 },
  { x: 6285, y: 235 },
  { x: 6035, y: 726 },
  { x: 6262, y: 812 },
  { x: 6055, y: 818 },
  { x: 5952, y: 205 },
  { x: 6270, y: 243 },
  { x: 6079, y: 147 },
  { x: 6024, y: 264 },
  { x: 5999, y: 422 },
  { x: 6020, y: 732 },
  { x: 5983, y: 672 },
  { x: 5998, y: 602 },
  { x: 5942, y: 351 },
  { x: 5944, y: 781 },
  { x: 5964, y: 570 },
  { x: 5991, y: 674 },
  { x: 6070, y: 501 },
  { x: 5972, y: 591 },
  { x: 5995, y: 268 },
  { x: 6097, y: 141 },
  { x: 6017, y: 391 },
  { x: 6202, y: 768 },
  { x: 5943, y: 346 },
  { x: 6043, y: 652 },
  { x: 5960, y: 220 },
  { x: 5974, y: 585 },
  { x: 6239, y: 155 },
  { x: 5954, y: 191 },
  { x: 6005, y: 791 },
  { x: 6240, y: 509 },
  { x: 6137, y: 183 },
  { x: 6020, y: 430 },
  { x: 5992, y: 409 },
  { x: 6140, y: 733 },
  { x: 6197, y: 497 },
  { x: 5936, y: 721 },
  { x: 5941, y: 386 },
  { x: 6268, y: 485 },
  { x: 6233, y: 465 },
  { x: 6155, y: 808 },
  { x: 5996, y: 634 },
  { x: 5997, y: 410 },
  { x: 5952, y: 330 },
  { x: 5980, y: 735 },
  { x: 6093, y: 496 },
  { x: 5935, y: 819 },
  { x: 5993, y: 454 },
  { x: 5948, y: 370 },
  { x: 5933, y: 787 },
  { x: 5986, y: 398 },
  { x: 6088, y: 479 },
  { x: 6226, y: 178 },
  { x: 5909, y: 584 },
  { x: 5988, y: 378 },
  { x: 5920, y: 460 },
  { x: 5942, y: 618 },
  { x: 6117, y: 246 },
  { x: 6288, y: 238 },
  { x: 6267, y: 756 },
  { x: 5926, y: 191 },
  { x: 6202, y: 448 },
  { x: 5915, y: 547 },
  { x: 5994, y: 288 },
  { x: 6031, y: 592 },
  { x: 6017, y: 249 },
  { x: 6040, y: 248 },
  { x: 6129, y: 802 },
  { x: 6146, y: 452 },
  { x: 6079, y: 220 },
  { x: 6046, y: 261 },
  { x: 6199, y: 449 },
  { x: 5924, y: 195 },
  { x: 6027, y: 198 },
  { x: 5951, y: 250 },
  { x: 5927, y: 684 },
  { x: 6283, y: 179 },
  { x: 5913, y: 778 },
  { x: 6144, y: 476 },
  { x: 6136, y: 546 },
  { x: 6034, y: 501 },
  { x: 6013, y: 601 },
  { x: 5943, y: 150 },
  { x: 6105, y: 505 },
  { x: 6012, y: 304 },
  { x: 5919, y: 217 },
  { x: 6191, y: 147 },
  { x: 6195, y: 829 },
  { x: 6293, y: 182 },
  { x: 5921, y: 221 },
  { x: 6036, y: 508 },
  { x: 5962, y: 344 },
  { x: 6167, y: 770 },
  { x: 6120, y: 759 },
  { x: 6044, y: 537 },
  { x: 6033, y: 163 },
  { x: 6045, y: 245 },
  { x: 5975, y: 416 },
  { x: 6009, y: 520 },
  { x: 6015, y: 610 },
  { x: 6311, y: 148 },
  { x: 6279, y: 818 },
  { x: 5976, y: 442 },
  { x: 6236, y: 801 },
  { x: 5999, y: 614 },
  { x: 6030, y: 745 },
  { x: 6097, y: 169 },
  { x: 6058, y: 141 },
  { x: 6159, y: 209 },
  { x: 5990, y: 481 },
  { x: 5995, y: 372 },
  { x: 6073, y: 765 },
  { x: 6143, y: 204 },
  { x: 6008, y: 758 },
  { x: 5985, y: 591 },
  { x: 6089, y: 208 },
  { x: 6010, y: 212 },
  { x: 5956, y: 413 },
  { x: 6028, y: 761 },
  { x: 6149, y: 192 },
  { x: 6032, y: 531 },
  { x: 6273, y: 206 },
  { x: 6040, y: 715 },
  { x: 6118, y: 530 },
  { x: 5986, y: 342 },
  { x: 6309, y: 147 },
  { x: 5949, y: 261 },
  { x: 5930, y: 366 },
  { x: 6056, y: 210 },
  { x: 6030, y: 382 },
  { x: 6039, y: 673 },
  { x: 6076, y: 187 },
  { x: 6034, y: 644 },
  { x: 5976, y: 444 },
  { x: 5983, y: 696 },
  { x: 5946, y: 697 },
  { x: 6099, y: 212 },
  { x: 5981, y: 685 },
  { x: 5947, y: 258 },
  { x: 5917, y: 325 },
  { x: 6171, y: 193 },
  { x: 6266, y: 224 },
  { x: 5922, y: 229 },
  { x: 6227, y: 744 },
  { x: 6147, y: 463 },
  { x: 6086, y: 144 },
  { x: 6130, y: 174 },
  { x: 6006, y: 740 },
  { x: 6237, y: 241 },
  { x: 5941, y: 181 },
  { x: 6004, y: 669 },
  { x: 6022, y: 683 },
  { x: 6060, y: 524 },
  { x: 6013, y: 282 },
  { x: 6162, y: 779 },
  { x: 5950, y: 399 },
  { x: 5908, y: 655 },
  { x: 5945, y: 665 },
  { x: 5954, y: 350 },
  { x: 5993, y: 180 },
  { x: 5956, y: 643 },
  { x: 5939, y: 706 },
  { x: 6172, y: 488 },
  { x: 6029, y: 309 },
  { x: 6025, y: 384 },
  { x: 5996, y: 196 },
  { x: 5970, y: 634 },
  { x: 6160, y: 737 },
  { x: 5914, y: 421 },
  { x: 6032, y: 242 },
  { x: 5933, y: 690 },
  { x: 6220, y: 798 },
  { x: 6202, y: 507 },
  { x: 6064, y: 171 },
  { x: 6217, y: 771 },
  { x: 6011, y: 452 },
  { x: 6141, y: 521 },
  { x: 6139, y: 158 },
  { x: 6184, y: 480 },
  { x: 6018, y: 165 },
  { x: 6152, y: 533 },
  { x: 6078, y: 789 },
  { x: 6085, y: 159 },
  { x: 6260, y: 234 },
  { x: 6188, y: 761 },
  { x: 6292, y: 225 },
  { x: 6231, y: 756 },
  { x: 5971, y: 238 },
  { x: 6035, y: 575 },
  { x: 6017, y: 629 },
  { x: 5999, y: 782 },
  { x: 6196, y: 226 },
  { x: 5926, y: 368 },
  { x: 5938, y: 430 },
  { x: 6127, y: 138 },
  { x: 6163, y: 243 },
  { x: 6200, y: 232 },
  { x: 6003, y: 279 },
  { x: 6154, y: 768 },
  { x: 5934, y: 206 },
  { x: 6023, y: 660 },
  { x: 6164, y: 766 },
  { x: 6096, y: 813 },
  { x: 6005, y: 289 },
  { x: 6031, y: 506 },
  { x: 6108, y: 728 },
  { x: 5979, y: 175 },
  { x: 6300, y: 780 },
  { x: 6089, y: 200 },
  { x: 5980, y: 150 },
  { x: 6195, y: 773 },
  { x: 6133, y: 464 },
  { x: 6159, y: 731 },
  { x: 5988, y: 395 },
  { x: 5997, y: 441 },
  { x: 6158, y: 203 },
  { x: 5952, y: 811 },
  { x: 6247, y: 471 },
  { x: 6001, y: 502 },
  { x: 6199, y: 477 },
  { x: 6168, y: 548 },
  { x: 6144, y: 510 },
  { x: 6989, y: 376 },
  { x: 6712, y: 166 },
  { x: 6979, y: 198 },
  { x: 6994, y: 168 },
  { x: 6972, y: 748 },
  { x: 6834, y: 781 },
  { x: 6574, y: 716 },
  { x: 6578, y: 408 },
  { x: 6567, y: 312 },
  { x: 6793, y: 793 },
  { x: 6983, y: 751 },
  { x: 6924, y: 444 },
  { x: 6993, y: 350 },
  { x: 6514, y: 354 },
  { x: 6894, y: 512 },
  { x: 6840, y: 213 },
  { x: 7023, y: 349 },
  { x: 6999, y: 811 },
  { x: 6702, y: 248 },
  { x: 6984, y: 779 },
  { x: 6747, y: 790 },
  { x: 7075, y: 288 },
  { x: 6762, y: 189 },
  { x: 7027, y: 348 },
  { x: 6981, y: 337 },
  { x: 6523, y: 438 },
  { x: 6797, y: 211 },
  { x: 6668, y: 188 },
  { x: 6817, y: 806 },
  { x: 6839, y: 782 },
  { x: 6595, y: 272 },
  { x: 6724, y: 817 },
  { x: 6558, y: 508 },
  { x: 6980, y: 293 },
  { x: 6710, y: 815 },
  { x: 6961, y: 240 },
  { x: 6931, y: 745 },
  { x: 6525, y: 448 },
  { x: 7028, y: 356 },
  { x: 6698, y: 292 },
  { x: 6742, y: 157 },
  { x: 6883, y: 794 },
  { x: 6960, y: 743 },
  { x: 6871, y: 174 },
  { x: 6636, y: 381 },
  { x: 6585, y: 667 },
  { x: 6722, y: 202 },
  { x: 6684, y: 772 },
  { x: 6564, y: 331 },
  { x: 6855, y: 509 },
  { x: 6670, y: 746 },
  { x: 6863, y: 437 },
  { x: 6756, y: 761 },
  { x: 6881, y: 507 },
  { x: 7068, y: 421 },
  { x: 6556, y: 657 },
  { x: 7018, y: 777 },
  { x: 6846, y: 187 },
  { x: 7046, y: 433 },
  { x: 6838, y: 732 },
  { x: 7064, y: 492 },
  { x: 6634, y: 780 },
  { x: 6909, y: 465 },
  { x: 6625, y: 742 },
  { x: 6821, y: 808 },
  { x: 6912, y: 531 },
  { x: 6565, y: 572 },
  { x: 6693, y: 778 },
  { x: 6623, y: 209 },
  { x: 6648, y: 590 },
  { x: 7053, y: 696 },
  { x: 6955, y: 417 },
  { x: 6620, y: 497 },
  { x: 6597, y: 449 },
  { x: 6892, y: 755 },
  { x: 7022, y: 309 },
  { x: 6577, y: 268 },
  { x: 7044, y: 706 },
  { x: 7037, y: 411 },
  { x: 6903, y: 472 },
  { x: 6935, y: 135 },
  { x: 6953, y: 221 },
  { x: 6592, y: 727 },
  { x: 6952, y: 294 },
  { x: 7033, y: 205 },
  { x: 6856, y: 783 },
  { x: 6690, y: 733 },
  { x: 6950, y: 302 },
  { x: 6547, y: 493 },
  { x: 6521, y: 486 },
  { x: 6619, y: 372 },
  { x: 6549, y: 342 },
  { x: 6576, y: 253 },
  { x: 7039, y: 161 },
  { x: 7042, y: 392 },
  { x: 6985, y: 416 },
  { x: 7020, y: 163 },
  { x: 6638, y: 569 },
  { x: 6646, y: 753 },
  { x: 6630, y: 476 },
  { x: 6976, y: 812 },
  { x: 6996, y: 474 },
  { x: 7025, y: 306 },
  { x: 7029, y: 212 },
  { x: 6705, y: 287 },
  { x: 6674, y: 185 },
  { x: 6829, y: 230 },
  { x: 6922, y: 200 },
  { x: 6988, y: 723 },
  { x: 7076, y: 387 },
  { x: 6621, y: 347 },
  { x: 6563, y: 338 },
  { x: 7079, y: 429 },
  { x: 6626, y: 409 },
  { x: 6940, y: 765 },
  { x: 6865, y: 237 },
  { x: 6641, y: 310 },
  { x: 6692, y: 740 },
  { x: 6744, y: 750 },
  { x: 6596, y: 281 },
  { x: 6904, y: 229 },
  { x: 7022, y: 222 },
  { x: 6633, y: 689 },
  { x: 7033, y: 174 },
  { x: 6883, y: 211 },
  { x: 7025, y: 456 },
  { x: 6801, y: 830 },
  { x: 6809, y: 457 },
  { x: 6513, y: 361 },
  { x: 6540, y: 323 },
  { x: 6891, y: 139 },
  { x: 7055, y: 687 },
  { x: 7023, y: 401 },
  { x: 7014, y: 467 },
  { x: 6592, y: 549 },
  { x: 6603, y: 504 },
  { x: 6665, y: 632 },
  { x: 6739, y: 253 },
  { x: 6969, y: 280 },
  { x: 7008, y: 327 },
  { x: 7037, y: 240 },
  { x: 7077, y: 365 },
  { x: 6632, y: 729 },
  { x: 6558, y: 340 },
  { x: 6782, y: 815 },
  { x: 6763, y: 730 },
  { x: 6800, y: 763 },
  { x: 6613, y: 274 },
  { x: 6497, y: 465 },
  { x: 6524, y: 394 },
  { x: 7034, y: 284 },
  { x: 6710, y: 721 },
  { x: 6975, y: 414 },
  { x: 6559, y: 428 },
  { x: 6947, y: 371 },
  { x: 7047, y: 739 },
  { x: 6797, y: 434 },
  { x: 6725, y: 787 },
  { x: 6854, y: 477 },
  { x: 7041, y: 432 },
  { x: 6701, y: 175 },
  { x: 6994, y: 149 },
  { x: 7076, y: 319 },
  { x: 6678, y: 218 },
  { x: 6721, y: 694 },
  { x: 6549, y: 408 },
  { x: 6988, y: 778 },
  { x: 6600, y: 746 },
  { x: 7011, y: 353 },
  { x: 6677, y: 166 },
  { x: 6563, y: 358 },
  { x: 6962, y: 783 },
  { x: 6533, y: 350 },
  { x: 6575, y: 711 },
  { x: 6779, y: 164 },
  { x: 6981, y: 732 },
  { x: 6863, y: 487 },
  { x: 6581, y: 543 },
  { x: 6970, y: 278 },
  { x: 6993, y: 312 },
  { x: 6808, y: 796 },
  { x: 6523, y: 622 },
  { x: 6653, y: 630 },
  { x: 6754, y: 802 },
  { x: 6525, y: 511 },
  { x: 6987, y: 760 },
  { x: 6565, y: 686 },
  { x: 6535, y: 572 },
  { x: 6841, y: 476 },
  { x: 6593, y: 693 },
  { x: 6979, y: 820 },
  { x: 6568, y: 509 },
  { x: 6704, y: 178 },
  { x: 6621, y: 433 },
  { x: 7029, y: 789 },
  { x: 7066, y: 737 },
  { x: 6628, y: 440 },
  { x: 6950, y: 241 },
  { x: 6976, y: 287 },
  { x: 6948, y: 136 },
  { x: 6921, y: 177 },
  { x: 6597, y: 466 },
  { x: 6977, y: 306 },
  { x: 6547, y: 614 },
  { x: 6980, y: 768 },
  { x: 6502, y: 399 },
  { x: 7030, y: 359 },
  { x: 7015, y: 168 },
  { x: 6875, y: 478 },
  { x: 6805, y: 148 },
  { x: 6957, y: 490 },
  { x: 6991, y: 459 },
  { x: 6661, y: 224 },
  { x: 6846, y: 749 },
  { x: 7054, y: 430 },
  { x: 6545, y: 292 },
  { x: 6727, y: 232 },
  { x: 6514, y: 422 },
  { x: 6651, y: 260 },
  { x: 7067, y: 237 },
  { x: 6640, y: 183 },
  { x: 6717, y: 709 },
  { x: 6624, y: 249 },
  { x: 6723, y: 219 },
  { x: 6985, y: 460 },
  { x: 6890, y: 508 },
  { x: 6771, y: 194 },
  { x: 6756, y: 749 },
  { x: 6897, y: 484 },
  { x: 6939, y: 190 },
  { x: 6984, y: 248 },
  { x: 7037, y: 276 },
  { x: 6927, y: 504 },
  { x: 6567, y: 582 },
  { x: 6936, y: 753 },
  { x: 6632, y: 291 },
  { x: 6968, y: 381 },
  { x: 6552, y: 447 },
  { x: 6587, y: 562 },
  { x: 6519, y: 576 },
  { x: 6852, y: 511 },
  { x: 6858, y: 732 },
  { x: 6848, y: 147 },
  { x: 7065, y: 772 },
  { x: 6669, y: 278 },
  { x: 6813, y: 432 },
  { x: 6561, y: 408 },
  { x: 7045, y: 430 },
  { x: 6976, y: 290 },
  { x: 6786, y: 195 },
  { x: 6588, y: 234 },
  { x: 7019, y: 418 },
  { x: 6530, y: 619 },
  { x: 6973, y: 397 },
  { x: 7057, y: 209 },
  { x: 6928, y: 182 },
  { x: 7046, y: 393 },
  { x: 6612, y: 211 },
  { x: 6615, y: 328 },
  { x: 6847, y: 804 },
  { x: 6540, y: 456 },
  { x: 6494, y: 482 },
  { x: 6826, y: 527 },
  { x: 6505, y: 395 },
  { x: 7047, y: 207 },
  { x: 6787, y: 820 },
  { x: 6614, y: 495 },
  { x: 6759, y: 228 },
  { x: 6596, y: 623 },
  { x: 7016, y: 508 },
  { x: 6896, y: 781 },
  { x: 6933, y: 191 },
  { x: 6795, y: 168 },
  { x: 7043, y: 688 },
  { x: 7063, y: 483 },
  { x: 6863, y: 138 },
  { x: 6731, y: 785 },
  { x: 7049, y: 755 },
  { x: 6998, y: 236 },
  { x: 6726, y: 152 },
  { x: 6637, y: 733 },
  { x: 6700, y: 173 },
  { x: 6621, y: 471 },
  { x: 6861, y: 793 },
  { x: 6765, y: 181 },
  { x: 6781, y: 164 },
  { x: 6598, y: 439 },
  { x: 6670, y: 641 },
  { x: 6963, y: 769 },
  { x: 6534, y: 610 },
  { x: 6774, y: 202 },
  { x: 6590, y: 354 },
  { x: 6622, y: 454 },
  { x: 6592, y: 680 },
  { x: 6597, y: 296 },
  { x: 6678, y: 657 },
  { x: 6965, y: 176 },
  { x: 6902, y: 170 },
  { x: 6663, y: 327 },
  { x: 6608, y: 434 },
  { x: 6892, y: 522 },
  { x: 6672, y: 651 },
  { x: 6525, y: 472 },
  { x: 7054, y: 254 },
  { x: 6809, y: 458 },
  { x: 6633, y: 717 },
  { x: 6560, y: 258 },
  { x: 7058, y: 525 },
  { x: 6648, y: 614 },
  { x: 6792, y: 233 },
  { x: 6960, y: 262 },
  { x: 6770, y: 151 },
  { x: 6935, y: 431 },
  { x: 7071, y: 196 },
  { x: 6946, y: 271 },
  { x: 6619, y: 553 },
  { x: 6906, y: 746 },
  { x: 6803, y: 809 },
  { x: 6542, y: 539 },
  { x: 6767, y: 748 },
  { x: 6880, y: 479 },
  { x: 6582, y: 719 },
  { x: 6904, y: 224 },
  { x: 7042, y: 744 },
  { x: 6947, y: 280 },
  { x: 6734, y: 218 },
  { x: 6543, y: 460 },
  { x: 6709, y: 747 },
  { x: 7050, y: 428 },
  { x: 6841, y: 766 },
  { x: 7069, y: 451 },
  { x: 6953, y: 192 },
  { x: 6711, y: 808 },
  { x: 7075, y: 760 },
  { x: 7048, y: 424 },
  { x: 6956, y: 407 },
  { x: 6559, y: 376 },
  { x: 6677, y: 240 },
  { x: 6623, y: 399 },
  { x: 7073, y: 316 },
  { x: 6678, y: 195 },
  { x: 6515, y: 586 },
  { x: 6542, y: 682 },
  { x: 6527, y: 616 },
  { x: 6863, y: 186 },
  { x: 7070, y: 323 },
  { x: 6961, y: 308 },
  { x: 6789, y: 183 },
  { x: 6667, y: 781 },
  { x: 6659, y: 689 },
  { x: 6715, y: 232 },
  { x: 6704, y: 810 },
  { x: 6815, y: 762 },
  { x: 6963, y: 754 },
  { x: 6929, y: 801 },
  { x: 7063, y: 422 },
  { x: 7010, y: 153 },
  { x: 6906, y: 218 },
  { x: 6879, y: 520 },
  { x: 6942, y: 490 },
  { x: 7066, y: 780 },
  { x: 6648, y: 271 },
  { x: 6857, y: 227 },
  { x: 6841, y: 487 },
  { x: 6573, y: 667 },
  { x: 6911, y: 472 },
  { x: 7067, y: 467 },
  { x: 6958, y: 363 },
  { x: 7049, y: 176 },
  { x: 6827, y: 528 },
  { x: 6749, y: 725 },
  { x: 6551, y: 608 },
  { x: 6598, y: 596 },
  { x: 6557, y: 511 },
  { x: 6838, y: 501 },
  { x: 7065, y: 518 },
  { x: 6673, y: 288 },
  { x: 6722, y: 265 },
  { x: 7043, y: 739 },
  { x: 6980, y: 718 },
  { x: 6650, y: 591 },
  { x: 6691, y: 665 },
  { x: 6550, y: 672 },
  { x: 6957, y: 437 },
  { x: 7055, y: 783 },
  { x: 6584, y: 342 },
  { x: 6962, y: 148 },
  { x: 6498, y: 421 },
  { x: 6575, y: 402 },
  { x: 6496, y: 543 },
  { x: 6620, y: 652 },
  { x: 6874, y: 444 },
  { x: 6985, y: 257 },
  { x: 6832, y: 479 },
  { x: 6945, y: 790 },
  { x: 6740, y: 828 },
  { x: 6574, y: 278 },
  { x: 6974, y: 266 },
  { x: 6842, y: 160 },
  { x: 6792, y: 129 },
  { x: 6686, y: 174 },
  { x: 6979, y: 453 },
  { x: 6593, y: 748 },
  { x: 6621, y: 263 },
  { x: 6628, y: 337 },
  { x: 7060, y: 468 },
  { x: 6603, y: 503 },
  { x: 7075, y: 301 },
  { x: 6723, y: 803 },
  { x: 6526, y: 550 },
  { x: 6607, y: 261 },
  { x: 6805, y: 772 },
  { x: 6769, y: 755 },
  { x: 6965, y: 175 },
  { x: 6851, y: 206 },
  { x: 7008, y: 381 },
  { x: 6954, y: 749 },
  { x: 6707, y: 733 },
  { x: 6565, y: 391 },
  { x: 6666, y: 731 },
  { x: 6521, y: 371 },
  { x: 6802, y: 241 },
  { x: 6814, y: 726 },
  { x: 6616, y: 255 },
  { x: 6713, y: 214 },
  { x: 6837, y: 785 },
  { x: 6558, y: 589 },
  { x: 6561, y: 663 },
  { x: 6594, y: 298 },
  { x: 6536, y: 333 },
  { x: 6889, y: 224 },
  { x: 7030, y: 328 },
  { x: 6614, y: 546 },
  { x: 7068, y: 294 },
  { x: 6562, y: 639 },
  { x: 6567, y: 684 },
  { x: 6556, y: 436 },
  { x: 6541, y: 303 },
  { x: 6767, y: 724 },
  { x: 7036, y: 283 },
  { x: 6690, y: 165 },
  { x: 6503, y: 411 },
  { x: 7078, y: 396 },
  { x: 6647, y: 279 },
  { x: 7016, y: 290 },
  { x: 6808, y: 735 },
  { x: 6782, y: 793 },
  { x: 6619, y: 461 },
  { x: 6617, y: 203 },
  { x: 6850, y: 831 },
  { x: 6881, y: 131 },
  { x: 6631, y: 426 },
  { x: 6818, y: 786 },
  { x: 6938, y: 449 },
  { x: 6592, y: 313 },
  { x: 6599, y: 445 },
  { x: 7075, y: 200 },
  { x: 7005, y: 750 },
  { x: 6944, y: 803 },
  { x: 6892, y: 526 },
  { x: 6520, y: 549 },
  { x: 6812, y: 812 },
  { x: 6676, y: 775 },
  { x: 7007, y: 779 },
  { x: 6671, y: 755 },
  { x: 6582, y: 566 },
  { x: 6565, y: 294 },
  { x: 6969, y: 725 },
  { x: 6930, y: 135 },
  { x: 6756, y: 230 },
  { x: 6814, y: 436 },
  { x: 6608, y: 752 },
  { x: 7009, y: 452 },
  { x: 6562, y: 539 },
  { x: 6850, y: 170 },
  { x: 6704, y: 265 },
  { x: 6972, y: 735 },
  { x: 6975, y: 731 },
  { x: 6625, y: 373 },
  { x: 6811, y: 234 },
  { x: 6861, y: 191 },
  { x: 6785, y: 762 },
  { x: 6891, y: 802 },
  { x: 6684, y: 707 },
  { x: 6599, y: 720 },
  { x: 6825, y: 513 },
  { x: 6714, y: 703 },
  { x: 6626, y: 606 },
  { x: 6999, y: 404 },
  { x: 7019, y: 781 },
  { x: 6617, y: 394 },
  { x: 7048, y: 400 },
  { x: 6560, y: 668 },
  { x: 6523, y: 322 },
  { x: 6591, y: 688 },
  { x: 6965, y: 224 },
  { x: 6776, y: 813 },
  { x: 6845, y: 729 },
  { x: 6534, y: 612 },
  { x: 6637, y: 396 },
  { x: 6728, y: 713 },
  { x: 6630, y: 319 },
  { x: 6513, y: 389 },
  { x: 6685, y: 312 },
  { x: 6849, y: 428 },
  { x: 7006, y: 444 },
  { x: 7013, y: 273 },
  { x: 6700, y: 706 },
  { x: 7063, y: 391 },
  { x: 6602, y: 737 },
  { x: 6757, y: 722 },
  { x: 6803, y: 732 },
  { x: 6946, y: 289 },
  { x: 6578, y: 412 },
  { x: 6525, y: 387 },
  { x: 6911, y: 152 },
  { x: 6957, y: 462 },
  { x: 6503, y: 458 },
  { x: 6799, y: 164 },
  { x: 6697, y: 243 },
  { x: 6628, y: 437 },
  { x: 6890, y: 464 },
  { x: 6778, y: 820 },
  { x: 7050, y: 427 },
  { x: 6584, y: 667 },
  { x: 6614, y: 434 },
  { x: 7066, y: 705 },
  { x: 6527, y: 475 },
  { x: 6548, y: 365 },
  { x: 7079, y: 214 },
  { x: 6934, y: 136 },
  { x: 6961, y: 486 },
  { x: 6899, y: 430 },
  { x: 7039, y: 180 },
  { x: 7043, y: 185 },
  { x: 6555, y: 281 },
  { x: 6821, y: 748 },
  { x: 7035, y: 459 },
  { x: 6490, y: 518 },
  { x: 6615, y: 369 },
  { x: 6970, y: 468 },
  { x: 7051, y: 209 },
  { x: 6828, y: 226 },
  { x: 6593, y: 271 },
  { x: 6687, y: 810 },
  { x: 6683, y: 708 },
  { x: 7073, y: 683 },
  { x: 7062, y: 401 },
  { x: 6771, y: 141 },
  { x: 7010, y: 478 },
  { x: 6820, y: 809 },
  { x: 6572, y: 658 },
  { x: 6652, y: 677 },
  { x: 6595, y: 698 },
  { x: 6860, y: 758 },
  { x: 6693, y: 252 },
  { x: 6607, y: 347 },
  { x: 6737, y: 176 },
  { x: 6758, y: 743 },
  { x: 6601, y: 583 },
  { x: 6809, y: 145 },
  { x: 6529, y: 521 },
  { x: 6971, y: 751 },
  { x: 6571, y: 579 },
  { x: 6791, y: 237 },
  { x: 6929, y: 774 },
  { x: 6509, y: 350 },
  { x: 6976, y: 500 },
  { x: 6985, y: 344 },
  { x: 6551, y: 354 },
  { x: 6752, y: 715 },
  { x: 7015, y: 401 },
  { x: 6516, y: 325 },
  { x: 7078, y: 337 },
  { x: 6926, y: 454 },
  { x: 6904, y: 504 },
  { x: 6824, y: 475 },
  { x: 6990, y: 478 },
  { x: 6717, y: 820 },
  { x: 6938, y: 167 },
  { x: 6626, y: 669 },
  { x: 6929, y: 507 },
  { x: 6793, y: 728 },
  { x: 6673, y: 758 },
  { x: 6525, y: 494 },
  { x: 6953, y: 813 },
  { x: 6605, y: 323 },
  { x: 7076, y: 217 },
  { x: 7025, y: 486 },
  { x: 6608, y: 624 },
  { x: 6592, y: 390 },
  { x: 6600, y: 457 },
  { x: 7043, y: 370 },
  { x: 6582, y: 369 },
  { x: 6853, y: 194 },
  { x: 6555, y: 402 },
  { x: 6627, y: 538 },
  { x: 6593, y: 666 },
  { x: 6616, y: 535 },
  { x: 6507, y: 381 },
  { x: 6587, y: 652 },
  { x: 6559, y: 676 },
  { x: 6942, y: 213 },
  { x: 6515, y: 529 },
  { x: 6538, y: 368 },
  { x: 6957, y: 165 },
  { x: 6965, y: 481 },
  { x: 6860, y: 232 },
  { x: 7074, y: 462 },
  { x: 6602, y: 288 },
  { x: 6517, y: 531 },
  { x: 6988, y: 168 },
  { x: 6862, y: 837 },
  { x: 6553, y: 610 },
  { x: 6952, y: 447 },
  { x: 6919, y: 734 },
  { x: 6979, y: 417 },
  { x: 6834, y: 735 },
  { x: 6705, y: 725 },
  { x: 6613, y: 604 },
  { x: 6897, y: 512 },
  { x: 6641, y: 692 },
  { x: 6635, y: 584 },
  { x: 6737, y: 783 },
  { x: 6930, y: 150 },
  { x: 6880, y: 823 },
  { x: 6666, y: 246 },
  { x: 6794, y: 142 },
  { x: 6586, y: 571 },
  { x: 6840, y: 763 },
  { x: 7016, y: 455 },
  { x: 6570, y: 387 },
  { x: 6736, y: 703 },
  { x: 6656, y: 781 },
  { x: 6956, y: 759 },
  { x: 6986, y: 362 },
  { x: 7066, y: 187 },
  { x: 6899, y: 511 },
  { x: 6776, y: 812 },
  { x: 6668, y: 233 },
  { x: 6565, y: 450 },
  { x: 6524, y: 646 },
  { x: 6975, y: 509 },
  { x: 6699, y: 673 },
  { x: 7001, y: 190 },
  { x: 6993, y: 385 },
  { x: 6928, y: 497 },
  { x: 7036, y: 250 },
  { x: 6636, y: 384 },
  { x: 6831, y: 791 },
  { x: 6667, y: 215 },
  { x: 7061, y: 295 },
  { x: 6918, y: 789 },
  { x: 6997, y: 214 },
  { x: 6513, y: 469 },
  { x: 6946, y: 797 },
  { x: 6585, y: 341 },
  { x: 6521, y: 518 },
  { x: 6621, y: 219 },
  { x: 6599, y: 249 },
  { x: 6629, y: 372 },
  { x: 6940, y: 269 },
  { x: 6628, y: 441 },
  { x: 6686, y: 700 },
  { x: 6790, y: 146 },
  { x: 7009, y: 313 },
  { x: 6788, y: 825 },
  { x: 7063, y: 324 },
  { x: 6569, y: 406 },
  { x: 7011, y: 453 },
  { x: 6660, y: 632 },
  { x: 6934, y: 198 },
  { x: 6654, y: 253 },
  { x: 7045, y: 708 },
  { x: 7021, y: 523 },
  { x: 6764, y: 192 },
  { x: 6850, y: 133 },
  { x: 6945, y: 248 },
  { x: 6642, y: 782 },
  { x: 6916, y: 749 },
  { x: 6563, y: 317 },
  { x: 7028, y: 707 },
  { x: 6653, y: 691 },
  { x: 6924, y: 230 },
  { x: 6652, y: 750 },
  { x: 6800, y: 475 },
  { x: 6607, y: 662 },
  { x: 6569, y: 414 },
  { x: 6597, y: 665 },
  { x: 6565, y: 678 },
  { x: 6657, y: 321 },
  { x: 6506, y: 381 },
  { x: 6583, y: 248 },
  { x: 6602, y: 548 },
  { x: 6519, y: 508 },
  { x: 6815, y: 801 },
  { x: 6759, y: 739 },
  { x: 6581, y: 356 },
  { x: 6608, y: 529 },
  { x: 6959, y: 144 },
  { x: 6546, y: 392 },
  { x: 6624, y: 664 },
  { x: 6589, y: 347 },
  { x: 6804, y: 524 },
  { x: 6829, y: 154 },
  { x: 6991, y: 715 },
  { x: 6917, y: 835 },
  { x: 6548, y: 487 },
  { x: 7047, y: 493 },
  { x: 6670, y: 711 },
  { x: 6986, y: 815 },
  { x: 6556, y: 394 },
  { x: 6972, y: 360 },
  { x: 6693, y: 214 },
  { x: 6840, y: 502 },
  { x: 6867, y: 760 },
  { x: 7019, y: 504 },
  { x: 6521, y: 513 },
  { x: 6707, y: 773 },
  { x: 6534, y: 629 },
  { x: 6898, y: 130 },
  { x: 6950, y: 283 },
  { x: 6975, y: 415 },
  { x: 7043, y: 223 },
  { x: 6620, y: 215 },
  { x: 6539, y: 471 },
  { x: 6684, y: 185 },
  { x: 6808, y: 180 },
  { x: 6958, y: 517 },
  { x: 6928, y: 171 },
  { x: 7004, y: 290 },
  { x: 6821, y: 155 },
  { x: 6605, y: 476 },
  { x: 6713, y: 805 },
  { x: 7039, y: 409 },
  { x: 6496, y: 425 },
  { x: 6641, y: 258 },
  { x: 6499, y: 382 },
  { x: 6591, y: 249 },
  { x: 6927, y: 827 },
  { x: 6535, y: 416 },
  { x: 6528, y: 600 },
  { x: 6660, y: 293 },
  { x: 6590, y: 348 },
  { x: 6813, y: 459 },
  { x: 6687, y: 297 },
  { x: 7061, y: 279 },
  { x: 6887, y: 229 },
  { x: 6544, y: 603 },
  { x: 6774, y: 778 },
  { x: 6582, y: 558 },
  { x: 7025, y: 265 },
  { x: 7024, y: 159 },
  { x: 6978, y: 188 },
  { x: 6943, y: 447 },
  { x: 6618, y: 688 },
  { x: 7068, y: 213 },
  { x: 6575, y: 444 },
  { x: 6957, y: 407 },
  { x: 6634, y: 182 },
  { x: 6847, y: 777 },
  { x: 7046, y: 768 },
  { x: 6712, y: 780 },
  { x: 6739, y: 720 },
  { x: 7057, y: 759 },
  { x: 6873, y: 173 },
  { x: 6921, y: 772 },
  { x: 6990, y: 247 },
  { x: 6870, y: 216 },
  { x: 6725, y: 164 },
  { x: 6752, y: 782 },
  { x: 6682, y: 197 },
  { x: 6633, y: 714 },
  { x: 6522, y: 335 },
  { x: 7044, y: 202 },
  { x: 7000, y: 362 },
  { x: 6912, y: 820 },
  { x: 6966, y: 267 },
  { x: 7014, y: 448 },
  { x: 6601, y: 226 },
  { x: 6629, y: 706 },
  { x: 6623, y: 385 },
  { x: 6580, y: 676 },
  { x: 7062, y: 681 },
  { x: 6586, y: 282 },
  { x: 7037, y: 266 },
  { x: 6594, y: 217 },
  { x: 6962, y: 383 },
  { x: 7012, y: 438 },
  { x: 6874, y: 442 },
  { x: 6793, y: 747 },
  { x: 6999, y: 252 },
  { x: 6852, y: 232 },
  { x: 6846, y: 792 },
  { x: 6931, y: 470 },
  { x: 6531, y: 592 },
  { x: 6654, y: 261 },
  { x: 6593, y: 483 },
  { x: 6576, y: 586 },
  { x: 6910, y: 490 },
  { x: 6640, y: 205 },
  { x: 6663, y: 177 },
  { x: 6897, y: 200 },
  { x: 7064, y: 774 },
  { x: 7006, y: 198 },
  { x: 6740, y: 137 },
  { x: 6644, y: 386 },
  { x: 6926, y: 466 },
  { x: 6530, y: 423 },
  { x: 6836, y: 218 },
  { x: 6645, y: 643 },
  { x: 7074, y: 184 },
  { x: 6560, y: 595 },
  { x: 6744, y: 174 },
  { x: 6678, y: 743 },
  { x: 6611, y: 425 },
  { x: 6627, y: 231 },
  { x: 6801, y: 500 },
  { x: 6882, y: 489 },
  { x: 6532, y: 432 },
  { x: 6534, y: 557 },
  { x: 7027, y: 769 },
  { x: 6995, y: 326 },
  { x: 6615, y: 587 },
  { x: 6788, y: 227 },
  { x: 6938, y: 246 },
  { x: 7011, y: 286 },
  { x: 6823, y: 149 },
  { x: 7009, y: 261 },
  { x: 6628, y: 524 },
  { x: 6587, y: 678 },
  { x: 6741, y: 727 },
  { x: 6749, y: 766 },
  { x: 6941, y: 398 },
  { x: 6908, y: 778 },
  { x: 6603, y: 613 },
  { x: 6960, y: 189 },
  { x: 6972, y: 203 },
  { x: 6577, y: 414 },
  { x: 6956, y: 488 },
  { x: 6834, y: 531 },
  { x: 6909, y: 798 },
  { x: 6547, y: 518 },
  { x: 7040, y: 706 },
  { x: 7026, y: 459 },
  { x: 7070, y: 305 },
  { x: 6910, y: 811 },
  { x: 7046, y: 271 },
  { x: 6816, y: 128 },
  { x: 7029, y: 724 },
  { x: 6946, y: 825 },
  { x: 6999, y: 434 },
  { x: 6529, y: 563 },
  { x: 6553, y: 503 },
  { x: 6632, y: 725 },
  { x: 6964, y: 461 },
  { x: 6925, y: 471 },
  { x: 6570, y: 401 },
  { x: 7050, y: 382 },
  { x: 6973, y: 399 },
  { x: 6509, y: 487 },
  { x: 7012, y: 317 },
  { x: 6636, y: 714 },
  { x: 7055, y: 237 },
  { x: 6588, y: 223 },
  { x: 7047, y: 254 },
  { x: 6839, y: 470 },
  { x: 6647, y: 210 },
  { x: 6838, y: 512 },
  { x: 6555, y: 529 },
  { x: 7045, y: 426 },
  { x: 6552, y: 417 },
  { x: 6645, y: 258 },
  { x: 6684, y: 232 },
  { x: 6575, y: 686 },
  { x: 6586, y: 475 },
  { x: 6993, y: 146 },
  { x: 6637, y: 283 },
  { x: 6673, y: 683 },
  { x: 7035, y: 173 },
  { x: 6918, y: 508 },
  { x: 6499, y: 506 },
  { x: 6514, y: 510 },
  { x: 6914, y: 453 },
  { x: 6980, y: 335 },
  { x: 6850, y: 806 },
  { x: 6967, y: 478 },
  { x: 6855, y: 465 },
  { x: 6656, y: 169 },
  { x: 6985, y: 235 },
  { x: 6735, y: 726 },
  { x: 6521, y: 402 },
  { x: 6535, y: 556 },
  { x: 7037, y: 718 },
  { x: 7076, y: 733 },
  { x: 6962, y: 812 },
  { x: 6607, y: 745 },
  { x: 6755, y: 818 },
  { x: 6652, y: 205 },
  { x: 6970, y: 243 },
  { x: 7020, y: 728 },
  { x: 6779, y: 147 },
  { x: 6724, y: 264 },
  { x: 6879, y: 837 },
  { x: 7052, y: 700 },
  { x: 6720, y: 732 },
  { x: 6597, y: 349 },
  { x: 6683, y: 672 },
  { x: 7066, y: 216 },
  { x: 7074, y: 774 },
  { x: 6642, y: 351 },
  { x: 7016, y: 463 },
  { x: 6644, y: 781 },
  { x: 6691, y: 674 },
  { x: 6490, y: 494 },
  { x: 7019, y: 219 },
  { x: 6991, y: 424 },
  { x: 6695, y: 268 },
  { x: 6573, y: 670 },
  { x: 6797, y: 141 },
  { x: 6557, y: 397 },
  { x: 6902, y: 768 },
  { x: 6927, y: 433 },
  { x: 6643, y: 346 },
  { x: 6660, y: 220 },
  { x: 6939, y: 155 },
  { x: 6654, y: 191 },
  { x: 6561, y: 625 },
  { x: 6546, y: 510 },
  { x: 7026, y: 172 },
  { x: 6705, y: 791 },
  { x: 6940, y: 509 },
  { x: 6837, y: 183 },
  { x: 6584, y: 670 },
  { x: 6593, y: 357 },
  { x: 6551, y: 327 },
  { x: 6840, y: 733 },
  { x: 6897, y: 497 },
  { x: 6636, y: 721 },
  { x: 6858, y: 439 },
  { x: 7018, y: 173 },
  { x: 7079, y: 313 },
  { x: 7044, y: 355 },
  { x: 6896, y: 426 },
  { x: 6641, y: 386 },
  { x: 6968, y: 485 },
  { x: 6933, y: 465 },
  { x: 6855, y: 808 },
  { x: 6531, y: 631 },
  { x: 6572, y: 401 },
  { x: 7027, y: 226 },
  { x: 6652, y: 330 },
  { x: 6680, y: 735 },
  { x: 6605, y: 545 },
  { x: 6793, y: 496 },
  { x: 6956, y: 436 },
  { x: 6648, y: 370 },
  { x: 6788, y: 479 },
  { x: 6529, y: 470 },
  { x: 6578, y: 540 },
  { x: 6602, y: 431 },
  { x: 6926, y: 178 },
  { x: 6511, y: 563 },
  { x: 6609, y: 584 },
  { x: 6620, y: 460 },
  { x: 6642, y: 618 },
  { x: 6988, y: 238 },
  { x: 6967, y: 756 },
  { x: 6626, y: 191 },
  { x: 7052, y: 711 },
  { x: 7041, y: 239 },
  { x: 6902, y: 448 },
  { x: 6615, y: 547 },
  { x: 6694, y: 288 },
  { x: 6588, y: 292 },
  { x: 6717, y: 249 },
  { x: 6740, y: 248 },
  { x: 6523, y: 312 },
  { x: 6585, y: 484 },
  { x: 6579, y: 314 },
  { x: 6829, y: 802 },
  { x: 6846, y: 452 },
  { x: 6557, y: 383 },
  { x: 6779, y: 220 },
  { x: 6746, y: 261 },
  { x: 6747, y: 831 },
  { x: 6899, y: 449 },
  { x: 6535, y: 655 },
  { x: 6624, y: 195 },
  { x: 7040, y: 473 },
  { x: 6727, y: 198 },
  { x: 6599, y: 225 },
  { x: 6651, y: 250 },
  { x: 6627, y: 684 },
  { x: 6983, y: 179 },
  { x: 6844, y: 476 },
  { x: 6545, y: 464 },
  { x: 7012, y: 333 },
  { x: 6805, y: 505 },
  { x: 7080, y: 727 },
  { x: 6619, y: 217 },
  { x: 6891, y: 147 },
  { x: 6595, y: 579 },
  { x: 6796, y: 835 },
  { x: 7030, y: 766 },
  { x: 6895, y: 829 },
  { x: 6993, y: 182 },
  { x: 6621, y: 221 },
  { x: 6662, y: 344 },
  { x: 6867, y: 770 },
  { x: 7017, y: 236 },
  { x: 6820, y: 759 },
  { x: 6606, y: 260 },
  { x: 7058, y: 495 },
  { x: 6733, y: 163 },
  { x: 6745, y: 245 },
  { x: 6597, y: 367 },
  { x: 6600, y: 548 },
  { x: 7008, y: 360 },
  { x: 6972, y: 285 },
  { x: 7047, y: 767 },
  { x: 6594, y: 240 },
  { x: 6568, y: 657 },
  { x: 7070, y: 693 },
  { x: 6979, y: 818 },
  { x: 6558, y: 529 },
  { x: 6826, y: 135 },
  { x: 6526, y: 331 },
  { x: 6936, y: 801 },
  { x: 6730, y: 745 },
  { x: 6797, y: 169 },
  { x: 6758, y: 141 },
  { x: 7019, y: 753 },
  { x: 6859, y: 209 },
  { x: 6941, y: 263 },
  { x: 6773, y: 765 },
  { x: 6543, y: 415 },
  { x: 6939, y: 432 },
  { x: 6512, y: 478 },
  { x: 6843, y: 204 },
  { x: 7014, y: 750 },
  { x: 6708, y: 758 },
  { x: 7007, y: 366 },
  { x: 6789, y: 208 },
  { x: 7042, y: 293 },
  { x: 6710, y: 212 },
  { x: 6728, y: 761 },
  { x: 6849, y: 192 },
  { x: 6997, y: 274 },
  { x: 6574, y: 491 },
  { x: 6973, y: 206 },
  { x: 6564, y: 618 },
  { x: 6740, y: 715 },
  { x: 6981, y: 431 },
  { x: 6818, y: 530 },
  { x: 7071, y: 393 },
  { x: 6532, y: 528 },
  { x: 6579, y: 540 },
  { x: 6649, y: 261 },
  { x: 6630, y: 366 },
  { x: 6756, y: 210 },
  { x: 7061, y: 336 },
  { x: 7015, y: 710 },
  { x: 6997, y: 522 },
  { x: 6776, y: 187 },
  { x: 6939, y: 259 },
  { x: 6534, y: 297 },
  { x: 6959, y: 349 },
  { x: 6566, y: 412 },
  { x: 6683, y: 696 },
  { x: 7047, y: 188 },
  { x: 6646, y: 697 },
  { x: 6799, y: 212 },
  { x: 6980, y: 531 },
  { x: 6681, y: 685 },
  { x: 6647, y: 258 },
  { x: 6571, y: 684 },
  { x: 6617, y: 325 },
  { x: 6871, y: 193 },
  { x: 6966, y: 224 },
  { x: 6622, y: 229 },
  { x: 7023, y: 269 },
  { x: 6927, y: 744 },
  { x: 7014, y: 389 },
  { x: 6847, y: 463 },
  { x: 6786, y: 144 },
  { x: 6830, y: 174 },
  { x: 6706, y: 740 },
  { x: 6937, y: 241 },
  { x: 6641, y: 181 },
  { x: 7073, y: 270 },
  { x: 6713, y: 282 },
  { x: 6862, y: 779 },
  { x: 6608, y: 655 },
  { x: 6962, y: 434 },
  { x: 7028, y: 208 },
  { x: 6645, y: 665 },
  { x: 7052, y: 167 },
  { x: 7003, y: 313 },
  { x: 6654, y: 350 },
  { x: 7005, y: 286 },
  { x: 6693, y: 180 },
  { x: 6541, y: 437 },
  { x: 6656, y: 643 },
  { x: 6639, y: 706 },
  { x: 6872, y: 488 },
  { x: 6696, y: 196 },
  { x: 6670, y: 634 },
  { x: 6860, y: 737 },
  { x: 6614, y: 421 },
  { x: 7039, y: 201 },
  { x: 6569, y: 348 },
  { x: 6587, y: 440 },
  { x: 7064, y: 674 },
  { x: 6732, y: 242 },
  { x: 6633, y: 690 },
  { x: 6920, y: 798 },
  { x: 6592, y: 487 },
  { x: 6902, y: 507 },
  { x: 6764, y: 171 },
  { x: 7049, y: 205 },
  { x: 6917, y: 771 },
  { x: 6983, y: 505 },
  { x: 6970, y: 272 },
  { x: 6841, y: 521 },
  { x: 6839, y: 158 },
  { x: 7058, y: 361 },
  { x: 6884, y: 480 },
  { x: 6718, y: 165 },
  { x: 6509, y: 595 },
  { x: 6778, y: 789 },
  { x: 6785, y: 159 },
  { x: 6960, y: 234 },
  { x: 6888, y: 761 },
  { x: 6593, y: 381 },
  { x: 6565, y: 550 },
  { x: 6501, y: 413 },
  { x: 6992, y: 225 },
  { x: 6931, y: 756 },
  { x: 6671, y: 238 },
  { x: 6699, y: 782 },
  { x: 6996, y: 511 },
  { x: 6896, y: 226 },
  { x: 6626, y: 368 },
  { x: 6827, y: 138 },
  { x: 6900, y: 232 },
  { x: 6703, y: 279 },
  { x: 7046, y: 466 },
  { x: 6854, y: 768 },
  { x: 6599, y: 514 },
  { x: 6976, y: 717 },
  { x: 6634, y: 206 },
  { x: 6864, y: 766 },
  { x: 6766, y: 833 },
  { x: 7074, y: 450 },
  { x: 6573, y: 461 },
  { x: 6600, y: 315 },
  { x: 6796, y: 813 },
  { x: 6705, y: 289 },
  { x: 6808, y: 728 },
  { x: 6679, y: 175 },
  { x: 7000, y: 780 },
  { x: 6499, y: 469 },
  { x: 6594, y: 492 },
  { x: 6789, y: 200 },
  { x: 6514, y: 358 },
  { x: 6895, y: 773 },
  { x: 6984, y: 291 },
  { x: 6833, y: 464 },
  { x: 6859, y: 731 },
  { x: 6858, y: 203 },
  { x: 6947, y: 471 },
  { x: 6899, y: 477 },
  { x: 6844, y: 510 },
  { x: 7373, y: 549 },
  { x: 7412, y: 166 },
  { x: 7672, y: 748 },
  { x: 7534, y: 781 },
  { x: 7441, y: 273 },
  { x: 7493, y: 793 },
  { x: 7683, y: 751 },
  { x: 7403, y: 525 },
  { x: 7624, y: 444 },
  { x: 7594, y: 512 },
  { x: 7430, y: 553 },
  { x: 7699, y: 811 },
  { x: 7402, y: 248 },
  { x: 7684, y: 779 },
  { x: 7447, y: 790 },
  { x: 7463, y: 500 },
  { x: 7462, y: 189 },
  { x: 7479, y: 457 },
  { x: 7368, y: 188 },
  { x: 7517, y: 806 },
  { x: 7539, y: 782 },
  { x: 7452, y: 516 },
  { x: 7424, y: 817 },
  { x: 7364, y: 532 },
  { x: 7410, y: 815 },
  { x: 7631, y: 745 },
  { x: 7398, y: 292 },
  { x: 7442, y: 157 },
  { x: 7357, y: 514 },
  { x: 7414, y: 426 },
  { x: 7583, y: 794 },
  { x: 7660, y: 743 },
  { x: 7336, y: 381 },
  { x: 7422, y: 202 },
  { x: 7384, y: 772 },
  { x: 7358, y: 467 },
  { x: 7555, y: 509 },
  { x: 7370, y: 746 },
  { x: 7563, y: 437 },
  { x: 7456, y: 761 },
  { x: 7434, y: 319 },
  { x: 7581, y: 507 },
  { x: 7538, y: 732 },
  { x: 7334, y: 780 },
  { x: 7609, y: 465 },
  { x: 7325, y: 742 },
  { x: 7521, y: 808 },
  { x: 7393, y: 778 },
  { x: 7323, y: 209 },
  { x: 7347, y: 483 },
  { x: 7348, y: 590 },
  { x: 7438, y: 670 },
  { x: 7655, y: 417 },
  { x: 7592, y: 755 },
  { x: 7445, y: 606 },
  { x: 7603, y: 472 },
  { x: 7354, y: 403 },
  { x: 7556, y: 783 },
  { x: 7390, y: 733 },
  { x: 7439, y: 633 },
  { x: 7454, y: 290 },
  { x: 7401, y: 136 },
  { x: 7352, y: 162 },
  { x: 7381, y: 820 },
  { x: 7426, y: 299 },
  { x: 7461, y: 424 },
  { x: 7338, y: 569 },
  { x: 7371, y: 600 },
  { x: 7346, y: 753 },
  { x: 7330, y: 476 },
  { x: 7404, y: 488 },
  { x: 7676, y: 812 },
  { x: 7696, y: 474 },
  { x: 7405, y: 287 },
  { x: 7374, y: 185 },
  { x: 7688, y: 723 },
  { x: 7389, y: 341 },
  { x: 7372, y: 480 },
  { x: 7326, y: 409 },
  { x: 7640, y: 765 },
  { x: 7341, y: 310 },
  { x: 7392, y: 740 },
  { x: 7444, y: 750 },
  { x: 7325, y: 793 },
  { x: 7333, y: 689 },
  { x: 7354, y: 506 },
  { x: 7501, y: 830 },
  { x: 7509, y: 457 },
  { x: 7445, y: 567 },
  { x: 7349, y: 463 },
  { x: 7365, y: 632 },
  { x: 7439, y: 253 },
  { x: 7435, y: 418 },
  { x: 7446, y: 550 },
  { x: 7332, y: 729 },
  { x: 7482, y: 815 },
  { x: 7463, y: 730 },
  { x: 7500, y: 763 },
  { x: 7360, y: 607 },
  { x: 7374, y: 539 },
  { x: 7448, y: 411 },
  { x: 7410, y: 721 },
  { x: 7497, y: 434 },
  { x: 7355, y: 573 },
  { x: 7425, y: 787 },
  { x: 7554, y: 477 },
  { x: 7401, y: 175 },
  { x: 7403, y: 444 },
  { x: 7389, y: 439 },
  { x: 7378, y: 218 },
  { x: 7421, y: 694 },
  { x: 7372, y: 551 },
  { x: 7688, y: 778 },
  { x: 7380, y: 526 },
  { x: 7377, y: 166 },
  { x: 7662, y: 783 },
  { x: 7681, y: 732 },
  { x: 7563, y: 487 },
  { x: 7379, y: 559 },
  { x: 7508, y: 796 },
  { x: 7353, y: 630 },
  { x: 7454, y: 802 },
  { x: 7687, y: 760 },
  { x: 7397, y: 580 },
  { x: 7541, y: 476 },
  { x: 7679, y: 820 },
  { x: 7404, y: 178 },
  { x: 7391, y: 424 },
  { x: 7370, y: 555 },
  { x: 7328, y: 440 },
  { x: 7398, y: 364 },
  { x: 7680, y: 768 },
  { x: 7575, y: 478 },
  { x: 7657, y: 490 },
  { x: 7691, y: 459 },
  { x: 7447, y: 558 },
  { x: 7350, y: 521 },
  { x: 7387, y: 507 },
  { x: 7361, y: 224 },
  { x: 7546, y: 749 },
  { x: 7427, y: 232 },
  { x: 7351, y: 260 },
  { x: 7340, y: 183 },
  { x: 7417, y: 709 },
  { x: 7324, y: 249 },
  { x: 7423, y: 219 },
  { x: 7685, y: 460 },
  { x: 7590, y: 508 },
  { x: 7456, y: 749 },
  { x: 7597, y: 484 },
  { x: 7421, y: 548 },
  { x: 7449, y: 699 },
  { x: 7627, y: 504 },
  { x: 7427, y: 572 },
  { x: 7636, y: 753 },
  { x: 7394, y: 662 },
  { x: 7332, y: 291 },
  { x: 7425, y: 347 },
  { x: 7341, y: 171 },
  { x: 7552, y: 511 },
  { x: 7558, y: 732 },
  { x: 7369, y: 278 },
  { x: 7513, y: 432 },
  { x: 7441, y: 655 },
  { x: 7359, y: 806 },
  { x: 7547, y: 804 },
  { x: 7358, y: 368 },
  { x: 7526, y: 527 },
  { x: 7487, y: 820 },
  { x: 7351, y: 415 },
  { x: 7398, y: 378 },
  { x: 7459, y: 228 },
  { x: 7596, y: 781 },
  { x: 7396, y: 628 },
  { x: 7420, y: 520 },
  { x: 7431, y: 785 },
  { x: 7430, y: 405 },
  { x: 7426, y: 152 },
  { x: 7337, y: 733 },
  { x: 7400, y: 173 },
  { x: 7407, y: 353 },
  { x: 7561, y: 793 },
  { x: 7389, y: 643 },
  { x: 7410, y: 367 },
  { x: 7461, y: 493 },
  { x: 7370, y: 641 },
  { x: 7663, y: 769 },
  { x: 7378, y: 657 },
  { x: 7458, y: 633 },
  { x: 7363, y: 327 },
  { x: 7592, y: 522 },
  { x: 7372, y: 651 },
  { x: 7509, y: 458 },
  { x: 7333, y: 717 },
  { x: 7462, y: 575 },
  { x: 7348, y: 614 },
  { x: 7635, y: 431 },
  { x: 7606, y: 746 },
  { x: 7482, y: 485 },
  { x: 7503, y: 809 },
  { x: 7467, y: 748 },
  { x: 7580, y: 479 },
  { x: 7490, y: 441 },
  { x: 7434, y: 218 },
  { x: 7417, y: 427 },
  { x: 7383, y: 591 },
  { x: 7409, y: 747 },
  { x: 7445, y: 300 },
  { x: 7454, y: 414 },
  { x: 7404, y: 598 },
  { x: 7541, y: 766 },
  { x: 7411, y: 808 },
  { x: 7439, y: 349 },
  { x: 7453, y: 384 },
  { x: 7377, y: 240 },
  { x: 7323, y: 399 },
  { x: 7378, y: 195 },
  { x: 7384, y: 553 },
  { x: 7367, y: 781 },
  { x: 7359, y: 689 },
  { x: 7415, y: 232 },
  { x: 7404, y: 810 },
  { x: 7515, y: 762 },
  { x: 7663, y: 754 },
  { x: 7456, y: 525 },
  { x: 7629, y: 801 },
  { x: 7579, y: 520 },
  { x: 7642, y: 490 },
  { x: 7348, y: 271 },
  { x: 7541, y: 487 },
  { x: 7461, y: 390 },
  { x: 7611, y: 472 },
  { x: 7364, y: 806 },
  { x: 7527, y: 528 },
  { x: 7402, y: 557 },
  { x: 7449, y: 725 },
  { x: 7419, y: 406 },
  { x: 7362, y: 613 },
  { x: 7538, y: 501 },
  { x: 7373, y: 288 },
  { x: 7422, y: 265 },
  { x: 7680, y: 718 },
  { x: 7350, y: 591 },
  { x: 7391, y: 665 },
  { x: 7657, y: 437 },
  { x: 7388, y: 394 },
  { x: 7574, y: 444 },
  { x: 7532, y: 479 },
  { x: 7376, y: 561 },
  { x: 7400, y: 140 },
  { x: 7394, y: 478 },
  { x: 7393, y: 473 },
  { x: 7645, y: 790 },
  { x: 7440, y: 828 },
  { x: 7383, y: 477 },
  { x: 7363, y: 355 },
  { x: 7361, y: 552 },
  { x: 7386, y: 174 },
  { x: 7679, y: 453 },
  { x: 7328, y: 337 },
  { x: 7423, y: 803 },
  { x: 7505, y: 772 },
  { x: 7469, y: 755 },
  { x: 7654, y: 749 },
  { x: 7412, y: 605 },
  { x: 7407, y: 733 },
  { x: 7366, y: 731 },
  { x: 7453, y: 531 },
  { x: 7426, y: 408 },
  { x: 7401, y: 537 },
  { x: 7514, y: 726 },
  { x: 7479, y: 505 },
  { x: 7413, y: 214 },
  { x: 7537, y: 785 },
  { x: 7608, y: 419 },
  { x: 7405, y: 452 },
  { x: 7406, y: 582 },
  { x: 7403, y: 563 },
  { x: 7467, y: 724 },
  { x: 7390, y: 165 },
  { x: 7369, y: 592 },
  { x: 7347, y: 279 },
  { x: 7508, y: 735 },
  { x: 7482, y: 793 },
  { x: 7385, y: 818 },
  { x: 7331, y: 426 },
  { x: 7518, y: 786 },
  { x: 7457, y: 620 },
  { x: 7638, y: 449 },
  { x: 7368, y: 146 },
  { x: 7537, y: 724 },
  { x: 7705, y: 750 },
  { x: 7431, y: 442 },
  { x: 7644, y: 803 },
  { x: 7592, y: 526 },
  { x: 7434, y: 399 },
  { x: 7512, y: 812 },
  { x: 7420, y: 426 },
  { x: 7376, y: 775 },
  { x: 7707, y: 779 },
  { x: 7371, y: 755 },
  { x: 7375, y: 582 },
  { x: 7358, y: 148 },
  { x: 7669, y: 725 },
  { x: 7456, y: 230 },
  { x: 7514, y: 436 },
  { x: 7349, y: 515 },
  { x: 7404, y: 265 },
  { x: 7388, y: 332 },
  { x: 7672, y: 735 },
  { x: 7675, y: 731 },
  { x: 7325, y: 373 },
  { x: 7485, y: 762 },
  { x: 7483, y: 467 },
  { x: 7591, y: 802 },
  { x: 7384, y: 707 },
  { x: 7525, y: 513 },
  { x: 7414, y: 703 },
  { x: 7462, y: 588 },
  { x: 7326, y: 606 },
  { x: 7476, y: 813 },
  { x: 7545, y: 729 },
  { x: 7337, y: 396 },
  { x: 7428, y: 713 },
  { x: 7330, y: 319 },
  { x: 7385, y: 312 },
  { x: 7549, y: 428 },
  { x: 7400, y: 706 },
  { x: 7450, y: 424 },
  { x: 7459, y: 453 },
  { x: 7457, y: 722 },
  { x: 7503, y: 732 },
  { x: 7407, y: 457 },
  { x: 7461, y: 388 },
  { x: 7657, y: 462 },
  { x: 7370, y: 477 },
  { x: 7374, y: 441 },
  { x: 7397, y: 243 },
  { x: 7328, y: 437 },
  { x: 7449, y: 502 },
  { x: 7590, y: 464 },
  { x: 7364, y: 411 },
  { x: 7439, y: 393 },
  { x: 7478, y: 820 },
  { x: 7335, y: 789 },
  { x: 7661, y: 486 },
  { x: 7599, y: 430 },
  { x: 7394, y: 139 },
  { x: 7381, y: 367 },
  { x: 7521, y: 748 },
  { x: 7670, y: 468 },
  { x: 7387, y: 810 },
  { x: 7383, y: 708 },
  { x: 7380, y: 586 },
  { x: 7520, y: 809 },
  { x: 7352, y: 677 },
  { x: 7378, y: 525 },
  { x: 7560, y: 758 },
  { x: 7393, y: 252 },
  { x: 7437, y: 176 },
  { x: 7361, y: 448 },
  { x: 7515, y: 718 },
  { x: 7424, y: 830 },
  { x: 7458, y: 743 },
  { x: 7671, y: 751 },
  { x: 7396, y: 318 },
  { x: 7629, y: 774 },
  { x: 7676, y: 500 },
  { x: 7452, y: 455 },
  { x: 7442, y: 687 },
  { x: 7474, y: 527 },
  { x: 7452, y: 715 },
  { x: 7489, y: 434 },
  { x: 7385, y: 438 },
  { x: 7626, y: 454 },
  { x: 7604, y: 504 },
  { x: 7524, y: 475 },
  { x: 7690, y: 478 },
  { x: 7417, y: 820 },
  { x: 7326, y: 669 },
  { x: 7629, y: 507 },
  { x: 7428, y: 665 },
  { x: 7397, y: 367 },
  { x: 7493, y: 728 },
  { x: 7432, y: 598 },
  { x: 7373, y: 758 },
  { x: 7653, y: 813 },
  { x: 7419, y: 321 },
  { x: 7327, y: 538 },
  { x: 7665, y: 481 },
  { x: 7433, y: 695 },
  { x: 7652, y: 447 },
  { x: 7619, y: 734 },
  { x: 7679, y: 417 },
  { x: 7534, y: 735 },
  { x: 7405, y: 725 },
  { x: 7597, y: 512 },
  { x: 7410, y: 425 },
  { x: 7341, y: 692 },
  { x: 7596, y: 424 },
  { x: 7335, y: 584 },
  { x: 7390, y: 400 },
  { x: 7437, y: 783 },
  { x: 7580, y: 823 },
  { x: 7379, y: 592 },
  { x: 7366, y: 246 },
  { x: 7540, y: 763 },
  { x: 7389, y: 645 },
  { x: 7451, y: 285 },
  { x: 7436, y: 703 },
  { x: 7356, y: 781 },
  { x: 7485, y: 471 },
  { x: 7656, y: 759 },
  { x: 7459, y: 702 },
  { x: 7384, y: 827 },
  { x: 7599, y: 511 },
  { x: 7476, y: 812 },
  { x: 7368, y: 233 },
  { x: 7675, y: 509 },
  { x: 7399, y: 673 },
  { x: 7457, y: 634 },
  { x: 7628, y: 497 },
  { x: 7336, y: 384 },
  { x: 7531, y: 791 },
  { x: 7484, y: 458 },
  { x: 7367, y: 215 },
  { x: 7618, y: 789 },
  { x: 7375, y: 396 },
  { x: 7646, y: 797 },
  { x: 7349, y: 828 },
  { x: 7329, y: 372 },
  { x: 7328, y: 441 },
  { x: 7386, y: 700 },
  { x: 7488, y: 825 },
  { x: 7406, y: 136 },
  { x: 7360, y: 632 },
  { x: 7354, y: 253 },
  { x: 7434, y: 623 },
  { x: 7412, y: 651 },
  { x: 7352, y: 557 },
  { x: 7331, y: 793 },
  { x: 7342, y: 782 },
  { x: 7376, y: 463 },
  { x: 7616, y: 749 },
  { x: 7353, y: 691 },
  { x: 7352, y: 750 },
  { x: 7500, y: 475 },
  { x: 7442, y: 636 },
  { x: 7357, y: 321 },
  { x: 7435, y: 503 },
  { x: 7515, y: 801 },
  { x: 7670, y: 829 },
  { x: 7459, y: 739 },
  { x: 7324, y: 664 },
  { x: 7434, y: 663 },
  { x: 7504, y: 524 },
  { x: 7381, y: 401 },
  { x: 7370, y: 711 },
  { x: 7350, y: 403 },
  { x: 7686, y: 815 },
  { x: 7393, y: 214 },
  { x: 7540, y: 502 },
  { x: 7567, y: 760 },
  { x: 7431, y: 276 },
  { x: 7407, y: 773 },
  { x: 7453, y: 441 },
  { x: 7433, y: 656 },
  { x: 7335, y: 480 },
  { x: 7428, y: 351 },
  { x: 7476, y: 460 },
  { x: 7384, y: 185 },
  { x: 7658, y: 517 },
  { x: 7413, y: 805 },
  { x: 7341, y: 258 },
  { x: 7627, y: 827 },
  { x: 7360, y: 293 },
  { x: 7432, y: 350 },
  { x: 7513, y: 459 },
  { x: 7387, y: 297 },
  { x: 7474, y: 778 },
  { x: 7411, y: 638 },
  { x: 7643, y: 447 },
  { x: 7418, y: 342 },
  { x: 7334, y: 182 },
  { x: 7547, y: 777 },
  { x: 7412, y: 780 },
  { x: 7439, y: 720 },
  { x: 7460, y: 598 },
  { x: 7621, y: 772 },
  { x: 7425, y: 164 },
  { x: 7452, y: 782 },
  { x: 7382, y: 197 },
  { x: 7333, y: 714 },
  { x: 7353, y: 528 },
  { x: 7612, y: 820 },
  { x: 7376, y: 329 },
  { x: 7339, y: 160 },
  { x: 7475, y: 473 },
  { x: 7329, y: 706 },
  { x: 7323, y: 385 },
  { x: 7379, y: 501 },
  { x: 7351, y: 405 },
  { x: 7574, y: 442 },
  { x: 7493, y: 747 },
  { x: 7479, y: 443 },
  { x: 7546, y: 792 },
  { x: 7631, y: 470 },
  { x: 7354, y: 261 },
  { x: 7610, y: 490 },
  { x: 7421, y: 570 },
  { x: 7340, y: 205 },
  { x: 7374, y: 436 },
  { x: 7363, y: 177 },
  { x: 7344, y: 386 },
  { x: 7372, y: 428 },
  { x: 7626, y: 466 },
  { x: 7437, y: 687 },
  { x: 7345, y: 643 },
  { x: 7444, y: 174 },
  { x: 7378, y: 743 },
  { x: 7390, y: 630 },
  { x: 7327, y: 231 },
  { x: 7501, y: 500 },
  { x: 7582, y: 489 },
  { x: 7425, y: 343 },
  { x: 7453, y: 562 },
  { x: 7328, y: 524 },
  { x: 7441, y: 727 },
  { x: 7437, y: 697 },
  { x: 7449, y: 766 },
  { x: 7468, y: 479 },
  { x: 7608, y: 778 },
  { x: 7656, y: 488 },
  { x: 7401, y: 464 },
  { x: 7609, y: 798 },
  { x: 7408, y: 374 },
  { x: 7610, y: 811 },
  { x: 7405, y: 618 },
  { x: 7418, y: 344 },
  { x: 7646, y: 825 },
  { x: 7329, y: 150 },
  { x: 7332, y: 725 },
  { x: 7664, y: 461 },
  { x: 7625, y: 471 },
  { x: 7558, y: 418 },
  { x: 7353, y: 499 },
  { x: 7336, y: 714 },
  { x: 7457, y: 310 },
  { x: 7539, y: 470 },
  { x: 7347, y: 210 },
  { x: 7538, y: 512 },
  { x: 7456, y: 285 },
  { x: 7345, y: 258 },
  { x: 7362, y: 609 },
  { x: 7422, y: 667 },
  { x: 7384, y: 232 },
  { x: 7397, y: 441 },
  { x: 7337, y: 283 },
  { x: 7373, y: 683 },
  { x: 7618, y: 508 },
  { x: 7614, y: 453 },
  { x: 7550, y: 806 },
  { x: 7667, y: 478 },
  { x: 7393, y: 405 },
  { x: 7555, y: 465 },
  { x: 7356, y: 169 },
  { x: 7435, y: 726 },
  { x: 7662, y: 812 },
  { x: 7455, y: 818 },
  { x: 7352, y: 205 },
  { x: 7424, y: 264 },
  { x: 7399, y: 422 },
  { x: 7420, y: 732 },
  { x: 7383, y: 672 },
  { x: 7398, y: 602 },
  { x: 7342, y: 351 },
  { x: 7344, y: 781 },
  { x: 7364, y: 570 },
  { x: 7391, y: 674 },
  { x: 7470, y: 501 },
  { x: 7372, y: 591 },
  { x: 7395, y: 268 },
  { x: 7417, y: 391 },
  { x: 7602, y: 768 },
  { x: 7627, y: 433 },
  { x: 7343, y: 346 },
  { x: 7443, y: 652 },
  { x: 7360, y: 220 },
  { x: 7374, y: 585 },
  { x: 7354, y: 191 },
  { x: 7405, y: 791 },
  { x: 7640, y: 509 },
  { x: 7420, y: 430 },
  { x: 7392, y: 409 },
  { x: 7540, y: 733 },
  { x: 7597, y: 497 },
  { x: 7336, y: 721 },
  { x: 7558, y: 439 },
  { x: 7596, y: 426 },
  { x: 7341, y: 386 },
  { x: 7668, y: 485 },
  { x: 7633, y: 465 },
  { x: 7555, y: 808 },
  { x: 7396, y: 634 },
  { x: 7397, y: 410 },
  { x: 7352, y: 330 },
  { x: 7380, y: 735 },
  { x: 7493, y: 496 },
  { x: 7335, y: 819 },
  { x: 7656, y: 436 },
  { x: 7393, y: 454 },
  { x: 7562, y: 423 },
  { x: 7348, y: 370 },
  { x: 7333, y: 787 },
  { x: 7386, y: 398 },
  { x: 7488, y: 479 },
  { x: 7388, y: 378 },
  { x: 7342, y: 618 },
  { x: 7667, y: 756 },
  { x: 7326, y: 191 },
  { x: 7602, y: 448 },
  { x: 7394, y: 288 },
  { x: 7431, y: 592 },
  { x: 7417, y: 249 },
  { x: 7440, y: 248 },
  { x: 7529, y: 802 },
  { x: 7546, y: 452 },
  { x: 7446, y: 261 },
  { x: 7599, y: 449 },
  { x: 7324, y: 195 },
  { x: 7427, y: 198 },
  { x: 7351, y: 250 },
  { x: 7327, y: 684 },
  { x: 7544, y: 476 },
  { x: 7434, y: 501 },
  { x: 7413, y: 601 },
  { x: 7343, y: 150 },
  { x: 7505, y: 505 },
  { x: 7412, y: 304 },
  { x: 7595, y: 829 },
  { x: 7436, y: 508 },
  { x: 7362, y: 344 },
  { x: 7567, y: 770 },
  { x: 7520, y: 759 },
  { x: 7444, y: 537 },
  { x: 7433, y: 163 },
  { x: 7445, y: 245 },
  { x: 7375, y: 416 },
  { x: 7409, y: 520 },
  { x: 7415, y: 610 },
  { x: 7679, y: 818 },
  { x: 7376, y: 442 },
  { x: 7636, y: 801 },
  { x: 7399, y: 614 },
  { x: 7430, y: 745 },
  { x: 7485, y: 428 },
  { x: 7460, y: 613 },
  { x: 7390, y: 481 },
  { x: 7395, y: 372 },
  { x: 7473, y: 765 },
  { x: 7639, y: 432 },
  { x: 7408, y: 758 },
  { x: 7385, y: 591 },
  { x: 7410, y: 212 },
  { x: 7356, y: 413 },
  { x: 7428, y: 761 },
  { x: 7432, y: 531 },
  { x: 7440, y: 715 },
  { x: 7681, y: 431 },
  { x: 7386, y: 342 },
  { x: 7349, y: 261 },
  { x: 7330, y: 366 },
  { x: 7456, y: 210 },
  { x: 7430, y: 382 },
  { x: 7439, y: 673 },
  { x: 7434, y: 644 },
  { x: 7376, y: 444 },
  { x: 7383, y: 696 },
  { x: 7346, y: 697 },
  { x: 7381, y: 685 },
  { x: 7347, y: 258 },
  { x: 7627, y: 744 },
  { x: 7547, y: 463 },
  { x: 7454, y: 621 },
  { x: 7406, y: 740 },
  { x: 7341, y: 181 },
  { x: 7404, y: 669 },
  { x: 7422, y: 683 },
  { x: 7460, y: 524 },
  { x: 7413, y: 282 },
  { x: 7562, y: 779 },
  { x: 7350, y: 399 },
  { x: 7662, y: 434 },
  { x: 7345, y: 665 },
  { x: 7354, y: 350 },
  { x: 7393, y: 180 },
  { x: 7356, y: 643 },
  { x: 7339, y: 706 },
  { x: 7572, y: 488 },
  { x: 7429, y: 309 },
  { x: 7425, y: 384 },
  { x: 7396, y: 196 },
  { x: 7370, y: 634 },
  { x: 7560, y: 737 },
  { x: 7432, y: 242 },
  { x: 7333, y: 690 },
  { x: 7620, y: 798 },
  { x: 7450, y: 584 },
  { x: 7602, y: 507 },
  { x: 7617, y: 771 },
  { x: 7411, y: 452 },
  { x: 7683, y: 505 },
  { x: 7541, y: 521 },
  { x: 7584, y: 480 },
  { x: 7418, y: 165 },
  { x: 7478, y: 789 },
  { x: 7588, y: 761 },
  { x: 7631, y: 756 },
  { x: 7371, y: 238 },
  { x: 7435, y: 575 },
  { x: 7417, y: 629 },
  { x: 7399, y: 782 },
  { x: 7326, y: 368 },
  { x: 7338, y: 430 },
  { x: 7403, y: 279 },
  { x: 7554, y: 768 },
  { x: 7676, y: 717 },
  { x: 7334, y: 206 },
  { x: 7451, y: 330 },
  { x: 7423, y: 660 },
  { x: 7564, y: 766 },
  { x: 7496, y: 813 },
  { x: 7405, y: 289 },
  { x: 7431, y: 506 },
  { x: 7508, y: 728 },
  { x: 7379, y: 175 },
  { x: 7700, y: 780 },
  { x: 7447, y: 300 },
  { x: 7380, y: 150 },
  { x: 7595, y: 773 },
  { x: 7533, y: 464 },
  { x: 7559, y: 731 },
  { x: 7448, y: 310 },
  { x: 7388, y: 395 },
  { x: 7397, y: 441 },
  { x: 7352, y: 811 },
  { x: 7647, y: 471 },
  { x: 7401, y: 502 },
  { x: 7599, y: 477 },
  { x: 7544, y: 510 },
  { x: 8073, y: 549 },
  { x: 8112, y: 166 },
  { x: 8379, y: 198 },
  { x: 8394, y: 168 },
  { x: 8372, y: 748 },
  { x: 8234, y: 781 },
  { x: 8141, y: 273 },
  { x: 8193, y: 793 },
  { x: 8383, y: 751 },
  { x: 8103, y: 525 },
  { x: 8324, y: 444 },
  { x: 8294, y: 512 },
  { x: 8240, y: 213 },
  { x: 8130, y: 553 },
  { x: 8399, y: 811 },
  { x: 8102, y: 248 },
  { x: 8384, y: 779 },
  { x: 8147, y: 790 },
  { x: 8163, y: 500 },
  { x: 8162, y: 189 },
  { x: 8179, y: 457 },
  { x: 8197, y: 211 },
  { x: 8068, y: 188 },
  { x: 8217, y: 806 },
  { x: 8239, y: 782 },
  { x: 8280, y: 547 },
  { x: 8152, y: 516 },
  { x: 8124, y: 817 },
  { x: 8064, y: 532 },
  { x: 8110, y: 815 },
  { x: 8361, y: 240 },
  { x: 8331, y: 745 },
  { x: 8098, y: 292 },
  { x: 8142, y: 157 },
  { x: 8057, y: 514 },
  { x: 8114, y: 426 },
  { x: 8283, y: 794 },
  { x: 8360, y: 743 },
  { x: 8271, y: 174 },
  { x: 8036, y: 381 },
  { x: 8122, y: 202 },
  { x: 8084, y: 772 },
  { x: 8058, y: 467 },
  { x: 8255, y: 509 },
  { x: 8070, y: 746 },
  { x: 8156, y: 761 },
  { x: 8134, y: 319 },
  { x: 8281, y: 507 },
  { x: 8246, y: 187 },
  { x: 8238, y: 732 },
  { x: 8034, y: 780 },
  { x: 8309, y: 465 },
  { x: 8025, y: 742 },
  { x: 8221, y: 808 },
  { x: 8312, y: 531 },
  { x: 8093, y: 778 },
  { x: 8023, y: 209 },
  { x: 8047, y: 483 },
  { x: 8048, y: 590 },
  { x: 8138, y: 670 },
  { x: 8020, y: 497 },
  { x: 8292, y: 755 },
  { x: 8145, y: 606 },
  { x: 8225, y: 534 },
  { x: 8303, y: 472 },
  { x: 8353, y: 221 },
  { x: 8054, y: 403 },
  { x: 8256, y: 783 },
  { x: 8090, y: 733 },
  { x: 8139, y: 633 },
  { x: 8390, y: 142 },
  { x: 8019, y: 372 },
  { x: 8052, y: 162 },
  { x: 8081, y: 820 },
  { x: 8126, y: 299 },
  { x: 8038, y: 569 },
  { x: 8071, y: 600 },
  { x: 8046, y: 753 },
  { x: 8030, y: 476 },
  { x: 8104, y: 488 },
  { x: 8376, y: 812 },
  { x: 8105, y: 287 },
  { x: 8222, y: 246 },
  { x: 8074, y: 185 },
  { x: 8229, y: 230 },
  { x: 8322, y: 200 },
  { x: 8388, y: 723 },
  { x: 8021, y: 347 },
  { x: 8089, y: 341 },
  { x: 8072, y: 480 },
  { x: 8026, y: 409 },
  { x: 8340, y: 765 },
  { x: 8265, y: 237 },
  { x: 8041, y: 310 },
  { x: 8092, y: 740 },
  { x: 8144, y: 750 },
  { x: 8362, y: 535 },
  { x: 8025, y: 793 },
  { x: 8318, y: 532 },
  { x: 8304, y: 229 },
  { x: 8033, y: 689 },
  { x: 8307, y: 547 },
  { x: 8283, y: 211 },
  { x: 8054, y: 506 },
  { x: 8201, y: 830 },
  { x: 8209, y: 457 },
  { x: 8145, y: 567 },
  { x: 8355, y: 548 },
  { x: 8291, y: 139 },
  { x: 8049, y: 463 },
  { x: 8065, y: 632 },
  { x: 8139, y: 253 },
  { x: 8135, y: 418 },
  { x: 8146, y: 550 },
  { x: 8032, y: 729 },
  { x: 8182, y: 815 },
  { x: 8019, y: 779 },
  { x: 8163, y: 730 },
  { x: 8200, y: 763 },
  { x: 8013, y: 274 },
  { x: 8060, y: 607 },
  { x: 8074, y: 539 },
  { x: 8110, y: 721 },
  { x: 8055, y: 573 },
  { x: 8125, y: 787 },
  { x: 8254, y: 477 },
  { x: 8101, y: 175 },
  { x: 8394, y: 149 },
  { x: 8103, y: 444 },
  { x: 8089, y: 439 },
  { x: 8078, y: 218 },
  { x: 8121, y: 694 },
  { x: 8072, y: 551 },
  { x: 8388, y: 778 },
  { x: 8080, y: 526 },
  { x: 8077, y: 166 },
  { x: 8362, y: 783 },
  { x: 8179, y: 164 },
  { x: 8381, y: 732 },
  { x: 8263, y: 487 },
  { x: 8079, y: 559 },
  { x: 8208, y: 796 },
  { x: 8262, y: 245 },
  { x: 8053, y: 630 },
  { x: 8154, y: 802 },
  { x: 8387, y: 760 },
  { x: 8097, y: 580 },
  { x: 8241, y: 476 },
  { x: 8379, y: 820 },
  { x: 8104, y: 178 },
  { x: 8091, y: 424 },
  { x: 8070, y: 555 },
  { x: 8021, y: 433 },
  { x: 8028, y: 440 },
  { x: 8350, y: 241 },
  { x: 8098, y: 364 },
  { x: 8321, y: 177 },
  { x: 8380, y: 768 },
  { x: 8415, y: 168 },
  { x: 8275, y: 478 },
  { x: 8205, y: 148 },
  { x: 8357, y: 490 },
  { x: 8050, y: 521 },
  { x: 8087, y: 507 },
  { x: 8061, y: 224 },
  { x: 8246, y: 749 },
  { x: 8127, y: 232 },
  { x: 8051, y: 260 },
  { x: 8040, y: 183 },
  { x: 8117, y: 709 },
  { x: 8024, y: 249 },
  { x: 8123, y: 219 },
  { x: 8290, y: 508 },
  { x: 8171, y: 194 },
  { x: 8156, y: 749 },
  { x: 8297, y: 484 },
  { x: 8339, y: 190 },
  { x: 8121, y: 548 },
  { x: 8327, y: 504 },
  { x: 8127, y: 572 },
  { x: 8245, y: 543 },
  { x: 8336, y: 753 },
  { x: 8094, y: 662 },
  { x: 8032, y: 291 },
  { x: 8405, y: 148 },
  { x: 8125, y: 347 },
  { x: 8041, y: 171 },
  { x: 8252, y: 511 },
  { x: 8258, y: 732 },
  { x: 8248, y: 147 },
  { x: 8069, y: 278 },
  { x: 8186, y: 195 },
  { x: 8141, y: 655 },
  { x: 8294, y: 547 },
  { x: 8328, y: 182 },
  { x: 8012, y: 211 },
  { x: 8059, y: 806 },
  { x: 8015, y: 328 },
  { x: 8247, y: 804 },
  { x: 8058, y: 368 },
  { x: 8226, y: 527 },
  { x: 8187, y: 820 },
  { x: 8051, y: 415 },
  { x: 8098, y: 378 },
  { x: 8014, y: 495 },
  { x: 8159, y: 228 },
  { x: 8179, y: 533 },
  { x: 8296, y: 781 },
  { x: 8333, y: 191 },
  { x: 8195, y: 168 },
  { x: 8096, y: 628 },
  { x: 8120, y: 520 },
  { x: 8263, y: 138 },
  { x: 8131, y: 785 },
  { x: 8398, y: 236 },
  { x: 8130, y: 405 },
  { x: 8126, y: 152 },
  { x: 8037, y: 733 },
  { x: 8100, y: 173 },
  { x: 8021, y: 471 },
  { x: 8107, y: 353 },
  { x: 8261, y: 793 },
  { x: 8165, y: 181 },
  { x: 8089, y: 643 },
  { x: 8110, y: 367 },
  { x: 8161, y: 493 },
  { x: 8181, y: 164 },
  { x: 8070, y: 641 },
  { x: 8363, y: 769 },
  { x: 8174, y: 202 },
  { x: 8022, y: 454 },
  { x: 8078, y: 657 },
  { x: 8365, y: 176 },
  { x: 8302, y: 170 },
  { x: 8063, y: 327 },
  { x: 8008, y: 434 },
  { x: 8292, y: 522 },
  { x: 8072, y: 651 },
  { x: 8209, y: 458 },
  { x: 8033, y: 717 },
  { x: 8048, y: 614 },
  { x: 8192, y: 233 },
  { x: 8170, y: 151 },
  { x: 8019, y: 553 },
  { x: 8306, y: 746 },
  { x: 8182, y: 485 },
  { x: 8203, y: 809 },
  { x: 8167, y: 748 },
  { x: 8290, y: 536 },
  { x: 8280, y: 479 },
  { x: 8304, y: 224 },
  { x: 8134, y: 218 },
  { x: 8117, y: 427 },
  { x: 8083, y: 591 },
  { x: 8109, y: 747 },
  { x: 8145, y: 300 },
  { x: 8104, y: 598 },
  { x: 8241, y: 766 },
  { x: 8353, y: 192 },
  { x: 8111, y: 808 },
  { x: 8139, y: 349 },
  { x: 8077, y: 240 },
  { x: 8023, y: 399 },
  { x: 8078, y: 195 },
  { x: 8084, y: 553 },
  { x: 8263, y: 186 },
  { x: 8189, y: 183 },
  { x: 8067, y: 781 },
  { x: 8059, y: 689 },
  { x: 8115, y: 232 },
  { x: 8104, y: 810 },
  { x: 8215, y: 762 },
  { x: 8363, y: 754 },
  { x: 8156, y: 525 },
  { x: 8329, y: 801 },
  { x: 8410, y: 153 },
  { x: 8306, y: 218 },
  { x: 8279, y: 520 },
  { x: 8342, y: 490 },
  { x: 8353, y: 542 },
  { x: 8048, y: 271 },
  { x: 8257, y: 227 },
  { x: 8241, y: 487 },
  { x: 8311, y: 472 },
  { x: 8064, y: 806 },
  { x: 8227, y: 528 },
  { x: 8102, y: 557 },
  { x: 8149, y: 725 },
  { x: 8119, y: 406 },
  { x: 8062, y: 613 },
  { x: 8238, y: 501 },
  { x: 8073, y: 288 },
  { x: 8122, y: 265 },
  { x: 8050, y: 591 },
  { x: 8091, y: 665 },
  { x: 8362, y: 148 },
  { x: 8020, y: 652 },
  { x: 8088, y: 394 },
  { x: 8274, y: 444 },
  { x: 8232, y: 479 },
  { x: 8076, y: 561 },
  { x: 8100, y: 140 },
  { x: 8094, y: 478 },
  { x: 8093, y: 473 },
  { x: 8345, y: 790 },
  { x: 8140, y: 828 },
  { x: 8083, y: 477 },
  { x: 8242, y: 160 },
  { x: 8063, y: 355 },
  { x: 8061, y: 552 },
  { x: 8086, y: 174 },
  { x: 8021, y: 263 },
  { x: 8028, y: 337 },
  { x: 8123, y: 803 },
  { x: 8205, y: 772 },
  { x: 8169, y: 755 },
  { x: 8365, y: 175 },
  { x: 8251, y: 206 },
  { x: 8354, y: 749 },
  { x: 8112, y: 605 },
  { x: 8107, y: 733 },
  { x: 8066, y: 731 },
  { x: 8153, y: 531 },
  { x: 8126, y: 408 },
  { x: 8202, y: 241 },
  { x: 8101, y: 537 },
  { x: 8214, y: 726 },
  { x: 8179, y: 505 },
  { x: 8016, y: 255 },
  { x: 8113, y: 214 },
  { x: 8237, y: 785 },
  { x: 8289, y: 224 },
  { x: 8014, y: 546 },
  { x: 8105, y: 452 },
  { x: 8106, y: 582 },
  { x: 8103, y: 563 },
  { x: 8167, y: 724 },
  { x: 8090, y: 165 },
  { x: 8069, y: 592 },
  { x: 8047, y: 279 },
  { x: 8208, y: 735 },
  { x: 8182, y: 793 },
  { x: 8019, y: 461 },
  { x: 8017, y: 203 },
  { x: 8085, y: 818 },
  { x: 8031, y: 426 },
  { x: 8218, y: 786 },
  { x: 8338, y: 449 },
  { x: 8068, y: 146 },
  { x: 8237, y: 724 },
  { x: 8405, y: 750 },
  { x: 8131, y: 442 },
  { x: 8344, y: 803 },
  { x: 8292, y: 526 },
  { x: 8134, y: 399 },
  { x: 8212, y: 812 },
  { x: 8120, y: 426 },
  { x: 8076, y: 775 },
  { x: 8407, y: 779 },
  { x: 8071, y: 755 },
  { x: 8075, y: 582 },
  { x: 8058, y: 148 },
  { x: 8369, y: 725 },
  { x: 8156, y: 230 },
  { x: 8008, y: 752 },
  { x: 8049, y: 515 },
  { x: 8250, y: 170 },
  { x: 8104, y: 265 },
  { x: 8088, y: 332 },
  { x: 8372, y: 735 },
  { x: 8375, y: 731 },
  { x: 8025, y: 373 },
  { x: 8211, y: 234 },
  { x: 8261, y: 191 },
  { x: 8185, y: 762 },
  { x: 8183, y: 467 },
  { x: 8291, y: 802 },
  { x: 8084, y: 707 },
  { x: 8225, y: 513 },
  { x: 8114, y: 703 },
  { x: 8026, y: 606 },
  { x: 8017, y: 394 },
  { x: 8365, y: 224 },
  { x: 8176, y: 813 },
  { x: 8245, y: 729 },
  { x: 8037, y: 396 },
  { x: 8128, y: 713 },
  { x: 8030, y: 319 },
  { x: 8085, y: 312 },
  { x: 8100, y: 706 },
  { x: 8159, y: 453 },
  { x: 8157, y: 722 },
  { x: 8203, y: 732 },
  { x: 8107, y: 457 },
  { x: 8311, y: 152 },
  { x: 8357, y: 462 },
  { x: 8199, y: 164 },
  { x: 8070, y: 477 },
  { x: 8074, y: 441 },
  { x: 8213, y: 548 },
  { x: 8097, y: 243 },
  { x: 8028, y: 437 },
  { x: 8149, y: 502 },
  { x: 8290, y: 464 },
  { x: 8064, y: 411 },
  { x: 8139, y: 393 },
  { x: 8178, y: 820 },
  { x: 8014, y: 434 },
  { x: 8035, y: 789 },
  { x: 8361, y: 486 },
  { x: 8094, y: 139 },
  { x: 8081, y: 367 },
  { x: 8221, y: 748 },
  { x: 8015, y: 369 },
  { x: 8370, y: 468 },
  { x: 8228, y: 226 },
  { x: 8087, y: 810 },
  { x: 8083, y: 708 },
  { x: 8171, y: 141 },
  { x: 8080, y: 586 },
  { x: 8220, y: 809 },
  { x: 8052, y: 677 },
  { x: 8078, y: 525 },
  { x: 8260, y: 758 },
  { x: 8093, y: 252 },
  { x: 8137, y: 176 },
  { x: 8061, y: 448 },
  { x: 8320, y: 546 },
  { x: 8124, y: 830 },
  { x: 8217, y: 541 },
  { x: 8158, y: 743 },
  { x: 8209, y: 145 },
  { x: 8371, y: 751 },
  { x: 8191, y: 237 },
  { x: 8096, y: 318 },
  { x: 8329, y: 774 },
  { x: 8376, y: 500 },
  { x: 8152, y: 455 },
  { x: 8142, y: 687 },
  { x: 8174, y: 527 },
  { x: 8085, y: 438 },
  { x: 8326, y: 454 },
  { x: 8304, y: 504 },
  { x: 8224, y: 475 },
  { x: 8117, y: 820 },
  { x: 8338, y: 167 },
  { x: 8026, y: 669 },
  { x: 8329, y: 507 },
  { x: 8128, y: 665 },
  { x: 8097, y: 367 },
  { x: 8193, y: 728 },
  { x: 8132, y: 598 },
  { x: 8073, y: 758 },
  { x: 8022, y: 160 },
  { x: 8353, y: 813 },
  { x: 8008, y: 624 },
  { x: 8119, y: 321 },
  { x: 8253, y: 194 },
  { x: 8027, y: 538 },
  { x: 8016, y: 535 },
  { x: 8342, y: 213 },
  { x: 8357, y: 165 },
  { x: 8365, y: 481 },
  { x: 8260, y: 232 },
  { x: 8133, y: 695 },
  { x: 8388, y: 168 },
  { x: 8352, y: 447 },
  { x: 8319, y: 734 },
  { x: 8234, y: 735 },
  { x: 8105, y: 725 },
  { x: 8013, y: 604 },
  { x: 8297, y: 512 },
  { x: 8110, y: 425 },
  { x: 8041, y: 692 },
  { x: 8035, y: 584 },
  { x: 8090, y: 400 },
  { x: 8137, y: 783 },
  { x: 8330, y: 150 },
  { x: 8280, y: 823 },
  { x: 8079, y: 592 },
  { x: 8066, y: 246 },
  { x: 8194, y: 142 },
  { x: 8240, y: 763 },
  { x: 8089, y: 645 },
  { x: 8136, y: 703 },
  { x: 8056, y: 781 },
  { x: 8185, y: 471 },
  { x: 8356, y: 759 },
  { x: 8084, y: 827 },
  { x: 8299, y: 511 },
  { x: 8176, y: 812 },
  { x: 8068, y: 233 },
  { x: 8375, y: 509 },
  { x: 8099, y: 673 },
  { x: 8401, y: 190 },
  { x: 8328, y: 497 },
  { x: 8036, y: 384 },
  { x: 8231, y: 791 },
  { x: 8184, y: 458 },
  { x: 8067, y: 215 },
  { x: 8318, y: 789 },
  { x: 8075, y: 396 },
  { x: 8397, y: 214 },
  { x: 8346, y: 797 },
  { x: 8021, y: 219 },
  { x: 8049, y: 828 },
  { x: 8029, y: 372 },
  { x: 8028, y: 441 },
  { x: 8086, y: 700 },
  { x: 8190, y: 146 },
  { x: 8188, y: 825 },
  { x: 8060, y: 632 },
  { x: 8334, y: 198 },
  { x: 8054, y: 253 },
  { x: 8134, y: 623 },
  { x: 8164, y: 192 },
  { x: 8112, y: 651 },
  { x: 8052, y: 557 },
  { x: 8031, y: 793 },
  { x: 8042, y: 782 },
  { x: 8076, y: 463 },
  { x: 8316, y: 749 },
  { x: 8053, y: 691 },
  { x: 8324, y: 230 },
  { x: 8052, y: 750 },
  { x: 8200, y: 475 },
  { x: 8142, y: 636 },
  { x: 8057, y: 321 },
  { x: 8135, y: 503 },
  { x: 8215, y: 801 },
  { x: 8370, y: 829 },
  { x: 8159, y: 739 },
  { x: 8008, y: 529 },
  { x: 8359, y: 144 },
  { x: 8024, y: 664 },
  { x: 8134, y: 663 },
  { x: 8204, y: 524 },
  { x: 8229, y: 154 },
  { x: 8081, y: 401 },
  { x: 8070, y: 711 },
  { x: 8050, y: 403 },
  { x: 8386, y: 815 },
  { x: 8093, y: 214 },
  { x: 8240, y: 502 },
  { x: 8267, y: 760 },
  { x: 8131, y: 276 },
  { x: 8107, y: 773 },
  { x: 8133, y: 656 },
  { x: 8035, y: 480 },
  { x: 8128, y: 351 },
  { x: 8176, y: 460 },
  { x: 8020, y: 215 },
  { x: 8084, y: 185 },
  { x: 8208, y: 180 },
  { x: 8358, y: 517 },
  { x: 8328, y: 171 },
  { x: 8221, y: 155 },
  { x: 8113, y: 805 },
  { x: 8041, y: 258 },
  { x: 8327, y: 827 },
  { x: 8060, y: 293 },
  { x: 8132, y: 350 },
  { x: 8213, y: 459 },
  { x: 8087, y: 297 },
  { x: 8287, y: 229 },
  { x: 8174, y: 778 },
  { x: 8378, y: 188 },
  { x: 8111, y: 638 },
  { x: 8343, y: 447 },
  { x: 8018, y: 688 },
  { x: 8118, y: 342 },
  { x: 8034, y: 182 },
  { x: 8247, y: 777 },
  { x: 8342, y: 535 },
  { x: 8112, y: 780 },
  { x: 8139, y: 720 },
  { x: 8273, y: 173 },
  { x: 8321, y: 772 },
  { x: 8270, y: 216 },
  { x: 8125, y: 164 },
  { x: 8152, y: 782 },
  { x: 8082, y: 197 },
  { x: 8033, y: 714 },
  { x: 8053, y: 528 },
  { x: 8312, y: 820 },
  { x: 8271, y: 550 },
  { x: 8322, y: 537 },
  { x: 8076, y: 329 },
  { x: 8039, y: 160 },
  { x: 8175, y: 473 },
  { x: 8029, y: 706 },
  { x: 8023, y: 385 },
  { x: 8079, y: 501 },
  { x: 8051, y: 405 },
  { x: 8193, y: 747 },
  { x: 8252, y: 232 },
  { x: 8246, y: 792 },
  { x: 8331, y: 470 },
  { x: 8054, y: 261 },
  { x: 8310, y: 490 },
  { x: 8121, y: 570 },
  { x: 8040, y: 205 },
  { x: 8074, y: 436 },
  { x: 8063, y: 177 },
  { x: 8297, y: 200 },
  { x: 8406, y: 198 },
  { x: 8044, y: 386 },
  { x: 8072, y: 428 },
  { x: 8326, y: 466 },
  { x: 8137, y: 687 },
  { x: 8236, y: 218 },
  { x: 8045, y: 643 },
  { x: 8144, y: 174 },
  { x: 8078, y: 743 },
  { x: 8090, y: 630 },
  { x: 8011, y: 425 },
  { x: 8027, y: 231 },
  { x: 8201, y: 500 },
  { x: 8282, y: 489 },
  { x: 8015, y: 587 },
  { x: 8125, y: 343 },
  { x: 8188, y: 227 },
  { x: 8338, y: 246 },
  { x: 8223, y: 149 },
  { x: 8028, y: 524 },
  { x: 8315, y: 548 },
  { x: 8141, y: 727 },
  { x: 8137, y: 697 },
  { x: 8149, y: 766 },
  { x: 8168, y: 479 },
  { x: 8308, y: 778 },
  { x: 8360, y: 189 },
  { x: 8372, y: 203 },
  { x: 8356, y: 488 },
  { x: 8101, y: 464 },
  { x: 8234, y: 531 },
  { x: 8309, y: 798 },
  { x: 8019, y: 148 },
  { x: 8108, y: 374 },
  { x: 8310, y: 811 },
  { x: 8105, y: 618 },
  { x: 8118, y: 344 },
  { x: 8346, y: 825 },
  { x: 8029, y: 150 },
  { x: 8267, y: 532 },
  { x: 8032, y: 725 },
  { x: 8364, y: 461 },
  { x: 8325, y: 471 },
  { x: 8053, y: 499 },
  { x: 8036, y: 714 },
  { x: 8239, y: 470 },
  { x: 8047, y: 210 },
  { x: 8238, y: 512 },
  { x: 8045, y: 258 },
  { x: 8062, y: 609 },
  { x: 8122, y: 667 },
  { x: 8084, y: 232 },
  { x: 8097, y: 441 },
  { x: 8393, y: 146 },
  { x: 8037, y: 283 },
  { x: 8073, y: 683 },
  { x: 8318, y: 508 },
  { x: 8314, y: 453 },
  { x: 8261, y: 543 },
  { x: 8250, y: 806 },
  { x: 8367, y: 478 },
  { x: 8093, y: 405 },
  { x: 8255, y: 465 },
  { x: 8056, y: 169 },
  { x: 8385, y: 235 },
  { x: 8135, y: 726 },
  { x: 8362, y: 812 },
  { x: 8155, y: 818 },
  { x: 8052, y: 205 },
  { x: 8370, y: 243 },
  { x: 8179, y: 147 },
  { x: 8124, y: 264 },
  { x: 8099, y: 422 },
  { x: 8120, y: 732 },
  { x: 8083, y: 672 },
  { x: 8098, y: 602 },
  { x: 8042, y: 351 },
  { x: 8044, y: 781 },
  { x: 8064, y: 570 },
  { x: 8091, y: 674 },
  { x: 8170, y: 501 },
  { x: 8072, y: 591 },
  { x: 8095, y: 268 },
  { x: 8197, y: 141 },
  { x: 8117, y: 391 },
  { x: 8302, y: 768 },
  { x: 8043, y: 346 },
  { x: 8143, y: 652 },
  { x: 8060, y: 220 },
  { x: 8074, y: 585 },
  { x: 8339, y: 155 },
  { x: 8054, y: 191 },
  { x: 8105, y: 791 },
  { x: 8340, y: 509 },
  { x: 8237, y: 183 },
  { x: 8120, y: 430 },
  { x: 8092, y: 409 },
  { x: 8240, y: 733 },
  { x: 8297, y: 497 },
  { x: 8036, y: 721 },
  { x: 8041, y: 386 },
  { x: 8368, y: 485 },
  { x: 8333, y: 465 },
  { x: 8255, y: 808 },
  { x: 8096, y: 634 },
  { x: 8097, y: 410 },
  { x: 8052, y: 330 },
  { x: 8080, y: 735 },
  { x: 8193, y: 496 },
  { x: 8035, y: 819 },
  { x: 8093, y: 454 },
  { x: 8048, y: 370 },
  { x: 8033, y: 787 },
  { x: 8086, y: 398 },
  { x: 8188, y: 479 },
  { x: 8326, y: 178 },
  { x: 8009, y: 584 },
  { x: 8088, y: 378 },
  { x: 8020, y: 460 },
  { x: 8042, y: 618 },
  { x: 8217, y: 246 },
  { x: 8388, y: 238 },
  { x: 8367, y: 756 },
  { x: 8026, y: 191 },
  { x: 8302, y: 448 },
  { x: 8015, y: 547 },
  { x: 8094, y: 288 },
  { x: 8131, y: 592 },
  { x: 8117, y: 249 },
  { x: 8140, y: 248 },
  { x: 8229, y: 802 },
  { x: 8246, y: 452 },
  { x: 8179, y: 220 },
  { x: 8146, y: 261 },
  { x: 8299, y: 449 },
  { x: 8024, y: 195 },
  { x: 8127, y: 198 },
  { x: 8051, y: 250 },
  { x: 8027, y: 684 },
  { x: 8383, y: 179 },
  { x: 8013, y: 778 },
  { x: 8244, y: 476 },
  { x: 8236, y: 546 },
  { x: 8134, y: 501 },
  { x: 8113, y: 601 },
  { x: 8043, y: 150 },
  { x: 8205, y: 505 },
  { x: 8112, y: 304 },
  { x: 8019, y: 217 },
  { x: 8291, y: 147 },
  { x: 8295, y: 829 },
  { x: 8393, y: 182 },
  { x: 8021, y: 221 },
  { x: 8136, y: 508 },
  { x: 8062, y: 344 },
  { x: 8267, y: 770 },
  { x: 8220, y: 759 },
  { x: 8144, y: 537 },
  { x: 8133, y: 163 },
  { x: 8145, y: 245 },
  { x: 8075, y: 416 },
  { x: 8109, y: 520 },
  { x: 8115, y: 610 },
  { x: 8411, y: 148 },
  { x: 8379, y: 818 },
  { x: 8076, y: 442 },
  { x: 8336, y: 801 },
  { x: 8099, y: 614 },
  { x: 8130, y: 745 },
  { x: 8197, y: 169 },
  { x: 8158, y: 141 },
  { x: 8259, y: 209 },
  { x: 8090, y: 481 },
  { x: 8095, y: 372 },
  { x: 8173, y: 765 },
  { x: 8243, y: 204 },
  { x: 8108, y: 758 },
  { x: 8085, y: 591 },
  { x: 8189, y: 208 },
  { x: 8110, y: 212 },
  { x: 8056, y: 413 },
  { x: 8128, y: 761 },
  { x: 8249, y: 192 },
  { x: 8132, y: 531 },
  { x: 8373, y: 206 },
  { x: 8140, y: 715 },
  { x: 8218, y: 530 },
  { x: 8086, y: 342 },
  { x: 8409, y: 147 },
  { x: 8049, y: 261 },
  { x: 8030, y: 366 },
  { x: 8156, y: 210 },
  { x: 8130, y: 382 },
  { x: 8139, y: 673 },
  { x: 8176, y: 187 },
  { x: 8134, y: 644 },
  { x: 8076, y: 444 },
  { x: 8083, y: 696 },
  { x: 8046, y: 697 },
  { x: 8199, y: 212 },
  { x: 8081, y: 685 },
  { x: 8047, y: 258 },
  { x: 8017, y: 325 },
  { x: 8271, y: 193 },
  { x: 8366, y: 224 },
  { x: 8022, y: 229 },
  { x: 8327, y: 744 },
  { x: 8247, y: 463 },
  { x: 8186, y: 144 },
  { x: 8230, y: 174 },
  { x: 8106, y: 740 },
  { x: 8337, y: 241 },
  { x: 8041, y: 181 },
  { x: 8104, y: 669 },
  { x: 8122, y: 683 },
  { x: 8160, y: 524 },
  { x: 8113, y: 282 },
  { x: 8262, y: 779 },
  { x: 8050, y: 399 },
  { x: 8008, y: 655 },
  { x: 8045, y: 665 },
  { x: 8054, y: 350 },
  { x: 8093, y: 180 },
  { x: 8056, y: 643 },
  { x: 8039, y: 706 },
  { x: 8272, y: 488 },
  { x: 8129, y: 309 },
  { x: 8125, y: 384 },
  { x: 8096, y: 196 },
  { x: 8070, y: 634 },
  { x: 8260, y: 737 },
  { x: 8014, y: 421 },
  { x: 8132, y: 242 },
  { x: 8033, y: 690 },
  { x: 8320, y: 798 },
  { x: 8302, y: 507 },
  { x: 8164, y: 171 },
  { x: 8317, y: 771 },
  { x: 8111, y: 452 },
  { x: 8241, y: 521 },
  { x: 8239, y: 158 },
  { x: 8284, y: 480 },
  { x: 8118, y: 165 },
  { x: 8252, y: 533 },
  { x: 8178, y: 789 },
  { x: 8185, y: 159 },
  { x: 8360, y: 234 },
  { x: 8288, y: 761 },
  { x: 8392, y: 225 },
  { x: 8331, y: 756 },
  { x: 8071, y: 238 },
  { x: 8135, y: 575 },
  { x: 8117, y: 629 },
  { x: 8099, y: 782 },
  { x: 8296, y: 226 },
  { x: 8026, y: 368 },
  { x: 8038, y: 430 },
  { x: 8227, y: 138 },
  { x: 8263, y: 243 },
  { x: 8300, y: 232 },
  { x: 8103, y: 279 },
  { x: 8254, y: 768 },
  { x: 8034, y: 206 },
  { x: 8123, y: 660 },
  { x: 8264, y: 766 },
  { x: 8196, y: 813 },
  { x: 8105, y: 289 },
  { x: 8131, y: 506 },
  { x: 8208, y: 728 },
  { x: 8079, y: 175 },
  { x: 8400, y: 780 },
  { x: 8189, y: 200 },
  { x: 8080, y: 150 },
  { x: 8295, y: 773 },
  { x: 8233, y: 464 },
  { x: 8259, y: 731 },
  { x: 8088, y: 395 },
  { x: 8097, y: 441 },
  { x: 8258, y: 203 },
  { x: 8052, y: 811 },
  { x: 8347, y: 471 },
  { x: 8101, y: 502 },
  { x: 8299, y: 477 },
  { x: 8268, y: 548 },
  { x: 8244, y: 510 },
  { x: 9089, y: 376 },
  { x: 8773, y: 549 },
  { x: 9079, y: 198 },
  { x: 9094, y: 168 },
  { x: 8934, y: 781 },
  { x: 9039, y: 274 },
  { x: 8678, y: 408 },
  { x: 8667, y: 312 },
  { x: 8903, y: 374 },
  { x: 8893, y: 793 },
  { x: 8803, y: 525 },
  { x: 9024, y: 444 },
  { x: 9093, y: 350 },
  { x: 8994, y: 512 },
  { x: 8706, y: 171 },
  { x: 8830, y: 553 },
  { x: 9123, y: 349 },
  { x: 8878, y: 325 },
  { x: 8847, y: 790 },
  { x: 9175, y: 288 },
  { x: 8964, y: 710 },
  { x: 9127, y: 348 },
  { x: 9081, y: 337 },
  { x: 8917, y: 806 },
  { x: 8939, y: 782 },
  { x: 8695, y: 272 },
  { x: 8980, y: 547 },
  { x: 8824, y: 817 },
  { x: 9080, y: 293 },
  { x: 8671, y: 143 },
  { x: 8764, y: 532 },
  { x: 8989, y: 413 },
  { x: 8810, y: 815 },
  { x: 8969, y: 343 },
  { x: 9061, y: 240 },
  { x: 8688, y: 147 },
  { x: 9128, y: 356 },
  { x: 8991, y: 404 },
  { x: 8876, y: 687 },
  { x: 8757, y: 514 },
  { x: 9043, y: 609 },
  { x: 8951, y: 378 },
  { x: 8983, y: 794 },
  { x: 8736, y: 381 },
  { x: 8664, y: 331 },
  { x: 8758, y: 467 },
  { x: 8979, y: 339 },
  { x: 8856, y: 761 },
  { x: 8834, y: 319 },
  { x: 8981, y: 507 },
  { x: 8938, y: 732 },
  { x: 9028, y: 704 },
  { x: 9009, y: 465 },
  { x: 9020, y: 708 },
  { x: 8921, y: 808 },
  { x: 9012, y: 531 },
  { x: 8793, y: 778 },
  { x: 8723, y: 209 },
  { x: 8747, y: 483 },
  { x: 8622, y: 231 },
  { x: 8748, y: 590 },
  { x: 8838, y: 670 },
  { x: 9055, y: 417 },
  { x: 8960, y: 642 },
  { x: 8720, y: 497 },
  { x: 8697, y: 449 },
  { x: 8992, y: 755 },
  { x: 9122, y: 309 },
  { x: 8677, y: 268 },
  { x: 8845, y: 606 },
  { x: 8883, y: 307 },
  { x: 8935, y: 627 },
  { x: 8626, y: 138 },
  { x: 9004, y: 671 },
  { x: 9003, y: 472 },
  { x: 9053, y: 221 },
  { x: 8754, y: 403 },
  { x: 8999, y: 588 },
  { x: 9052, y: 294 },
  { x: 9133, y: 205 },
  { x: 8956, y: 783 },
  { x: 8790, y: 733 },
  { x: 8839, y: 633 },
  { x: 9050, y: 302 },
  { x: 8962, y: 379 },
  { x: 9090, y: 142 },
  { x: 8719, y: 372 },
  { x: 9180, y: 217 },
  { x: 8649, y: 342 },
  { x: 8676, y: 253 },
  { x: 9139, y: 161 },
  { x: 8826, y: 299 },
  { x: 9085, y: 416 },
  { x: 9120, y: 163 },
  { x: 8738, y: 569 },
  { x: 8882, y: 355 },
  { x: 8771, y: 600 },
  { x: 8730, y: 476 },
  { x: 8804, y: 488 },
  { x: 9096, y: 474 },
  { x: 9125, y: 306 },
  { x: 9129, y: 212 },
  { x: 8721, y: 347 },
  { x: 8663, y: 338 },
  { x: 8789, y: 341 },
  { x: 8772, y: 480 },
  { x: 9183, y: 259 },
  { x: 8726, y: 409 },
  { x: 8635, y: 183 },
  { x: 9029, y: 297 },
  { x: 8933, y: 669 },
  { x: 8741, y: 310 },
  { x: 8792, y: 740 },
  { x: 9017, y: 397 },
  { x: 8844, y: 750 },
  { x: 8986, y: 388 },
  { x: 9062, y: 535 },
  { x: 8696, y: 281 },
  { x: 9010, y: 305 },
  { x: 9018, y: 532 },
  { x: 9122, y: 222 },
  { x: 9133, y: 174 },
  { x: 8976, y: 356 },
  { x: 9007, y: 547 },
  { x: 8754, y: 506 },
  { x: 8901, y: 830 },
  { x: 8845, y: 567 },
  { x: 8886, y: 300 },
  { x: 8640, y: 323 },
  { x: 9055, y: 548 },
  { x: 8642, y: 167 },
  { x: 9202, y: 162 },
  { x: 8996, y: 402 },
  { x: 9123, y: 401 },
  { x: 8977, y: 309 },
  { x: 8749, y: 463 },
  { x: 8703, y: 504 },
  { x: 8765, y: 632 },
  { x: 9069, y: 280 },
  { x: 9108, y: 327 },
  { x: 8706, y: 170 },
  { x: 9137, y: 240 },
  { x: 8658, y: 340 },
  { x: 8882, y: 815 },
  { x: 8618, y: 151 },
  { x: 8863, y: 730 },
  { x: 8900, y: 763 },
  { x: 8713, y: 274 },
  { x: 8997, y: 629 },
  { x: 8760, y: 607 },
  { x: 8774, y: 539 },
  { x: 9134, y: 284 },
  { x: 8810, y: 721 },
  { x: 9075, y: 414 },
  { x: 9047, y: 371 },
  { x: 8953, y: 379 },
  { x: 8755, y: 573 },
  { x: 8825, y: 787 },
  { x: 9094, y: 149 },
  { x: 8803, y: 444 },
  { x: 8789, y: 439 },
  { x: 8821, y: 694 },
  { x: 8772, y: 551 },
  { x: 9111, y: 353 },
  { x: 8780, y: 526 },
  { x: 8682, y: 176 },
  { x: 9026, y: 680 },
  { x: 8663, y: 358 },
  { x: 8994, y: 571 },
  { x: 9031, y: 370 },
  { x: 9042, y: 590 },
  { x: 8966, y: 698 },
  { x: 8963, y: 487 },
  { x: 8898, y: 381 },
  { x: 8993, y: 690 },
  { x: 9070, y: 278 },
  { x: 8779, y: 559 },
  { x: 9093, y: 312 },
  { x: 8908, y: 796 },
  { x: 8753, y: 630 },
  { x: 8854, y: 802 },
  { x: 8973, y: 646 },
  { x: 8797, y: 580 },
  { x: 8985, y: 568 },
  { x: 9002, y: 613 },
  { x: 8923, y: 398 },
  { x: 8611, y: 165 },
  { x: 8791, y: 424 },
  { x: 8770, y: 555 },
  { x: 8721, y: 433 },
  { x: 8873, y: 390 },
  { x: 8728, y: 440 },
  { x: 9050, y: 241 },
  { x: 9076, y: 287 },
  { x: 9040, y: 640 },
  { x: 8798, y: 364 },
  { x: 9039, y: 666 },
  { x: 8669, y: 210 },
  { x: 8697, y: 466 },
  { x: 9077, y: 306 },
  { x: 8944, y: 332 },
  { x: 9130, y: 359 },
  { x: 9035, y: 635 },
  { x: 9115, y: 168 },
  { x: 8975, y: 478 },
  { x: 9022, y: 602 },
  { x: 8864, y: 650 },
  { x: 9057, y: 490 },
  { x: 9091, y: 459 },
  { x: 9139, y: 146 },
  { x: 8750, y: 521 },
  { x: 8787, y: 507 },
  { x: 8915, y: 669 },
  { x: 8946, y: 749 },
  { x: 8645, y: 292 },
  { x: 8938, y: 349 },
  { x: 9167, y: 237 },
  { x: 8817, y: 709 },
  { x: 8724, y: 249 },
  { x: 9085, y: 460 },
  { x: 8990, y: 508 },
  { x: 8856, y: 749 },
  { x: 8623, y: 186 },
  { x: 9024, y: 675 },
  { x: 8962, y: 568 },
  { x: 8997, y: 484 },
  { x: 8884, y: 329 },
  { x: 9084, y: 248 },
  { x: 9137, y: 276 },
  { x: 8821, y: 548 },
  { x: 8849, y: 699 },
  { x: 9027, y: 504 },
  { x: 8827, y: 572 },
  { x: 8945, y: 543 },
  { x: 8794, y: 662 },
  { x: 8732, y: 291 },
  { x: 9105, y: 148 },
  { x: 9011, y: 401 },
  { x: 9068, y: 381 },
  { x: 8917, y: 315 },
  { x: 8825, y: 347 },
  { x: 8958, y: 732 },
  { x: 8951, y: 700 },
  { x: 8973, y: 679 },
  { x: 9034, y: 595 },
  { x: 9076, y: 290 },
  { x: 8688, y: 234 },
  { x: 9119, y: 418 },
  { x: 8668, y: 141 },
  { x: 8841, y: 655 },
  { x: 8994, y: 547 },
  { x: 9073, y: 397 },
  { x: 9157, y: 209 },
  { x: 8712, y: 211 },
  { x: 8715, y: 328 },
  { x: 8939, y: 303 },
  { x: 8947, y: 804 },
  { x: 8758, y: 368 },
  { x: 8976, y: 668 },
  { x: 9147, y: 207 },
  { x: 8932, y: 701 },
  { x: 8887, y: 820 },
  { x: 8751, y: 415 },
  { x: 8650, y: 222 },
  { x: 8633, y: 232 },
  { x: 8798, y: 378 },
  { x: 9032, y: 636 },
  { x: 8714, y: 495 },
  { x: 8897, y: 375 },
  { x: 8996, y: 781 },
  { x: 8796, y: 628 },
  { x: 8820, y: 520 },
  { x: 8831, y: 785 },
  { x: 9023, y: 406 },
  { x: 9098, y: 236 },
  { x: 8721, y: 471 },
  { x: 8807, y: 353 },
  { x: 8961, y: 793 },
  { x: 8789, y: 643 },
  { x: 8810, y: 367 },
  { x: 8924, y: 340 },
  { x: 8698, y: 439 },
  { x: 8770, y: 641 },
  { x: 8690, y: 354 },
  { x: 9029, y: 314 },
  { x: 8722, y: 454 },
  { x: 8993, y: 676 },
  { x: 8697, y: 296 },
  { x: 8778, y: 657 },
  { x: 8858, y: 633 },
  { x: 9065, y: 176 },
  { x: 8763, y: 327 },
  { x: 8708, y: 434 },
  { x: 8992, y: 522 },
  { x: 8772, y: 651 },
  { x: 9154, y: 254 },
  { x: 8660, y: 258 },
  { x: 8748, y: 614 },
  { x: 9060, y: 262 },
  { x: 9035, y: 431 },
  { x: 9171, y: 196 },
  { x: 9046, y: 271 },
  { x: 8959, y: 696 },
  { x: 8719, y: 553 },
  { x: 9006, y: 746 },
  { x: 8903, y: 809 },
  { x: 8867, y: 748 },
  { x: 8990, y: 536 },
  { x: 8980, y: 479 },
  { x: 9047, y: 280 },
  { x: 8783, y: 591 },
  { x: 9022, y: 344 },
  { x: 8809, y: 747 },
  { x: 8845, y: 300 },
  { x: 8804, y: 598 },
  { x: 8941, y: 766 },
  { x: 9044, y: 645 },
  { x: 8811, y: 808 },
  { x: 8839, y: 349 },
  { x: 9056, y: 407 },
  { x: 9042, y: 597 },
  { x: 8853, y: 384 },
  { x: 8659, y: 376 },
  { x: 8723, y: 399 },
  { x: 9018, y: 412 },
  { x: 8672, y: 181 },
  { x: 9185, y: 235 },
  { x: 8784, y: 553 },
  { x: 9061, y: 308 },
  { x: 8865, y: 659 },
  { x: 8804, y: 810 },
  { x: 8915, y: 762 },
  { x: 9110, y: 153 },
  { x: 8979, y: 520 },
  { x: 9042, y: 490 },
  { x: 9053, y: 542 },
  { x: 8748, y: 271 },
  { x: 8861, y: 390 },
  { x: 8909, y: 335 },
  { x: 9011, y: 472 },
  { x: 9058, y: 363 },
  { x: 8933, y: 641 },
  { x: 9149, y: 176 },
  { x: 8802, y: 557 },
  { x: 8849, y: 725 },
  { x: 8611, y: 193 },
  { x: 8990, y: 661 },
  { x: 9012, y: 564 },
  { x: 8762, y: 613 },
  { x: 9195, y: 163 },
  { x: 9184, y: 234 },
  { x: 9016, y: 398 },
  { x: 8913, y: 649 },
  { x: 8750, y: 591 },
  { x: 8791, y: 665 },
  { x: 9057, y: 437 },
  { x: 8936, y: 350 },
  { x: 8670, y: 139 },
  { x: 8684, y: 342 },
  { x: 8675, y: 402 },
  { x: 8788, y: 394 },
  { x: 9085, y: 257 },
  { x: 8776, y: 561 },
  { x: 8794, y: 478 },
  { x: 8793, y: 473 },
  { x: 8840, y: 828 },
  { x: 8674, y: 278 },
  { x: 8948, y: 694 },
  { x: 9074, y: 266 },
  { x: 8783, y: 477 },
  { x: 8763, y: 355 },
  { x: 8761, y: 552 },
  { x: 8904, y: 327 },
  { x: 9079, y: 453 },
  { x: 8721, y: 263 },
  { x: 8728, y: 337 },
  { x: 8955, y: 587 },
  { x: 8635, y: 156 },
  { x: 8703, y: 503 },
  { x: 8823, y: 803 },
  { x: 8900, y: 695 },
  { x: 8707, y: 261 },
  { x: 8924, y: 348 },
  { x: 8905, y: 772 },
  { x: 8869, y: 755 },
  { x: 9065, y: 175 },
  { x: 8997, y: 677 },
  { x: 9108, y: 381 },
  { x: 8812, y: 605 },
  { x: 8807, y: 733 },
  { x: 8665, y: 391 },
  { x: 8704, y: 179 },
  { x: 8801, y: 537 },
  { x: 8914, y: 726 },
  { x: 8716, y: 255 },
  { x: 8937, y: 785 },
  { x: 9008, y: 419 },
  { x: 8920, y: 622 },
  { x: 8694, y: 298 },
  { x: 9130, y: 328 },
  { x: 8714, y: 546 },
  { x: 8805, y: 452 },
  { x: 9168, y: 294 },
  { x: 8806, y: 582 },
  { x: 8803, y: 563 },
  { x: 9033, y: 669 },
  { x: 9004, y: 691 },
  { x: 8998, y: 714 },
  { x: 8641, y: 303 },
  { x: 8867, y: 724 },
  { x: 9136, y: 283 },
  { x: 9022, y: 662 },
  { x: 8769, y: 592 },
  { x: 8747, y: 279 },
  { x: 9116, y: 290 },
  { x: 8908, y: 735 },
  { x: 8882, y: 793 },
  { x: 8719, y: 461 },
  { x: 8717, y: 203 },
  { x: 8950, y: 831 },
  { x: 8975, y: 618 },
  { x: 8917, y: 678 },
  { x: 8731, y: 426 },
  { x: 8911, y: 671 },
  { x: 8918, y: 786 },
  { x: 8857, y: 620 },
  { x: 9038, y: 449 },
  { x: 8692, y: 313 },
  { x: 9156, y: 155 },
  { x: 8699, y: 445 },
  { x: 8937, y: 724 },
  { x: 9175, y: 200 },
  { x: 8598, y: 190 },
  { x: 8638, y: 187 },
  { x: 8992, y: 526 },
  { x: 8834, y: 399 },
  { x: 8912, y: 812 },
  { x: 8665, y: 294 },
  { x: 8775, y: 582 },
  { x: 9050, y: 623 },
  { x: 8749, y: 515 },
  { x: 9021, y: 696 },
  { x: 9109, y: 452 },
  { x: 8788, y: 332 },
  { x: 9006, y: 659 },
  { x: 8725, y: 373 },
  { x: 8926, y: 647 },
  { x: 8885, y: 762 },
  { x: 8886, y: 353 },
  { x: 8895, y: 325 },
  { x: 8991, y: 802 },
  { x: 8784, y: 707 },
  { x: 8814, y: 703 },
  { x: 8596, y: 161 },
  { x: 8971, y: 299 },
  { x: 9031, y: 363 },
  { x: 8986, y: 714 },
  { x: 9099, y: 404 },
  { x: 8717, y: 394 },
  { x: 9016, y: 392 },
  { x: 8641, y: 142 },
  { x: 9065, y: 224 },
  { x: 8876, y: 813 },
  { x: 8945, y: 729 },
  { x: 8737, y: 396 },
  { x: 8828, y: 713 },
  { x: 8730, y: 319 },
  { x: 8632, y: 188 },
  { x: 8919, y: 386 },
  { x: 8785, y: 312 },
  { x: 9106, y: 444 },
  { x: 9113, y: 273 },
  { x: 8800, y: 706 },
  { x: 9036, y: 657 },
  { x: 8941, y: 563 },
  { x: 8857, y: 722 },
  { x: 8903, y: 732 },
  { x: 9046, y: 289 },
  { x: 8678, y: 412 },
  { x: 8807, y: 457 },
  { x: 8861, y: 388 },
  { x: 9057, y: 462 },
  { x: 8602, y: 146 },
  { x: 8936, y: 664 },
  { x: 8770, y: 477 },
  { x: 8774, y: 441 },
  { x: 8996, y: 599 },
  { x: 8728, y: 437 },
  { x: 8990, y: 464 },
  { x: 8764, y: 411 },
  { x: 8839, y: 393 },
  { x: 8878, y: 820 },
  { x: 8714, y: 434 },
  { x: 9171, y: 140 },
  { x: 9179, y: 214 },
  { x: 8667, y: 221 },
  { x: 9061, y: 486 },
  { x: 8966, y: 362 },
  { x: 8999, y: 430 },
  { x: 9139, y: 180 },
  { x: 9143, y: 185 },
  { x: 8781, y: 367 },
  { x: 8655, y: 281 },
  { x: 8921, y: 748 },
  { x: 8715, y: 369 },
  { x: 9070, y: 468 },
  { x: 9151, y: 209 },
  { x: 8898, y: 316 },
  { x: 8967, y: 385 },
  { x: 8693, y: 271 },
  { x: 8783, y: 708 },
  { x: 8988, y: 636 },
  { x: 8780, y: 586 },
  { x: 8614, y: 155 },
  { x: 8644, y: 184 },
  { x: 8920, y: 809 },
  { x: 8778, y: 525 },
  { x: 8946, y: 655 },
  { x: 8960, y: 758 },
  { x: 8707, y: 347 },
  { x: 8761, y: 448 },
  { x: 8915, y: 718 },
  { x: 9020, y: 546 },
  { x: 8858, y: 743 },
  { x: 8796, y: 318 },
  { x: 8994, y: 641 },
  { x: 9008, y: 383 },
  { x: 9076, y: 500 },
  { x: 8948, y: 351 },
  { x: 9027, y: 342 },
  { x: 9085, y: 344 },
  { x: 8842, y: 687 },
  { x: 8651, y: 354 },
  { x: 8852, y: 715 },
  { x: 9115, y: 401 },
  { x: 9193, y: 178 },
  { x: 8785, y: 438 },
  { x: 8604, y: 137 },
  { x: 9026, y: 454 },
  { x: 8966, y: 333 },
  { x: 9004, y: 504 },
  { x: 8898, y: 355 },
  { x: 9090, y: 478 },
  { x: 8866, y: 668 },
  { x: 8817, y: 820 },
  { x: 9029, y: 507 },
  { x: 8828, y: 665 },
  { x: 8797, y: 367 },
  { x: 8893, y: 728 },
  { x: 8832, y: 598 },
  { x: 9201, y: 143 },
  { x: 8865, y: 359 },
  { x: 8705, y: 323 },
  { x: 9176, y: 217 },
  { x: 8819, y: 321 },
  { x: 8692, y: 390 },
  { x: 8700, y: 457 },
  { x: 8691, y: 148 },
  { x: 9143, y: 370 },
  { x: 8682, y: 369 },
  { x: 8727, y: 538 },
  { x: 8947, y: 315 },
  { x: 8716, y: 535 },
  { x: 9005, y: 612 },
  { x: 9033, y: 277 },
  { x: 8944, y: 371 },
  { x: 9067, y: 550 },
  { x: 8933, y: 685 },
  { x: 9065, y: 481 },
  { x: 8702, y: 288 },
  { x: 8833, y: 695 },
  { x: 9088, y: 168 },
  { x: 8878, y: 392 },
  { x: 8943, y: 654 },
  { x: 9052, y: 447 },
  { x: 8995, y: 397 },
  { x: 9021, y: 618 },
  { x: 9019, y: 734 },
  { x: 9079, y: 417 },
  { x: 8934, y: 735 },
  { x: 8900, y: 373 },
  { x: 8805, y: 725 },
  { x: 8937, y: 388 },
  { x: 8997, y: 512 },
  { x: 8634, y: 210 },
  { x: 8996, y: 424 },
  { x: 8735, y: 584 },
  { x: 8790, y: 400 },
  { x: 8909, y: 377 },
  { x: 8837, y: 783 },
  { x: 8582, y: 156 },
  { x: 8980, y: 823 },
  { x: 8779, y: 592 },
  { x: 8940, y: 763 },
  { x: 8633, y: 181 },
  { x: 8789, y: 645 },
  { x: 9116, y: 455 },
  { x: 8670, y: 387 },
  { x: 8836, y: 703 },
  { x: 8657, y: 202 },
  { x: 8883, y: 391 },
  { x: 8925, y: 613 },
  { x: 9086, y: 362 },
  { x: 8859, y: 702 },
  { x: 9166, y: 187 },
  { x: 8999, y: 511 },
  { x: 8876, y: 812 },
  { x: 8592, y: 185 },
  { x: 8929, y: 344 },
  { x: 9075, y: 509 },
  { x: 8799, y: 673 },
  { x: 9101, y: 190 },
  { x: 9093, y: 385 },
  { x: 8857, y: 634 },
  { x: 9028, y: 497 },
  { x: 9136, y: 250 },
  { x: 8736, y: 384 },
  { x: 8931, y: 791 },
  { x: 9161, y: 295 },
  { x: 8775, y: 396 },
  { x: 9097, y: 214 },
  { x: 8646, y: 171 },
  { x: 8685, y: 341 },
  { x: 8721, y: 219 },
  { x: 8608, y: 212 },
  { x: 8699, y: 249 },
  { x: 8729, y: 372 },
  { x: 9040, y: 269 },
  { x: 8728, y: 441 },
  { x: 8631, y: 135 },
  { x: 8786, y: 700 },
  { x: 9109, y: 313 },
  { x: 8888, y: 825 },
  { x: 8868, y: 349 },
  { x: 9163, y: 324 },
  { x: 8968, y: 688 },
  { x: 8669, y: 406 },
  { x: 9111, y: 453 },
  { x: 8760, y: 632 },
  { x: 8958, y: 308 },
  { x: 8834, y: 623 },
  { x: 8938, y: 641 },
  { x: 8628, y: 169 },
  { x: 8987, y: 393 },
  { x: 8679, y: 161 },
  { x: 8812, y: 651 },
  { x: 8752, y: 557 },
  { x: 9045, y: 248 },
  { x: 8776, y: 463 },
  { x: 8663, y: 317 },
  { x: 8923, y: 664 },
  { x: 9119, y: 139 },
  { x: 8669, y: 414 },
  { x: 8842, y: 636 },
  { x: 8757, y: 321 },
  { x: 8928, y: 341 },
  { x: 8683, y: 248 },
  { x: 8991, y: 395 },
  { x: 8915, y: 801 },
  { x: 8865, y: 303 },
  { x: 8859, y: 739 },
  { x: 8681, y: 356 },
  { x: 8708, y: 529 },
  { x: 9038, y: 554 },
  { x: 8689, y: 347 },
  { x: 8834, y: 663 },
  { x: 8781, y: 401 },
  { x: 8972, y: 642 },
  { x: 8770, y: 711 },
  { x: 8750, y: 403 },
  { x: 8625, y: 236 },
  { x: 9009, y: 378 },
  { x: 9020, y: 313 },
  { x: 9072, y: 360 },
  { x: 8967, y: 760 },
  { x: 8807, y: 773 },
  { x: 8965, y: 355 },
  { x: 8833, y: 656 },
  { x: 8735, y: 480 },
  { x: 9050, y: 283 },
  { x: 8629, y: 238 },
  { x: 9075, y: 415 },
  { x: 8828, y: 351 },
  { x: 9143, y: 223 },
  { x: 8720, y: 215 },
  { x: 9058, y: 517 },
  { x: 9104, y: 290 },
  { x: 8705, y: 476 },
  { x: 8813, y: 805 },
  { x: 8598, y: 199 },
  { x: 9011, y: 327 },
  { x: 8741, y: 258 },
  { x: 8691, y: 249 },
  { x: 8897, y: 707 },
  { x: 8760, y: 293 },
  { x: 8832, y: 350 },
  { x: 8690, y: 348 },
  { x: 8787, y: 297 },
  { x: 8932, y: 599 },
  { x: 9161, y: 279 },
  { x: 8874, y: 778 },
  { x: 9200, y: 191 },
  { x: 9125, y: 265 },
  { x: 8924, y: 685 },
  { x: 9124, y: 159 },
  { x: 9078, y: 188 },
  { x: 8811, y: 638 },
  { x: 9043, y: 447 },
  { x: 8995, y: 704 },
  { x: 9168, y: 213 },
  { x: 8818, y: 342 },
  { x: 9057, y: 407 },
  { x: 9018, y: 406 },
  { x: 8947, y: 777 },
  { x: 9042, y: 535 },
  { x: 8812, y: 780 },
  { x: 8839, y: 720 },
  { x: 8849, y: 832 },
  { x: 9090, y: 247 },
  { x: 8607, y: 194 },
  { x: 8852, y: 782 },
  { x: 9144, y: 202 },
  { x: 9100, y: 362 },
  { x: 8753, y: 528 },
  { x: 8971, y: 550 },
  { x: 9066, y: 267 },
  { x: 8912, y: 340 },
  { x: 9114, y: 448 },
  { x: 9022, y: 537 },
  { x: 8776, y: 329 },
  { x: 8701, y: 226 },
  { x: 8723, y: 385 },
  { x: 8935, y: 587 },
  { x: 8686, y: 282 },
  { x: 9137, y: 266 },
  { x: 8694, y: 217 },
  { x: 9062, y: 383 },
  { x: 8779, y: 501 },
  { x: 9013, y: 310 },
  { x: 8751, y: 405 },
  { x: 9112, y: 438 },
  { x: 8893, y: 747 },
  { x: 9099, y: 252 },
  { x: 8946, y: 792 },
  { x: 9031, y: 470 },
  { x: 8661, y: 220 },
  { x: 8693, y: 483 },
  { x: 8662, y: 168 },
  { x: 9010, y: 490 },
  { x: 8821, y: 570 },
  { x: 8774, y: 436 },
  { x: 9106, y: 198 },
  { x: 8744, y: 386 },
  { x: 8772, y: 428 },
  { x: 9026, y: 466 },
  { x: 9023, y: 686 },
  { x: 8666, y: 140 },
  { x: 8837, y: 687 },
  { x: 9174, y: 184 },
  { x: 9000, y: 649 },
  { x: 8790, y: 630 },
  { x: 8711, y: 425 },
  { x: 8937, y: 611 },
  { x: 8727, y: 231 },
  { x: 8982, y: 489 },
  { x: 9095, y: 326 },
  { x: 8825, y: 343 },
  { x: 8648, y: 198 },
  { x: 9111, y: 286 },
  { x: 9109, y: 261 },
  { x: 8728, y: 524 },
  { x: 9015, y: 548 },
  { x: 8841, y: 727 },
  { x: 8837, y: 697 },
  { x: 9012, y: 597 },
  { x: 8849, y: 766 },
  { x: 9041, y: 398 },
  { x: 8894, y: 331 },
  { x: 8874, y: 378 },
  { x: 9060, y: 189 },
  { x: 9072, y: 203 },
  { x: 8677, y: 414 },
  { x: 9056, y: 488 },
  { x: 8801, y: 464 },
  { x: 9043, y: 566 },
  { x: 8957, y: 357 },
  { x: 8882, y: 303 },
  { x: 8999, y: 319 },
  { x: 8808, y: 374 },
  { x: 8904, y: 304 },
  { x: 8805, y: 618 },
  { x: 9146, y: 271 },
  { x: 8818, y: 344 },
  { x: 8654, y: 228 },
  { x: 9099, y: 434 },
  { x: 8967, y: 532 },
  { x: 8645, y: 194 },
  { x: 9064, y: 461 },
  { x: 9025, y: 471 },
  { x: 8670, y: 401 },
  { x: 9073, y: 399 },
  { x: 8651, y: 213 },
  { x: 8598, y: 175 },
  { x: 8753, y: 499 },
  { x: 8650, y: 247 },
  { x: 9112, y: 317 },
  { x: 9155, y: 237 },
  { x: 8688, y: 223 },
  { x: 9147, y: 254 },
  { x: 8857, y: 310 },
  { x: 8875, y: 658 },
  { x: 9032, y: 698 },
  { x: 8583, y: 170 },
  { x: 8745, y: 258 },
  { x: 8762, y: 609 },
  { x: 8822, y: 667 },
  { x: 8616, y: 212 },
  { x: 8797, y: 441 },
  { x: 9093, y: 146 },
  { x: 8737, y: 283 },
  { x: 8773, y: 683 },
  { x: 9135, y: 173 },
  { x: 9018, y: 508 },
  { x: 8995, y: 404 },
  { x: 9014, y: 453 },
  { x: 9080, y: 335 },
  { x: 8961, y: 543 },
  { x: 8950, y: 806 },
  { x: 9067, y: 478 },
  { x: 9047, y: 614 },
  { x: 8918, y: 632 },
  { x: 9000, y: 390 },
  { x: 9085, y: 235 },
  { x: 8835, y: 726 },
  { x: 8855, y: 818 },
  { x: 9070, y: 243 },
  { x: 9007, y: 411 },
  { x: 8820, y: 732 },
  { x: 8697, y: 349 },
  { x: 8783, y: 672 },
  { x: 9166, y: 216 },
  { x: 8798, y: 602 },
  { x: 8742, y: 351 },
  { x: 8764, y: 570 },
  { x: 8682, y: 200 },
  { x: 8933, y: 313 },
  { x: 8791, y: 674 },
  { x: 8625, y: 226 },
  { x: 9119, y: 219 },
  { x: 9024, y: 372 },
  { x: 9091, y: 424 },
  { x: 8772, y: 591 },
  { x: 8817, y: 391 },
  { x: 8972, y: 685 },
  { x: 9002, y: 768 },
  { x: 9027, y: 433 },
  { x: 8743, y: 346 },
  { x: 8843, y: 652 },
  { x: 8774, y: 585 },
  { x: 9126, y: 172 },
  { x: 8805, y: 791 },
  { x: 9040, y: 509 },
  { x: 8792, y: 409 },
  { x: 8915, y: 309 },
  { x: 8693, y: 357 },
  { x: 8651, y: 327 },
  { x: 8940, y: 733 },
  { x: 8997, y: 497 },
  { x: 9118, y: 173 },
  { x: 9174, y: 177 },
  { x: 9005, y: 384 },
  { x: 9144, y: 355 },
  { x: 8996, y: 426 },
  { x: 8741, y: 386 },
  { x: 9068, y: 485 },
  { x: 9033, y: 465 },
  { x: 8955, y: 808 },
  { x: 8796, y: 634 },
  { x: 8672, y: 401 },
  { x: 9127, y: 226 },
  { x: 8752, y: 330 },
  { x: 8780, y: 735 },
  { x: 9056, y: 436 },
  { x: 8793, y: 454 },
  { x: 8748, y: 370 },
  { x: 8866, y: 635 },
  { x: 8786, y: 398 },
  { x: 8877, y: 361 },
  { x: 8702, y: 431 },
  { x: 8788, y: 378 },
  { x: 8720, y: 460 },
  { x: 8742, y: 618 },
  { x: 8884, y: 326 },
  { x: 9088, y: 238 },
  { x: 8726, y: 191 },
  { x: 9141, y: 239 },
  { x: 9009, y: 587 },
  { x: 9002, y: 448 },
  { x: 8715, y: 547 },
  { x: 8963, y: 392 },
  { x: 9013, y: 561 },
  { x: 8688, y: 292 },
  { x: 8638, y: 149 },
  { x: 8831, y: 592 },
  { x: 8867, y: 645 },
  { x: 8911, y: 324 },
  { x: 8679, y: 314 },
  { x: 8929, y: 802 },
  { x: 8657, y: 383 },
  { x: 8847, y: 831 },
  { x: 8999, y: 449 },
  { x: 8724, y: 195 },
  { x: 8699, y: 225 },
  { x: 8919, y: 669 },
  { x: 9083, y: 179 },
  { x: 9112, y: 333 },
  { x: 8942, y: 665 },
  { x: 8813, y: 601 },
  { x: 9010, y: 567 },
  { x: 8812, y: 304 },
  { x: 8719, y: 217 },
  { x: 9038, y: 585 },
  { x: 8956, y: 554 },
  { x: 9093, y: 182 },
  { x: 8986, y: 339 },
  { x: 8988, y: 715 },
  { x: 8721, y: 221 },
  { x: 8762, y: 344 },
  { x: 8967, y: 770 },
  { x: 9117, y: 236 },
  { x: 8920, y: 759 },
  { x: 8932, y: 335 },
  { x: 8627, y: 197 },
  { x: 9031, y: 557 },
  { x: 8909, y: 656 },
  { x: 8706, y: 260 },
  { x: 9016, y: 674 },
  { x: 8697, y: 367 },
  { x: 9108, y: 360 },
  { x: 9072, y: 285 },
  { x: 8775, y: 416 },
  { x: 8694, y: 240 },
  { x: 8809, y: 520 },
  { x: 8815, y: 610 },
  { x: 9111, y: 148 },
  { x: 8935, y: 322 },
  { x: 8776, y: 442 },
  { x: 8799, y: 614 },
  { x: 8830, y: 745 },
  { x: 8711, y: 151 },
  { x: 8860, y: 613 },
  { x: 8790, y: 481 },
  { x: 9041, y: 263 },
  { x: 8795, y: 372 },
  { x: 9007, y: 695 },
  { x: 8873, y: 765 },
  { x: 9039, y: 432 },
  { x: 8808, y: 758 },
  { x: 9107, y: 366 },
  { x: 8785, y: 591 },
  { x: 9065, y: 572 },
  { x: 9142, y: 293 },
  { x: 8756, y: 413 },
  { x: 8828, y: 761 },
  { x: 9097, y: 274 },
  { x: 8832, y: 531 },
  { x: 9073, y: 206 },
  { x: 8840, y: 715 },
  { x: 9081, y: 431 },
  { x: 8786, y: 342 },
  { x: 8633, y: 149 },
  { x: 9109, y: 147 },
  { x: 8749, y: 261 },
  { x: 8730, y: 366 },
  { x: 8830, y: 382 },
  { x: 8978, y: 561 },
  { x: 8839, y: 673 },
  { x: 8834, y: 644 },
  { x: 9039, y: 259 },
  { x: 8634, y: 297 },
  { x: 9059, y: 349 },
  { x: 8666, y: 412 },
  { x: 8776, y: 444 },
  { x: 8783, y: 696 },
  { x: 9147, y: 188 },
  { x: 8977, y: 340 },
  { x: 9080, y: 531 },
  { x: 8781, y: 685 },
  { x: 8747, y: 258 },
  { x: 8928, y: 620 },
  { x: 8717, y: 325 },
  { x: 9066, y: 224 },
  { x: 8722, y: 229 },
  { x: 9123, y: 269 },
  { x: 9114, y: 389 },
  { x: 8854, y: 621 },
  { x: 8806, y: 740 },
  { x: 9173, y: 270 },
  { x: 8804, y: 669 },
  { x: 8822, y: 683 },
  { x: 8962, y: 779 },
  { x: 8750, y: 399 },
  { x: 9062, y: 434 },
  { x: 9128, y: 208 },
  { x: 9152, y: 167 },
  { x: 9040, y: 630 },
  { x: 9103, y: 313 },
  { x: 8754, y: 350 },
  { x: 8948, y: 367 },
  { x: 9105, y: 286 },
  { x: 9004, y: 322 },
  { x: 8756, y: 643 },
  { x: 8972, y: 488 },
  { x: 8829, y: 309 },
  { x: 8825, y: 384 },
  { x: 8642, y: 166 },
  { x: 8770, y: 634 },
  { x: 8936, y: 337 },
  { x: 8960, y: 737 },
  { x: 8714, y: 421 },
  { x: 9139, y: 201 },
  { x: 8669, y: 348 },
  { x: 8687, y: 440 },
  { x: 9051, y: 590 },
  { x: 8991, y: 555 },
  { x: 8986, y: 370 },
  { x: 8692, y: 487 },
  { x: 8850, y: 584 },
  { x: 9002, y: 507 },
  { x: 9044, y: 612 },
  { x: 9149, y: 205 },
  { x: 9083, y: 505 },
  { x: 9070, y: 272 },
  { x: 9030, y: 312 },
  { x: 8984, y: 480 },
  { x: 8952, y: 533 },
  { x: 8878, y: 789 },
  { x: 9075, y: 549 },
  { x: 9060, y: 234 },
  { x: 8988, y: 761 },
  { x: 8693, y: 381 },
  { x: 8883, y: 323 },
  { x: 9092, y: 225 },
  { x: 9035, y: 687 },
  { x: 8835, y: 575 },
  { x: 8817, y: 629 },
  { x: 8799, y: 782 },
  { x: 9096, y: 511 },
  { x: 8726, y: 368 },
  { x: 8738, y: 430 },
  { x: 8934, y: 666 },
  { x: 8888, y: 364 },
  { x: 8910, y: 302 },
  { x: 8954, y: 768 },
  { x: 8851, y: 330 },
  { x: 8823, y: 660 },
  { x: 8964, y: 766 },
  { x: 8866, y: 833 },
  { x: 8979, y: 392 },
  { x: 8969, y: 299 },
  { x: 8700, y: 315 },
  { x: 8660, y: 209 },
  { x: 8896, y: 813 },
  { x: 8908, y: 728 },
  { x: 8929, y: 681 },
  { x: 8694, y: 492 },
  { x: 8847, y: 300 },
  { x: 8995, y: 773 },
  { x: 9058, y: 585 },
  { x: 9084, y: 291 },
  { x: 8931, y: 637 },
  { x: 8959, y: 731 },
  { x: 8848, y: 310 },
  { x: 8788, y: 395 },
  { x: 8797, y: 441 },
  { x: 9047, y: 471 },
  { x: 8897, y: 371 },
  { x: 9071, y: 546 },
  { x: 8801, y: 502 },
  { x: 8999, y: 477 },
  { x: 9036, y: 265 },
  { x: 8968, y: 548 },
  { x: 12075, y: -27994 },
  { x: -51503, y: -61119 },
  { x: 87166, y: -41220 },
  { x: -9937, y: 72503 },
  { x: -30849, y: 40504 },
  { x: -62409, y: -10481 },
  { x: -7020, y: -59926 },
  { x: -51927, y: -64327 },
  { x: -37494, y: 79572 },
  { x: 33163, y: -12072 },
  { x: 18778, y: 92392 },
  { x: -42990, y: -41325 },
  { x: 17289, y: -21985 },
  { x: -44969, y: -37655 },
  { x: -63686, y: 30685 },
  { x: -4914, y: 91390 },
  { x: -23733, y: -67591 },
  { x: -21964, y: 48174 },
  { x: 25531, y: 56602 },
  { x: 66413, y: -22223 },
  { x: -72178, y: 36351 },
  { x: 11151, y: -33294 },
  { x: 21273, y: 33755 },
  { x: 81737, y: 85691 },
  { x: -63232, y: -14882 },
  { x: 51092, y: 3697 },
  { x: 17586, y: -33664 },
  { x: -59987, y: -14905 },
  { x: 36658, y: 88885 },
  { x: -29611, y: 9834 },
  { x: 90905, y: 71072 },
  { x: -3385, y: -41792 },
  { x: -22951, y: -1463 },
  { x: -24666, y: -76039 },
  { x: 83113, y: -68688 },
  { x: -63611, y: 35715 },
  { x: -532, y: 92414 },
  { x: -25598, y: 18950 },
  { x: 18654, y: -5258 },
  { x: -14005, y: -60705 },
  { x: 52743, y: -31593 },
  { x: 25099, y: 36302 },
  { x: 64014, y: 44332 },
  { x: -2870, y: 75055 },
  { x: -19957, y: -49688 },
  { x: 39031, y: 53618 },
  { x: -11254, y: -7448 },
  { x: 70694, y: 79637 },
  { x: -70183, y: 79753 },
  { x: 34672, y: 13894 },
  { x: -68457, y: 79334 },
  { x: 29583, y: -49289 },
  { x: 57151, y: -64888 },
  { x: 82089, y: -624 },
  { x: 3448, y: -11069 },
  { x: 1145, y: -69235 },
  { x: -53553, y: 41170 },
  { x: 47599, y: -73364 },
  { x: 16763, y: 60070 },
  { x: -30874, y: 1239 },
  { x: 28004, y: -47405 },
  { x: -46375, y: -53316 },
  { x: 87389, y: 78252 },
  { x: -31810, y: -65671 },
  { x: -13431, y: -42434 },
  { x: -36368, y: 75691 },
  { x: -36629, y: 59588 },
  { x: -25112, y: -60584 },
  { x: -62245, y: 51163 },
  { x: -11929, y: -757 },
  { x: 25046, y: -24562 },
  { x: 22170, y: 84352 },
  { x: 30139, y: 88320 },
  { x: -9249, y: -19893 },
  { x: -51746, y: -49518 },
  { x: -6151, y: 67767 },
  { x: 8538, y: -48330 },
  { x: 27149, y: 64452 },
  { x: 68181, y: -24613 },
  { x: -73592, y: 3160 },
  { x: -66357, y: -23806 },
  { x: 28540, y: 28112 },
  { x: 74755, y: 18218 },
  { x: -46598, y: 87 },
  { x: 32315, y: -42538 },
  { x: 91185, y: 3069 },
  { x: 86118, y: 23409 },
  { x: -53784, y: 88876 },
  { x: 87873, y: -39919 },
  { x: 74259, y: 89391 },
  { x: 6318, y: -12114 },
  { x: -6985, y: 8563 },
  { x: -38398, y: 56823 },
  { x: 78699, y: 15888 },
  { x: -45471, y: -19614 },
  { x: 71780, y: 47461 },
  { x: -28854, y: -39353 },
  { x: 78940, y: 69945 },
  { x: -26120, y: -24927 },
  { x: 17389, y: 80498 },
]