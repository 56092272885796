<template>
  <div>
    <p v-html="$t('TreasureHunt.Chapter3.programmingInstructions')"></p>

    <div class="monospace">
      <draggable class="list-group" tag="ul" v-model="list" v-bind="dragOptions" :move="onMove" @start="isDragging = true"
        @end="isDragging = false">
        <transition-group type="transition" :name="'flip-list'">
          <li class="list-group-item" :class="{ 'disabled-element': element.disabled }" v-for="element in list"
            :key="element.order">
            <i :class="element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'"
              @click="element.fixed = !element.fixed" aria-hidden="true"></i>
            <span v-for="n in element.indent" :key="n">&ensp;</span>
            <span>{{ element.name }}</span>
          </li>
        </transition-group>
      </draggable>
    </div>

    <br>

    <b-button class="treasure-hunt-button" @click="checkOrder">{{ $t('TreasureHunt.Chapter3.executeCodeButton') }}</b-button>
    <span style="margin-left:10px"><b-spinner small v-if="showUpdateChartSpinner"></b-spinner></span>

    <br><br>

    <p>{{ codeExecutionFeedback }}</p>
    <img v-if="showSolution & $t('TreasureHunt.Chapter3.secretConstName') == 'sphinx_secret_en'" alt="plot"
      src="@/assets/treasure_hunt/ch3_secretSolution.png" style="max-height:600px; max-width:100%; height:auto">
    <img v-if="showSolution & $t('TreasureHunt.Chapter3.secretConstName') == 'sphinx_secret_de'" alt="plot_de"
      src="@/assets/treasure_hunt/ch3_secretSolution_de.png" style="max-height:600px; max-width:100%; height:auto">
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      list: [
        {
          "name": "line = ''",
          "order": 1,
          "indent": 0,
          "disabled": true,
        },
        {
          "name": "list_of_lines = []",
          "order": 2,
          "indent": 0,
          "disabled": true,
        },
        {
          "name": "i = 1",
          "order": 3,
          "indent": 0,
          "disabled": true,
        },
        {
          "name": "characters_per_line = 200",
          "order": 4,
          "indent": 0,
          "disabled": true,
        },
        {
          "name": "i += 1",
          "order": 8,
          "indent": 8
        },
        {
          "name": "else:",
          "order": 9,
          "indent": 4
        },
        {
          "name": "list_of_lines.append(line)",
          "order": 10,
          "indent": 8
        },
        {
          "name": "for character in secret:",
          "order": 5,
          "indent": 0
        },
        {
          "name": "if i != characters_per_line:",
          "order": 6,
          "indent": 4
        },
        {
          "name": "line = line + character",
          "order": 7,
          "indent": 8
        },
        {
          "name": "line = ''",
          "order": 11,
          "indent": 8,
          "disabled": true,
        },
        {
          "name": "i = 1",
          "order": 12,
          "indent": 8,
          "disabled": true,
        },
      ],
      solution: {
        1: [1, 2, 3, 4],
        2: [1, 2, 3, 4],
        3: [1, 2, 3, 4],
        4: [1, 2, 3, 4],
        5: [5],
        6: [6],
        7: [7, 8],
        8: [7, 8],
        9: [9],
        10: [10, 11, 12],
        11: [10, 11, 12],
        12: [10, 11, 12],
      },
      isDragging: false,
      delayedDragging: false,
      codeExecutionFeedback: "",
      showSolution: false,
      showUpdateChartSpinner: false,
      solutionFound: false,
      chest: require("@/assets/reusables/chest_closed.png"),
      confetti_in_execution: false,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        ghostClass: "ghost"
      };
    },
    orderIsCorrect() {
      let item_10_position = 0;
      let item_11_position = 0;

      let check1 = true;
      let check2 = true;

      for (let i = 0; i < this.list.length; i++) {
        // Record current position of "list_of_lines.append(line)" (10) and "line = ''" (11)
        if (this.list[i]['order'] == 10) {
          item_10_position = i + 1
        }
        if (this.list[i]['order'] == 11) {
          item_11_position = i + 1
        }

        // Check if position of each item is allowed
        if (!this.solution[this.list[i]['order']].includes(i + 1)) {
          check1 = false;
        }
      }

      // Check if "list_of_lines.append(line)" is after "line = ''"
      // Separate check as it cannot be done with first check
      if (item_11_position < item_10_position) {
        check2 = false;
      }

      if (check1 && check2) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
  methods: {
    orderList() {
      this.list = this.list.sort((one, two) => {
        return one.order - two.order;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      // Check if the element being dragged or the related element has 'fixed' property
      if (
        (relatedElement && relatedElement.disabled) ||
        (draggedElement && draggedElement.disabled)
      ) {
        return false; // Prevent dragging for fixed elements
      }

      return true;
    },
    checkOrder() {
      this.showUpdateChartSpinner = true;
      this.codeExecutionFeedback = "";

      setTimeout(() => {
        this.showUpdateChartSpinner = false;
        if (this.orderIsCorrect) {
          this.showSolution = true;
          this.codeExecutionFeedback = this.$t('TreasureHunt.Chapter3.executionSuccess');
        } else {
          this.showSolution = false;
          this.codeExecutionFeedback = this.$t('TreasureHunt.Chapter3.executionError');
        }
      }, 500);
    },
  },
}
</script>

<style scoped>

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.disabled-element {
  background-color: whitesmoke;
}
</style>