import Vue from 'vue';
import VueRouter from 'vue-router';
import { passwordStore } from '@/password.js';

import GetStarted from './components/GetStarted.vue';
import AdminArea from './components/AdminArea/AdminArea.vue';
import TreasureHunt from './components/TreasureHunt/TreasureHunt.vue';
import SecretSociety from "./components/SecretSociety/SecretSociety.vue";
import PirateBay from "./components/PirateBay/PirateBay.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: GetStarted,
    meta: { requiresPassword: false },
  },
  {
    path: '/admin',
    name: 'AdminArea',
    component: AdminArea,
    meta: { requiresAdminPassword: true },
  },
  {
    path: '/secret-society',
    name: 'SecretSociety',
    component: SecretSociety,
    meta: { requiresPassword: false },
  },
  {
    path: '/treasure-hunt',
    name: 'TreasureHunt',
    component: TreasureHunt,
    meta: { requiresTreasureHuntPassword: true},
  },
  {
    path: '/pirate-bay',
    name: 'PirateBay',
    component: PirateBay,
    meta: { requiresPirateBayPassword: true},
  },
]

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  // base: __dirname,
  routes: routes
});

router.beforeEach((to, from, next) => {
  // Treasure Hunt
  if (to.meta.requiresTreasureHuntPassword) {
    if (passwordStore.treasureHuntPassword) {
      next()
    } else {
      router.push("/")
    }
  }
  // Pirate Bay
  else if (to.meta.requiresPirateBayPassword) {
    if (passwordStore.PirateBayPassword) {
      next()
    } else {
      router.push("/")
    }
  } else {
    next();
  }

  // Admin
  if (to.meta.requiresAdminPassword) {
    if (prompt("Enter admin password.") == "icdi") { //i-can-do-it
      next()
    } else {
      router.push("/")
    }
  } else {
    next();
  }
});

export default router