<template>
  <div class="before-you-start-box">
    <span v-html="$t('GetStarted.welcomeMessage.wrapper-text')"></span>
    <b-button v-b-modal.annas-advice size="sm" style="background-color: tomato; border: none">{{ $t('GetStarted.welcomeMessage.wrapper-button') }}</b-button>

    <b-modal id="annas-advice" size="xl" hide-footer>
      <anna-introduction></anna-introduction>
    </b-modal>
  </div>
</template>

<script>
import AnnaIntroduction from '@/components/AnnaIntroduction.vue'

export default {
  components: {
    AnnaIntroduction,
  },
}
</script>

<style>
.before-you-start-box {
  background-color: white;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  animation: highlight 5s ease-in-out 1s;
}

@keyframes highlight {
  5% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(3);
  }
  30% {
    transform: scale(1);
    background-color: white;
  }
  50% {
    background-color: tomato;
  }
  100% {
    background-color: white;
  }
}
</style>
