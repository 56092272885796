<template>
  <div>
    <h3>{{ $t('certificate.text') }}</h3>
    <p>{{ $t('certificate.fileInfo') }}</p>
    <b-row class="my-1">
      <b-col sm="4">
        <b-form-input v-model="name" type="text" placeholder="Name" maxlength="30" />
      </b-col>
      <b-col sm="4">
        <b-button @click="generatePDF">{{ $t('certificate.button') }}</b-button>
      </b-col>
    </b-row>

    <!-- <div class="super-container">
      <div class="inner-border">
        <div class="content">
          <img :src="laurelWreathBase64" width="80mm">
          <div class="header">
            Certificate of Achievement
          </div>
          <p>This certificate is awarded to</p>
          <div class="person">
            {{ name }}
          </div>
          <p>for successfully completing the challenge</p>
          <p class="challenge">AI Adventure: The Secret Society</p>
          <div class="learnings">
            <p>and improving Data Literacy skills in the fields</p>
            <p><i>Data Cleansing</i>, <i>Expert Knowledge</i> and <i>Data Exploration</i>.</p>
          </div>

          <br>

          <div class="signature">
            <span>Anna Lytics</span>
            <hr>
            <p style="margin:0">Head Data Detective</p>
          </div>

          <img :src="annaBase64" width="230mm" class="anna-image">
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import { laurelWreathBase64, annaBase64 } from './images.js'; // Adjust the path if the file is in a different location


export default {
  props: ["challenge", "learnings1", "learnings2", "learnings3"],
  data() {
    return {
      name: "",
      laurelWreathBase64: laurelWreathBase64,
      annaBase64: annaBase64,
    };
  },
  methods: {
    generatePDF() {
      if (this.name == "") {
        alert(this.$t('certificate.emptyNameAlert'));
        return
      }

      // Create the content for the PDF using the user's input and HTML template
      const pdfContent = `
      <html>
          <head>
              <style type='text/css'>
              @import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap');

              .super-container {
                color: rgb(123, 36, 28);
                background-color: white;
                display: table;
                font-family: Georgia, serif;
                font-size: 17px;
                text-align: center;
                border: 7px solid rgb(123, 36, 28);
                width: 297mm;
                height: 210mm;
                position: relative;
                padding: 7px;
              }

              .inner-border {
                border: 3px solid rgb(123, 36, 28);
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .content {
                width: 100%;
              }

              .header {
                font-family: 'Pinyon Script', cursive;
                font-size: 60px;
                margin-bottom: 30px;
              }

              .person {
                color: rgb(192, 57, 43);
                font-size: 44px;
                font-style: italic;
                margin: 20px auto;
                width: 400px;
              }

              .challenge {
                font-size: 24px;
                font-weight: bold;
                color: rgb(192, 57, 43);
              }

              .learnings i {
                color: rgb(192, 57, 43);
                font-size: 18px;
              }

              .signature {
                width: 40%;
                color: rgb(192, 57, 43);
                margin-left: 80px;
              }

              .signature span {
                font-family: 'Bonheur Royale', cursive;
                font-size: 70px;
                color: black;
              }

              .signature hr {
                border: 1px solid;
                margin-top: -25px;
                width: 80%;
              }

              .anna-image {
                position: absolute;
                bottom: 10px;
                right: 80px
              }
              </style>
          </head>
          <body>
            <div class="super-container">
              <div class="inner-border">
                <div class="content">
                  <img src="${laurelWreathBase64}" width="80mm">
                  <div class="header">
                    ${this.$t('certificate.header')}
                  </div>
                  <p>${this.$t('certificate.line1')}</p>
                  <div class="person">
                    ${this.name}
                  </div>
                  <p>${this.$t('certificate.line2')}</p>
                  <p class="challenge">AI Adventure: ${this.challenge}</p>
                  <div class="learnings">
                    <p>${this.$t('certificate.line3')}</p>
                    <p><i>${this.learnings1}</i>, <i>${this.learnings2}</i> ${this.$t('certificate.and')} <i>${this.learnings3}</i>.</p>
                  </div>

                  <br>

                  <div class="signature">
                    <span>Anna Lytics</span>
                    <hr>
                    <p style="margin:0">Head Data Detective</p>
                  </div>

                  <img src="${annaBase64}" width="230mm" class="anna-image">
                </div>
              </div>
            </div>
          </body>
      </html>
      `;

      // Convert the content to PDF using html2pdf.js
      var opt = {
        margin: 0,
        filename: `certificate_ai-adventure_secret-society_${this.name}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
      };
      html2pdf().set(opt).from(pdfContent).save();

      this.name = "";
    },
  },
};
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap');

.super-container {
  color: rgb(123, 36, 28);
  background-color: white;
  display: table;
  font-family: Georgia, serif;
  font-size: 17px;
  text-align: center;
  border: 7px solid rgb(123, 36, 28);
  width: 297mm;
  height: 210mm;
  position: relative;
  padding: 7px;
}

.inner-border {
  border: 3px solid rgb(123, 36, 28);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
}

.header {
  font-family: 'Pinyon Script', cursive;
  font-size: 60px;
  margin-bottom: 30px;
}

.person {
  color: rgb(192, 57, 43);
  font-size: 44px;
  font-style: italic;
  margin: 20px auto;
  width: 400px;
}

.challenge {
  font-size: 24px;
  font-weight: bold;
  color: rgb(192, 57, 43);
}

.learnings i {
  color: rgb(192, 57, 43);
  font-size: 18px;
}

.signature {
  width: 40%;
  color: rgb(192, 57, 43);
  margin-left: 80px;
}

.signature span {
  font-family: 'Bonheur Royale', cursive;
  font-size: 70px;
  color: black;
}

.signature hr {
  border: 1px solid;
  margin-top: -25px;
  width: 80%;
}

.anna-image {
  position: absolute;
  bottom: 10px;
  right: 80px
} */
</style>