<template>
  <div>
    <h2>{{ $t('secretSociety.debrief.debrief') }}</h2>

    <p>{{ $t('secretSociety.debrief.intro') }}</p>

    <b-card-group deck>
      <b-card :title="$t('secretSociety.debrief.dataCleaning.header')" :img-src="path_dataCleaning" img-alt="Image"
        img-top>
        <b-card-text>
          <span v-html="$t('secretSociety.debrief.dataCleaning.intro')"></span>
        </b-card-text>
        <b-button class="secret-society-button" v-b-modal.modal-secret-debrief-1>{{ $t('secretSociety.debrief.button-explore')
        }}</b-button>
      </b-card>

      <b-card :title="$t('secretSociety.debrief.expertKnowledge.header')" :img-src="path_expertKnowledge" img-alt="Image"
        img-top>
        <b-card-text>
          {{ $t('secretSociety.debrief.expertKnowledge.intro') }}
        </b-card-text>
        <b-button class="secret-society-button" v-b-modal.modal-secret-debrief-2>{{
          $t('secretSociety.debrief.button-explore') }}</b-button>
      </b-card>

      <b-card :title="$t('secretSociety.debrief.dataExploration.header')" :img-src="path_dataExploration" img-alt="Image"
        img-top>
        <b-card-text>
          {{ $t('secretSociety.debrief.dataExploration.intro') }}
        </b-card-text>
        <b-button class="secret-society-button" v-b-modal.modal-secret-debrief-3>{{
          $t('secretSociety.debrief.button-explore') }}</b-button>
      </b-card>

    </b-card-group>




    <b-modal id="modal-secret-debrief-1" size="xl" :title="$t('secretSociety.debrief.dataCleaning.header')" ok-only
      :ok-title="$t('secretSociety.debrief.button-ok')">
      <h4>{{ $t('secretSociety.debrief.dataCleaning.q1') }}</h4>
      <p v-html="$t('secretSociety.debrief.dataCleaning.line1')"></p>
      <p v-html="$t('secretSociety.debrief.dataCleaning.line2')"></p>
      <img v-if="$t('secretSociety.debrief.dataCleaning.img1') == 'invalid_data_en.png'"
        src="@/assets/secret_society/debrief/invalid_data_en.png" alt="invalid_data" width="100%">
      <img v-if="$t('secretSociety.debrief.dataCleaning.img1') == 'invalid_data_de.png'"
        src="@/assets/secret_society/debrief/invalid_data_de.png" alt="invalid_data" width="100%">
      <br><br>
      <p v-html="$t('secretSociety.debrief.dataCleaning.line3')"></p>
      <br>
      <h4>{{ $t('secretSociety.debrief.dataCleaning.q2') }}</h4>
      <p v-html="$t('secretSociety.debrief.dataCleaning.line4')"></p>
      <img v-if="$t('secretSociety.debrief.dataCleaning.img2') == 'data_cleaning_steps_en.png'"
        src="@/assets/secret_society/debrief/data_cleaning_steps_en.png" alt="data_cleaning_steps" height="450px">
      <img v-if="$t('secretSociety.debrief.dataCleaning.img2') == 'data_cleaning_steps_de.png'"
        src="@/assets/secret_society/debrief/data_cleaning_steps_de.png" alt="data_cleaning_steps" height="450px">
    </b-modal>

    <b-modal id="modal-secret-debrief-2" size="xl" :title="$t('secretSociety.debrief.expertKnowledge.header')" ok-only
      :ok-title="$t('secretSociety.debrief.button-ok')">
      <h4>{{ $t('secretSociety.debrief.expertKnowledge.intro') }}</h4>
      <p v-html="$t('secretSociety.debrief.expertKnowledge.line1')"></p>
      <p v-html="$t('secretSociety.debrief.expertKnowledge.line2')"></p>
      <img v-if="$t('secretSociety.debrief.expertKnowledge.img1') == 'domain_knowledge_en.png'"
        src="@/assets/secret_society/debrief/domain_knowledge_en.png" alt="domain_knowledge" width="100%">
      <img v-if="$t('secretSociety.debrief.expertKnowledge.img1') == 'domain_knowledge_de.png'"
        src="@/assets/secret_society/debrief/domain_knowledge_de.png" alt="domain_knowledge" width="100%">
      <hr>
      <p v-html="$t('secretSociety.debrief.expertKnowledge.line3')"></p>
    </b-modal>

    <b-modal id="modal-secret-debrief-3" size="xl" :title="$t('secretSociety.debrief.dataExploration.header')" ok-only
      :ok-title="$t('secretSociety.debrief.button-ok')">
      <h4>{{ $t('secretSociety.debrief.dataExploration.q1') }}</h4>
      <p v-html="$t('secretSociety.debrief.dataExploration.line1')"></p>
      <p v-html="$t('secretSociety.debrief.dataExploration.line2')"></p>

      <br>
      <h4>{{ $t('secretSociety.debrief.dataExploration.q2') }}</h4>

      <p v-html="$t('secretSociety.debrief.dataExploration.line3')"></p>
      <img v-if="$t('secretSociety.debrief.dataExploration.img1') == 'exploration_en.png'"
        src="@/assets/secret_society/debrief/exploration_en.png" alt="exploration" width="100%">
      <img v-if="$t('secretSociety.debrief.dataExploration.img1') == 'exploration_de.png'"
        src="@/assets/secret_society/debrief/exploration_de.png" alt="exploration" width="100%">

      <p v-html="$t('secretSociety.debrief.dataExploration.line4')"></p>
    </b-modal>


  </div>
</template>

<script>
export default {
  data() {
    return {
      path_dataCleaning: require("@/assets/secret_society/debrief/cleaning.png"),
      path_expertKnowledge: require("@/assets/secret_society/debrief/expert.jpg"),
      path_dataExploration: require("@/assets/secret_society/debrief/analysis.jpg"),
    }
  },
}
</script>

<style scoped>
.card:hover {
  box-shadow: 0 0 15px grey;
  transform: scale(1.01);
}
</style>