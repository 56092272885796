<template>
  <div>
    <div class="vertical-align-container">
      <b-form-input v-model="min" :type="type" v-on:input="$emit('update:minValue', min)"></b-form-input>
      <span class="text">&#8804; &nbsp;{{ label }} &nbsp; &#8804;</span>
      <b-form-input v-model="max" :type="type" v-on:input="$emit('update:maxValue', max)"></b-form-input>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "label",
    "minValue",
    "maxValue",
    "type",

  ],
  data() {
    return {
      min: this.minValue,
      max: this.maxValue,
    }
  },
  emits: [
  "update:minValue",
  "update:maxValue"
  ],
}
</script>

<style>
.vertical-align-container {
  display: flex;
  align-items: center;
  width: 500px;
}

.text {
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  font-weight: bold;
}
</style>