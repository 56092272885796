<template>
  <div>
    <br><br><br><br><br>
    <h3>{{ $t('TreasureHunt.Chapter3.title') }}</h3>
    <h1>{{ $t('TreasureHunt.Chapter3.luckyAtLast') }}</h1>

    <br>

    <img :src="imgPath_tresure_hunt_ch3_intro" alt="treasure-hunt-ch3_intro" width="85%">

    <br><br>
    <p class="treasure-hunt-highlight" style="white-space: nowrap"><b style="font-family:Papyrus;" v-html="$t('TreasureHunt.Chapter3.sphinxPoem')"></b>
    </p>

    <p><b>{{ $t('TreasureHunt.Chapter3.sphinxDemand') }}</b></p>

    <hr>

    <h4>{{ $t('TreasureHunt.Chapter3.dataInsightsHeader') }}</h4>

    <task-instruction :instruction="$t('TreasureHunt.Chapter3.dataInsightsInstructions')"></task-instruction>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch3-1>{{ $t('TreasureHunt.Chapter3.showSphinxDataButton')
      }}</b-button>
      <b-collapse id="collapse-ch3-1" class="mt-2 collapse-white">
        <p>{{ $t('TreasureHunt.Chapter3.secretDescription') }}</p>
        <div class="secret" v-html="secret"></div>
      </b-collapse>
    </div>

    <hr>

    <h4>{{ $t('TreasureHunt.Chapter3.programmingEnvTitle') }}</h4>

    <task-instruction :instruction="$t('TreasureHunt.Chapter3.programmingEnvInstructions')"></task-instruction>

    <p v-html="$t('TreasureHunt.Chapter3.programmingEnvText')"></p>

    <p v-html="$t('TreasureHunt.Chapter3.codeReorderingDesc')"></p>

    <div>
      <b-button class="treasure-hunt-button" v-b-toggle.collapse-ch3-2>{{ $t('TreasureHunt.Chapter3.programmingEnvButton')
      }}</b-button>
      <b-collapse id="collapse-ch3-2" class="mt-2 collapse-white">
        <code-ordering></code-ordering>
      </b-collapse>
    </div>

    <hr>

    <p><b>{{ $t('TreasureHunt.Chapter3.sphinxDemandQuestion') }}</b></p>

    <check-solution :solution="$t('TreasureHunt.Chapter3.solutionPhrase')"
      @solution-correct="showEpilog"></check-solution>

    <div v-if="!solutionFound">
      <hint-group :hints=hints buttonClass="treasure-hunt-button" backgroundClass="treasure-hunt-background"></hint-group>
    </div>

    <div v-if="solutionFound">
      <p v-html="$t('TreasureHunt.Chapter3.sphinxResponse')"></p>
      <p>{{ $t('TreasureHunt.Chapter3.greatTreasure') }}</p>
      <img alt="chest" v-b-modal.modal-chest :src="chest" width="300" @click="openChest">

      <br><br><br>

      <create-certificate :challenge="$t('certificate.treasureHunt.challenge')"
        :learnings1="$t('certificate.treasureHunt.learnings1')" :learnings2="$t('certificate.treasureHunt.learnings2')"
        :learnings3="$t('certificate.treasureHunt.learnings3')">
      </create-certificate>
    </div>

    <br><br>

  </div>
</template>

<script>
import HintGroup from '@/components/HintGroup.vue';
import CheckSolution from '@/components/CheckSolution.vue';
import CodeOrdering from '@/components/TreasureHunt/ChapterThree/CodeOrdering.vue';
import CreateCertificate from '@/components/AiaCertificate/CreateCertificate.vue';

import { sphinx_secret_en } from "./secret_en.js"
import { sphinx_secret_de } from "./secret_de.js"

export default {
  components: {
    HintGroup,
    CheckSolution,
    CodeOrdering,
    CreateCertificate
  },
  data() {
    return {
      sphinx_secret_en: sphinx_secret_en,
      sphinx_secret_de: sphinx_secret_de,
      solutionFound: false,
      chest: require("@/assets/reusables/chest_closed.png"),
      confetti_in_execution: false,
    }
  },
  computed: {
    imgPath_tresure_hunt_ch3_intro() {
      if (this.$i18n.locale === 'de') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch3-intro_de.png');
      } else if (this.$i18n.locale === 'en') {
        return require('@/assets/treasure_hunt/comics/treasure-hunt-ch3-intro_en.png');
      } else {
        console.warn("No image found for treasure hunt ch3 intro.")
        return null
      }
    },
    secret() {
      if (this.$t('TreasureHunt.Chapter3.secretConstName') == "sphinx_secret_en") {
        return this.sphinx_secret_en
      } else {
        return sphinx_secret_de
      }
    },
    hints() {
      return this.$t("TreasureHunt.Chapter3.hints")
    }
  },
  methods: {
    showEpilog(payload) {
      if (payload) {
        this.solutionFound = true
      }
    },
    openChest() {
      this.chest = require("@/assets/reusables/chest_open.png")
      this.start_confetti()
    },
    start_confetti() {
      if (!this.confetti_in_execution) {
        this.confetti_in_execution = true
        this.$confetti.start();
        setTimeout(() => this.stop_confetti(), 2000)
      }
    },
    stop_confetti() {
      this.$confetti.stop();
      this.confetti_in_execution = false
    },
  },
}
</script>

<style scoped>
.secret {
  width: 200px;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: lightgrey;
  padding: 15px;
  border-radius: 5px
}
</style>