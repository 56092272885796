<template>
  <div>
    <div class="chart-container">
      <Scatter ref="myChart" id="my-chart-id" :options="chartOptions" :data="chartData" />
    </div>

    <b-container fluid>
      <b-row class="my-1" v-for="boundary in boundaries" :key="boundary.key">
        <b-col sm="2">
          <label>{{ boundary.name }}</label>
        </b-col>
        <b-col sm="2">
          <b-form-input v-model="boundary.value" type="number" size="sm"></b-form-input>
        </b-col>
        <b-col sm="8"></b-col>
      </b-row>
    </b-container>

    <b-button @click="updateData">{{ $t('TreasureHunt.Chapter1.updateChartButton') }}</b-button>
    <span style="margin-left:10px"><b-spinner small v-if="showUpdateChartSpinner"></b-spinner></span>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from 'chart.js'
import { Scatter } from 'vue-chartjs'
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

import { treasureData } from "./treasureData.js"

export default {
  name: 'ScatterPlot',
  components: { Scatter },
  data() {
    return {
      chartDataOriginal: {
        datasets: [
          {
            label: 'Clue Data',
            data: treasureData,
            backgroundColor: '#0096FF',
            borderWidth: 1,
            pointRadius: 5,
            pointBackgroundColor: '#0096FF',
          },
        ]
      },
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'x',
            },
          },
          y: {
            title: {
              display: true,
              text: 'y',
            },
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
      },
      boundaries: {
        x_min: { value: -100000, name: "X lower limit" },
        x_max: { value: 100000, name: "X upper limit" },
        y_min: { value: -100000, name: "Y lower limit" },
        y_max: { value: 100000, name: "Y upper limit" },
      },
      showUpdateChartSpinner: false,
    }
  },
  methods: {
    updateData() {
      this.showUpdateChartSpinner = true;

      let newChartData = JSON.parse(JSON.stringify(this.chartDataOriginal));

      newChartData.datasets[0].data = newChartData.datasets[0].data.filter(value => {
        return value.x >= this.boundaries.x_min.value &&
          value.x <= this.boundaries.x_max.value &&
          value.y >= this.boundaries.y_min.value &&
          value.y <= this.boundaries.y_max.value;
      });

      this.chartData = newChartData;
      
      setTimeout(() => this.showUpdateChartSpinner = false, 1000);
    }
  },
  beforeMount() {
    this.updateData();
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  background-color: white;
  height: 350px;
  border-radius: 5px;
  padding: 10px
}
</style>