import Vue from 'vue';

export const passwordStore = Vue.observable({
  treasureHuntPassword: false,
  pirateBayPassword: false,
});

export function setTreasureHuntPasswordTo(treasureHuntPassword) {
  passwordStore.treasureHuntPassword = treasureHuntPassword;
}
export function setPirateBayPasswordTo(pirateBayPassword) {
  passwordStore.pirateBayPassword = pirateBayPassword;
}