<template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group :label="solutionLabel" label-for="input-solution">
        <b-form-input id="input-solution" v-model="entry" :placeholder="$t('SolutionCode.yourAnswerPlaceholder')"
          required></b-form-input>
      </b-form-group>

      <b-button type="submit" style="background-color:Olive; border:none">{{ $t('SolutionCode.submitButton') }}</b-button>
    </b-form>

    <br>
    <b-alert v-if="solution_is_wrong" show variant="warning">{{ $t('SolutionCode.wrongSolutionAlert') }}</b-alert>
    <b-alert v-if="solution_is_correct" show variant="success">{{ $t('SolutionCode.correctSolutionAlert') }}</b-alert>
  </div>
</template>

<script>
export default {
  props: {
    solution: String,
  },
  data() {
    return {
      entry: "",
      solution_is_correct: false,
      solution_is_wrong: false,
    }
  },
  computed: {
    solutionLabel() {
      return this.$t("SolutionCode.solutionLabel");
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      if (this.entry.toLowerCase() == this.solution.toLowerCase()) {
        this.solution_is_correct = true;
        this.solution_is_wrong = false;

        this.$emit("solution-correct", true);
      } else {
        this.solution_is_correct = false;
        this.solution_is_wrong = true;
      }
    },
  }
}
</script>
