<template>
  <div>
    <div>
      <canvas ref="canvas" :width="canvasWidth" style="border: 1px solid black; border-radius: 5px;"
        @mousedown="startDrawing" @mousemove="draw" @mouseup="stopDrawing"></canvas>
    </div>

    <div class="color-palette">
      <div v-for="paletteColor in paletteColors" :key="paletteColor"
        :class="{ 'color-block': true, 'active': paletteColor === color }" :style="{ backgroundColor: paletteColor }"
        @click="changeColor(paletteColor)"></div>
    </div>


    <div style="margin-top: 5px;">
      <b-button class="secret-society-button" @click="clearCanvas">{{ $t('secretSociety.clearDrawing') }}</b-button>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      canvasWidth: 500,
      size: "8",
      color: "Tomato",
      isDrawing: false,
      paletteColors: ["Tomato", "DodgerBlue", "LimeGreen", "Gold", "MediumOrchid", "DimGray"],
    };
  },
  methods: {
    updateCanvasWidth() {
      const parentWidth = this.$refs.canvas.parentNode.offsetWidth;
      this.canvasWidth = parentWidth;
      this.uploadMap();
    },
    clearCanvas() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.uploadMap()
    },
    uploadMap() {
      const img = new Image();
      img.onload = () => {
        this.drawOnImage(img);
      };
      img.src = require("@/assets/secret_society/Europe_capitals_map_de.png");
    },
    async uploadImage(e) {
      const file = e.target.files[0];
      const imageUrl = await this.fileToDataUri(file);
      const image = new Image();
      image.src = imageUrl;

      image.addEventListener("load", () => {
        this.drawOnImage(image);
      });
    },
    fileToDataUri(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          resolve(reader.result);
        });

        reader.readAsDataURL(file);
      });
    },
    drawOnImage(image = null) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      if (image) {
        let imageWidth = image.width;
        let imageHeight = image.height;
        let imageRatio = imageHeight / imageWidth

        if (imageWidth > canvas.width) {
          imageWidth = canvas.width;
          imageHeight = imageRatio * imageWidth;
        }

        canvas.width = imageWidth;
        canvas.height = imageHeight;

        context.drawImage(image, 0, 0, imageWidth, imageHeight);
      }
    },
    startDrawing(e) {
      this.isDrawing = true;
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const context = canvas.getContext("2d");
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      context.beginPath();
      context.lineWidth = this.size;
      context.strokeStyle = this.color;
      context.lineJoin = "round";
      context.lineCap = "round";
      context.moveTo(x, y);
    },
    draw(e) {
      if (!this.isDrawing) return;
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const context = canvas.getContext("2d");
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      context.lineTo(x, y);
      context.stroke();
    },
    stopDrawing() {
      this.isDrawing = false;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.closePath();
    },
    changeColor(newColor) {
      this.color = newColor;
    },
  },
  mounted() {
    this.updateCanvasWidth(); // Set initial canvas width

    // Add event listener for window resize
    window.addEventListener("resize", this.updateCanvasWidth);

    this.uploadMap()
  },
  beforeUnmount() {
    // Clean up event listener on component unmount
    window.removeEventListener("resize", this.updateCanvasWidth);
  },
};
</script>

<style scoped>
.color-palette {
  display: flex;
  justify-content: left;
}

.color-block {
  width: 30px;
  height: 30px;
  margin: 5px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.active {
  border: 2px solid #fff;
  box-shadow: 0 0 15px grey;
  transform: scale(1.01);
}
</style>
