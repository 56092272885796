<!-- <template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group :label="solutionLabel" label-for="input-solution">
        <b-form-input id="input-solution" v-model="entry" :placeholder="$t('SolutionCode.yourAnswerPlaceholder')"
          required></b-form-input>
      </b-form-group>

      <b-button type="submit" style="background-color:Olive; border:none">{{ $t('SolutionCode.submitButton') }}</b-button>
    </b-form>

    <br> -->
  <template>
    <div>
      <b-form @submit="onSubmit">
      <b-form-group :label="solutionLabel" label-for="input-solution">
        <b-form-input id="input-solution" v-model="entry" :placeholder="$t('SolutionCode.yourAnswerPlaceholder')"
          required></b-form-input>
      </b-form-group>
  
        <span><b-button type="submit" style="background-color:Olive; border:none">{{ $t('SolutionCode.submitButton')
        }}</b-button>&nbsp;&nbsp;<b-spinner small variant="secondary" v-if="showSpinner"></b-spinner></span>
      </b-form>
  
      <br>

    <div v-if="show_first_riddle_result">
      <span>Did you answer the first riddle correctly?&nbsp;</span>
      <b-icon v-if="!first_riddle_result" icon="x-circle-fill" variant="danger"></b-icon>
      <b-icon v-if="first_riddle_result" icon="check-circle-fill" variant="success"></b-icon>
    </div>

    <div v-if="show_second_riddle_result">
      <span>Did you answer the second riddle correctly?&nbsp;</span>
      <b-icon v-if="!second_riddle_result" icon="x-circle-fill" variant="danger"></b-icon>
      <b-icon v-if="second_riddle_result" icon="check-circle-fill" variant="success"></b-icon>
    </div>

    <div v-if="show_third_riddle_result">
      <span>Did you answer the third riddle correctly?&nbsp;</span>
      <b-icon v-if="!third_riddle_result" icon="x-circle-fill" variant="danger"></b-icon>
      <b-icon v-if="third_riddle_result" icon="check-circle-fill" variant="success"></b-icon>
    </div>

    <br>

    <br>
    <b-alert v-if="solution_is_wrong" show variant="warning">{{ $t('SolutionCode.wrongSolutionAlert') }}</b-alert>
    <b-alert v-if="solution_is_correct" show variant="success">{{ $t('SolutionCode.correctSolutionAlert') }}</b-alert>
  </div>
</template>

<script>
export default {
  props: {
    solution: String,
  },
  data() {
    return {
      entry: "",
      solutionWords: [],
      solution_is_correct: false,
      solution_is_wrong: false,
      first_riddle_result: false,
      second_riddle_result: false,
      third_riddle_result: false,
      show_first_riddle_result: false, 
      show_second_riddle_result: false, 
      show_third_riddle_result: false,
      showSpinner: false
    }
  },
  computed: {
    solutionLabel() {
      return this.$t("SolutionCode.solutionLabel");
    }
  },
  methods: {

    extractRiddleSolutions(){
      const words = this.entry.split('-');
      // Ensure there are three words
      if (words.length === 3) {
        this.solutionWords = words;
      } else {
        // Handle the case where the input doesn't have three words
        alert('Please enter a valid input with three words separated by hyphens.');
      }
      return this.solutionWords
    },
    onSubmit(event) {
      // Show spinner
      this.showSpinner = true;

      event.preventDefault()

      // Reset displays
      this.show_first_riddle_result = false
      this.show_second_riddle_result = false
      this.show_third_riddle_result = false

      this.solutionWords = this.extractRiddleSolutions()

      console.log(this.solutionWords)

      // Check if riddle results are correct
      this.first_riddle_result = this.solutionWords[0] == this.$t("PirateBay.Chapter4.riddle1.solutionWord") // 'lion2'
      this.second_riddle_result = this.solutionWords[1] == this.$t("PirateBay.Chapter4.riddle2.solutionWord") // '4'
      this.third_riddle_result = this.solutionWords[2] == this.$t("PirateBay.Chapter4.riddle3.solutionWord") // 'chess'
      console.log("first_riddle_result:", this.solutionWords[0])
      console.log("second_riddle_result:", this.solutionWords[1])
      console.log("third_riddle_result:", this.solutionWords[2])

      // Display results
      this.show_first_riddle_result = true
      this.show_second_riddle_result = true
      this.show_third_riddle_result = true

      if (this.first_riddle_result && (this.second_riddle_result) && (this.third_riddle_result)) {
        this.solution_is_correct = true;
        this.solution_is_wrong = false;

        this.$emit("solution-correct", true);
      } else {
        this.solution_is_correct = false;
        this.solution_is_wrong = true;
      }

        // Hide spinner
      this.showSpinner = false;
    }
  }
}
</script>
